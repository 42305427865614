import React, { useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { TriggerPromotionSettingsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Banner,
  BlockStack,
  Box,
  Card,
  ChoiceList,
  Icon,
  InlineStack,
  Link,
  List,
  Text,
  TextField,
} from '@shopify/polaris';
import { BoxWithSubduedHeader, CopyIcon } from 'core/components';
import {
  LinkIcon,
  ChevronRightIcon,
  ClipboardIcon,
} from '@shopify/polaris-icons';
import { PromotionCodeMultipleModeEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useAppSelector } from 'core/hooks';
type SharePromotionSectionProps = {
  trigger?: TriggerPromotionSettingsDto;
  updateSettingsState: (data: TriggerPromotionSettingsDto) => void;
  navigateThrough: (path?: string) => void;
};

export const SharePromotionSection: React.FC<SharePromotionSectionProps> = ({
  trigger,
  updateSettingsState,
  navigateThrough,
}) => {
  const [i18n] = useI18n();
  const { showValidation } = useAppSelector((store) => store.promotions);
  const codesModeOptions = Object.values(PromotionCodeMultipleModeEnum).map(
    (value) => ({
      label: i18n.translate(value),
      value,
      id: `${value}_CodesRadioButton`,
    })
  );
  const codeCount = useMemo(() => {
    const totalInPromotion = trigger?.promotionCodesSummary?.totalInPromotion;
    switch (trigger?.promotionCodeMultipleMode) {
      case PromotionCodeMultipleModeEnum.MULTIPLE:
        return `${i18n.translate(
          totalInPromotion === 1 ? 'CodeCount' : 'CodesCount',
          {
            count: totalInPromotion,
          }
        )}`;
      default:
        return '';
    }
  }, [
    trigger?.promotionCodesSummary?.totalInPromotion,
    trigger?.promotionCodeMultipleMode,
  ]);

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' fontWeight='semibold'>
            {i18n.translate('SharePromotion')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('SharePromotionSubtitle', {
              followingArticle: (
                <Link>{i18n.translate('followingArticle')}</Link>
              ),
            })}
          </Text>
        </BlockStack>
        <BoxWithSubduedHeader
          header={{
            title: i18n.translate('DiscountLinks'),
            icon: LinkIcon,
            tag: `${i18n.translate(
              trigger?.discountLinksCount === 1 ? 'LinkCount' : 'LinksCount',
              {
                count: trigger?.discountLinksCount,
              }
            )}`,
            button: (
              <Link
                removeUnderline
                onClick={() => navigateThrough('discount-links')}
              >
                <InlineStack gap='050' blockAlign='center'>
                  {i18n.translate('ManageLinks')}
                  <Icon source={ChevronRightIcon}></Icon>
                </InlineStack>
              </Link>
            ),
          }}
        >
          <TextField
            label={i18n.translate('DefaultLink')}
            value={trigger?.defaultLink?.display}
            autoComplete=''
            readOnly
            connectedRight={
              <Box paddingInlineStart='100'>
                <CopyIcon
                  icon={ClipboardIcon}
                  variant='tertiary'
                  copyText={trigger?.defaultLink?.copy}
                  tooltip={i18n.translate('CopyLink')}
                />
              </Box>
            }
          />
        </BoxWithSubduedHeader>
        <BoxWithSubduedHeader
          header={{
            title: i18n.translate('PromoCodes'),
            icon: LinkIcon,
            tag: codeCount,
          }}
        >
          <BlockStack gap='400'>
            <ChoiceList
              title=''
              choices={codesModeOptions}
              selected={[trigger?.promotionCodeMultipleMode || '']}
              onChange={(value) =>
                updateSettingsState({
                  ...trigger,
                  promotionCodeMultipleMode:
                    value[0] as PromotionCodeMultipleModeEnum,
                })
              }
            />
            {trigger?.promotionCodeMultipleMode ===
              PromotionCodeMultipleModeEnum.SINGLE && (
              <BlockStack gap='400'>
                <TextField
                  label={i18n.translate('PromoCode')}
                  placeholder={i18n.translate('CreateCode')}
                  id='SinglePromotionCodeInput'
                  value={trigger?.singlePromotionCode}
                  autoComplete=''
                  error={
                    !trigger?.singlePromotionCode &&
                    showValidation &&
                    i18n.translate('ThisCodeRequired')
                  }
                  onChange={(value) =>
                    updateSettingsState({
                      ...trigger,
                      singlePromotionCode: value,
                    })
                  }
                  connectedRight={
                    <Box paddingInlineStart='100'>
                      <CopyIcon
                        icon={ClipboardIcon}
                        variant='tertiary'
                        copyText={trigger?.singlePromotionCode}
                        tooltip={i18n.translate('CopyCode')}
                      />
                    </Box>
                  }
                />
                <Banner title={i18n.translate('BestPractices')}>
                  <List type='bullet'>
                    <List.Item>{i18n.translate('Practice1')}</List.Item>
                    <List.Item>{i18n.translate('Practice2')}</List.Item>
                    <List.Item>{i18n.translate('Practice3')}</List.Item>
                  </List>
                </Banner>
              </BlockStack>
            )}
            {trigger?.promotionCodeMultipleMode ===
              PromotionCodeMultipleModeEnum.MULTIPLE && (
              <Box
                background='bg-surface-secondary'
                borderRadius='300'
                borderColor='border'
                borderWidth='025'
                padding='300'
              >
                <InlineStack align='space-between' blockAlign='start'>
                  <BlockStack gap='100'>
                    <Text as='p' fontWeight='medium'>
                      {trigger.promotionCodesSummary?.totalInPromotion
                        ? i18n.translate('CodesInThisPromotion', {
                            codes:
                              trigger.promotionCodesSummary.totalInPromotion,
                          })
                        : i18n.translate('NoCodesAssociated')}
                    </Text>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('PromotionCodesSummary', {
                        available:
                          trigger.promotionCodesSummary?.globalTotalRemaining,
                        limit: trigger.promotionCodesSummary?.globalTotalLimit,
                      })}
                    </Text>
                  </BlockStack>
                  <Link
                    removeUnderline
                    onClick={() => navigateThrough('promotion-codes')}
                  >
                    <InlineStack gap='050' blockAlign='center'>
                      {i18n.translate(
                        trigger.promotionCodesSummary?.totalInPromotion
                          ? 'ManagePromoCodes'
                          : 'AddPromocodes'
                      )}
                      <Icon source={ChevronRightIcon}></Icon>
                    </InlineStack>
                  </Link>
                </InlineStack>
              </Box>
            )}
          </BlockStack>
        </BoxWithSubduedHeader>
      </BlockStack>
    </Card>
  );
};
