import React, { useCallback } from 'react';
import {
  Background,
  CornerRadius,
  ShopSettingsWrapper,
  Stacking,
} from '../../../../../components';
import {
  ProductBannerPresetGeneralDto,
  ProductBannerSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useAppDispatch } from 'core/hooks';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type ProductBannerGeneralProps = {
  general: ProductBannerPresetGeneralDto;
  defaultGeneral: ProductBannerPresetGeneralDto;
  shopDefaultGeneral: ProductBannerSystemDefaultsDto;
  isPresetEditor?: boolean;
  setGeneral: (data: ProductBannerPresetGeneralDto) => void;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const ProductBannerGeneral: React.FC<ProductBannerGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  isPresetEditor,
  setGeneral,
  onShowHide,
  onRemove,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();
  const resetAllToSystemDefault = useCallback(() => {
    const { background, cornerRadius } = shopDefaultGeneral;
    setGeneral({
      ...general,
      ...(!isPresetEditor ? {} : { background, cornerRadius }),
    });
  }, [shopDefaultGeneral]);

  return (
    <div className='ProductBannerGeneral'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onRemove={onRemove}
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListTypeDtoEnum.PRODUCT_BANNER}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {isPresetEditor && (
        <>
          <div className='RightSideSection'>
            <Background
              onSystemReset={() =>
                setGeneral({
                  ...general,
                  background: shopDefaultGeneral.background,
                })
              }
              background={general.background || {}}
              setBackground={(data) =>
                setGeneral({ ...general, background: data })
              }
            />
          </div>
          <div className='RightSideSection'>
            <CornerRadius
              onSystemReset={() =>
                setGeneral({
                  ...general,
                  cornerRadius: shopDefaultGeneral.cornerRadius,
                })
              }
              cornerRadius={general.cornerRadius || {}}
              setCornerRadius={(data) =>
                setGeneral({ ...general, cornerRadius: data })
              }
            />
          </div>
        </>
      )}
      <ShopSettingsWrapper
        onOpen={() =>
          dispatch(
            setToggleSettingsConfig({
              action: 'open',
              widgetType: WidgetTypeDtoEnum.PRODUCT_BANNER,
            })
          )
        }
      >
        <div className='RightSideSection'>
          <Stacking
            stacking={general.shopSettings?.stacking || {}}
            allowDirection={false}
            maxCustomSpacing={20}
            disabled
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default ProductBannerGeneral;
