import React, { forwardRef, useEffect } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import { StyleSetting } from '../../components';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';
import { Banner, BlockStack, Card, Text } from '@shopify/polaris';
import { useWidget } from 'features/widgets/hooks/useWidget';

type EmptyWidgetSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  widgetType: WidgetTypeDtoEnum;
};

export const EmptyWidgetSettings = forwardRef<
  WidgetSavebarRef,
  EmptyWidgetSettingsProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ setIsLoading, widgetType }, ref) => {
  const [i18n] = useI18n();

  const isNinjaCart = widgetType.includes('NINJA_CART');

  const ninjaCartData = useNinjaCartWidget(
    isNinjaCart ? widgetType : undefined
  );
  const widgetData = useWidget(!isNinjaCart ? widgetType : undefined);

  const {
    emptyWidgetSettingsData,
    emptyWidgetSettingsDataIsFetching,
    // refetchEmptyWidgetSettingsData,
  } = isNinjaCart ? ninjaCartData : widgetData;

  useEffect(() => {
    setIsLoading(emptyWidgetSettingsDataIsFetching);
  }, [emptyWidgetSettingsDataIsFetching]);

  return !emptyWidgetSettingsDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate(`${widgetType}_CardDataTitle`),
            description: i18n.translate(`${widgetType}_CardDataDescription`),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!emptyWidgetSettingsData?.isShopLevelWidget} />
      <Card>
        <BlockStack gap='400'>
          <Text as='p' fontWeight='bold'>
            {i18n.translate('Settings')}
          </Text>
          <Banner>{i18n.translate('WidgetDoesntHaveSettings')}</Banner>
        </BlockStack>
      </Card>
    </>
  ) : null;
});

EmptyWidgetSettings.displayName = 'EmptyWidgetSettings';
