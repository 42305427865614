import React from 'react';
import {
  Image,
  CornerRadius,
  WidgetOptionHeader,
} from '../../../../../../../components';
import { NinjaCartEmptyStateImagePresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type Preset_NDC_EmptyImageProps = {
  image: NinjaCartEmptyStateImagePresetDto;
  defaultImage: NinjaCartEmptyStateImagePresetDto;
  shopDefaultImage: NinjaCartEmptyStateImagePresetDto;
  setImage: (data: NinjaCartEmptyStateImagePresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const Preset_NDC_EmptyImage: React.FC<Preset_NDC_EmptyImageProps> = ({
  image,
  defaultImage,
  shopDefaultImage,
  setImage,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  return (
    <div>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setImage(defaultImage)}
        onResetToSystem={() => setImage(shopDefaultImage)}
        name={WidgetListOptionsDtoEnum.IMAGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Image
          groupKeys={[ResourceGroupKeyDtoEnum.OFFER]}
          showImage
          onSystemReset={() =>
            setImage({ ...image, image: shopDefaultImage.image })
          }
          image={image.image || {}}
          setImage={(data) => setImage({ ...image, image: data })}
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          onSystemReset={() =>
            setImage({ ...image, cornerRadius: shopDefaultImage.cornerRadius })
          }
          cornerRadius={image.cornerRadius || {}}
          setCornerRadius={(data) => setImage({ ...image, cornerRadius: data })}
        />
      </div>
    </div>
  );
};

export default Preset_NDC_EmptyImage;
