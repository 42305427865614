import React, { useCallback } from 'react';
import { BorderStyle, Image, CornerRadius } from '../../../../../components';
import { NotificationExpandedStateImagePresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { isEqual } from 'lodash';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type NotificationImageProps = {
  image: NotificationExpandedStateImagePresetDto;
  defaultImage: NotificationExpandedStateImagePresetDto;
  shopDefaultImage: NotificationExpandedStateImagePresetDto;
  presetSetup?: NotificationExpandedStateImagePresetDto;
  isPresetEditor?: boolean;
  setImage: (data: NotificationExpandedStateImagePresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const NotificationImage: React.FC<NotificationImageProps> = ({
  image,
  defaultImage,
  shopDefaultImage,
  presetSetup,
  isPresetEditor,
  setImage,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const updateImageState = useCallback(
    (
      field: keyof NotificationExpandedStateImagePresetDto,
      data: NotificationExpandedStateImagePresetDto[keyof NotificationExpandedStateImagePresetDto]
    ) => {
      setImage({
        ...image,
        ...(!isPresetEditor && {
          ...presetSetup,
          image: { ...presetSetup?.image, imageId: image.image?.imageId },
        }),
        [field]: data,
      });
    },
    [setImage, image]
  );

  const resetAllToSystemDefault = useCallback(() => {
    setImage({
      ...image,
      border: shopDefaultImage.border,
      cornerRadius: shopDefaultImage.cornerRadius,
      image: {
        fit: shopDefaultImage.image?.fit,
        size: shopDefaultImage.image?.size,
        imageId: !isPresetEditor ? shopDefaultImage.image?.imageId : undefined,
      },
    });
  }, [isPresetEditor, shopDefaultImage, image]);

  return (
    <div className='NotificationImage'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setImage(defaultImage)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.IMAGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Image
          groupKeys={[ResourceGroupKeyDtoEnum.OFFER]}
          image={
            !isPresetEditor
              ? { ...presetSetup?.image, imageId: image.image?.imageId }
              : image.image || {}
          }
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.image?.fit, defaultImage.image?.fit)
              : undefined
          }
          disableSize
          promotionsNote={isPresetEditor}
          showImage={!isPresetEditor}
          setImage={(value) => updateImageState('image', value)}
          onSystemReset={() =>
            updateImageState('image', shopDefaultImage.image)
          }
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          onSystemReset={() =>
            updateImageState('cornerRadius', shopDefaultImage.cornerRadius)
          }
          cornerRadius={
            (!isPresetEditor
              ? presetSetup?.cornerRadius
              : image.cornerRadius) || {}
          }
          setCornerRadius={(value) => updateImageState('cornerRadius', value)}
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.cornerRadius, defaultImage.cornerRadius)
              : undefined
          }
        />
      </div>
      <div className='RightSideSection'>
        <BorderStyle
          border={(!isPresetEditor ? presetSetup?.border : image.border) || {}}
          disableStyle
          disableSides
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.border, defaultImage.border)
              : undefined
          }
          setBorder={(value) => updateImageState('border', value)}
          onSystemReset={() =>
            updateImageState('border', shopDefaultImage.border)
          }
        />
      </div>
    </div>
  );
};

export default NotificationImage;
