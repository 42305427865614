import {
  useGetPromotionsV6LookupOfferAiNamingLocalesQuery,
  usePutPromotionsV6PromotionOffersNamesByIdGenerateMutation,
  usePutPromotionsV6PromotionOffersNamesSwitchAiUsageMutation,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { setOfferAiNamingLocales } from 'core/store/offersWizardSlice';
import resolveEnvVar from 'env-var-resolver';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
export const useOfferNameConfiguration = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const { offerAiNamingLocales } = useAppSelector(
    (state) => state.offersWizard
  );

  const { data: offerAiNamingLocalesData } =
    useGetPromotionsV6LookupOfferAiNamingLocalesQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: !!offerAiNamingLocales }
    );

  const [
    offersNamesSwitchAiUsageMutation,
    {
      isLoading: offersNamesSwitchAiUsageIsLoading,
      data: offersNamesSwitchAiUsageData,
    },
  ] = usePutPromotionsV6PromotionOffersNamesSwitchAiUsageMutation();

  const offersNamesSwitchAiUsage = useCallback(
    async (isUsingAi: boolean, locale: string) => {
      await offersNamesSwitchAiUsageMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        switchOfferNamesAiUsageRequestDto: {
          isUsingAi,
          locale,
          offerId: params.id || '',
        },
      }).unwrap();
    },
    [params.id, offersNamesSwitchAiUsageMutation]
  );

  const [
    generateOfferNameMutation,
    { isLoading: generateOfferNameIsLoading, data: generateOfferNameData },
  ] = usePutPromotionsV6PromotionOffersNamesByIdGenerateMutation();

  const generateOfferName = useCallback(
    async (locale: string) => {
      await generateOfferNameMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.id || '',
        generateOfferNameRequestDto: {
          locale,
        },
      }).unwrap();
    },
    [params.id, generateOfferNameMutation]
  );

  useEffect(() => {
    offerAiNamingLocalesData &&
      dispatch(setOfferAiNamingLocales(offerAiNamingLocalesData));
  }, [offerAiNamingLocalesData]);

  return {
    generateOfferNameIsLoading,
    offersNamesSwitchAiUsageIsLoading,
    offersNamesSwitchAiUsageData,
    generateOfferNameData,
    offersNamesSwitchAiUsage,
    generateOfferName,
  };
};
