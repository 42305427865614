import React, { useMemo } from 'react';
import {
  BlockStack,
  Button,
  ButtonGroup,
  InlineStack,
  List,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionScheduleDto,
  PromotionStatusDto,
  RecurringScheduleDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  PromotionStatusDtoEnum,
  TriggerScheduleTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { EditIcon, CalendarTimeIcon, DeleteIcon } from '@shopify/polaris-icons';
import moment from 'moment';
import { getRecurringIntervalSummary } from '../../../SchedulePromotionModal/components/RecurringSchedule/utils/utils';
import './DefaultStatus.scss';
import classNames from 'classnames';

type DefaultStatusProps = {
  promotionStatus: PromotionStatusDto;
  schedule: PromotionScheduleDto;
  scheduleIsLoading: boolean;
  isPromotionEditable: boolean;
  toggleScheduleModal: () => void;
  onRemoveSchedule: () => void;
};
const DefaultStatus: React.FC<DefaultStatusProps> = ({
  promotionStatus,
  schedule,
  scheduleIsLoading,
  isPromotionEditable,
  toggleScheduleModal,
  onRemoveSchedule,
}) => {
  const [i18n] = useI18n();

  const selectedRepeat = useMemo(
    () =>
      Object.entries(schedule.recurringSchedule || {})
        .filter(([_, value]) => !!value)
        .map(([key]) => key as keyof RecurringScheduleDto)[0],
    [schedule.recurringSchedule]
  );

  const recurringIntervalSummary = getRecurringIntervalSummary(
    i18n,
    selectedRepeat,
    schedule,
    false
  );

  const statusTitle = useMemo(() => {
    switch (promotionStatus) {
      case PromotionStatusDtoEnum.SCHEDULED:
        return i18n.translate('PromotionWillRun');
      case PromotionStatusDtoEnum.DRAFT:
        return i18n.translate('WhenScheduledPromotionRun');
      case PromotionStatusDtoEnum.PUBLISHED:
      case PromotionStatusDtoEnum.PUBLISHED_EDITED:
        return i18n.translate('PromotionIsActive');
      default:
        return i18n.translate('PromotionWasActive');
    }
  }, [i18n, promotionStatus]);

  return (
    <div className='DefaultStatus'>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='center'>
          <Text fontWeight='semibold' as='p'>
            {i18n.translate('Schedule')}
          </Text>
          {isPromotionEditable && (
            <div style={{ maxHeight: 20 }}>
              <ButtonGroup>
                <Button
                  onClick={toggleScheduleModal}
                  icon={schedule.startsAt ? EditIcon : CalendarTimeIcon}
                  variant='tertiary'
                />
                {!!schedule.startsAt && (
                  <Button
                    onClick={onRemoveSchedule}
                    icon={DeleteIcon}
                    disabled={scheduleIsLoading}
                    variant='tertiary'
                  />
                )}
              </ButtonGroup>
            </div>
          )}
        </InlineStack>
        {!schedule.startsAt && (
          <Text as='p' tone='subdued'>
            {i18n.translate('NoScheduleConfigured')}
          </Text>
        )}
        {!!schedule.startsAt && (
          <div
            className={classNames({ ScheduleSummaryList: isPromotionEditable })}
            onClick={isPromotionEditable ? toggleScheduleModal : undefined}
          >
            <BlockStack gap='100'>
              <Text as='p' fontWeight='medium'>
                {statusTitle}
              </Text>
              {schedule.type === TriggerScheduleTypeDtoEnum.SIMPLE && (
                <List>
                  <List.Item>
                    {i18n.translate(
                      schedule.endsAt ? 'FromDate' : 'FromDateIndefinitely',
                      {
                        date: moment(schedule.startsAt).format(
                          'MMMM Do, YYYY h:mm A'
                        ),
                      }
                    )}
                  </List.Item>
                  {schedule.endsAt && (
                    <List.Item>
                      {i18n.translate('ToDate', {
                        date: moment(schedule.endsAt).format(
                          'MMMM Do, YYYY h:mm A'
                        ),
                      })}
                    </List.Item>
                  )}
                </List>
              )}
              {schedule.type === TriggerScheduleTypeDtoEnum.RECURRING && (
                <List>
                  <List.Item>
                    {i18n.translate('FromDate', {
                      date: moment(schedule.startsAt).format('MMMM Do, YYYY'),
                    })}
                  </List.Item>
                  {schedule.endsAt && (
                    <List.Item>
                      {i18n.translate('ToDate', {
                        date: moment(schedule.endsAt).format('MMMM Do, YYYY'),
                      })}
                    </List.Item>
                  )}
                  {recurringIntervalSummary && (
                    <List.Item>{recurringIntervalSummary}</List.Item>
                  )}
                  <List.Item>
                    {i18n.translate('RecurringTimeSummary', {
                      from: moment(schedule.startsAt).format('h:mm A'),
                      to: moment(schedule.endsAt).format('hh:mm A'),
                      eachDay: i18n.translate('EachDay'),
                    })}
                  </List.Item>
                </List>
              )}
            </BlockStack>
          </div>
        )}
      </BlockStack>
    </div>
  );
};

export default DefaultStatus;
