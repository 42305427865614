import React, { useCallback } from 'react';
import { BorderStyle, Image, CornerRadius } from '../../../../../components';
import {
  ProductBannerImagePresetDto,
  ProductBannerImageSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { isEqual } from 'lodash';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type ProductBannerImageProps = {
  image: ProductBannerImagePresetDto;
  defaultImage: ProductBannerImagePresetDto;
  shopDefaultImage: ProductBannerImageSystemDefaultsDto;
  isPresetEditor?: boolean;
  presetSetup?: ProductBannerImagePresetDto;
  setImage: (data: ProductBannerImagePresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const ProductBannerImage: React.FC<ProductBannerImageProps> = ({
  image,
  defaultImage,
  shopDefaultImage,
  isPresetEditor,
  presetSetup,
  setImage,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const updateImageState = useCallback(
    (
      field: keyof ProductBannerImagePresetDto,
      data: ProductBannerImagePresetDto[keyof ProductBannerImagePresetDto]
    ) => {
      setImage({
        ...image,
        ...(!isPresetEditor && {
          ...presetSetup,
          image: { ...presetSetup?.image, imageId: image.image?.imageId },
        }),
        [field]: data,
      });
    },
    [setImage, image]
  );

  const resetAllToSystemDefault = useCallback(() => {
    setImage({
      ...image,
      border: shopDefaultImage.border,
      cornerRadius: shopDefaultImage.cornerRadius,
      image: {
        fit: shopDefaultImage.image?.fit,
        size: shopDefaultImage.image?.size,
        imageId: !isPresetEditor ? shopDefaultImage.image?.imageId : undefined,
      },
    });
  }, [isPresetEditor, shopDefaultImage, image]);

  return (
    <div className='ProductBannerImage'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setImage(defaultImage)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.IMAGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Image
          groupKeys={[ResourceGroupKeyDtoEnum.OFFER]}
          offersNote={isPresetEditor}
          onSystemReset={() =>
            updateImageState('image', {
              fit: shopDefaultImage.image?.fit,
              size: shopDefaultImage.image?.size,
              imageId: !isPresetEditor
                ? shopDefaultImage.image?.imageId
                : undefined,
            })
          }
          showImage={!isPresetEditor}
          image={
            !isPresetEditor
              ? { ...presetSetup?.image, imageId: image.image?.imageId }
              : image.image || {}
          }
          setImage={(value) => updateImageState('image', value)}
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.image, defaultImage.image)
              : undefined
          }
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          onSystemReset={() =>
            updateImageState('cornerRadius', shopDefaultImage.cornerRadius)
          }
          cornerRadius={
            (!isPresetEditor
              ? presetSetup?.cornerRadius
              : image.cornerRadius) || {}
          }
          setCornerRadius={(value) => updateImageState('cornerRadius', value)}
          inherited={
            !isPresetEditor &&
            isEqual(presetSetup?.cornerRadius, defaultImage.cornerRadius)
          }
        />
      </div>
      <div className='RightSideSection'>
        <BorderStyle
          border={(!isPresetEditor ? presetSetup?.border : image.border) || {}}
          disableStyle
          disableSides
          setBorder={(value) => updateImageState('border', value)}
          onSystemReset={() =>
            updateImageState('border', shopDefaultImage.border)
          }
          inherited={
            !isPresetEditor
              ? isEqual(presetSetup?.border, defaultImage.border)
              : undefined
          }
        />
      </div>
    </div>
  );
};

export default ProductBannerImage;
