import React, { useCallback } from 'react';
import {
  BlockStack,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
} from '@shopify/polaris';

export const WidgetWrapperSkeleton = () => {
  const itemsArray = useCallback(
    (number: number) => Array.from({ length: number }, (_, index) => index + 1),
    []
  );
  return (
    <>
      <BlockStack gap='400'>
        {itemsArray(10).map((item) => (
          <Card key={item}>
            <BlockStack gap='400'>
              <SkeletonDisplayText size='small' />
              <SkeletonBodyText lines={8} />
            </BlockStack>
          </Card>
        ))}
      </BlockStack>
      <BlockStack gap='400'>
        {itemsArray(2).map((item) => (
          <div
            key={item}
            style={{
              height: 'fit-content',
            }}
          >
            <Card>
              <BlockStack gap='400'>
                <SkeletonThumbnail size='large' />
                <SkeletonBodyText lines={6} />
              </BlockStack>
            </Card>
          </div>
        ))}
      </BlockStack>
    </>
  );
};
