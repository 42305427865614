import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  InlineStack,
  Text,
  BlockStack,
  Card,
  Banner,
  Icon,
} from '@shopify/polaris';
import { CaretDownIcon, CaretUpIcon } from '@shopify/polaris-icons';
import {
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
  SearchFieldWithGrayBox,
} from 'core/components/SearchFieldWithGrayBoxOfferWizard';
import { OfferTargetTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  OfferPrerequisiteEntitledAppliesToDto,
  OfferProductLimitDto,
  OfferProductsDto,
  OfferTargetTypeDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { ResourcePickerOptions, ResourceSelection } from 'core/api/appBridge';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { GrayBoxBogoOptions } from 'core/components/GrayBox/components/GrayBoxBogoOptions/GrayBoxBogoOptions';
import classNames from 'classnames';
import {
  useForm,
  Controller,
  ControllerRenderProps,
  useController,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberFormat from 'react-number-format';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { ExclusionsPhrasePicker } from 'core/components/ExclusionsBox/components/ExclusionsPhrasePicker/ExclusionsPhrasePicker';
import { PickerExclusionsType } from 'core/components';

type FormFields = {
  numberOfProducts: number | null;
};

type AppliesToProps = {
  selectList: ShopifyObjectDto[];
  selectedProductsApplication: OfferTargetTypeDto;
  variant?: OfferTypeSpecialCases;
  data?: OfferProductsDto;
  hideComponent?: boolean;
  onAppliesToUpdate: (data: OfferPrerequisiteEntitledAppliesToDto) => void;
  setSelectedProductsApplication: (
    selectedProductsApplication: OfferTargetTypeDto
  ) => void;
  onResourceSelection(
    data: ResourceSelection<ResourcePickerOptions['type']>[] | string[]
  ): void;
  onFormValidityChange(formIsInvalid: boolean): void;
  onLimitUpdate: (data: OfferProductLimitDto) => void;
};

export const AppliesTo: React.FC<AppliesToProps> = (props) => {
  const {
    selectList,
    selectedProductsApplication,
    hideComponent,
    variant,
    data,
    setSelectedProductsApplication,
    onResourceSelection,
    onFormValidityChange,
    onAppliesToUpdate,
    onLimitUpdate,
  } = props;
  const [i18n] = useI18n();

  const [phraseModal, setPhraseModal] = useState<boolean>(false);
  const [currentValues, setCurrentValues] = useState<FormFields>({
    numberOfProducts: data?.limit?.perOrder?.value || null,
  });
  const [showVariants, setShowVariants] = useState<boolean>(false);

  const form = yup.object({
    numberOfProducts: yup.number().min(1).integer().required(),
  });

  const { control, formState, watch, trigger, getValues } = useForm<FormFields>(
    {
      defaultValues: {
        numberOfProducts: currentValues.numberOfProducts,
      },
      mode: 'onChange',
      resolver: yupResolver(form),
    }
  );

  const handleOnSelectionChange = useCallback(
    (list: ResourceSelectionProps[] | string[]) => {
      onResourceSelection(list);
    },
    [onResourceSelection]
  );

  const onSearchFieldValidityChange = useCallback(
    (formIsInvalid: boolean) => {
      onFormValidityChange(formIsInvalid);
    },
    [onFormValidityChange]
  );

  const changeValue = useCallback(
    (field: ControllerRenderProps<FormFields>, value: string) => {
      const parsedValue = parseFloat(value);
      if (field.value !== (isNaN(parsedValue) ? null : parsedValue)) {
        setCurrentValues((prevState) => ({
          ...prevState,
          [field.name]: isNaN(parsedValue) ? null : parsedValue,
        }));
        field.onChange(isNaN(parsedValue) ? null : parsedValue);
      }
    },
    [setCurrentValues]
  );

  const grayBoxResourceType = useMemo(() => {
    setShowVariants(false);
    switch (selectedProductsApplication) {
      case OfferTargetTypeDtoEnum.ALL:
        break;
      case OfferTargetTypeDtoEnum.COLLECTIONS:
        return GrayBoxResourceTypeEnum.Collection;
      case OfferTargetTypeDtoEnum.VARIANTS:
        setShowVariants(true);
        return GrayBoxResourceTypeEnum.Product;
      case OfferTargetTypeDtoEnum.PRODUCTS:
        return GrayBoxResourceTypeEnum.Product;
    }
  }, [selectedProductsApplication]);

  const validationMessage = useMemo(() => {
    const { numberOfProducts: numberOfProductsErrors } = formState.errors;

    let label;
    if (numberOfProductsErrors) {
      switch (numberOfProductsErrors.type) {
        case 'min':
          label = 'WarningProduct1';
          break;
        case 'typeError':
          label = 'WarningProduct2';
          break;
      }
    }

    if (label) {
      return <Banner tone='critical'>{i18n.translate(label)}</Banner>;
    } else {
      return null;
    }
  }, [formState, i18n]);

  const togglePhraseModal = useCallback(
    () => setPhraseModal((prev) => !prev),
    []
  );

  const grayBox = useMemo(
    () => (
      <>
        {[OfferTargetTypeDtoEnum.TAGS, OfferTargetTypeDtoEnum.VENDORS].includes(
          selectedProductsApplication as OfferTargetTypeDtoEnum
        ) ? (
          <ExclusionsPhrasePicker
            type={
              selectedProductsApplication?.toLowerCase() as keyof PickerExclusionsType
            }
            label={i18n.translate(
              selectedProductsApplication?.toLowerCase() || 'tags'
            )}
            phrases={{ enabled: true, value: selectList as string[] }}
            isModalOpen={phraseModal}
            toggleModal={togglePhraseModal}
            setPhrases={(data) => handleOnSelectionChange(data.value || [])}
            onSearchFieldValidityChange={onSearchFieldValidityChange}
          />
        ) : (
          grayBoxResourceType && (
            <SearchFieldWithGrayBox
              resourceType={grayBoxResourceType as GrayBoxResourceTypeEnum}
              showVariants={showVariants}
              selectList={selectList as unknown as ResourceSelectionProps[]}
              onSelectedChange={handleOnSelectionChange}
              onFormValidityChange={onSearchFieldValidityChange}
              hideComponent={hideComponent}
            />
          )
        )}
        {data && variant && !hideComponent && (
          <GrayBoxBogoOptions
            data={data}
            productsUpdateValue={onAppliesToUpdate}
          />
        )}
      </>
    ),

    [
      grayBoxResourceType,
      selectedProductsApplication,
      showVariants,
      phraseModal,
      hideComponent,
      togglePhraseModal,
      handleOnSelectionChange,
      onSearchFieldValidityChange,
    ]
  );

  const isCrossSellOrBunlde = useMemo(
    () =>
      variant &&
      [
        OfferTypeSpecialCases.CrossSell,
        OfferTypeSpecialCases.BundleOrderDiscount,
      ].includes(variant),
    [variant]
  );

  const { field: conditionalInputNameField } = useController({
    name: 'numberOfProducts',
    control,
  });

  const onRadioButtonValueChange = useCallback(
    (data: OfferTargetTypeDtoEnum) => {
      setSelectedProductsApplication(data);
    },
    [setSelectedProductsApplication]
  );

  const incrementDecrementValue = useCallback(
    (
      field: ControllerRenderProps<FormFields>,
      type: 'increment' | 'decrement'
    ) => {
      const currentValue =
        typeof getValues(field.name) === 'number' ? getValues(field.name) : 1;
      if (type === 'increment' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue + 1}`);
      } else if (type === 'decrement' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue - 1}`);
      }
    },
    [getValues, changeValue]
  );

  const incrementDecrementButtons = useCallback(
    (field: ControllerRenderProps<FormFields>) => {
      return (
        <div className='Polaris-TextField__Spinner' aria-hidden='true'>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'increment');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretUpIcon} />
              </span>
            </div>
          </div>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'decrement');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretDownIcon} />
              </span>
            </div>
          </div>
        </div>
      );
    },
    [incrementDecrementValue]
  );

  useEffect(() => {
    isCrossSellOrBunlde && onFormValidityChange(!formState.isValid);
  }, [formState.isValid, onFormValidityChange]);

  useEffect(() => {
    trigger('numberOfProducts');
  }, []);

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        onLimitUpdate({
          ...data?.limit,
          perOrder: {
            enabled: true,
            value:
              value.numberOfProducts !== null
                ? Number(value.numberOfProducts)
                : null,
          },
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger, data]);

  return (
    <div data-cy='AppliesToBox'>
      {!hideComponent ? (
        <Card>
          <BlockStack gap='400'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('Products')}
            </Text>
            {isCrossSellOrBunlde && validationMessage}
            <InlineStack blockAlign='center' gap='200'>
              {isCrossSellOrBunlde ? (
                <>
                  <Text as='p'>{i18n.translate('Apply')}</Text>
                  <div
                    className={classNames(
                      'Polaris-TextField Polaris-TextField--hasValue',
                      {
                        'Polaris-TextField--error': validationMessage,
                      }
                    )}
                    style={{ maxWidth: '100px' }}
                  >
                    <Controller<FormFields>
                      name='numberOfProducts'
                      control={control}
                      render={({ field }) => (
                        <NumberFormat
                          {...field}
                          value={field.value as number}
                          decimalScale={0}
                          className={`Polaris-TextField__Input numberOfProducts`}
                          allowNegative={false}
                          autoComplete='off'
                          onValueChange={({ floatValue }) =>
                            changeValue(field, `${floatValue ?? ''}`)
                          }
                        />
                      )}
                    />
                    {incrementDecrementButtons(conditionalInputNameField)}
                    <div className='Polaris-TextField__Backdrop'></div>
                  </div>
                  <Text as='p'>{i18n.translate('From')}</Text>
                </>
              ) : (
                <Text as='p'>{i18n.translate('Apply')}</Text>
              )}
              <SelectOptions
                label=''
                selectedOption={selectedProductsApplication}
                options={Object.entries(OfferTargetTypeDtoEnum).map(
                  ([key, value]: [string, OfferTargetTypeDtoEnum]) => ({
                    label: i18n.translate(key) || value,
                    value,
                  })
                )}
                onOptionSelect={(value) =>
                  onRadioButtonValueChange(value as OfferTargetTypeDtoEnum)
                }
              />
            </InlineStack>
            {grayBox}
          </BlockStack>
        </Card>
      ) : (
        <>{grayBox}</>
      )}
    </div>
  );
};
