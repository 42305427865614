import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Banner,
  BlockStack,
  Box,
  Button,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import {
  GrayBoxResourceType,
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
} from '../../SearchFieldWithGrayBoxOfferWizard';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CollectionIcon,
  AlertTriangleIcon,
  DisabledIcon,
} from '@shopify/polaris-icons';
import { headerTitle } from '../../utils/utils';
import { SelectedProductList } from '../SelectedProductList/SelectedProductList';
import { CustomSekeletonBox } from 'core/components/ControlLibrary';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector } from 'core/hooks';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { OfferConflictTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

type SearchFieldPlaceholderProps = {
  currentResourceType: string;
  selectedGrayBoxElements: ResourceSelectionProps[];
  resourceType: GrayBoxResourceType;
  resourceIsLoading: boolean;
  showVariants?: boolean;
  isOrderBumpType?: boolean;
  asExclusions?: boolean;
  toggleShowGrayBoxState: (breakCondition?: boolean) => void;
};
export const SearchFieldPlaceholder: React.FC<SearchFieldPlaceholderProps> = ({
  currentResourceType,
  // Investigate if we still need it (for Yevhen)
  // isOrderBumpType,
  selectedGrayBoxElements,
  showVariants,
  resourceType,
  resourceIsLoading,
  asExclusions,
  toggleShowGrayBoxState,
}) => {
  const [i18n] = useI18n();

  const { offerConflictsData, offerConflictsIsLoading } = useConfigureOffers();

  const { currentConfigPage } = useAppSelector((store) => store.offersWizard);
  const { showValidation } = useAppSelector((store) => store.promotions);
  const [showList, setShowList] = useState<boolean>(false);

  const currectConflict = useMemo(() => {
    if (!offerConflictsData?.length || !currentConfigPage) return;

    const conflictTypes = {
      products: OfferConflictTypeDtoEnum.BROKEN_ITEM_ENTITLEMENTS,
      productsExclusions:
        OfferConflictTypeDtoEnum.BROKEN_ITEM_ENTITLEMENTS_EXCLUSIONS,
      cartRules: OfferConflictTypeDtoEnum.BROKEN_ITEM_PREREQUISITES,
      cartRulesExclusions:
        OfferConflictTypeDtoEnum.BROKEN_ITEM_PREREQUISITES_EXCLUSIONS,
    };

    const conflictType =
      conflictTypes[
        `${currentConfigPage}${
          asExclusions ? 'Exclusions' : ''
        }` as keyof typeof conflictTypes
      ];

    return offerConflictsData.find(
      (conflict) => conflict.type === conflictType
    );
  }, [offerConflictsData, currentConfigPage, asExclusions]);

  const isEmptyResourceList = useMemo(
    () => !selectedGrayBoxElements?.length && !resourceIsLoading,
    [selectedGrayBoxElements, resourceIsLoading]
  );

  const placeholderTranslation = useMemo(() => {
    if (resourceType !== GrayBoxResourceTypeEnum.Product) {
      return asExclusions ? 'ExcludeCollections' : 'SelectedCollections';
    }
    if (showVariants) {
      return asExclusions
        ? 'ExcludeProductVariants'
        : 'SelectedProductVariants';
    }
    return asExclusions ? 'ExcludeProducts' : 'SelectedProducts';
  }, [asExclusions, resourceType, showVariants]);

  const showValidationError = useMemo(
    () => showValidation && isEmptyResourceList,
    [showValidation, isEmptyResourceList]
  );

  const toggleShowList = useCallback(() => setShowList((prev) => !prev), []);

  useEffect(() => {
    setShowList(false);
  }, [currentResourceType]);

  return (
    <BlockStack gap='200'>
      {currectConflict && (
        <Banner tone='warning'>{i18n.translate('ConflictedBanner')}</Banner>
      )}
      <Box
        background={showValidationError ? 'bg-surface-critical' : 'bg-surface'}
        borderColor='border'
        borderRadius='200'
        borderWidth={showValidationError ? '0' : '025'}
      >
        <Box
          padding='400'
          borderColor='border'
          borderBlockEndWidth={showList ? '025' : '0'}
        >
          <InlineStack align='space-between' wrap={false} gap={'200'}>
            <InlineStack gap='200'>
              <InlineStack gap='200' blockAlign='center' wrap={false}>
                <Box>
                  <Icon
                    tone={
                      showValidationError
                        ? 'textCritical'
                        : isEmptyResourceList
                        ? 'textCaution'
                        : 'base'
                    }
                    source={
                      isEmptyResourceList
                        ? AlertTriangleIcon
                        : asExclusions
                        ? DisabledIcon
                        : CollectionIcon
                    }
                  />
                </Box>
                {isEmptyResourceList && (
                  <Text as='p' tone={showValidation ? 'critical' : 'caution'}>
                    {i18n.translate(placeholderTranslation, {
                      selected: (
                        <Text as='span' fontWeight='medium'>
                          {i18n.translate(
                            `EmptyPlaceholder.${placeholderTranslation}Empty`
                          )}
                        </Text>
                      ),
                    })}
                  </Text>
                )}
                {!!selectedGrayBoxElements?.length &&
                  !resourceIsLoading &&
                  !offerConflictsIsLoading && (
                    <Text
                      as='p'
                      fontWeight='regular'
                      tone={currectConflict ? 'caution' : 'base'}
                    >
                      {i18n.translate(placeholderTranslation, {
                        selected: (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: headerTitle(
                                selectedGrayBoxElements,
                                currentResourceType,
                                i18n
                              ),
                            }}
                          ></span>
                        ),
                      })}
                    </Text>
                  )}
                {(resourceIsLoading || offerConflictsIsLoading) && (
                  <CustomSekeletonBox height='8px' width='200px' />
                )}
              </InlineStack>
            </InlineStack>
            <InlineStack gap='200' wrap={false}>
              {(resourceIsLoading || selectedGrayBoxElements.length > 0) && (
                <Button
                  icon={!showList ? ChevronDownIcon : ChevronUpIcon}
                  onClick={toggleShowList}
                  variant='monochromePlain'
                  disabled={resourceIsLoading}
                />
              )}
              <Button
                variant='plain'
                id='SearchFieldEditButton'
                disabled={resourceIsLoading}
                onClick={() =>
                  toggleShowGrayBoxState(selectedGrayBoxElements.length !== 0)
                }
              >
                {i18n.translate(isEmptyResourceList ? 'Select' : 'Edit')}
              </Button>
            </InlineStack>
          </InlineStack>
        </Box>
        {showList && (
          <SelectedProductList
            currentResourceType={currentResourceType}
            resourceType={resourceType}
            currectConflict={currectConflict}
            selectedGrayBoxElements={selectedGrayBoxElements}
          />
        )}
      </Box>
    </BlockStack>
  );
};
