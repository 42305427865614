import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  FrequentBoughtTogetherShopSettingsDto,
  NinjaCartFrequentBoughtTogetherWidgetSettingsDto,
  WidgetSettingsDtoFrequentBoughtTogetherShopSettingsDto,
  WidgetSettingsDtoNinjaCartFrequentBoughtTogetherWidgetSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import {
  IndicatorsSetting,
  NinjaCartDisplayOption,
  StyleSetting,
} from '../../components';
import { DisplayOrderRadioSetting } from '../../components/DisplayOrderRadioSetting/DisplayOrderRadioSetting';
import { PageDisplayCheckboxSetting } from '../../components/PageDisplayCheckboxSetting/PageDisplayCheckboxSetting';
import { ProductSetting } from '../../components/ProductSetting/ProductSetting';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';

type FrequentlyBoughtTogetherSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  widgetType: WidgetTypeDtoEnum;
};
type FrequentBoughtTogetherSettingsDto =
  NinjaCartFrequentBoughtTogetherWidgetSettingsDto &
    FrequentBoughtTogetherShopSettingsDto;

export const FrequentlyBoughtTogetherSettings = forwardRef<
  WidgetSavebarRef,
  FrequentlyBoughtTogetherSettingsProps
>(({ setIsLoading, setIsSaving, widgetType }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const isNinjaCart =
    widgetType === WidgetTypeDtoEnum.NINJA_CART_FREQUENT_BOUGHT_TOGETHER;

  const ninjaCartData = useNinjaCartWidget(
    isNinjaCart
      ? WidgetTypeDtoEnum.NINJA_CART_FREQUENT_BOUGHT_TOGETHER
      : undefined
  );

  const widgetData = useWidget(
    !isNinjaCart ? WidgetTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER : undefined
  );

  const {
    frequentlyBoughtTogetherData,
    saveFrequentlyBoughtTogether,
    frequentlyBoughtTogetherDataIsFetching,
    saveFrequentlyBoughtTogetherIsLoading,
    // refetchFrequentlyBoughtTogetherData,
  } = isNinjaCart ? ninjaCartData : widgetData;

  const [savedData, setSavedData] = useState<
    WidgetSettingsDtoNinjaCartFrequentBoughtTogetherWidgetSettingsDto &
      WidgetSettingsDtoFrequentBoughtTogetherShopSettingsDto
  >({});
  const [frequentlyBoughtTogetherState, setFrequentlyBoughtTogetherState] =
    useState<
      WidgetSettingsDtoNinjaCartFrequentBoughtTogetherWidgetSettingsDto &
        WidgetSettingsDtoFrequentBoughtTogetherShopSettingsDto
    >({});

  const updateFrequentlyBoughtTogetherState = useCallback(
    (
      field: keyof FrequentBoughtTogetherSettingsDto,
      data: FrequentBoughtTogetherSettingsDto[keyof FrequentBoughtTogetherSettingsDto]
    ) => {
      setFrequentlyBoughtTogetherState((prev) => {
        const newState = {
          ...prev,
          settings: {
            ...prev.settings,
            [field]: data,
          },
        };
        return newState;
      });
    },
    [setFrequentlyBoughtTogetherState]
  );

  const handleSave = useCallback(
    () =>
      saveFrequentlyBoughtTogether(
        frequentlyBoughtTogetherState.settings || null,
        () => setSavedData(frequentlyBoughtTogetherState)
      ),
    [frequentlyBoughtTogetherState]
  );

  useEffect(() => {
    if (!isEmpty(frequentlyBoughtTogetherData)) {
      setFrequentlyBoughtTogetherState(frequentlyBoughtTogetherData);
      setSavedData(frequentlyBoughtTogetherData);
    }
  }, [frequentlyBoughtTogetherData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(frequentlyBoughtTogetherState)) {
      dispatch(
        setIsUnsavedChanges(!isEqual(frequentlyBoughtTogetherState, savedData))
      );
    }
  }, [savedData, frequentlyBoughtTogetherState]);

  useEffect(() => {
    setIsLoading(frequentlyBoughtTogetherDataIsFetching);
  }, [frequentlyBoughtTogetherDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveFrequentlyBoughtTogetherIsLoading);
  }, [saveFrequentlyBoughtTogetherIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setFrequentlyBoughtTogetherState(savedData),
  }));

  return !frequentlyBoughtTogetherDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting
        styled={!!frequentlyBoughtTogetherData?.isShopLevelWidget}
      />
      <DisplayOrderRadioSetting
        displayOrder={frequentlyBoughtTogetherState.settings?.displayOrder}
        setDisplayOrder={(data) =>
          updateFrequentlyBoughtTogetherState('displayOrder', data)
        }
      />
      {!isNinjaCart && (
        <PageDisplayCheckboxSetting
          pageDisplay={frequentlyBoughtTogetherState.settings?.pageDisplay}
          setPageDisplay={(data) =>
            updateFrequentlyBoughtTogetherState('pageDisplay', data)
          }
        />
      )}
      <IndicatorsSetting
        indicators={
          frequentlyBoughtTogetherState.settings?.indicatorsStyle || {}
        }
        setIndicators={(data) =>
          updateFrequentlyBoughtTogetherState('indicatorsStyle', data)
        }
      />
      <ProductSetting
        productSettings={
          frequentlyBoughtTogetherState.settings?.productSettings
        }
        setProductSettings={(data) =>
          updateFrequentlyBoughtTogetherState('productSettings', data)
        }
      />
      {isNinjaCart &&
        ['productOptions', 'vendor', 'productProperties'].map((value) => {
          const type = value as keyof Pick<
            NinjaCartFrequentBoughtTogetherWidgetSettingsDto,
            'productProperties' | 'productOptions' | 'vendor'
          >;
          return (
            <NinjaCartDisplayOption
              key={type}
              setup={frequentlyBoughtTogetherState.settings?.[type] || {}}
              setSetup={(data) =>
                updateFrequentlyBoughtTogetherState(type, data)
              }
              type={type}
            />
          );
        })}
    </>
  ) : null;
});

FrequentlyBoughtTogetherSettings.displayName =
  'FrequentlyBoughtTogetherSettings';
