import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  GetNinjaCartSingleWidgetInstallationStatusResponseDto,
  GetSingleWidgetInstallationStatusResponseDto,
  NinjaCartWidgetInstallationDto,
  ThemeStatusesDto,
  WidgetInstallationDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineStack,
  SkeletonBodyText,
  SkeletonThumbnail,
  Text,
} from '@shopify/polaris';
import ManageThemesModal from 'features/settings/components/GeneralSettings/ManageWidgets/components/ManageThemesModal/ManageThemesModal';
import { useWidgetsControlCenter } from 'core/hooks/useWidgetsControlCenter';
import {
  ThemeTypeDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { ActionListInPopover } from 'features/promotions/components/ActionListInPopover/ActionListInPopover';
import { useToggleWidget } from 'features/widgets/hooks/useToggleWidget';
import ToggleWidgetModal from 'features/settings/components/GeneralSettings/ManageWidgets/components/WidgetSettings/components/WidgetSettingsWrapper/components/ToggleWidgetModal/ToggleWidgetModal';

type WidgetStatusProps = {
  data?:
    | GetNinjaCartSingleWidgetInstallationStatusResponseDto
    | GetSingleWidgetInstallationStatusResponseDto;
  widgetStatusesDataIsFetching: boolean;
  isWidgetControlCenterPage: boolean;
  refetchWidgetStatusesData: () => void;
};

enum WidgetStatusOptionDtoEnum {
  SELECT = 'SELECT',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
}

export const WidgetStatus: React.FC<WidgetStatusProps> = ({
  data,
  isWidgetControlCenterPage,
  widgetStatusesDataIsFetching,
  refetchWidgetStatusesData,
}) => {
  const [i18n] = useI18n();

  const { saveThemeSlotsIsLoading, saveThemeSlots } = useWidgetsControlCenter(
    undefined,
    true,
    undefined
  );

  const [actionWidget, setActionWidget] = useState<
    WidgetInstallationDto | NinjaCartWidgetInstallationDto | null
  >(null);
  const [currentWidgetThemeType, setCurrentWidgetThemeType] =
    useState<ThemeTypeDtoEnum | null>(null);

  const { toggleWidget, loading } = useToggleWidget({
    widgetThemeType: currentWidgetThemeType as ThemeTypeDtoEnum,
    isWidgetControlCenterPage,
    setActionWidget,
    refetchWidgetInstallationData: refetchWidgetStatusesData,
  });

  const [activePopoverId, setActivePopoverId] = useState<string>('');

  const [isThemesModalOpen, setIsThemesModalOpen] = useState<boolean>(false);
  const [templatesUpdated, setTemplatesUpdated] = useState<boolean>(false);

  const [themeSlots, setThemeSlots] = useState<ThemeStatusesDto[]>([]);

  const currentThemeName = useMemo(
    () =>
      themeSlots?.find((slot) => slot.type === currentWidgetThemeType)
        ?.themeName || '',
    [themeSlots, currentWidgetThemeType]
  );

  const toggleThemesModal = useCallback(
    () => setIsThemesModalOpen((prev) => !prev),

    []
  );

  const updateStates = useCallback((data: ThemeStatusesDto[]) => {
    setThemeSlots(data);
    setIsThemesModalOpen(false);
  }, []);

  const handleSaveThemeSlots = useCallback(
    (data: ThemeStatusesDto[]) => {
      saveThemeSlots({ slots: data }, updateStates);
    },
    [saveThemeSlots]
  );

  const handleOptionClick = useCallback(
    (
      currentOption: WidgetStatusOptionDtoEnum,
      widget?: WidgetInstallationDto | NinjaCartWidgetInstallationDto
    ) => {
      if (currentOption === WidgetStatusOptionDtoEnum.SELECT) {
        toggleThemesModal();
      } else {
        widget && toggleWidget(widget);
      }
    },
    []
  );

  const handleActionWidgetClose = useCallback(() => {
    setActionWidget(null);
  }, []);

  useEffect(() => {
    if (data?.names) {
      const mappedThemeSlots = data.names.map((name) => ({
        themeId: name.id,
        themeName: name.name,
        type: name.slot,
      }));

      if (mappedThemeSlots.length === 1) {
        mappedThemeSlots.push({
          type: ThemeTypeDtoEnum.TEST,
          themeId: undefined,
          themeName: undefined,
        });
      }

      setThemeSlots(mappedThemeSlots);
    }
  }, [data?.names]);

  useEffect(() => {
    activePopoverId.length &&
      setCurrentWidgetThemeType(activePopoverId as ThemeTypeDtoEnum);
  }, [activePopoverId]);

  useEffect(() => {
    if (templatesUpdated && !actionWidget) {
      refetchWidgetStatusesData();
      setTemplatesUpdated(false);
    }
  }, [templatesUpdated, actionWidget]);

  return widgetStatusesDataIsFetching || !data || loading ? (
    <div
      style={{
        height: 'fit-content',
      }}
    >
      <Card>
        <BlockStack gap='400'>
          <SkeletonThumbnail size='large' />
          <SkeletonBodyText lines={6} />
        </BlockStack>
      </Card>
    </div>
  ) : (
    <>
      <Card>
        <BlockStack gap='400'>
          <InlineStack align='space-between'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Title')}
            </Text>
            <Button onClick={toggleThemesModal} variant='plain'>
              {i18n.translate('Link')}
            </Button>
          </InlineStack>
          <Box
            borderWidth='025'
            borderColor='border'
            borderRadius='200'
            paddingInline='300'
            paddingBlock='200'
          >
            <BlockStack gap='200'>
              {themeSlots.map((slot, idx) => {
                const currentSlotStatus = Array.isArray(data?.statuses)
                  ? (
                      data.statuses as Array<
                        NinjaCartWidgetInstallationDto | WidgetInstallationDto
                      >
                    ).find((status) => {
                      return (
                        'themeType' in status && status.themeType === slot.type
                      );
                    })
                  : undefined;

                const isEnabled = [
                  WidgetStatusDtoEnum.ENABLED,
                  WidgetStatusDtoEnum.ENABLED_BUILT_IN,
                  WidgetStatusDtoEnum.ENABLED_VIA_CODE,
                  WidgetStatusDtoEnum.ENABLED_VIA_CSS,
                ].includes(currentSlotStatus?.status as WidgetStatusDtoEnum);
                const currentOption = currentSlotStatus
                  ? isEnabled
                    ? WidgetStatusOptionDtoEnum.DISABLE
                    : WidgetStatusOptionDtoEnum.ENABLE
                  : WidgetStatusOptionDtoEnum.SELECT;
                return (
                  <div key={slot.type}>
                    <BlockStack gap='050'>
                      <InlineStack align='space-between' blockAlign='center'>
                        <Text variant='headingSm' fontWeight='regular' as='p'>
                          {i18n.translate(slot.type!)}
                        </Text>
                        <InlineStack blockAlign='center' gap='100'>
                          {slot.themeId && slot.themeName && (
                            <Badge tone={isEnabled ? 'success' : undefined}>
                              {i18n.translate(isEnabled ? 'On' : 'Off')}
                            </Badge>
                          )}
                          <ActionListInPopover
                            id={slot.type || ''}
                            btnContent=''
                            activePopoverId={activePopoverId}
                            setActivePopoverId={setActivePopoverId}
                            actionList={[
                              {
                                content: i18n.translate(currentOption),
                                onAction: () =>
                                  handleOptionClick(
                                    currentOption,
                                    currentSlotStatus
                                  ),
                                destructive: isEnabled,
                              },
                            ]}
                          />
                        </InlineStack>
                      </InlineStack>
                      <Text
                        variant='headingXs'
                        fontWeight='regular'
                        tone='subdued'
                        as='p'
                        truncate
                      >
                        {slot.themeName?.slice(0, 24) ??
                          i18n.translate('NotSet')}
                      </Text>
                    </BlockStack>
                    {!idx && (
                      <Box paddingBlockStart='200'>
                        <Bleed marginInline='300'>
                          <Divider borderColor='border' />
                        </Bleed>
                      </Box>
                    )}
                  </div>
                );
              })}
            </BlockStack>
          </Box>
          <Bleed marginInline='400' marginBlockEnd='400'>
            <Box background='bg-surface-secondary' padding='400'>
              <Text as='p' tone='subdued'>
                {i18n.translate('FooterContent')}
              </Text>
            </Box>
          </Bleed>
        </BlockStack>
      </Card>
      {isThemesModalOpen && (
        <ManageThemesModal
          themeSlotsData={themeSlots || []}
          themeSlotsDataIsFetching={widgetStatusesDataIsFetching}
          isOpen={isThemesModalOpen}
          isSaving={saveThemeSlotsIsLoading}
          onClose={toggleThemesModal}
          onSave={(data) => handleSaveThemeSlots(data)}
        />
      )}
      {actionWidget && (
        <ToggleWidgetModal
          isOpen={!!actionWidget}
          widgetType={actionWidget.type as WidgetTypeDtoEnum}
          installationMode={
            actionWidget.installationMode as WidgetInstallationModeDtoEnum
          }
          installationLink={
            ('installationDeepLink' in actionWidget
              ? actionWidget.installationDeepLink
              : '') || ''
          }
          currentThemeName={currentThemeName}
          onClose={handleActionWidgetClose}
          refetchWidget={refetchWidgetStatusesData}
          setTemplatesUpdated={setTemplatesUpdated}
        />
      )}
    </>
  );
};
