import React, { useMemo } from 'react';
import { BlockStack, Card, ChoiceList, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionSummaryShippingAmountDto } from 'core/api/adminSettings/adminSettingsApi';
import { PromotionSummaryShippingAmountModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { SimplifiedTextEntries } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import { Element } from 'react-scroll';
import './ShippingAmountSetting.scss';

type ShippingAmountSettingProps = {
  shippingAmount: PromotionSummaryShippingAmountDto;
  setShippingAmount: (data: PromotionSummaryShippingAmountDto) => void;
};
export const ShippingAmountSetting: React.FC<ShippingAmountSettingProps> = ({
  shippingAmount,
  setShippingAmount,
}) => {
  const [i18n] = useI18n();
  const modeOptions = useMemo(
    () =>
      Object.values(PromotionSummaryShippingAmountModeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
        helpText:
          value === PromotionSummaryShippingAmountModeDtoEnum.CALCULATED_IN_CART
            ? i18n.translate('EstimatedUsingIP')
            : '',
      })),
    [i18n]
  );
  return (
    <Element name='ShippingAmountSetting' className='ShippingAmountSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('ShippingAmount')}
            </Text>
          </BlockStack>

          <ChoiceList
            title=''
            choices={modeOptions}
            selected={[
              shippingAmount.mode ||
                PromotionSummaryShippingAmountModeDtoEnum.CALCULATED_AT_CHECKOUT,
            ]}
            onChange={(value) =>
              setShippingAmount({
                ...shippingAmount,
                mode: value[0] as PromotionSummaryShippingAmountModeDtoEnum,
              })
            }
          />
          {shippingAmount.mode ===
            PromotionSummaryShippingAmountModeDtoEnum.CALCULATED_IN_CART && (
            <>
              <Text as='p' fontWeight='medium'>
                {i18n.translate('DisplayText')}
              </Text>
              <SimplifiedTextEntries
                message={{
                  entries: [{ text: shippingAmount.displayText || [] }],
                }}
                setMessage={(data) =>
                  setShippingAmount({
                    ...shippingAmount,
                    displayText: data.entries?.[0].text,
                  })
                }
                defaultLanguage='en'
                title={''}
                hideHeader
                onSystemReset={() => null}
              />
            </>
          )}
        </BlockStack>
      </Card>
    </Element>
  );
};
