import React, { useCallback, useMemo } from 'react';
import { PromotionScheduleDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { BlockStack, ChoiceList } from '@shopify/polaris';
import { TriggerScheduleTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useI18n } from '@shopify/react-i18n';
import { SingleSchedule } from '../SingleSchedule/SingleSchedule';
import { RecurringSchedule } from '../RecurringSchedule/RecurringSchedule';

type SchedulePromotionProps = {
  scheduleSetup: PromotionScheduleDto;
  timezone: string;
  setScheduleSetup: React.Dispatch<React.SetStateAction<PromotionScheduleDto>>;
  onFormValidityChange: (isValid: boolean) => void;
};
export const SchedulePromotion: React.FC<SchedulePromotionProps> = ({
  scheduleSetup,
  timezone,
  setScheduleSetup,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();

  const scheduleTypeOptions = useMemo(
    () =>
      Object.values(TriggerScheduleTypeDtoEnum).map((value) => ({
        label: i18n.translate(`${value}_OPTION`),
        value,
      })),
    []
  );

  const handleScheduleSetup = useCallback(
    (
      field: keyof PromotionScheduleDto,
      value: PromotionScheduleDto[keyof PromotionScheduleDto]
    ) => setScheduleSetup({ ...scheduleSetup, [field]: value }),
    [scheduleSetup, setScheduleSetup]
  );

  return (
    <BlockStack gap='400'>
      <ChoiceList
        title=''
        choices={scheduleTypeOptions}
        selected={[scheduleSetup.type as string]}
        titleHidden
        onChange={(value) =>
          handleScheduleSetup('type', value[0] as TriggerScheduleTypeDtoEnum)
        }
      />
      {scheduleSetup.type === TriggerScheduleTypeDtoEnum.SIMPLE && (
        <SingleSchedule
          timezone={timezone}
          scheduleSetup={scheduleSetup}
          handleScheduleSetup={handleScheduleSetup}
          onFormValidityChange={onFormValidityChange}
        />
      )}
      {scheduleSetup.type === TriggerScheduleTypeDtoEnum.RECURRING && (
        <RecurringSchedule
          scheduleSetup={scheduleSetup}
          handleScheduleSetup={handleScheduleSetup}
          onFormValidityChange={onFormValidityChange}
        />
      )}
    </BlockStack>
  );
};
