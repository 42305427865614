import React, { useCallback, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { PromotionScheduleDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { Modal } from '@shopify/polaris';
import { SchedulePromotion } from './components/SchedulePromotion/SchedulePromotion';

type SchedulePromotionModalProps = {
  isOpen: boolean;
  schedule: PromotionScheduleDto;
  timezone: string;
  onClose: () => void;
  onSchedulePromotion: (data: PromotionScheduleDto) => void;
};
export const SchedulePromotionModal: React.FC<SchedulePromotionModalProps> = ({
  isOpen,
  schedule,
  timezone,
  onClose,
  onSchedulePromotion,
}) => {
  const [i18n] = useI18n();
  const [schedulePromotionSetup, setSchedulePromotionSetup] =
    useState<PromotionScheduleDto>(schedule);
  const [isValidationError, setIsValidationError] = useState<boolean>(false);

  const handleSaveScheduling = useCallback(() => {
    onSchedulePromotion(schedulePromotionSetup);
    onClose();
  }, [schedulePromotionSetup]);

  return (
    <Modal
      title={i18n.translate('ModalTitle')}
      open={isOpen}
      primaryAction={{
        content: i18n.translate('Save'),
        disabled: isValidationError,
        onAction: handleSaveScheduling,
      }}
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        },
      ]}
      onClose={onClose}
    >
      <Modal.Section>
        <SchedulePromotion
          timezone={timezone}
          scheduleSetup={schedulePromotionSetup}
          setScheduleSetup={setSchedulePromotionSetup}
          onFormValidityChange={(isValid) => setIsValidationError(!isValid)}
        />
      </Modal.Section>
    </Modal>
  );
};
