import React, { useMemo, useState } from 'react';
import { BlockStack, ChoiceList, Modal, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionScheduleDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { SchedulePromotion } from '../SchedulePromotionModal/components/SchedulePromotion/SchedulePromotion';
import { TriggerScheduleTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

enum PublishTypeEnum {
  PUBLISH_NOW = 'PUBLISH_NOW',
  SCHEDULE_LATER = 'SCHEDULE_LATER',
}

type PublishOrScheduleModalProps = {
  isOpen: boolean;
  timezone: string;
  onClose: () => void;
  onPublish: () => void;
  onSchedule: (data: PromotionScheduleDto, publish?: boolean) => void;
};
export const PublishOrScheduleModal: React.FC<PublishOrScheduleModalProps> = ({
  isOpen,
  timezone,
  onClose,
  onPublish,
  onSchedule,
}) => {
  const [i18n] = useI18n();
  const [isValidationError, setIsValidationError] = useState<boolean>(false);
  const [publishType, setPublishType] = useState<PublishTypeEnum>(
    PublishTypeEnum.PUBLISH_NOW
  );
  const [schedulePromotionSetup, setSchedulePromotionSetup] =
    useState<PromotionScheduleDto>({ type: TriggerScheduleTypeDtoEnum.SIMPLE });

  const publishOptions = useMemo(
    () =>
      Object.values(PublishTypeEnum).map((value) => ({
        label: i18n.translate(value),
        id: `${value}_RadioButton`,
        value: value,
      })),
    [i18n]
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={i18n.translate('PublishOrSchedulePromotion')}
      primaryAction={
        publishType === PublishTypeEnum.PUBLISH_NOW
          ? {
              content: i18n.translate('Publish'),
              id: `${publishType}_ButtonType`,
              disabled: isValidationError,
              onAction: () => {
                onPublish();
                onClose();
              },
            }
          : {
              content: i18n.translate('Schedule'),
              id: `${publishType}_ButtonType`,
              onAction: () => {
                onSchedule(schedulePromotionSetup, true);
                onClose();
              },
            }
      }
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <ChoiceList
            choices={publishOptions}
            title=''
            selected={[publishType]}
            onChange={(value) => setPublishType(value[0] as PublishTypeEnum)}
          />
          {publishType === PublishTypeEnum.SCHEDULE_LATER && (
            <>
              <Text as='p'>{i18n.translate('SchedulePromotion')}</Text>
              <SchedulePromotion
                scheduleSetup={schedulePromotionSetup}
                timezone={timezone}
                onFormValidityChange={(isValid) =>
                  setIsValidationError(!isValid)
                }
                setScheduleSetup={setSchedulePromotionSetup}
              />
            </>
          )}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
