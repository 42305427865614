import React, { useCallback, useEffect, useMemo } from 'react';
import { Bleed, BlockStack, Card, Divider } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCartRuleDto,
  PrerequisiteEntitledExclusionsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { ExclusionsBox } from 'core/components';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import {
  setShopLevelExclusionsData,
  setShopLevelSavedExclusionsData,
} from 'core/store/offersWizardSlice';

export type GrayBoxExclusionsProps = {
  data: OfferCartRuleDto;
  productsExclusionsUpdate?: (data: PrerequisiteEntitledExclusionsDto) => void;
  updateValue?: (data: OfferCartRuleDto) => void;
  onFormValidityChange?(
    isFormInvalid: boolean,
    isExclusionsField: boolean
  ): void;
};

export const GrayBoxExclusions: React.FC<GrayBoxExclusionsProps> = (props) => {
  const { data, updateValue, productsExclusionsUpdate, onFormValidityChange } =
    props;

  const [i18n] = useI18n();

  const dispatch = useAppDispatch();

  const { exclusionsData, exclusionsIsFetching } = useConfigurePromotions(
    PromotionFetchTypeEnum.EXCLUSIONS
  );

  const { shopLevelExclusionsData } = useAppSelector(
    (state) => state.offersWizard
  );

  const type = useMemo(
    () => (productsExclusionsUpdate ? 'products' : 'cartRules'),
    [productsExclusionsUpdate]
  );

  const handleExcludeListChange = useCallback(
    (list: PrerequisiteEntitledExclusionsDto) => {
      updateValue?.({
        ...data,
        exclusions: list,
      });
      productsExclusionsUpdate?.(list);
    },
    [data, productsExclusionsUpdate, updateValue]
  );

  const handleShopLevelExcludeListChange = useCallback(
    (list: PrerequisiteEntitledExclusionsDto) => {
      dispatch(
        setShopLevelExclusionsData({
          ...shopLevelExclusionsData,
          [type]: list,
        })
      );
    },
    [type, shopLevelExclusionsData, dispatch]
  );

  const onSearchFieldValidityChange = useCallback((formIsInvalid: boolean) => {
    onFormValidityChange?.(!formIsInvalid, true);
  }, []);

  useEffect(() => {
    if (exclusionsData) {
      dispatch(setShopLevelExclusionsData(exclusionsData));
      dispatch(setShopLevelSavedExclusionsData(exclusionsData));
    }
  }, [exclusionsData]);

  return (
    <Card>
      <BlockStack gap='400'>
        <ExclusionsBox
          title={i18n.translate('Exclusions')}
          tooltip={i18n.translate('ExclusionsTooltip')}
          exclusions={data.exclusions || {}}
          selectedCompareAtPrice={
            shopLevelExclusionsData?.[type]?.excludeVariantsWithCompareAtPrice
              ? 'excludeVariantsWithCompareAtPrice'
              : shopLevelExclusionsData?.[type]
                  ?.excludeVariantsWithoutCompareAtPrice
              ? 'excludeVariantsWithoutCompareAtPrice'
              : undefined
          }
          selectedPurchaseType={
            shopLevelExclusionsData?.[type]?.excludeRecurringPurchase
              ? 'excludeRecurringPurchase'
              : shopLevelExclusionsData?.[type]?.excludeOneTimePurchase
              ? 'excludeOneTimePurchase'
              : undefined
          }
          setExclusions={handleExcludeListChange}
          onFormValidityChange={onSearchFieldValidityChange}
        />
        <Bleed marginInline='400'>
          <Divider borderColor='border' />
        </Bleed>
        <ExclusionsBox
          title={i18n.translate('StorewideExclusions')}
          tooltip={i18n.translate('StorewideTooltip')}
          selectedCompareAtPrice={
            data.exclusions?.excludeVariantsWithCompareAtPrice
              ? 'excludeVariantsWithCompareAtPrice'
              : data.exclusions?.excludeVariantsWithoutCompareAtPrice
              ? 'excludeVariantsWithoutCompareAtPrice'
              : undefined
          }
          selectedPurchaseType={
            data.exclusions?.excludeRecurringPurchase
              ? 'excludeRecurringPurchase'
              : data.exclusions?.excludeOneTimePurchase
              ? 'excludeOneTimePurchase'
              : undefined
          }
          exclusions={shopLevelExclusionsData?.[type] || {}}
          setExclusions={handleShopLevelExcludeListChange}
          onFormValidityChange={onSearchFieldValidityChange}
        />
      </BlockStack>
    </Card>
  );
};
