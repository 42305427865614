import {
  PromotionalBadgeBadgeOfferDto,
  PromotionalBadgeBadgeStyleSetDto,
  PromotionalBadgeSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import React, { useCallback, useMemo } from 'react';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import {
  Background,
  CornerRadius,
  Stitch,
  Style,
} from '../../../../../components';
import {
  BadgeStyleShapeDtoEnum,
  PromotionalBadgeBadgeStyleSetKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { useI18n } from '@shopify/react-i18n';
import { Box } from '@shopify/polaris';
import { isEqual } from 'lodash';
import { StyledTabs } from 'core/components/StyledTabs/StyledTabs';

type PromotionalBadgeBadgeProps = {
  badge: PromotionalBadgeBadgeOfferDto;
  defaultBadge: PromotionalBadgeBadgeOfferDto;
  shopDefaultData: PromotionalBadgeSystemDefaultsDto;
  isPresetEditor?: boolean;
  setBadge: (data: PromotionalBadgeBadgeOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionalBadgeBadge: React.FC<PromotionalBadgeBadgeProps> = ({
  badge,
  defaultBadge,
  shopDefaultData,
  isPresetEditor,
  setBadge,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const styleSetOptions = Object.keys(
    PromotionalBadgeBadgeStyleSetKeyDtoEnum
  ).map((value) => ({
    id: value,
    content: i18n.translate(`${value}`),
  }));

  const badgeTypeData = useMemo(
    () =>
      badge.selectedStyleSet === PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
        ? badge.primaryStyle
        : badge.secondaryStyle,
    [badge]
  );

  const defaultBadgeTypeData = useMemo(
    () =>
      defaultBadge.selectedStyleSet ===
      PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
        ? defaultBadge.primaryStyle
        : defaultBadge.secondaryStyle,
    [defaultBadge]
  );

  const shopDefaultBadgeType = useMemo(
    () =>
      badge.selectedStyleSet === PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
        ? shopDefaultData.badge?.primaryStyle
        : shopDefaultData.badge?.secondaryStyle,
    [shopDefaultData, badge]
  );

  const handleUpdateBadgeType = useCallback(
    (
      field: keyof PromotionalBadgeBadgeStyleSetDto,
      data: PromotionalBadgeBadgeStyleSetDto[keyof PromotionalBadgeBadgeStyleSetDto]
    ) => {
      const selectedStyleSet =
        badge.selectedStyleSet ===
        PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
          ? 'primaryStyle'
          : 'secondaryStyle';
      setBadge({
        ...badge,
        [selectedStyleSet]: {
          ...badge[selectedStyleSet],
          [field]: data,
        },
      });
    },
    [setBadge, badge]
  );

  const resetAllToSystemDefault = useCallback(() => {
    setBadge({
      ...badge,
      primaryStyle: shopDefaultData.badge?.primaryStyle,
      secondaryStyle: shopDefaultData.badge?.secondaryStyle,
      selectedStyleSet: defaultBadge.selectedStyleSet,
    });
  }, [shopDefaultData, badge, defaultBadge]);

  return (
    <div>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setBadge(defaultBadge)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.BADGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {isPresetEditor && (
        <div className='RightSideSection'>
          <Box padding='300'>
            <StyledTabs
              tabs={styleSetOptions}
              selected={
                badge.selectedStyleSet ===
                PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
                  ? 0
                  : 1
              }
              onSelect={(value) =>
                setBadge({
                  ...badge,
                  selectedStyleSet:
                    value === 0
                      ? PromotionalBadgeBadgeStyleSetKeyDtoEnum.PRIMARY
                      : PromotionalBadgeBadgeStyleSetKeyDtoEnum.SECONDARY,
                })
              }
            />
          </Box>
        </div>
      )}

      <div className='RightSideSection'>
        <Style
          style={badgeTypeData?.style || {}}
          inherited={
            !isPresetEditor &&
            isEqual(badgeTypeData?.style, defaultBadgeTypeData?.style)
          }
          styleSet={
            badge.selectedStyleSet as PromotionalBadgeBadgeStyleSetKeyDtoEnum
          }
          setStyle={(data) => handleUpdateBadgeType('style', data)}
          setStyleSet={(value) =>
            setBadge({ ...badge, selectedStyleSet: value })
          }
          onSystemReset={() =>
            handleUpdateBadgeType('style', shopDefaultBadgeType?.style)
          }
        />
      </div>
      {badgeTypeData?.style?.shape === BadgeStyleShapeDtoEnum.RECTANGLE && (
        <div className='RightSideSection'>
          <CornerRadius
            inherited={
              !isPresetEditor &&
              isEqual(
                badgeTypeData?.cornerRadius,
                defaultBadgeTypeData?.cornerRadius
              )
            }
            cornerRadius={badgeTypeData?.cornerRadius || {}}
            setCornerRadius={(data) =>
              handleUpdateBadgeType('cornerRadius', data)
            }
            onSystemReset={() =>
              handleUpdateBadgeType(
                'cornerRadius',
                shopDefaultBadgeType?.cornerRadius
              )
            }
          />
        </div>
      )}
      <div className='RightSideSection'>
        <Background
          inherited={
            !isPresetEditor &&
            isEqual(badgeTypeData?.background, defaultBadgeTypeData?.background)
          }
          background={badgeTypeData?.background || {}}
          setBackground={(data) => handleUpdateBadgeType('background', data)}
          onSystemReset={() =>
            handleUpdateBadgeType(
              'background',
              shopDefaultBadgeType?.background
            )
          }
        />
      </div>
      {badgeTypeData?.style?.shape !== BadgeStyleShapeDtoEnum.STAR && (
        <div className='RightSideSection'>
          <Stitch
            inherited={
              !isPresetEditor &&
              isEqual(badgeTypeData?.stitch, defaultBadgeTypeData?.stitch)
            }
            stitch={badgeTypeData?.stitch || {}}
            setStitch={(data) => handleUpdateBadgeType('stitch', data)}
            onSystemReset={() =>
              handleUpdateBadgeType('stitch', shopDefaultBadgeType?.stitch)
            }
          />
        </div>
      )}
    </div>
  );
};

export default PromotionalBadgeBadge;
