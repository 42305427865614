import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

import {
  CheckoutModeCheckoutSettingsDto,
  CheckoutSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setIsValidationError,
} from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { PreferredCheckoutMode } from './components/PreferredCheckoutMode/PreferredCheckoutMode';
import { FallbackMode } from './components/FallbackMode/FallbackMode';
import { InternationalPricing } from './components/InternationalPricing/InternationalPricing';
import { DynamicCheckoutButtons } from './components/DynamicCheckoutButtons/DynamicCheckoutButtons';
import { DiscountCodePrefix } from './components/DiscountCodePrefix/DiscountCodePrefix';
import { RemoveDraftOrders } from './components/RemoveDraftOrders/RemoveDraftOrders';
import { CheckoutLoadingEffect } from './components/CheckoutLoadingEffect/CheckoutLoadingEffect';
import { CheckoutModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { useAppDispatch } from 'core/hooks';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';
import { setShowValidation } from 'core/store/promotionsSlice';

export const CheckoutOptions = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const pageBackAction = useSettingsPageBackAction();
  const {
    checkoutOptionsData,
    checkoutOptionsIsFetching,
    saveCheckoutOptions,
  } = useConfigureSettings(SettingsFetchTypeEnum.CHECKOUT_OPTIONS);

  const [checkoutState, setCheckoutState] = useState<CheckoutSettingsDto>({});
  const [savedData, setSavedData] = useState<CheckoutSettingsDto>({});

  const mode = checkoutState.checkoutModeSettings?.mode;

  const hasValidationError = useMemo(
    () =>
      checkoutState.checkoutModeSettings?.mode ===
        CheckoutModeDtoEnum.DISCOUNT_CODE &&
      !checkoutState.checkoutModeSettings?.discountCodesPrefix?.value,
    [checkoutState.checkoutModeSettings]
  );

  const handleCheckoutModeSettings = useCallback(
    (
      field: keyof CheckoutModeCheckoutSettingsDto,
      data: CheckoutModeCheckoutSettingsDto[keyof CheckoutModeCheckoutSettingsDto]
    ) =>
      setCheckoutState({
        ...checkoutState,
        checkoutModeSettings: {
          ...checkoutState.checkoutModeSettings,
          [field]: data,
        },
      }),
    [checkoutState]
  );

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(checkoutState);
    return saveCheckoutOptions(checkoutState, onSuccess);
  }, [checkoutState]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => setCheckoutState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, checkoutState)));
  }, [checkoutState, savedData]);

  useEffect(() => {
    if (!isEmpty(checkoutOptionsData)) {
      setCheckoutState(checkoutOptionsData);
      setSavedData(checkoutOptionsData);
    }
  }, [checkoutOptionsData]);

  useEffect(() => {
    dispatch(setIsValidationError(hasValidationError));
  }, [hasValidationError]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
      dispatch(setShowValidation(false));
    };
  }, []);

  return (
    <>
      {!checkoutOptionsIsFetching && checkoutState ? (
        <Page
          backAction={pageBackAction}
          title={i18n.translate('CheckoutOptions')}
        >
          <BlockStack gap='600'>
            <PreferredCheckoutMode
              checkoutMode={checkoutState.checkoutModeSettings || {}}
              isLegacyFlow={!!checkoutState.isLegacyFlow}
              setCheckoutMode={(value) =>
                handleCheckoutModeSettings('mode', value)
              }
            />
            {checkoutState.isLegacyFlow && (
              <FallbackMode
                fallbackMode={checkoutState.checkoutModeSettings?.fallbackMode}
                setFallbackMode={(value) =>
                  handleCheckoutModeSettings('fallbackMode', value)
                }
              />
            )}

            {checkoutState.isLegacyFlow &&
              (checkoutState.checkoutModeSettings?.internationalPricing
                ?.supportedCurrenciesAtCheckout?.length as number) > 1 && (
                <InternationalPricing
                  internationalPricing={
                    checkoutState.checkoutModeSettings?.internationalPricing
                  }
                  setInternationalPricing={(data) =>
                    handleCheckoutModeSettings('internationalPricing', data)
                  }
                />
              )}

            {mode === CheckoutModeDtoEnum.DISCOUNT_CODE && (
              <DiscountCodePrefix
                prefix={
                  checkoutState.checkoutModeSettings?.discountCodesPrefix || {}
                }
                setPrefix={(data) =>
                  handleCheckoutModeSettings('discountCodesPrefix', data)
                }
              />
            )}
            {mode === CheckoutModeDtoEnum.DRAFT_ORDER && (
              <RemoveDraftOrders
                draftMode={
                  checkoutState.checkoutModeSettings?.draftOrderRemovalOption ||
                  {}
                }
                setDraftMode={(data) =>
                  handleCheckoutModeSettings('draftOrderRemovalOption', data)
                }
              />
            )}
            <DynamicCheckoutButtons
              dynamicCheckout={checkoutState.dynamicCheckoutButtons}
              setDynamicCheckout={(data) =>
                setCheckoutState({
                  ...checkoutState,
                  dynamicCheckoutButtons: data,
                })
              }
            />

            <CheckoutLoadingEffect
              loadingEffect={checkoutState.checkoutButtonLoadingEffectDuration}
              setLoadingEffect={(data) =>
                setCheckoutState({
                  ...checkoutState,
                  checkoutButtonLoadingEffectDuration: data,
                })
              }
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
CheckoutOptions.displayName = 'CheckoutOptions';
