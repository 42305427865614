import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OptionDtoTriggerCustomerFilterDto,
  TriggerCustomerFilterDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  IntendedAudienceTagTypeDtoEnum,
  TriggerIntendedAudienceTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { AudienceFilter } from './components/AudienceFilter/AudienceFilter';
import { PurchaseHistoryFilter } from './components/PurchaseHistoryFilter/PurchaseHistoryFilter';

type SettingCustomersProps = {
  customerFilter?: OptionDtoTriggerCustomerFilterDto;
  showValidation: boolean;
  updateSettingsState: (value: OptionDtoTriggerCustomerFilterDto) => void;
  onFormValidityChange(formIsValid: boolean): void;
};

export const SettingCustomers: React.FC<SettingCustomersProps> = ({
  customerFilter,
  showValidation,
  updateSettingsState,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();

  const emptyTagListError = useMemo(
    () =>
      customerFilter?.value?.intendedAudienceFilter?.type !==
        TriggerIntendedAudienceTypeDtoEnum.GUESTS_ONLY &&
      ((customerFilter?.value?.intendedAudienceFilter?.tagType !==
        IntendedAudienceTagTypeDtoEnum.ALL &&
        !customerFilter?.value?.intendedAudienceFilter?.includedTags?.length) ||
        (customerFilter?.value?.intendedAudienceFilter?.excludedTags?.enabled &&
          !customerFilter?.value?.intendedAudienceFilter?.excludedTags?.value
            ?.length)),
    [customerFilter?.value?.intendedAudienceFilter]
  );

  const handleUpdateFilters = useCallback(
    (
      field: keyof TriggerCustomerFilterDto,
      data: TriggerCustomerFilterDto[keyof TriggerCustomerFilterDto]
    ) =>
      updateSettingsState({
        ...customerFilter,
        value: {
          ...customerFilter?.value,
          [field]: data,
        },
      }),
    [customerFilter, updateSettingsState]
  );

  useEffect(() => {
    onFormValidityChange(!emptyTagListError);
  }, [emptyTagListError]);

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='center'>
          <Box width='80%'>
            <BlockStack gap='100'>
              <InlineStack align='start' gap='200'>
                <Text as='h2' fontWeight='semibold'>
                  {i18n.translate('Customers')}
                </Text>
                <Badge tone={customerFilter?.enabled ? 'success' : 'enabled'}>
                  {i18n.translate(customerFilter?.enabled ? 'On' : 'Off')}
                </Badge>
              </InlineStack>
              <Text as='p' tone='subdued'>
                {i18n.translate('CustomersSubtitle')}
              </Text>
            </BlockStack>
          </Box>
          <Button
            onClick={() =>
              updateSettingsState({
                ...customerFilter,
                enabled: !customerFilter?.enabled,
              })
            }
          >
            {i18n.translate(customerFilter?.enabled ? 'TurnOff' : 'TurnOn')}
          </Button>
        </InlineStack>
        <Collapsible id='collapsible' open={!!customerFilter?.enabled}>
          <BlockStack gap='400'>
            <AudienceFilter
              audienceFilter={customerFilter?.value?.intendedAudienceFilter}
              showValidation={showValidation}
              updateAudienceFilter={(data) =>
                handleUpdateFilters('intendedAudienceFilter', data)
              }
            />
            {customerFilter?.value?.intendedAudienceFilter?.type !==
              TriggerIntendedAudienceTypeDtoEnum.GUESTS_ONLY && (
              <PurchaseHistoryFilter
                purchaseHistory={customerFilter?.value?.purchaseHistoryFilter}
                updatePurchaseHistory={(data) =>
                  handleUpdateFilters('purchaseHistoryFilter', data)
                }
              />
            )}
          </BlockStack>
        </Collapsible>
      </BlockStack>
    </Card>
  );
};
