import {
  LegacyStack,
  Tag,
  Listbox,
  EmptySearchResult,
  Combobox,
  Text,
  AutoSelection,
  Card,
  SkeletonBodyText,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import React, { useState, useCallback, useMemo } from 'react';
import './MultiSelectComboBox.scss';

type MultiselectTagComboboxProps = {
  requiredIndicator: boolean;
  label: string;
  placeholder: string;
  helpText?: string | React.ReactNode;
  selectedTags: string[];
  labelHidden: boolean;
  withAddFunc?: boolean;
  suggestions?: string[];
  id: string;
  tagsInside?: boolean;
  isLoading?: boolean;
  setSelectedTags: (tags: string[]) => void;
};

export const MultiselectTagCombobox: React.FC<MultiselectTagComboboxProps> = ({
  requiredIndicator,
  label,
  placeholder,
  selectedTags,
  labelHidden,
  helpText,
  withAddFunc = true,
  suggestions,
  id,
  tagsInside,
  isLoading,
  setSelectedTags,
}) => {
  const [i18n] = useI18n();

  const [value, setValue] = useState<string>('');

  const updateSelection = useCallback(
    (selected: string) => {
      const nextSelectedTags = new Set([...selectedTags]);

      if (nextSelectedTags.has(selected)) {
        nextSelectedTags.delete(selected);
      } else {
        nextSelectedTags.add(selected);
      }

      setSelectedTags(Array.from(nextSelectedTags));

      setValue('');
    },
    [selectedTags, setSelectedTags]
  );

  const removeTag = useCallback(
    (tag: string) => () => {
      updateSelection(tag);
    },
    [updateSelection]
  );

  const getAllTags = useCallback(() => {
    const savedTags = suggestions?.length ? suggestions : [];
    const selectedTagsArray = Array.from(selectedTags);
    const savedTagsArray = Array.from(savedTags);
    const combinedTags = Array.from(
      new Set([...savedTagsArray, ...selectedTagsArray])
    ).sort();

    return combinedTags;
  }, [selectedTags, suggestions]);

  const formatOptionText = useCallback(
    (option: string) => {
      const trimValue = value.trim().toLocaleLowerCase();
      const matchIndex = option.toLocaleLowerCase().indexOf(trimValue);

      if (!value || matchIndex === -1) return option;

      const start = option.slice(0, matchIndex);
      const highlight = option.slice(matchIndex, matchIndex + trimValue.length);
      const end = option.slice(matchIndex + trimValue.length, option.length);

      return (
        <p>
          {start}
          <Text fontWeight='bold' as='span'>
            {highlight}
          </Text>
          {end}
        </p>
      );
    },
    [value]
  );

  const options = useMemo(() => {
    let list;
    const allTags = getAllTags();
    const filterRegex = new RegExp(value, 'i');

    if (value) {
      list = allTags.filter((tag) => tag.match(filterRegex));
    } else {
      list = allTags;
    }

    return [...list];
  }, [value, getAllTags]);

  const verticalContentMarkup =
    selectedTags.length > 0 ? (
      <LegacyStack spacing='extraTight' alignment='center'>
        {selectedTags.map((tag) => (
          <Tag key={`option-${tag}`} onRemove={removeTag(tag)}>
            {tag}
          </Tag>
        ))}
      </LegacyStack>
    ) : null;

  const optionMarkup =
    options.length > 0
      ? options.map((option) => {
          return (
            <Listbox.Option
              key={option}
              value={option}
              selected={selectedTags.includes(option)}
              accessibilityLabel={option}
            >
              <Listbox.TextOption selected={selectedTags.includes(option)}>
                {formatOptionText(option)}
              </Listbox.TextOption>
            </Listbox.Option>
          );
        })
      : null;

  const noResults = value && !getAllTags().includes(value);

  const actionMarkup =
    noResults && withAddFunc ? (
      <Listbox.Action value={value}>{`${i18n.translate(
        'Add'
      )} "${value}"`}</Listbox.Action>
    ) : null;

  const emptyStateMarkup =
    optionMarkup || actionMarkup || !value ? null : (
      <EmptySearchResult
        title=''
        description={`${i18n.translate('NoMatchFound')} "${value}"`}
      />
    );

  const listboxMarkup =
    optionMarkup || actionMarkup || emptyStateMarkup ? (
      <Listbox autoSelection={AutoSelection.First} onSelect={updateSelection}>
        {actionMarkup}
        {optionMarkup}
        {emptyStateMarkup}
      </Listbox>
    ) : null;

  if (isLoading) {
    return (
      <Card>
        <SkeletonBodyText lines={2} />
      </Card>
    );
  }

  return (
    <div className='customMultiSelectComboBox'>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            id={id}
            requiredIndicator={
              (!selectedTags || !selectedTags.length) && requiredIndicator
            }
            autoComplete='off'
            label={
              <Text as='p' tone='base'>
                {label}
              </Text>
            }
            labelHidden={labelHidden}
            value={value}
            placeholder={placeholder}
            verticalContent={tagsInside && verticalContentMarkup}
            onChange={setValue}
            error={
              (!selectedTags || !selectedTags.length) &&
              requiredIndicator &&
              i18n.translate('AtLeastOne')
            }
          />
        }
      >
        {listboxMarkup}
      </Combobox>
      {!tagsInside && (
        <div style={{ marginTop: 8 }}>{verticalContentMarkup}</div>
      )}
      {helpText && helpText}
    </div>
  );
};
