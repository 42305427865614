import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { InlineStack, Card, BlockStack, Text, Button } from '@shopify/polaris';
import { Element } from 'react-scroll';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  PromotionCodeFieldShopSettingsDto,
  WidgetSettingsDtoPromotionCodeFieldShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  PromotionCodeFieldCollapseExpandDtoEnum,
  PromotionCodeFieldDisplayModeDtoEnum,
  SpacingTypeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  BlockWithGeneralCheckboxSetting,
  StyleSetting,
} from '../../components';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';
import { useSearchParams } from 'react-router-dom';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';

type PromotionCodeFieldSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  widgetType: WidgetTypeDtoEnum;
};

export const PromotionCodeFieldSettings = forwardRef<
  WidgetSavebarRef,
  PromotionCodeFieldSettingsProps
>(({ setIsLoading, setIsSaving, widgetType }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const isNinjaCart =
    widgetType === WidgetTypeDtoEnum.NINJA_CART_PROMOTION_CODE_FIELD;

  const ninjaCartData = useNinjaCartWidget(
    isNinjaCart ? WidgetTypeDtoEnum.NINJA_CART_PROMOTION_CODE_FIELD : undefined
  );
  const widgetData = useWidget(
    !isNinjaCart ? WidgetTypeDtoEnum.PROMOTION_CODE_FIELD : undefined
  );

  const {
    promotionCodeFieldData,
    savePromotionCodeField,
    promotionCodeFieldDataIsFetching,
    savePromotionCodeFieldIsLoading,
    // refetchPromotionCodeFieldData,
  } = isNinjaCart ? ninjaCartData : widgetData;

  const [, setSearchParams] = useSearchParams();

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoPromotionCodeFieldShopSettingsDto>({});
  const [promotionCodeFieldState, setPromotionCodeFieldState] =
    useState<WidgetSettingsDtoPromotionCodeFieldShopSettingsDto>({});

  const updatePromotionCodeFieldState = useCallback(
    (
      field: keyof PromotionCodeFieldShopSettingsDto,
      data: PromotionCodeFieldShopSettingsDto[keyof PromotionCodeFieldShopSettingsDto]
    ) => {
      setPromotionCodeFieldState((prev) => {
        const newState = {
          ...prev,
          settings: {
            ...prev.settings,
            [field]: data,
          },
        };
        return newState;
      });
    },
    [setPromotionCodeFieldState]
  );

  const handleSave = useCallback(
    () =>
      savePromotionCodeField(promotionCodeFieldState.settings || null, () =>
        setSavedData(promotionCodeFieldState)
      ),
    [promotionCodeFieldState]
  );

  const handleOpenDiscountCodesConfiguration = useCallback(
    () =>
      setSearchParams({
        modal: NavPaths.SettingsModal,
        path: SettingsModalPathsEnums.DiscountCodes,
      }),
    []
  );

  useEffect(() => {
    if (!isEmpty(promotionCodeFieldData)) {
      setPromotionCodeFieldState(promotionCodeFieldData);
      setSavedData(promotionCodeFieldData);
    }
  }, [promotionCodeFieldData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(promotionCodeFieldState)) {
      dispatch(
        setIsUnsavedChanges(!isEqual(promotionCodeFieldState, savedData))
      );
    }
  }, [savedData, promotionCodeFieldState]);

  useEffect(() => {
    setIsLoading(promotionCodeFieldDataIsFetching);
  }, [promotionCodeFieldDataIsFetching]);

  useEffect(() => {
    setIsSaving(savePromotionCodeFieldIsLoading);
  }, [savePromotionCodeFieldIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setPromotionCodeFieldState(savedData),
  }));

  return !promotionCodeFieldDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!promotionCodeFieldData?.isShopLevelWidget} />
      <BlockWithGeneralCheckboxSetting
        settings={promotionCodeFieldState?.settings?.displayMode || {}}
        setSettingState={(data) =>
          updatePromotionCodeFieldState('displayMode', data)
        }
        valuesEnum={PromotionCodeFieldDisplayModeDtoEnum}
        descriptionEnum={{
          COLLAPSED: `${PromotionCodeFieldDisplayModeDtoEnum.COLLAPSED}_DESCRIPTION`,
          EXPANDED: `${PromotionCodeFieldDisplayModeDtoEnum.EXPANDED}_DESCRIPTION`,
          ALWAYS_EXPANDED: `${PromotionCodeFieldDisplayModeDtoEnum.ALWAYS_EXPANDED}_DESCRIPTION`,
        }}
        checkboxLabel='Checkbox'
        title='DisplayMode'
        fields={Object.keys(
          promotionCodeFieldState?.settings?.displayMode || {}
        )}
        defaultValue={PromotionCodeFieldDisplayModeDtoEnum.COLLAPSED}
      />
      <BlockWithGeneralCheckboxSetting
        settings={promotionCodeFieldState?.settings?.collapseExpand || {}}
        setSettingState={(data) =>
          updatePromotionCodeFieldState('collapseExpand', data)
        }
        valuesEnum={PromotionCodeFieldCollapseExpandDtoEnum}
        checkboxLabel='Checkbox'
        title='CollapseExpand'
        mainDescription='CollapseExpandDescription'
        fields={Object.keys(
          promotionCodeFieldState?.settings?.collapseExpand || {}
        )}
        defaultValue={PromotionCodeFieldCollapseExpandDtoEnum.CHEVRON}
      />
      <BlockWithGeneralCheckboxSetting
        settings={promotionCodeFieldState?.settings?.paddingSpacing || {}}
        setSettingState={(data) =>
          updatePromotionCodeFieldState('paddingSpacing', data)
        }
        valuesEnum={SpacingTypeDtoEnum}
        checkboxLabel='Checkbox'
        title='PaddingSpace'
        mainDescription='PaddingSpaceDescription'
        fields={Object.keys(
          promotionCodeFieldState?.settings?.paddingSpacing || {}
        )}
        defaultValue={SpacingTypeDtoEnum.MEDIUM}
      />
      <Element name='DiscountCodeSettings' className='DiscountCodeSettings'>
        <Card>
          <InlineStack align='space-between' blockAlign='center'>
            <BlockStack gap='100'>
              <Text as='p' fontWeight='bold'>
                {i18n.translate('DiscountCodeSettingsTitle')}
              </Text>
              <Text as='span' tone='subdued'>
                {i18n.translate('DiscountCodeSettingsDescription')}
              </Text>
            </BlockStack>
            <Button onClick={handleOpenDiscountCodesConfiguration}>
              {i18n.translate('Configure')}
            </Button>
          </InlineStack>
        </Card>
      </Element>
    </>
  ) : null;
});

PromotionCodeFieldSettings.displayName = 'PromotionCodeFieldSettings';
