import React, { forwardRef, useEffect } from 'react';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { StyleSetting } from '../../components';
import { useI18n } from '@shopify/react-i18n';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';

type DiscountLabelSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DiscountLabelSettings = forwardRef<
  WidgetSavebarRef,
  DiscountLabelSettingsProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ setIsLoading }, ref) => {
  const [i18n] = useI18n();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>

      <StyleSetting styled={false} isDiscountLabel />
    </>
  );
});

DiscountLabelSettings.displayName = 'DiscountLabelSettings';
