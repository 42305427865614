import React, { useCallback } from 'react';
import {
  Banner,
  BlockStack,
  Card,
  Checkbox,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { NinjaCartPaymentOptionsTypeDto } from 'core/api/adminSettings/adminSettingsApi';
import { NinjaCartPaymentOptionsTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { Element, scroller } from 'react-scroll';

type PaymentOptionsSettingProps = {
  paymentOptions: NinjaCartPaymentOptionsTypeDto[];
  setPaymentOptions: (value: NinjaCartPaymentOptionsTypeDto[]) => void;
};
export const PaymentOptionsSetting: React.FC<PaymentOptionsSettingProps> = ({
  paymentOptions,
  setPaymentOptions,
}) => {
  const [i18n] = useI18n();

  const handlePaymentOptions = useCallback(
    (value: NinjaCartPaymentOptionsTypeDto) => {
      setPaymentOptions(
        paymentOptions.includes(value)
          ? paymentOptions.filter((item) => item !== value)
          : [...paymentOptions, value]
      );
    },
    [paymentOptions]
  );

  const scrollToStyleSetting = useCallback(() => {
    scroller.scrollTo('StyleSetting', {
      duration: 500,
      delay: 100,
      smooth: true,
    });
  }, []);

  return (
    <Element className='PaymentOptionsSetting' name='PaymentOptionsSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Title')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <BlockStack>
            {Object.values(NinjaCartPaymentOptionsTypeDtoEnum).map((value) => (
              <Checkbox
                key={value}
                label={i18n.translate(value)}
                checked={paymentOptions.includes(value)}
                onChange={() => handlePaymentOptions(value)}
              />
            ))}
          </BlockStack>
          <Banner>
            {i18n.translate('BannerInfo', {
              link: (
                <Link onClick={scrollToStyleSetting}>
                  {i18n.translate('PresetEditor')}
                </Link>
              ),
            })}
          </Banner>
        </BlockStack>
      </Card>
    </Element>
  );
};
