import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockStack, Box, InlineStack, List, Text } from '@shopify/polaris';
import {
  PromotionScheduleDto,
  RecurringScheduleDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { useI18n } from '@shopify/react-i18n';
import { DateTimePicker, MultiSelector } from 'core/components';
import moment from 'moment';
import { getInitialDate, getRecurringIntervalSummary } from './utils/utils';
import './RecurringSchedule.scss';
import {
  WeekDayDtoEnum,
  WeekDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import classNames from 'classnames';
import { DaysSelector } from './components/DaysSelector/DaysSelector';

export enum RecurringScheduleTypeEnum {
  DAILY_SCHEDULE = 'dailySchedule',
  WEEKLY_SCHEDULE = 'weeklySchedule',
  MONTHLY_SCHEDULE = 'monthlySchedule',
}

type RecurringScheduleProps = {
  scheduleSetup: PromotionScheduleDto;
  handleScheduleSetup: (
    field: keyof PromotionScheduleDto,
    value: PromotionScheduleDto[keyof PromotionScheduleDto]
  ) => void;
  onFormValidityChange: (isValid: boolean) => void;
};

export const RecurringSchedule: React.FC<RecurringScheduleProps> = ({
  scheduleSetup,
  handleScheduleSetup,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();
  const { recurringSchedule } = scheduleSetup;

  const [selectedRepeat, setSelectedRepeat] =
    useState<keyof RecurringScheduleDto>();

  const recurringScheduleKeys: (keyof RecurringScheduleDto)[] = [
    RecurringScheduleTypeEnum.DAILY_SCHEDULE,
    RecurringScheduleTypeEnum.WEEKLY_SCHEDULE,
    RecurringScheduleTypeEnum.MONTHLY_SCHEDULE,
  ];

  const repeatOptions = useMemo(
    () =>
      recurringScheduleKeys.map((key) => ({
        label: i18n.translate(`${key}_Option`),
        value: key,
      })),
    [recurringScheduleKeys]
  );

  const monthWeekOptions = useMemo(
    () =>
      Object.values(WeekDtoEnum).map((value) => ({
        label: i18n.translate(`${value}_WEEK_OPTION`),
        value,
      })),
    [i18n]
  );

  const weekDayOptions = useMemo(
    () =>
      Object.values(WeekDayDtoEnum).map((value) => ({
        label: i18n.translate(`${value}_DAY_OPTION`),
        value,
      })),
    [i18n]
  );

  const isWeeklyScheduleInvalid = useMemo(
    () =>
      selectedRepeat === RecurringScheduleTypeEnum.WEEKLY_SCHEDULE &&
      (!scheduleSetup.recurringSchedule?.weeklySchedule?.weekDays?.length ||
        !scheduleSetup.recurringSchedule?.weeklySchedule?.weeks?.length),
    [selectedRepeat, scheduleSetup.recurringSchedule?.weeklySchedule]
  );

  const isMonthlyScheduleInvalid = useMemo(
    () =>
      selectedRepeat === RecurringScheduleTypeEnum.MONTHLY_SCHEDULE &&
      !scheduleSetup.recurringSchedule?.monthlySchedule?.days?.length,
    [selectedRepeat, scheduleSetup.recurringSchedule?.monthlySchedule?.days]
  );

  const isAllValid = useMemo(
    () => !isMonthlyScheduleInvalid && !isWeeklyScheduleInvalid,
    [isWeeklyScheduleInvalid, isMonthlyScheduleInvalid]
  );

  const recurringIntervalSummary = getRecurringIntervalSummary(
    i18n,
    selectedRepeat,
    scheduleSetup
  );

  const handleRecurringSchedule = useCallback(
    (
      field: keyof RecurringScheduleDto,
      value: RecurringScheduleDto[keyof RecurringScheduleDto]
    ) =>
      handleScheduleSetup('recurringSchedule', {
        ...scheduleSetup.recurringSchedule,
        [field]: value,
      }),
    [scheduleSetup.recurringSchedule, handleScheduleSetup]
  );

  useEffect(() => {
    if (selectedRepeat) {
      handleScheduleSetup('recurringSchedule', {
        [selectedRepeat]:
          scheduleSetup.recurringSchedule?.[selectedRepeat] || {},
      });
    }
  }, [selectedRepeat]);

  useEffect(() => {
    onFormValidityChange(isAllValid);
  }, [isAllValid]);

  useEffect(() => {
    const { endsAt, startsAt } = scheduleSetup || {};
    if ((startsAt && !endsAt) || (startsAt && endsAt && startsAt > endsAt)) {
      handleScheduleSetup('endsAt', getInitialDate(startsAt));
    }
  }, [scheduleSetup?.startsAt]);

  useEffect(() => {
    const activeRepeat = Object.entries(recurringSchedule || {})
      .filter(([_, value]) => !!value)
      .map(([key]) => key as keyof RecurringScheduleDto)[0];

    setSelectedRepeat(
      activeRepeat ? activeRepeat : RecurringScheduleTypeEnum.DAILY_SCHEDULE
    );
  }, []);

  return (
    <BlockStack gap='400'>
      <InlineStack gap='400'>
        <Box width='135px'>
          <SelectOptions
            label={i18n.translate('Repeat')}
            options={repeatOptions}
            selectedOption={selectedRepeat}
            onOptionSelect={(value) =>
              setSelectedRepeat(value as keyof RecurringScheduleDto)
            }
          />
        </Box>
        {selectedRepeat === RecurringScheduleTypeEnum.WEEKLY_SCHEDULE && (
          <Box width='135px'>
            <MultiSelector
              label={i18n.translate('WeekOfTheMonth')}
              options={monthWeekOptions}
              placeholder={i18n.translate('SelectWeeks')}
              selectedOptions={
                (scheduleSetup.recurringSchedule?.weeklySchedule
                  ?.weeks as string[]) || []
              }
              error={
                !scheduleSetup.recurringSchedule?.weeklySchedule?.weeks?.length
              }
              onChange={(value) =>
                handleRecurringSchedule('weeklySchedule', {
                  ...scheduleSetup.recurringSchedule?.weeklySchedule,
                  weeks: value,
                })
              }
            />
          </Box>
        )}
      </InlineStack>
      <div
        className={classNames('RecurringScheduleGridBox', {
          AdvancedSelection:
            selectedRepeat !== RecurringScheduleTypeEnum.DAILY_SCHEDULE,
        })}
      >
        {selectedRepeat === RecurringScheduleTypeEnum.WEEKLY_SCHEDULE && (
          <MultiSelector
            id='RecurrenceAdvancedSelector'
            label={i18n.translate('SelectDays')}
            placeholder={i18n.translate('SelectDays') + '...'}
            options={weekDayOptions}
            selectedOptions={
              (scheduleSetup.recurringSchedule?.weeklySchedule
                ?.weekDays as string[]) || []
            }
            error={
              !scheduleSetup.recurringSchedule?.weeklySchedule?.weekDays?.length
            }
            onChange={(value) =>
              handleRecurringSchedule('weeklySchedule', {
                ...scheduleSetup.recurringSchedule?.weeklySchedule,
                weekDays: value,
              })
            }
          />
        )}
        {selectedRepeat === RecurringScheduleTypeEnum.MONTHLY_SCHEDULE && (
          <DaysSelector
            id='RecurrenceAdvancedSelector'
            selectedOptions={
              scheduleSetup.recurringSchedule?.monthlySchedule?.days
            }
            error={
              !scheduleSetup.recurringSchedule?.monthlySchedule?.days?.length
            }
            onChange={(value) =>
              handleRecurringSchedule('monthlySchedule', {
                ...scheduleSetup.recurringSchedule?.monthlySchedule,
                days: value,
              })
            }
          />
        )}

        <DateTimePicker
          dateLabel={i18n.translate('RecurrenceStart')}
          timeLabel={i18n.translate('StartTime')}
          timestampValue={scheduleSetup?.startsAt || getInitialDate()}
          datePickerId='RecurrenceStartDate'
          timePickerId='RecurrenceStartTime'
          setTimestampValue={(value) => handleScheduleSetup('startsAt', value)}
          useWrapper={false}
        />
        {scheduleSetup?.startsAt && scheduleSetup?.endsAt && (
          <DateTimePicker
            dateLabel={i18n.translate('RecurrenceEnd')}
            timeLabel={i18n.translate('EndTime')}
            timestampValue={scheduleSetup?.endsAt as number}
            datePickerId='RecurrenceEndDate'
            timePickerId='RecurrenceEndTime'
            minDate={scheduleSetup?.startsAt || undefined}
            setTimestampValue={(value) => handleScheduleSetup('endsAt', value)}
            useWrapper={false}
          />
        )}
      </div>
      <Box background='bg-surface-secondary' padding='300' borderRadius='300'>
        <Text as='p'>{i18n.translate('ThePromotionWillRun')}</Text>
        <List type='bullet'>
          <List.Item>
            {i18n.translate('RecurrenceDates', {
              from: (
                <Text as='span' fontWeight='semibold'>
                  {moment(scheduleSetup.startsAt).format('MMMM Do YYYY')}
                </Text>
              ),
              to: (
                <Text as='span' fontWeight='semibold'>
                  {moment(scheduleSetup.endsAt).format('MMMM Do YYYY')}
                </Text>
              ),
            })}
          </List.Item>
          {recurringIntervalSummary && (
            <List.Item>{recurringIntervalSummary}</List.Item>
          )}

          <List.Item>
            <Text as='p'>
              {i18n.translate('RecurringTimeSummary', {
                from: (
                  <Text as='span' fontWeight='semibold'>
                    {moment(scheduleSetup.startsAt).format('h:mm A')}
                  </Text>
                ),
                to: (
                  <Text as='span' fontWeight='semibold'>
                    {moment(scheduleSetup.endsAt).format('hh:mm A')}
                  </Text>
                ),
                eachDay: (
                  <Text as='span' fontWeight='semibold'>
                    {i18n.translate('EachDay')}
                  </Text>
                ),
              })}
            </Text>
          </List.Item>
        </List>
      </Box>
      <Text as='p' tone='subdued'>
        {i18n.translate('ScheduleWillAligned')}
      </Text>
    </BlockStack>
  );
};
