import React, { useMemo } from 'react';
import {
  Banner,
  Bleed,
  BlockStack,
  Box,
  Checkbox,
  ChoiceList,
  Divider,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TriggerIntendedAudienceDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  IntendedAudienceTagTypeDtoEnum,
  TagCategoryDtoEnum,
  TriggerIntendedAudienceTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';
import { useTags } from 'core/hooks/useTags';

type AudienceFilterProps = {
  audienceFilter?: TriggerIntendedAudienceDto;
  showValidation: boolean;
  updateAudienceFilter: (data: TriggerIntendedAudienceDto) => void;
};
export const AudienceFilter: React.FC<AudienceFilterProps> = ({
  audienceFilter,
  showValidation,
  updateAudienceFilter,
}) => {
  const [i18n] = useI18n();

  const shouldSkip = useMemo(
    () =>
      !audienceFilter?.tagType ||
      audienceFilter?.tagType === IntendedAudienceTagTypeDtoEnum.ALL ||
      (audienceFilter?.type !==
        TriggerIntendedAudienceTypeDtoEnum.CUSTOMERS_ONLY &&
        audienceFilter?.type !==
          TriggerIntendedAudienceTypeDtoEnum.GUESTS_AND_SELECTED_CUSTOMERS),
    [audienceFilter]
  );

  const { tagList: availableTags } = useTags(
    shouldSkip,
    TagCategoryDtoEnum.CUSTOMER
  );

  const audienceOptions = useMemo(
    () =>
      Object.values(TriggerIntendedAudienceTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  const customerTagOptions = useMemo(
    () =>
      Object.values(IntendedAudienceTagTypeDtoEnum).map((value) => ({
        label: i18n.translate(`${value}_TAG`),
        value: value,
      })),
    [i18n]
  );

  const showCustomerTag = useMemo(
    () =>
      audienceFilter?.type !== TriggerIntendedAudienceTypeDtoEnum.GUESTS_ONLY,
    [audienceFilter?.type]
  );

  return (
    <>
      <ChoiceList
        title=''
        selected={[audienceFilter?.type as string]}
        onChange={(value: TriggerIntendedAudienceTypeDtoEnum[]) =>
          updateAudienceFilter({ ...audienceFilter, type: value[0] })
        }
        choices={audienceOptions}
      />
      {showCustomerTag && (
        <Box
          padding='400'
          borderColor='border'
          borderWidth='025'
          borderRadius='200'
        >
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' fontWeight='semibold'>
                {i18n.translate('CustomerTag')}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('CustomerTagSubtitle')}
              </Text>
            </BlockStack>
            <ChoiceList
              title=''
              selected={[audienceFilter?.tagType as string]}
              choices={customerTagOptions}
              onChange={(value: IntendedAudienceTagTypeDtoEnum[]) =>
                updateAudienceFilter({ ...audienceFilter, tagType: value[0] })
              }
            />
            {audienceFilter?.tagType ===
              IntendedAudienceTagTypeDtoEnum.SELECTED && (
              <MultiselectTagCombobox
                id='CustomerTagsLabel'
                label={i18n.translate('CustomerTagsLabel')}
                placeholder={i18n.translate('CustomerTagsPlaceholder')}
                tagsInside
                requiredIndicator={showValidation}
                labelHidden={false}
                suggestions={availableTags}
                selectedTags={audienceFilter?.includedTags || []}
                setSelectedTags={(tags: string[]) =>
                  updateAudienceFilter({
                    ...audienceFilter,
                    includedTags: tags,
                  })
                }
              />
            )}
            <Divider />
            <Checkbox
              label={i18n.translate('ExcludeCustomers')}
              checked={audienceFilter?.excludedTags?.enabled}
              onChange={(value) =>
                updateAudienceFilter({
                  ...audienceFilter,
                  excludedTags: {
                    ...audienceFilter?.excludedTags,
                    enabled: value,
                  },
                })
              }
            />
            {audienceFilter?.excludedTags?.enabled && (
              <MultiselectTagCombobox
                id='CustomerTagsLabel'
                label={i18n.translate('CustomerTagsLabel')}
                placeholder={i18n.translate('CustomerTagsPlaceholder')}
                tagsInside
                requiredIndicator={showValidation}
                labelHidden={false}
                suggestions={availableTags}
                selectedTags={audienceFilter?.excludedTags.value || []}
                setSelectedTags={(tags: string[]) =>
                  updateAudienceFilter({
                    ...audienceFilter,
                    excludedTags: {
                      ...audienceFilter.excludedTags,
                      value: tags,
                    },
                  })
                }
              />
            )}
            <Bleed marginBlockEnd='400' marginInline='400'>
              <Box
                padding='300'
                borderColor='border-brand'
                borderBlockStartWidth='025'
                borderEndEndRadius='200'
                borderEndStartRadius='200'
                background='bg-surface-secondary'
              >
                <Text as='p' tone='subdued'>
                  {i18n.translate('BannerInfo', {
                    followingArticle: (
                      <Link monochrome>
                        {i18n.translate('followingArticle')}
                      </Link>
                    ),
                  })}
                </Text>
              </Box>
            </Bleed>
          </BlockStack>
        </Box>
      )}
    </>
  );
};
