import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'core/hooks';
import { setSettingsTransformed } from 'core/store/settingsSlice';
import { ActiveTransformedSettingsEnum } from 'features/settings/enums/SettingsType';

export const useSettingsPageBackAction = () => {
  const dispatch = useAppDispatch();
  const { settingsTransformed } = useAppSelector((store) => store.settings);

  const pageBackAction = useMemo(
    () =>
      settingsTransformed === ActiveTransformedSettingsEnum.SETTINGS_PAGE
        ? {
            onAction: () =>
              dispatch(
                setSettingsTransformed(
                  ActiveTransformedSettingsEnum.NAVIGATION_BAR
                )
              ),
          }
        : undefined,
    [settingsTransformed]
  );

  return pageBackAction;
};
