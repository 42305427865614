import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { OfferNameDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  ActionList,
  BlockStack,
  Button,
  Card,
  Icon,
  InlineStack,
  Link,
  Popover,
  Text,
  TextField,
} from '@shopify/polaris';
import {
  MenuHorizontalIcon,
  DisabledIcon,
  MagicIcon,
} from '@shopify/polaris-icons';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { useOfferNameConfiguration } from 'features/promotions/hooks/useOfferNameConfiguration';
import { setShouldAnimateAiGeneratedName } from 'core/store/offersWizardSlice';

type NameSectionProps = {
  name?: OfferNameDto;
  onOfferNameUpdate: (data: OfferNameDto) => void;
  onFormValidityChange: (isValid: boolean) => void;
};

export const NameSection: React.FC<NameSectionProps> = (props) => {
  const [i18n] = useI18n();

  const dispatch = useAppDispatch();

  const { name, onOfferNameUpdate, onFormValidityChange } = props;

  const {
    generateOfferNameIsLoading,
    offersNamesSwitchAiUsageIsLoading,
    offersNamesSwitchAiUsageData,
    generateOfferNameData,
    offersNamesSwitchAiUsage,
    generateOfferName,
  } = useOfferNameConfiguration();

  const {
    offerAiNamingLocales,
    isUpdatePromotionOfferDraftIsFetching,
    shouldAnimateAiGeneratedName,
  } = useAppSelector((state) => state.offersWizard);

  const { showValidation } = useAppSelector((store) => store.promotions);

  const [popoverActive, setPopoverActive] = useState(false);
  const [extendedLoading, setExtendedLoading] = useState(false);

  const isLoading = useMemo(
    () =>
      isUpdatePromotionOfferDraftIsFetching ||
      offersNamesSwitchAiUsageIsLoading ||
      generateOfferNameIsLoading ||
      extendedLoading,
    [
      isUpdatePromotionOfferDraftIsFetching,
      offersNamesSwitchAiUsageIsLoading,
      generateOfferNameIsLoading,
      extendedLoading,
    ]
  );

  const [typedValue, setTypedValue] = useState(
    name?.isAiBased ? name?.generatedName || '' : name?.customName || ''
  );

  const currentValue = useMemo(
    () => (name?.isAiBased ? typedValue : name?.customName),
    [name?.customName, typedValue, name?.isAiBased]
  );

  const isInvalid = useMemo(
    () => !name?.isAiBased && !currentValue?.length,
    [name?.isAiBased, currentValue]
  );

  const updateNameDataField = useCallback(
    (field: keyof OfferNameDto, value: string | boolean) => {
      if (!isLoading) {
        onOfferNameUpdate({
          ...name,
          [field]: value,
        });
        if (field === 'locale') {
          generateOfferName(value as string);
        }
      }
    },
    [name, isLoading, onOfferNameUpdate, generateOfferName]
  );

  const popoverActions = useMemo(() => {
    return [
      ...(name?.isAiBased
        ? [
            {
              title: i18n.translate('Language'),
              items: (offerAiNamingLocales || []).map((locale) => ({
                content: locale.name,
                active: locale.code === name?.locale,
                onAction: () => {
                  updateNameDataField('locale', locale.code || '');
                  setPopoverActive(false);
                },
              })),
            },
          ]
        : []),
      {
        items: [
          {
            icon: name?.isAiBasedForAllOffers ? DisabledIcon : MagicIcon,
            content: i18n.translate(
              name?.isAiBasedForAllOffers ? 'Disable' : 'Enable'
            ),
            onAction: () => {
              if (name?.locale) {
                offersNamesSwitchAiUsage(
                  !name?.isAiBasedForAllOffers,
                  name?.locale
                );
                setPopoverActive(false);
              }
            },
          },
        ],
      },
    ];
  }, [
    i18n,
    offerAiNamingLocales,
    name?.locale,
    name?.isAiBased,
    name?.isAiBasedForAllOffers,
    updateNameDataField,
    offersNamesSwitchAiUsage,
  ]);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((active) => !active),
    []
  );

  useEffect(() => {
    if (offersNamesSwitchAiUsageData?.offerName) {
      onOfferNameUpdate(offersNamesSwitchAiUsageData.offerName);
    }
  }, [offersNamesSwitchAiUsageData?.offerName]);

  useEffect(() => {
    if (generateOfferNameData?.name) {
      updateNameDataField('generatedName', generateOfferNameData.name);
    }
  }, [generateOfferNameData?.name]);

  useEffect(() => onFormValidityChange(!isInvalid), [isInvalid]);

  // ✅ Typing animation
  useEffect(() => {
    if (shouldAnimateAiGeneratedName && name?.generatedName) {
      setExtendedLoading(true);
      let index = 0;
      const characters = name.generatedName.split('');
      let typedName = '';

      const typingInterval = setInterval(() => {
        if (index < characters.length) {
          typedName += characters[index];
          setTypedValue(typedName);
          index++;
        } else {
          clearInterval(typingInterval);
          setExtendedLoading(false);
          setTypedValue(typedName);
          dispatch(setShouldAnimateAiGeneratedName(false));
        }
      }, 25); // Typing speed (25ms per character)

      return () => clearInterval(typingInterval);
    }
  }, [shouldAnimateAiGeneratedName, name?.generatedName]);

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <InlineStack align='space-between' blockAlign='center'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('Title')}
            </Text>
            <Popover
              active={popoverActive}
              activator={
                <Button
                  onClick={togglePopoverActive}
                  icon={MenuHorizontalIcon}
                  variant='tertiary'
                />
              }
              autofocusTarget='first-node'
              onClose={togglePopoverActive}
            >
              <div style={{ width: 238 }}>
                <ActionList actionRole='menuitem' sections={popoverActions} />
              </div>
            </Popover>
          </InlineStack>

          <Text as='span' tone='subdued'>
            {i18n.translate('Description')}
          </Text>
        </BlockStack>
        <TextField
          label=''
          labelHidden
          value={currentValue}
          loading={isLoading}
          prefix={name?.isAiBased && <Icon source={MagicIcon} />}
          tone={name?.isAiBased ? 'magic' : undefined}
          maxLength={!name?.isAiBased ? 200 : undefined}
          showCharacterCount={!name?.isAiBased && !isLoading}
          error={showValidation && isInvalid && i18n.translate('Required')}
          helpText={
            name?.isAiBased ? (
              i18n.translate('HelpTextAi', {
                link: (
                  <Link
                    monochrome
                    onClick={() =>
                      updateNameDataField('isAiBased', !name?.isAiBased)
                    }
                  >
                    {i18n.translate('ManualSwitch')}
                  </Link>
                ),
              })
            ) : (
              <InlineStack gap='100' blockAlign='center'>
                <div>
                  <Icon source={MagicIcon} tone='magic' />
                </div>
                <Link
                  onClick={() =>
                    updateNameDataField('isAiBased', !name?.isAiBased)
                  }
                >
                  <Text as='p' tone='magic'>
                    {i18n.translate('HelpTextManual')}
                  </Text>
                </Link>
              </InlineStack>
            )
          }
          onChange={
            !name?.isAiBased
              ? (value) => updateNameDataField('customName', value)
              : undefined
          }
          autoComplete='off'
        />
      </BlockStack>
    </Card>
  );
};
