import {
  IntegrationsSettingsDto,
  useGetSettingsV6IntegrationsQuery,
  usePutSettingsV6IntegrationsMutation,
  useGetSettingsV6LookupTimezonesQuery,
  useGetSettingsV6LookupLocalesQuery,
  useGetSettingsV6LookupReleaseVersionsQuery,
  usePostSettingsV6WidgetInstallationSupportRequestMutation,
  InstallationSupportRequestDto,
  useGetSettingsV6ThemesWidgetSettingsPromotionCodeFieldQuery,
  usePutSettingsV6ThemesWidgetSettingsPromotionCodeFieldMutation,
  PromotionCodeFieldShopSettingsDto,
  PromotionSummaryShopSettingsDto,
  useGetSettingsV6ThemesWidgetSettingsPromotionSummaryQuery,
  usePutSettingsV6ThemesWidgetSettingsPromotionSummaryMutation,
  usePutSettingsV6ThemesWidgetSettingsOfferRulePopupMutation,
  OfferRulePopupShopSettingsDto,
  useGetSettingsV6ThemesWidgetSettingsCountdownClockQuery,
  usePutSettingsV6ThemesWidgetSettingsCountdownClockMutation,
  CountdownClockShopSettingsDto,
  usePutSettingsV6ThemesWidgetSettingsStrikethroughPricingProductMutation,
  ProductStrikethroughPricingShopSettingsDto,
  useGetSettingsV6TranslationsMainQuery,
  usePutSettingsV6TranslationsByLocaleMutation,
  TranslationsSettingsDto,
  usePutSettingsV6StyleMutation,
  StyleSettingsDto,
  useGetSettingsV6DiscountLinksQuery,
  usePutSettingsV6DiscountLinksMutation,
  DiscountLinksSettingsDto,
  useGetSettingsV6GiftsQuery,
  usePutSettingsV6GiftsMutation,
  GiftsSettingsDto,
  usePostSettingsV6ImportMutation,
  useGetSettingsV6CartBehaviorQuery,
  usePutSettingsV6CartBehaviorMutation,
  CartBehaviorSettingsDto,
  useGetSettingsV6TagsMetadataQuery,
  usePutSettingsV6TagsMetadataMutation,
  TagsMetadataSettingsDto,
  useGetSettingsV6TestingQuery,
  usePutSettingsV6TestingMutation,
  TestingSettingsDto,
  useGetSettingsV6AccountQuery,
  usePutSettingsV6AccountMutation,
  AccountSettingsDto,
  useGetSettingsV6PlansCurrentQuery,
  usePostSettingsV6PlansSelectSubscriptionMutation,
  SelectSubscriptionPlanRequestDto,
  usePostSettingsV6PlansBillsMutation,
  GetBillingsRequestDto,
  usePostSettingsV6PlansCancelSubscriptionMutation,
  CancelSubscriptionRequestDto,
  useGetSettingsV6PlansQuery,
  useGetSettingsV6CheckoutQuery,
  usePutSettingsV6CheckoutMutation,
  CheckoutSettingsDto,
  usePostSettingsV6PlansResumeSubscriptionMutation,
  usePostSettingsV6PlansPlanSupportingFeatureMutation,
  GetPlanSupportingFeatureRequestDto,
  usePostSettingsV6PlansRequestDevelopmentPlanMutation,
  useGetSettingsV6ShippingRatesQuery,
  usePostSettingsV6ShippingRatesMutation,
  CustomShippingRateDto,
  usePutSettingsV6ShippingRatesByIdMutation,
  useDeleteSettingsV6ShippingRatesByIdMutation,
  useGetSettingsV6NotificationsQuery,
  usePutSettingsV6NotificationsMutation,
  NotificationsSettingsDto,
  useGetSettingsV6DiscountCodesDiscountNinjaPromoCodesQuery,
  useGetSettingsV6DiscountCodesShopifyDiscountCodesQuery,
  usePutSettingsV6DiscountCodesShopifyDiscountCodesMutation,
  ShopifyDiscountCodesSettingsDto,
  usePutSettingsV6DiscountCodesDiscountNinjaPromoCodesMutation,
  DiscountNinjaPromoCodesSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { PlanThresholdTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import {
  useGetWidgetsV6LookupIconsCustomQuery,
  useGetWidgetsV6LookupImagesCustomQuery,
  useIconsQuery,
  useImagesQuery,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { useAppSelector } from 'core/hooks';
import {
  setAvailableLocales,
  setIntegrations,
  setLocalesFetch,
  setIsUnsavedChanges,
} from 'core/store/settingsSlice';
import {
  setCustomIconLibary,
  setCustomImageLibary,
  setDefaultIconLibary,
  setDefaultImageLibary,
} from 'core/store/widgetsSlice';
import { isEmpty } from 'lodash';
import { useEffect, useCallback, SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';

const APP_NAME = process.env.REACT_APP_APP_NAME || '';

export enum SettingsFetchTypeEnum {
  NOTIFICATION = 'NOTIFICATION',
  POP_UP = 'POP_UP',
  OFFER_RULE_POPUP = 'OFFER_RULE_POPUP',
  CHECKOUT_LOADING_POPUP = 'CHECKOUT_LOADING_POPUP',
  COUNTDOWN_CLOCK = 'COUNTDOWN_CLOCK',
  DISCOUNT_LABEL = 'DISCOUNT_LABEL',
  FREQUENTLY_BOUGHT_TOGETHER = 'FREQUENTLY_BOUGHT_TOGETHER',
  PRODUCT_BANNER = 'PRODUCT_BANNER',
  PROMOTION_CODE_FIELD = 'PROMOTION_CODE_FIELD',
  PROMOTION_SUMMARY = 'PROMOTION_SUMMARY',
  VOLUME_DISCOUNT = 'VOLUME_DISCOUNT',
  PRODUCT_STRIKETHROUGH_PRICING = 'PRODUCT_STRIKETHROUGH_PRICING',
  CART_TEXT = 'CART_TEXT',
  PROMOTIONAL_BADGE = 'PROMOTIONAL_BADGE',
  CUSTOM_STRIKETHROUGH_PRICING = 'CUSTOM_STRIKETHROUGH_PRICING',
  NINJA_CART = 'NINJA_CART',
  ESSENTIALS = 'ESSENTIALS',
  THEMES_SLOTS = 'THEMES_SLOTS',
  TOGGLE_WIDGET_MANUALLY = 'TOGGLE_WIDGET_MANUALLY',
  WIDGET_SUPPORT_REQUEST = 'WIDGET_SUPPORT_REQUEST',
  INTEGRATIONS = 'INTEGRATIONS',
  ACCOUNT = 'ACCOUNT',
  BRAND_COLORS = 'BRAND_COLORS',
  TRANSLATION_MAIN = 'TRANSLATION_MAIN',
  TRANSLATION_LOCALE = 'TRANSLATION_LOCALE',
  ORDER_TAGS = 'ORDER_TAGS',
  ADVANCED = 'ADVANCED',
  CHECKOUT_OPTIONS = 'CHECKOUT_OPTIONS',
  MANAGE_WIDGETS = 'MANAGE_WIDGETS',
  CART_BEHAVIOR = 'CART_BEHAVIOR',
  AUTO_TAGGING = 'AUTO_TAGGING',
  TESTING = 'TESTING',
  DISCOUNT_LINKS = 'DISCOUNT_LINKS',
  GIFTS = 'GIFTS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  IMPORT_EXPORT = 'IMPORT_EXPORT',
  TIMEZONE = 'TIMEZONE',
  PLAN = 'PLAN',
  PLAN_PAUSE_DETAILS = 'PLAN_PAUSE_DETAILS',
  PLAN_DETAILS = 'PLAN_DETAILS',
  SYSTEM_CHECK = 'SYSTEM_CHECK',
  CUSTOM_SHIPPING_RATES = 'CUSTOM_SHIPPING_RATES',
  DISCOUNT_CODES = 'DISCOUNT_CODES',
  SHOPIFY_DISCOUNT_CODES = 'SHOPIFY_DISCOUNT_CODES',
  DN_PROMO_CODES = 'DN_PROMO_CODES',
}

export const useConfigureSettings = (
  fetchType?: SettingsFetchTypeEnum,
  skipUnnecessaryRequests?: boolean
) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const ENCODED_HOST = localStorage.getItem('encodedHost') || '';
  const dispatch = useDispatch();

  const { integrations, localesFetch, availableLocales, releaseVersionsFetch } =
    useAppSelector((state) => state.settings);

  //   getSettingsV6DiscountCodesShopifyDiscountCodes
  // getSettingsV6DiscountCodesDiscountNinjaPromoCodes

  const {
    data: shopifyDiscountCodesData,
    isFetching: shopifyDiscountCodesIsFetching,
    refetch: refetchShopifyDiscountCodesData,
  } = useGetSettingsV6DiscountCodesShopifyDiscountCodesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: !(
        fetchType === SettingsFetchTypeEnum.SHOPIFY_DISCOUNT_CODES ||
        fetchType === SettingsFetchTypeEnum.DISCOUNT_CODES
      ),
      refetchOnMountOrArgChange: true,
    }
  );
  const [saveSDCMutation, { isLoading: saveShopifyDiscountCodesIsLoading }] =
    usePutSettingsV6DiscountCodesShopifyDiscountCodesMutation();

  const saveShopifyDiscountCodes = useCallback(
    async (data: ShopifyDiscountCodesSettingsDto, onSuccess: () => void) => {
      const response = await saveSDCMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess);
      return response;
    },
    [saveSDCMutation]
  );

  const {
    data: dnPromocodesData,
    isFetching: dnPromocodesIsFetching,
    refetch: refetchDNPromocodesData,
  } = useGetSettingsV6DiscountCodesDiscountNinjaPromoCodesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: !(
        fetchType === SettingsFetchTypeEnum.DN_PROMO_CODES ||
        fetchType === SettingsFetchTypeEnum.DISCOUNT_CODES
      ),
      refetchOnMountOrArgChange: true,
    }
  );

  const [saveDNPromocodesMutation, { isLoading: saveDNPromocodesIsLoading }] =
    usePutSettingsV6DiscountCodesDiscountNinjaPromoCodesMutation();

  const saveDNPromocodes = useCallback(
    async (data: DiscountNinjaPromoCodesSettingsDto, onSuccess: () => void) => {
      const response = await saveDNPromocodesMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess);
      return response;
    },
    [saveSDCMutation]
  );

  const {
    data: shippingRatesData,
    isFetching: shippingRatesIsFetching,
    refetch: refetchShippingRatesData,
  } = useGetSettingsV6ShippingRatesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.CUSTOM_SHIPPING_RATES,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    createShippingRateMutation,
    { isLoading: createShippingRateIsLoading, error: createShippingRateError },
  ] = usePostSettingsV6ShippingRatesMutation();

  const createShippingRate = useCallback(
    async (
      data: CustomShippingRateDto,
      onSuccess: (newRate: CustomShippingRateDto) => void
    ) => {
      await createShippingRateMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then((res) => onSuccess(res))
        .catch((err) => null);
    },
    [createShippingRateMutation]
  );

  const [
    updateShippingRateMutation,
    { isLoading: updateShippingRateIsLoading, error: updateShippingRateError },
  ] = usePutSettingsV6ShippingRatesByIdMutation();

  const updateShippingRate = useCallback(
    async (data: CustomShippingRateDto, onSuccess: () => void) => {
      await updateShippingRateMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
        id: data.id || '',
      })
        .unwrap()
        .then(() => onSuccess())
        .catch((err) => null);
    },
    [updateShippingRateMutation]
  );

  const [
    deleteShippingRateMutation,
    { isLoading: deleteShippingRateIsLoading, error: deleteShippingRateError },
  ] = useDeleteSettingsV6ShippingRatesByIdMutation();

  const deleteShippingRate = useCallback(
    async (id: string, onSuccess: () => void) => {
      await deleteShippingRateMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => onSuccess())
        .catch((err) => null);
    },
    [deleteShippingRateMutation]
  );

  const {
    data: checkoutOptionsData,
    isFetching: checkoutOptionsIsFetching,
    refetch: refetchCheckoutOptionsData,
  } = useGetSettingsV6CheckoutQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.CHECKOUT_OPTIONS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveCheckoutOptionsMutation,
    {
      isLoading: saveCheckoutOptionsIsLoading,
      error: saveCheckoutOptionsError,
    },
  ] = usePutSettingsV6CheckoutMutation();

  const saveCheckoutOptions = useCallback(
    async (data: CheckoutSettingsDto, onSuccess: () => void) => {
      await saveCheckoutOptionsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch((err) => null);
    },
    [saveCheckoutOptionsMutation]
  );

  const {
    data: planDetailsData,
    isFetching: planDetailsIsFetching,
    refetch: refetchPlanDetailsData,
  } = useGetSettingsV6PlansQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      thresholdType: PlanThresholdTypeDtoEnum.ORDER,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.PLAN_DETAILS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    requestDevelopmentPlanMutation,
    {
      isLoading: requestDevelopmentPlanIsLoading,
      error: requestDevelopmentPlanError,
      isSuccess: requestDevelopmentPlanSuccess,
    },
  ] = usePostSettingsV6PlansRequestDevelopmentPlanMutation();

  const requestDevelopmentPlan = useCallback(async () => {
    await requestDevelopmentPlanMutation({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    }).unwrap();
  }, [requestDevelopmentPlanMutation]);

  const [
    cancelSubscriptionMutation,
    { isLoading: cancelSubscriptionIsLoading, error: cancelSubscriptionError },
  ] = usePostSettingsV6PlansCancelSubscriptionMutation();

  const cancelSubscription = useCallback(
    async (data: CancelSubscriptionRequestDto, refetchData: () => void) => {
      await cancelSubscriptionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => refetchData())
        .catch((err) => null);
    },
    [cancelSubscriptionMutation]
  );

  const [
    getPlansSupportingFeatureMutation,
    {
      isLoading: plansSupportingFeatureIsLoading,
      data: plansSupportingFeatureData,
    },
  ] = usePostSettingsV6PlansPlanSupportingFeatureMutation();

  const getPlansSupportingFeature = useCallback(
    async (data: GetPlanSupportingFeatureRequestDto) => {
      await getPlansSupportingFeatureMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      }).unwrap();
    },
    [getPlansSupportingFeatureMutation]
  );

  const [
    resumeSubscriptionMutation,
    { isLoading: resumeSubscriptionIsLoading, error: resumeSubscriptionError },
  ] = usePostSettingsV6PlansResumeSubscriptionMutation();

  const resumeSubscription = useCallback(
    async (refetchData: () => void) => {
      await resumeSubscriptionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      })
        .unwrap()
        .then(() => refetchData());
    },
    [resumeSubscriptionMutation]
  );

  const [
    getBillsListMutation,
    {
      data: billsListData,
      isLoading: billsListIsLoading,
      error: billsListError,
    },
  ] = usePostSettingsV6PlansBillsMutation();

  const getBillsList = useCallback(
    async (data: GetBillingsRequestDto) => {
      await getBillsListMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      }).unwrap();
    },
    [getBillsListMutation]
  );

  const {
    data: currentPlanData,
    isFetching: currentPlansIsLoading,
    error: currentPlanDataError,
    refetch: refetchCurrentPlanData,
  } = useGetSettingsV6PlansCurrentQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: fetchType !== SettingsFetchTypeEnum.PLAN,
    }
  );

  const [
    selectSubscriptionMutation,
    {
      data: selectSubscriptionData,
      isLoading: selectSubscriptionIsLoading,
      error: selectSubscriptionError,
    },
  ] = usePostSettingsV6PlansSelectSubscriptionMutation();

  const selectSubscription = useCallback(
    async (body: SelectSubscriptionPlanRequestDto) => {
      await selectSubscriptionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        'X-DiscountNinja-EncodedHost': ENCODED_HOST,
        body: body,
      })
        .unwrap()
        .catch((err) => null);
    },
    [selectSubscriptionMutation]
  );

  const [
    saveStrikethroughProductMutation,
    { isLoading: saveStrikethroughProductIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsStrikethroughPricingProductMutation();

  const saveStrikethroughProduct = useCallback(
    async (
      data: ProductStrikethroughPricingShopSettingsDto,
      onSuccess: () => void
    ) => {
      await saveStrikethroughProductMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
    },
    [saveStrikethroughProductMutation, dispatch]
  );

  const {
    data: countdownClockData,
    isFetching: countdownClockDataIsFetching,
    refetch: refetchCountdownClockData,
  } = useGetSettingsV6ThemesWidgetSettingsCountdownClockQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.COUNTDOWN_CLOCK,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveCountdownClockMutation,
    { isLoading: saveCountdownClockIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsCountdownClockMutation();

  const saveCountdownClock = useCallback(
    async (data: CountdownClockShopSettingsDto) => {
      await saveCountdownClockMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [saveCountdownClockMutation, dispatch]
  );

  const [
    saveOfferRulePopupMutation,
    { isLoading: saveOfferRulePopupIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsOfferRulePopupMutation();

  const saveOfferRulePopup = useCallback(
    async (data: OfferRulePopupShopSettingsDto) => {
      await saveOfferRulePopupMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [saveOfferRulePopupMutation, dispatch]
  );

  const {
    data: promotionSummaryData,
    isFetching: promotionSummaryDataIsFetching,
    refetch: refetchPromotionSummaryData,
  } = useGetSettingsV6ThemesWidgetSettingsPromotionSummaryQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.PROMOTION_SUMMARY,
      refetchOnMountOrArgChange: true,
    }
  );
  const [
    savePromotionSummaryMutation,
    { isLoading: savePromotionSummaryIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsPromotionSummaryMutation();

  const savePromotionSummary = useCallback(
    async (data: PromotionSummaryShopSettingsDto) => {
      await savePromotionSummaryMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [savePromotionSummaryMutation, dispatch]
  );

  const {
    data: promotionCodeData,
    isFetching: promotionCodeDataIsFetching,
    refetch: refetchPromotionCodeData,
  } = useGetSettingsV6ThemesWidgetSettingsPromotionCodeFieldQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.PROMOTION_CODE_FIELD,
      refetchOnMountOrArgChange: true,
    }
  );
  const [savePromotionCodeMutation, { isLoading: savePromotionCodeIsLoading }] =
    usePutSettingsV6ThemesWidgetSettingsPromotionCodeFieldMutation();

  const savePromotionCode = useCallback(
    async (data: PromotionCodeFieldShopSettingsDto) => {
      await savePromotionCodeMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(setIsUnsavedChanges(false));
        });
    },
    [savePromotionCodeIsLoading, dispatch]
  );

  const { data: defaultImageLibary, isFetching: isDefaultImageLibaryFetching } =
    useImagesQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: skipUnnecessaryRequests }
    );

  const {
    data: customImageLibary,
    isFetching: isCustomImageLibaryFetching,
    refetch: refetchCustomImageLibary,
  } = useGetWidgetsV6LookupImagesCustomQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: skipUnnecessaryRequests }
  );

  useEffect(() => {
    if (defaultImageLibary) {
      dispatch(setDefaultImageLibary(defaultImageLibary));
    }
  }, [defaultImageLibary]);

  useEffect(() => {
    if (customImageLibary) {
      dispatch(setCustomImageLibary(customImageLibary));
    }
  }, [customImageLibary]);

  const { data: defaultIconLibary, isFetching: isDefaultIconLibaryFetching } =
    useIconsQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: skipUnnecessaryRequests }
    );
  const {
    data: customIconLibary,
    isFetching: isCustomIconLibaryFetching,
    refetch: refetchCustomIconLibary,
  } = useGetWidgetsV6LookupIconsCustomQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: skipUnnecessaryRequests }
  );

  useEffect(() => {
    if (defaultIconLibary) {
      dispatch(setDefaultIconLibary(defaultIconLibary));
    }
  }, [defaultIconLibary]);

  useEffect(() => {
    if (customIconLibary) {
      dispatch(setCustomIconLibary(customIconLibary));
    }
  }, [customIconLibary]);

  const {
    data: localesData,
    isFetching: localesIsFetching,
    error: localesError,
    refetch: refetchLocales,
  } = useGetSettingsV6LookupLocalesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: !localesFetch, refetchOnMountOrArgChange: false }
  );

  useEffect(() => {
    if (localesData && !availableLocales.length) {
      dispatch(setAvailableLocales(localesData));
      dispatch(setLocalesFetch(false));
    }
  }, [localesData]);

  const {
    data: integrationsData,
    isFetching: integrationsDataIsFetching,
    error: integrationsDataError,
  } = useGetSettingsV6IntegrationsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: fetchType !== SettingsFetchTypeEnum.INTEGRATIONS }
  );

  useEffect(() => {
    if (integrationsData && isEmpty(integrations)) {
      dispatch(setIntegrations(integrationsData));
    }
  }, [integrationsData, dispatch]);

  const {
    data: timezoneData,
    isFetching: timezoneIsFetching,
    error: timezoneError,
  } = useGetSettingsV6LookupTimezonesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: fetchType !== SettingsFetchTypeEnum.TIMEZONE }
  );

  const {
    data: releaseVersionsData,
    isFetching: releaseVersionsIsFetching,
    error: releaseVersionsError,
    refetch: refetchReleaseVersions,
  } = useGetSettingsV6LookupReleaseVersionsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: !releaseVersionsFetch }
  );

  const [
    saveIntegrationsMutation,
    { isLoading: saveIntegrationsSettingsLoading },
  ] = usePutSettingsV6IntegrationsMutation();

  const saveIntegrationsSettings = useCallback(
    async (data: IntegrationsSettingsDto, onSuccess: () => void) => {
      await saveIntegrationsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
    },
    [saveIntegrationsMutation, dispatch]
  );

  const [
    createSupportRequestMutation,
    {
      data: createSupportRequestData,
      isLoading: createSupportRequestIsLoading,
    },
  ] = usePostSettingsV6WidgetInstallationSupportRequestMutation();

  const createSupportRequest = useCallback(
    async (data: InstallationSupportRequestDto, onSuccess: () => void) => {
      const response = await createSupportRequestMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        'X-DiscountNinja-EncodedHost': ENCODED_HOST,
        body: data,
      })
        .unwrap()
        .then((res) => {
          onSuccess();
          return res;
        })
        .catch(() => null);
      return response;
    },
    [createSupportRequestMutation]
  );

  const {
    data: translationMainData,
    isFetching: translationMainIsFetching,
    refetch: refetchTranslationMainData,
  } = useGetSettingsV6TranslationsMainQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.TRANSLATION_MAIN,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    translationLocaleMutation,
    { isLoading: translationLocaleDataIsLoading },
  ] = usePutSettingsV6TranslationsByLocaleMutation();

  const translationLocaleDataChange = useCallback(
    async (
      data: TranslationsSettingsDto,
      locale: string,
      onSuccess: () => void
    ) => {
      const response = await translationLocaleMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        locale: locale,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
      return response;
    },
    [translationLocaleMutation]
  );

  const [brandColorsMutation, { isLoading: brandColorsDataIsLoading }] =
    usePutSettingsV6StyleMutation();

  const brandColorsDataChange = useCallback(
    async (
      data: StyleSettingsDto,
      setRefetchBrandColors?: Dispatch<SetStateAction<boolean>>
    ) => {
      try {
        const response = await brandColorsMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        }).unwrap();

        if (setRefetchBrandColors) {
          setRefetchBrandColors(true);
        }

        return response;
      } catch (error) {
        console.error('Error in brandColorsDataChange:', error);
        // Optionally handle error here
      }
    },
    [brandColorsMutation]
  );

  const {
    data: cartBehaviorData,
    isFetching: cartBehaviorDataIsFetching,
    refetch: refetchCartBehaviorData,
  } = useGetSettingsV6CartBehaviorQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.CART_BEHAVIOR,
      refetchOnMountOrArgChange: true,
    }
  );

  const [cartBehaviorMutation, { isLoading: cartBehaviorDataIsLoading }] =
    usePutSettingsV6CartBehaviorMutation();

  const cartBehaviorDataChange = useCallback(
    async (data: CartBehaviorSettingsDto, onSuccess: () => void) => {
      const response = await cartBehaviorMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch((err) => null);
      return response;
    },
    [cartBehaviorMutation]
  );

  const {
    data: autoTaggingData,
    isFetching: autoTaggingDataIsFetching,
    refetch: refetchAutoTaggingData,
  } = useGetSettingsV6TagsMetadataQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.AUTO_TAGGING,
      refetchOnMountOrArgChange: true,
    }
  );

  const [saveAutoTaggingMutation, { isLoading: saveAutoTaggingIsLoading }] =
    usePutSettingsV6TagsMetadataMutation();

  const saveAutoTagging = useCallback(
    async (data: TagsMetadataSettingsDto, onSuccess: () => void) => {
      const response = await saveAutoTaggingMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
      return response;
    },
    [saveAutoTaggingMutation]
  );

  const {
    data: testingData,
    isFetching: testingIsFetching,
    refetch: refetchTestingData,
  } = useGetSettingsV6TestingQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.TESTING,
      refetchOnMountOrArgChange: true,
    }
  );

  const [testingMutation, { isLoading: testingDataIsLoading }] =
    usePutSettingsV6TestingMutation();

  const testingDataChange = useCallback(
    async (data: TestingSettingsDto) => {
      const response = await testingMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      }).unwrap();
      return response;
    },
    [testingMutation]
  );

  const {
    data: discountLinksData,
    isFetching: discountLinksIsFetching,
    refetch: refetchDiscountLinksData,
  } = useGetSettingsV6DiscountLinksQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.DISCOUNT_LINKS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [discountLinksMutation, { isLoading: discountLinksDataIsLoading }] =
    usePutSettingsV6DiscountLinksMutation();

  const discountLinksDataChange = useCallback(
    async (data: DiscountLinksSettingsDto, onSuccess: () => void) => {
      const response = await discountLinksMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch(() => null);
      return response;
    },
    [discountLinksMutation]
  );

  const {
    data: giftsData,
    isFetching: giftsDataIsFetching,
    refetch: refetchGiftsData,
  } = useGetSettingsV6GiftsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.GIFTS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [giftsMutation, { isLoading: giftsDataIsLoading }] =
    usePutSettingsV6GiftsMutation();

  const giftsDataChange = useCallback(
    async (data: GiftsSettingsDto, onSuccess: () => void) => {
      const response = await giftsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch((err) => null);
      return response;
    },
    [giftsMutation]
  );

  const {
    data: notificationsData,
    isFetching: notificationsDataIsFetching,
    refetch: refetchNotifications,
  } = useGetSettingsV6NotificationsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.NOTIFICATIONS,
      refetchOnMountOrArgChange: true,
    }
  );
  const [
    notificationsMutation,
    { isLoading: notificationsDataChangeIsLoading },
  ] = usePutSettingsV6NotificationsMutation();

  const notificationsDataChange = useCallback(
    async (data: NotificationsSettingsDto, onSuccess: () => void) => {
      const response = await notificationsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch((err) => null);
      return response;
    },
    [notificationsMutation]
  );

  const [importSettingsMutation, { isLoading: importSettingsIsLoading }] =
    usePostSettingsV6ImportMutation();

  const importSettings = useCallback(
    async (data: any) => {
      const response = await importSettingsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .catch((err) => null);
      return response;
    },
    [importSettingsMutation]
  );

  const {
    data: accountData,
    isFetching: accountDataIsFetching,
    refetch: refetchAccountData,
  } = useGetSettingsV6AccountQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== SettingsFetchTypeEnum.ACCOUNT,
      refetchOnMountOrArgChange: true,
    }
  );

  const [accountMutation, { isLoading: accountDataIsLoading }] =
    usePutSettingsV6AccountMutation();

  const accountDataChange = useCallback(
    async (data: AccountSettingsDto, onSuccess: () => void) => {
      const response = await accountMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch((err) => null);
      return response;
    },
    [accountMutation]
  );

  return {
    integrationsData,
    integrationsDataIsFetching,
    integrationsDataError,
    saveIntegrationsSettingsLoading,
    saveIntegrationsSettings,
    timezoneData,
    timezoneIsFetching,
    timezoneError,
    localesData,
    localesIsFetching,
    localesError,
    refetchLocales,
    releaseVersionsData,
    releaseVersionsIsFetching,
    releaseVersionsError,
    refetchReleaseVersions,
    createSupportRequest,
    createSupportRequestData,
    createSupportRequestIsLoading,
    defaultImageLibary,
    isDefaultImageLibaryFetching,
    customImageLibary,
    isCustomImageLibaryFetching,
    isCustomIconLibaryFetching,
    refetchCustomIconLibary,
    promotionCodeData,
    promotionCodeDataIsFetching,
    refetchPromotionCodeData,
    savePromotionCodeIsLoading,
    savePromotionCode,
    promotionSummaryData,
    promotionSummaryDataIsFetching,
    refetchPromotionSummaryData,
    savePromotionSummaryIsLoading,
    savePromotionSummary,
    saveOfferRulePopupIsLoading,
    saveOfferRulePopup,
    countdownClockData,
    countdownClockDataIsFetching,
    refetchCountdownClockData,
    saveCountdownClockIsLoading,
    saveCountdownClock,
    saveStrikethroughProductIsLoading,
    saveStrikethroughProduct,
    translationMainData,
    translationMainIsFetching,
    refetchTranslationMainData,
    translationLocaleDataIsLoading,
    translationLocaleDataChange,
    brandColorsDataIsLoading,
    brandColorsDataChange,
    cartBehaviorData,
    cartBehaviorDataIsFetching,
    refetchCartBehaviorData,
    cartBehaviorDataIsLoading,
    cartBehaviorDataChange,
    autoTaggingData,
    autoTaggingDataIsFetching,
    refetchAutoTaggingData,
    saveAutoTaggingIsLoading,
    saveAutoTagging,
    testingData,
    testingIsFetching,
    refetchTestingData,
    testingDataIsLoading,
    testingDataChange,
    discountLinksData,
    discountLinksIsFetching,
    refetchDiscountLinksData,
    discountLinksDataIsLoading,
    discountLinksDataChange,
    giftsData,
    giftsDataIsFetching,
    refetchGiftsData,
    giftsDataIsLoading,
    giftsDataChange,
    importSettingsIsLoading,
    importSettings,
    accountData,
    accountDataIsFetching,
    refetchAccountData,
    accountDataIsLoading,
    accountDataChange,
    currentPlanData,
    currentPlansIsLoading,
    currentPlanDataError,
    refetchCurrentPlanData,
    selectSubscriptionData,
    selectSubscriptionIsLoading,
    selectSubscriptionError,
    selectSubscription,
    billsListData,
    billsListIsLoading,
    billsListError,
    getBillsList,
    cancelSubscriptionIsLoading,
    cancelSubscriptionError,
    cancelSubscription,
    planDetailsData,
    planDetailsIsFetching,
    refetchPlanDetailsData,
    checkoutOptionsData,
    checkoutOptionsIsFetching,
    refetchCheckoutOptionsData,
    saveCheckoutOptionsIsLoading,
    saveCheckoutOptionsError,
    saveCheckoutOptions,
    resumeSubscriptionIsLoading,
    resumeSubscriptionError,
    resumeSubscription,
    plansSupportingFeatureIsLoading,
    plansSupportingFeatureData,
    getPlansSupportingFeature,
    requestDevelopmentPlanIsLoading,
    requestDevelopmentPlanError,
    requestDevelopmentPlanSuccess,
    requestDevelopmentPlan,
    shippingRatesData,
    shippingRatesIsFetching,
    refetchShippingRatesData,
    updateShippingRateIsLoading,
    updateShippingRateError,
    updateShippingRate,
    deleteShippingRateIsLoading,
    deleteShippingRateError,
    deleteShippingRate,
    createShippingRateIsLoading,
    createShippingRateError,
    createShippingRate,
    notificationsData,
    notificationsDataIsFetching,
    refetchNotifications,
    notificationsDataChangeIsLoading,
    notificationsDataChange,
    shopifyDiscountCodesData,
    shopifyDiscountCodesIsFetching,
    refetchShopifyDiscountCodesData,
    dnPromocodesData,
    dnPromocodesIsFetching,
    refetchDNPromocodesData,
    saveShopifyDiscountCodesIsLoading,
    saveShopifyDiscountCodes,
    saveDNPromocodesIsLoading,
    saveDNPromocodes,
  };
};
