import React, { useEffect, useMemo, useState } from 'react';
import './ToggleWidgetModal.scss';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import { Badge, BlockStack, Icon, InlineStack, Text } from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import {
  WidgetInstallationModeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { useI18n } from '@shopify/react-i18n';
import ToggleWidgetManually from './components/ToggleWidgetManually/ToggleWidgetManually';
import RequestWidgetSupport from './components/RequestWidgetSupport/RequestWidgetSupport';
import { SuccessSupportRequestModalState } from 'features/widgets/WidgetsControlCenter';

type ToggleWidgetModalProps = {
  installationLink?: string;
  // make required in future
  currentThemeName?: string;
  installationMode?: WidgetInstallationModeDtoEnum;
  widgetType: WidgetTypeDtoEnum;
  isOpen: boolean;
  isAppBlockRequestSupport?: boolean;
  onClose: () => void;
  setTemplatesUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  refetchWidget: () => void;
  setSuccessModal?: React.Dispatch<
    React.SetStateAction<SuccessSupportRequestModalState>
  >;
  setIsWidgetsListUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
};
enum ToggleWidgetModalTabDtoEnum {
  MAIN_TAB = 'MAIN_TAB',
  TOGGLE_MANUALLY_TAB = 'TOGGLE_MANUALLY_TAB',
  REQUEST_SUPPORT_TAB = 'REQUEST_SUPPORT_TAB',
}
const ToggleWidgetModal: React.FC<ToggleWidgetModalProps> = ({
  installationLink,
  installationMode,
  currentThemeName,
  widgetType,
  isOpen,
  isAppBlockRequestSupport,
  onClose,
  refetchWidget,
  setSuccessModal,
  setTemplatesUpdated,
  setIsWidgetsListUpdating,
}) => {
  const [i18n] = useI18n();
  const [activeTab, setActiveTab] = useState<ToggleWidgetModalTabDtoEnum>(
    ToggleWidgetModalTabDtoEnum.MAIN_TAB
  );

  const mainTab = useMemo(() => {
    const navigationCards = [
      ToggleWidgetModalTabDtoEnum.TOGGLE_MANUALLY_TAB,
      ToggleWidgetModalTabDtoEnum.REQUEST_SUPPORT_TAB,
    ].map((tab) => (
      <div
        key={tab}
        onClick={() => setActiveTab(tab)}
        className='TabNavigateCard'
      >
        <InlineStack align='space-between' blockAlign='center'>
          <BlockStack gap='100'>
            <InlineStack align='start' gap='200'>
              <Text fontWeight='bold' as='p'>
                {i18n.translate(`${tab}_OPTION`)}
              </Text>
              {tab === ToggleWidgetModalTabDtoEnum.REQUEST_SUPPORT_TAB && (
                <Badge>{i18n.translate('Recommended')}</Badge>
              )}
            </InlineStack>

            <Text tone='subdued' as='p'>
              {i18n.translate(`${tab}_SUBTITLE`)}
            </Text>
          </BlockStack>
          <div>
            <Icon source={ChevronRightIcon} />
          </div>
        </InlineStack>
      </div>
    ));
    return (
      <div className='ToggleWidgetModalContent'>
        <BlockStack gap='300'>{navigationCards}</BlockStack>
      </div>
    );
  }, [i18n]);

  const tabToShow = useMemo(() => {
    switch (activeTab) {
      case ToggleWidgetModalTabDtoEnum.MAIN_TAB:
        return mainTab;
      case ToggleWidgetModalTabDtoEnum.TOGGLE_MANUALLY_TAB:
        return (
          <ToggleWidgetManually
            installationLink={installationLink}
            widgetType={widgetType}
            installationMode={installationMode}
            setTemplatesUpdated={setTemplatesUpdated}
            onClose={onClose}
            refetchWidget={refetchWidget}
            setIsWidgetsListUpdating={setIsWidgetsListUpdating}
          />
        );
      case ToggleWidgetModalTabDtoEnum.REQUEST_SUPPORT_TAB:
        return (
          <RequestWidgetSupport
            onClose={onClose}
            widgetType={widgetType}
            currentThemeName={currentThemeName}
            skipUnnecessaryRequests
            refetchWidget={refetchWidget}
            setSuccessModal={setSuccessModal}
          />
        );
    }
  }, [activeTab, mainTab, widgetType, currentThemeName]);

  useEffect(() => {
    if (installationMode === WidgetInstallationModeDtoEnum.APP_BLOCKS) {
      isAppBlockRequestSupport
        ? setActiveTab(ToggleWidgetModalTabDtoEnum.REQUEST_SUPPORT_TAB)
        : setActiveTab(ToggleWidgetModalTabDtoEnum.TOGGLE_MANUALLY_TAB);
    }
  }, [isAppBlockRequestSupport, installationMode]);

  return (
    <ModalCustom
      title={i18n.translate(`${activeTab}_TITLE`, {
        widgetName: i18n.translate(widgetType),
      })}
      isOpen={isOpen}
      onBackButton={
        activeTab !== ToggleWidgetModalTabDtoEnum.MAIN_TAB &&
        installationMode !== WidgetInstallationModeDtoEnum.APP_BLOCKS
          ? () => setActiveTab(ToggleWidgetModalTabDtoEnum.MAIN_TAB)
          : undefined
      }
      showCloseButton
      onClose={onClose}
      shouldChangeTopPosition={false}
    >
      <div className='ToggleWidgetModal'>{tabToShow}</div>
    </ModalCustom>
  );
};

export default ToggleWidgetModal;
