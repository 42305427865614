import { useCallback, useMemo } from 'react';
import {
  NinjaCartGiftPickerWidgetSettingsDto,
  NinjaCartDrawerCartAnnouncementBarWidgetSettingsDto,
  NinjaCartPaymentOptionsWidgetSettingsDto,
  NinjaCartProductBadgeWidgetSettingsDto,
  NinjaCartPromotionSummaryWidgetSettingsDto,
  useGetSettingsV6ThemesNinjaCartSettingsCartTextQuery,
  useGetSettingsV6ThemesNinjaCartSettingsMultiGoalProgressBarQuery,
  useGetSettingsV6ThemesNinjaCartSettingsSingleGoalProgressBarQuery,
  useGetSettingsV6ThemesNinjaCartSettingsSubscriptionUpsellQuery,
  useGetSettingsV6ThemesNinjaCartSettingsTermsAndConditionsQuery,
  useGetSettingsV6ThemesNinjaCartSettingsGiftPickerQuery,
  useGetSettingsV6ThemesNinjaCartSettingsPaymentOptionsQuery,
  useGetSettingsV6ThemesNinjaCartSettingsProductBadgeQuery,
  useGetSettingsV6ThemesNinjaCartSettingsPromotionSummaryQuery,
  usePutSettingsV6ThemesNinjaCartSettingsGiftPickerMutation,
  useGetSettingsV6ThemesNinjaCartSettingsDrawerCartAnnouncementBarQuery,
  usePutSettingsV6ThemesNinjaCartSettingsDrawerCartAnnouncementBarMutation,
  usePutSettingsV6ThemesNinjaCartSettingsPaymentOptionsMutation,
  usePutSettingsV6ThemesNinjaCartSettingsProductBadgeMutation,
  usePutSettingsV6ThemesNinjaCartSettingsPromotionSummaryMutation,
  useGetSettingsV6ThemesNinjaCartSettingsOrderBumpQuery,
  usePutSettingsV6ThemesNinjaCartSettingsOrderBumpMutation,
  NinjaCartOrderBumpWidgetSettingsDto,
  usePutSettingsV6ThemesNinjaCartSettingsPromoCodeFieldMutation,
  NinjaCartPromoCodeFieldWidgetSettingsDto,
  useGetSettingsV6ThemesNinjaCartSettingsPromoCodeFieldQuery,
  useGetSettingsV6ThemesNinjaCartSettingsFrequentBoughtTogetherQuery,
  usePutSettingsV6ThemesNinjaCartSettingsFrequentBoughtTogetherMutation,
  NinjaCartFrequentBoughtTogetherWidgetSettingsDto,
  useGetSettingsV6NinjaCartWidgetInstallationByWidgetTypeQuery,
} from 'core/api/adminSettings/adminSettingsApi';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import resolveEnvVar from 'env-var-resolver';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

export const useNinjaCartWidget = (
  widgetType?: WidgetTypeDtoEnum,
  widgetTypeForStatus?: WidgetTypeDtoEnum
) => {
  const emptyWidgetSettingsQuery = useMemo(() => {
    switch (widgetType) {
      case WidgetTypeDtoEnum.NINJA_CART_CART_TEXT:
        return useGetSettingsV6ThemesNinjaCartSettingsCartTextQuery;
      case WidgetTypeDtoEnum.NINJA_CART_SUBSCRIPTION_UPSELL:
        return useGetSettingsV6ThemesNinjaCartSettingsSubscriptionUpsellQuery;
      case WidgetTypeDtoEnum.NINJA_CART_REWARDS_MULTI_GOAL_PROGRESS_BAR:
        return useGetSettingsV6ThemesNinjaCartSettingsMultiGoalProgressBarQuery;
      case WidgetTypeDtoEnum.NINJA_CART_REWARDS_SINGLE_GOAL_PROGRESS_BAR:
        return useGetSettingsV6ThemesNinjaCartSettingsSingleGoalProgressBarQuery;
      case WidgetTypeDtoEnum.NINJA_CART_TERMS_AND_CONDITIONS:
        return useGetSettingsV6ThemesNinjaCartSettingsTermsAndConditionsQuery;
      default:
        return useGetSettingsV6ThemesNinjaCartSettingsCartTextQuery;
    }
  }, [widgetType]);

  const {
    data: widgetStatusesData,
    isFetching: widgetStatusesDataIsFetching,
    refetch: refetchWidgetStatusesData,
  } = useGetSettingsV6NinjaCartWidgetInstallationByWidgetTypeQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      widgetType:
        widgetTypeForStatus ||
        WidgetTypeDtoEnum.NINJA_CART_DRAWER_CART_ANNOUNCEMENT_BAR,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !widgetTypeForStatus,
    }
  );

  const shouldFetchEmptyWidgetData = useMemo(
    () =>
      widgetType &&
      [
        WidgetTypeDtoEnum.NINJA_CART_CART_TEXT,
        WidgetTypeDtoEnum.NINJA_CART_SUBSCRIPTION_UPSELL,
        WidgetTypeDtoEnum.NINJA_CART_REWARDS_MULTI_GOAL_PROGRESS_BAR,
        WidgetTypeDtoEnum.NINJA_CART_REWARDS_SINGLE_GOAL_PROGRESS_BAR,
        WidgetTypeDtoEnum.NINJA_CART_TERMS_AND_CONDITIONS,
      ].includes(widgetType),
    [widgetType]
  );

  const {
    data: emptyWidgetSettingsData,
    isFetching: emptyWidgetSettingsDataIsFetching,
    refetch: refetchEmptyWidgetSettingsData,
  } = emptyWidgetSettingsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !shouldFetchEmptyWidgetData,
    }
  );

  const {
    data: ninjaCartProductBadgeData,
    isFetching: ninjaCartProductBadgeDataIsFetching,
    refetch: refetchNinjaCartProductBadgeData,
  } = useGetSettingsV6ThemesNinjaCartSettingsProductBadgeQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.NINJA_CART_PRODUCT_BADGE,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: ninjaCartPaymentOptionsData,
    isFetching: ninjaCartPaymentOptionsDataIsFetching,
    refetch: refetchNinjaCartPaymentOptionsData,
  } = useGetSettingsV6ThemesNinjaCartSettingsPaymentOptionsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.NINJA_CART_PAYMENT_OPTIONS,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: promotionSummaryData,
    isFetching: promotionSummaryDataIsFetching,
    refetch: refetchPromotionSummaryData,
  } = useGetSettingsV6ThemesNinjaCartSettingsPromotionSummaryQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: ninjaCartGiftPickerData,
    isFetching: ninjaCartGiftPickerDataIsFetching,
    refetch: refetchNinjaCartGiftPickerData,
  } = useGetSettingsV6ThemesNinjaCartSettingsGiftPickerQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.NINJA_CART_FREE_GIFT_PICKER,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: announcementBarData,
    isFetching: announcementBarDataIsFetching,
    refetch: refetchAnnouncementBarData,
  } = useGetSettingsV6ThemesNinjaCartSettingsDrawerCartAnnouncementBarQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip:
        widgetType !==
        WidgetTypeDtoEnum.NINJA_CART_DRAWER_CART_ANNOUNCEMENT_BAR,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: orderBumpData,
    isFetching: orderBumpDataIsFetching,
    refetch: refetchOrderBumpData,
  } = useGetSettingsV6ThemesNinjaCartSettingsOrderBumpQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.NINJA_CART_ORDER_BUMP,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: promotionCodeFieldData,
    isFetching: promotionCodeFieldDataIsFetching,
    refetch: refetchPromotionCodeFieldData,
  } = useGetSettingsV6ThemesNinjaCartSettingsPromoCodeFieldQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.NINJA_CART_PROMOTION_CODE_FIELD,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    savePromotionCodeFieldMutation,
    { isLoading: savePromotionCodeFieldIsLoading },
  ] = usePutSettingsV6ThemesNinjaCartSettingsPromoCodeFieldMutation();

  const savePromotionCodeField = useCallback(
    async (
      data: NinjaCartPromoCodeFieldWidgetSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await savePromotionCodeFieldMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [savePromotionCodeFieldMutation]
  );

  const [saveOrderBumpMutation, { isLoading: saveOrderBumpIsLoading }] =
    usePutSettingsV6ThemesNinjaCartSettingsOrderBumpMutation();

  const saveOrderBump = useCallback(
    async (
      data: NinjaCartOrderBumpWidgetSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveOrderBumpMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveOrderBumpMutation]
  );

  const {
    data: frequentlyBoughtTogetherData,
    isFetching: frequentlyBoughtTogetherDataIsFetching,
    refetch: refetchFrequentlyBoughtTogetherData,
  } = useGetSettingsV6ThemesNinjaCartSettingsFrequentBoughtTogetherQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip:
        widgetType !== WidgetTypeDtoEnum.NINJA_CART_FREQUENT_BOUGHT_TOGETHER,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveNinjaCartFBTMutation,
    { isLoading: saveFrequentlyBoughtTogetherIsLoading },
  ] = usePutSettingsV6ThemesNinjaCartSettingsFrequentBoughtTogetherMutation();

  const saveFrequentlyBoughtTogether = useCallback(
    async (
      data: NinjaCartFrequentBoughtTogetherWidgetSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveNinjaCartFBTMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveNinjaCartFBTMutation]
  );

  const [
    saveAnnouncementBarMutation,
    { isLoading: saveAnnouncementBarIsLoading },
  ] =
    usePutSettingsV6ThemesNinjaCartSettingsDrawerCartAnnouncementBarMutation();

  const saveAnnouncementBar = useCallback(
    async (
      data: NinjaCartDrawerCartAnnouncementBarWidgetSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveAnnouncementBarMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveAnnouncementBarMutation]
  );

  const [
    saveNinjaCartGiftPickerMutation,
    { isLoading: saveNinjaCartGiftPickerIsLoading },
  ] = usePutSettingsV6ThemesNinjaCartSettingsGiftPickerMutation();

  const saveNinjaCartGiftPicker = useCallback(
    async (
      data: NinjaCartGiftPickerWidgetSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveNinjaCartGiftPickerMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveNinjaCartGiftPickerMutation]
  );

  const [
    savePromotionSummaryMutation,
    { isLoading: savePromotionSummaryIsLoading },
  ] = usePutSettingsV6ThemesNinjaCartSettingsPromotionSummaryMutation();

  const savePromotionSummary = useCallback(
    async (
      data: NinjaCartPromotionSummaryWidgetSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await savePromotionSummaryMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [savePromotionSummaryMutation]
  );

  const [
    saveNinjaCartProductBadgeMutation,
    { isLoading: saveNinjaCartProductBadgeIsLoading },
  ] = usePutSettingsV6ThemesNinjaCartSettingsProductBadgeMutation();

  const saveNinjaCartProductBadge = useCallback(
    async (
      data: NinjaCartProductBadgeWidgetSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveNinjaCartProductBadgeMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveNinjaCartProductBadgeMutation]
  );

  const [
    saveNinjaCartPaymentOptionsMutation,
    { isLoading: saveNinjaCartPaymentOptionsIsLoading },
  ] = usePutSettingsV6ThemesNinjaCartSettingsPaymentOptionsMutation();

  const saveNinjaCartPaymentOptions = useCallback(
    async (
      data: NinjaCartPaymentOptionsWidgetSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveNinjaCartPaymentOptionsMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveNinjaCartProductBadgeMutation]
  );

  return {
    ninjaCartProductBadgeData,
    ninjaCartPaymentOptionsData,
    promotionSummaryData,
    emptyWidgetSettingsData,
    announcementBarData,
    ninjaCartProductBadgeDataIsFetching,
    ninjaCartPaymentOptionsDataIsFetching,
    promotionSummaryDataIsFetching,
    emptyWidgetSettingsDataIsFetching,
    ninjaCartGiftPickerData,
    ninjaCartGiftPickerDataIsFetching,
    saveNinjaCartProductBadgeIsLoading,
    saveNinjaCartPaymentOptionsIsLoading,
    savePromotionSummaryIsLoading,
    saveNinjaCartGiftPickerIsLoading,
    announcementBarDataIsFetching,
    saveAnnouncementBarIsLoading,
    orderBumpData,
    orderBumpDataIsFetching,
    saveOrderBumpIsLoading,
    promotionCodeFieldData,
    promotionCodeFieldDataIsFetching,
    savePromotionCodeFieldIsLoading,
    saveFrequentlyBoughtTogetherIsLoading,
    frequentlyBoughtTogetherData,
    frequentlyBoughtTogetherDataIsFetching,
    refetchPromotionCodeFieldData,
    savePromotionCodeField,
    refetchOrderBumpData,
    saveOrderBump,
    refetchFrequentlyBoughtTogetherData,
    saveFrequentlyBoughtTogether,
    saveAnnouncementBar,
    savePromotionSummary,
    saveNinjaCartPaymentOptions,
    saveNinjaCartProductBadge,
    saveNinjaCartGiftPicker,
    refetchNinjaCartPaymentOptionsData,
    refetchNinjaCartProductBadgeData,
    refetchPromotionSummaryData,
    refetchEmptyWidgetSettingsData,
    refetchNinjaCartGiftPickerData,
    refetchAnnouncementBarData,
    widgetStatusesData,
    widgetStatusesDataIsFetching,
    refetchWidgetStatusesData,
  };
};
