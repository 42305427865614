import React, { useCallback, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  OptionDtoTriggerCountdownClockDto,
  TriggerCountdownClockDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  InlineStack,
  MediaCard,
  Text,
  TextField,
  VideoThumbnail,
} from '@shopify/polaris';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  FixedClockTimeTypeDtoEnum,
  TriggerCountdownClockEndModeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { Element } from 'react-scroll';
import { useSearchParams } from 'react-router-dom';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { useIsDebugOrLocal } from 'core/hooks';
import { transformToKebabOrSnakeCase } from 'core/utils/transformToKebabOrSnakeCase';

type SettingCountdownClockProps = {
  updateSettingsState: (data: OptionDtoTriggerCountdownClockDto) => void;
  countdownClock?: OptionDtoTriggerCountdownClockDto;
};

export const SettingCountdownClock: React.FC<SettingCountdownClockProps> = ({
  updateSettingsState,
  countdownClock,
}) => {
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [, setSearchParams] = useSearchParams();

  const modeOptions = useMemo(
    () =>
      Object.values(TriggerCountdownClockEndModeDtoEnum).map((value) => ({
        label: i18n.translate(`${value}_Title`),
        helpText: i18n.translate(`${value}_Subtitle`),
        value: value,
      })),
    [i18n]
  );

  const timeOptions = useMemo(
    () =>
      Object.values(FixedClockTimeTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );

  const handleUpdateCountdown = useCallback(
    (
      field: keyof TriggerCountdownClockDto,
      data: TriggerCountdownClockDto[keyof TriggerCountdownClockDto]
    ) => {
      updateSettingsState({
        ...countdownClock,
        value: {
          ...countdownClock?.value,
          [field]: data,
        },
      });
    },
    [countdownClock]
  );
  const getValidatedTime = useCallback(
    (type: FixedClockTimeTypeDtoEnum) => {
      const value = countdownClock?.value?.fixedClockTime?.value || 1;
      const validationMaxNumber =
        type === FixedClockTimeTypeDtoEnum.DAYS
          ? 30
          : type === FixedClockTimeTypeDtoEnum.HOURS
          ? 720
          : 43200;

      return value > validationMaxNumber
        ? validationMaxNumber
        : value < 1
        ? 1
        : value;
    },
    [countdownClock?.value?.fixedClockTime]
  );

  const handleTimeOptions = useCallback(
    (type: FixedClockTimeTypeDtoEnum) => {
      updateSettingsState({
        ...countdownClock,
        value: {
          ...countdownClock?.value,
          fixedClockTime: {
            type: type,
            value: getValidatedTime(type),
          },
        },
      });
    },
    [countdownClock, getValidatedTime]
  );

  const navigateToUrgencySettings = useCallback(async () => {
    if (!isDebugOrLocal) {
      await shopify.saveBar.leaveConfirmation();
      setSearchParams({
        modal: NavPaths.WidgetsControlCenter,
        widgetType: transformToKebabOrSnakeCase(
          WidgetTypeDtoEnum.ESSENTIALS,
          'kebab-case'
        ),
        subType: transformToKebabOrSnakeCase(
          WidgetTypeDtoEnum.COUNTDOWN_CLOCK,
          'kebab-case'
        ),
      });
    }
  }, []);

  const handleUpdateMode = useCallback(
    (value: TriggerCountdownClockEndModeDtoEnum) => {
      updateSettingsState({
        ...countdownClock,
        value: {
          ...countdownClock?.value,
          mode: value,
          fixedClockTime:
            value === TriggerCountdownClockEndModeDtoEnum.FIXED &&
            !countdownClock?.value?.fixedClockTime
              ? {
                  type: FixedClockTimeTypeDtoEnum.MINUTES,
                  value: 15,
                }
              : {},
        },
      });
    },
    [countdownClock]
  );

  return (
    <Element name='SettingCountdownClock' className='SettingCountdownClock'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack blockAlign='start' wrap={false} align='space-between'>
            <Box width='70%'>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text fontWeight='semibold' as='h2'>
                    {i18n.translate('Urgency')}
                  </Text>
                  <Badge tone={countdownClock?.enabled ? 'success' : 'enabled'}>
                    {i18n.translate(countdownClock?.enabled ? 'On' : 'Off')}
                  </Badge>
                </InlineStack>
                <Text as='p' tone='subdued'>
                  {i18n.translate('UrgencySubtitle')}
                </Text>
              </BlockStack>
            </Box>
            <Button
              onClick={() =>
                updateSettingsState({
                  ...countdownClock,
                  enabled: !countdownClock?.enabled,
                })
              }
            >
              {i18n.translate(countdownClock?.enabled ? 'TurnOff' : 'TurnOn')}
            </Button>
          </InlineStack>
          <Collapsible id='collapsible' open={!!countdownClock?.enabled}>
            <BlockStack gap='400'>
              <MediaCard
                title={i18n.translate('MediaCardTitle')}
                primaryAction={{
                  content: i18n.translate('LearnMore'),
                  onAction: () => null,
                }}
                description={i18n.translate(`MediaCardDescription`)}
                size='small'
              >
                <VideoThumbnail
                  videoLength={80}
                  thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley',
                      '_blank'
                    )
                  }
                />
              </MediaCard>
              <ChoiceList
                choices={modeOptions}
                title={''}
                selected={[countdownClock?.value?.mode as string]}
                onChange={(value) =>
                  handleUpdateMode(
                    value[0] as TriggerCountdownClockEndModeDtoEnum
                  )
                }
              />
              {countdownClock?.value?.mode ===
                TriggerCountdownClockEndModeDtoEnum.FIXED && (
                <InlineStack blockAlign='end' gap='300'>
                  <Box width='176px'>
                    <TextField
                      autoComplete=''
                      label={i18n.translate('Duration')}
                      value={countdownClock.value.fixedClockTime?.value?.toString()}
                      type='number'
                      onChange={(value) =>
                        handleUpdateCountdown('fixedClockTime', {
                          ...countdownClock.value?.fixedClockTime,
                          value: +value,
                        })
                      }
                      onBlur={() =>
                        handleUpdateCountdown('fixedClockTime', {
                          ...countdownClock?.value?.fixedClockTime,
                          value: getValidatedTime(
                            countdownClock.value?.fixedClockTime
                              ?.type as FixedClockTimeTypeDtoEnum
                          ),
                        })
                      }
                    />
                  </Box>

                  <SelectOptions
                    options={timeOptions}
                    selectedOption={
                      countdownClock.value.fixedClockTime?.type as string
                    }
                    onOptionSelect={(value) =>
                      handleTimeOptions(value as FixedClockTimeTypeDtoEnum)
                    }
                    label={''}
                  />
                </InlineStack>
              )}

              <Banner
                tone='info'
                title={i18n.translate('BannerTitle')}
                action={{
                  content: i18n.translate('ChangeUrgency'),
                  onAction: navigateToUrgencySettings,
                }}
              >
                {i18n.translate('BannerDescription')}
              </Banner>
            </BlockStack>
          </Collapsible>
        </BlockStack>
      </Card>
    </Element>
  );
};
