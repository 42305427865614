import React, { useCallback, useState } from 'react';
import {
  Text,
  BlockStack,
  Button,
  Card,
  InlineStack,
  Icon,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { AlertCircleIcon } from '@shopify/polaris-icons';
import { Element } from 'react-scroll';
import moment from 'moment';
import SupportDetailsModal from '../SupportDetailsModal/SupportDetailsModal';
type SettingsSupportDetailsProps = {
  submissionDate?: number | null;
  refetchWidgetInstallation: () => void;
};
export const SettingsSupportDetails: React.FC<SettingsSupportDetailsProps> = ({
  submissionDate,
  refetchWidgetInstallation,
}) => {
  const [i18n] = useI18n();

  const [isSupportDetailsOpen, setIsSupportDetailsOpen] =
    useState<boolean>(false);
  const toggleSupportModal = useCallback(
    () => setIsSupportDetailsOpen(!isSupportDetailsOpen),
    [isSupportDetailsOpen]
  );

  return (
    <>
      <Element name='SupportDetails'>
        <Card>
          <InlineStack wrap={false} gap='100'>
            <div>
              <Icon source={AlertCircleIcon} tone='base' />
            </div>
            <div style={{ width: '100%' }}>
              <BlockStack gap='200'>
                <InlineStack align='space-between'>
                  <Text as='h2' variant='headingSm'>
                    {i18n.translate('RequestSupport')}
                  </Text>
                  <Button variant='plain' onClick={toggleSupportModal}>
                    {i18n.translate('ShowStatus')}
                  </Button>
                </InlineStack>
                <Text tone='subdued' as='p'>
                  {i18n.translate('YouSubmittedRequest', {
                    date: (
                      <Text fontWeight='medium' as='span'>
                        {moment(submissionDate).format('DD.MM.YYYY HH:mm')}
                      </Text>
                    ),
                  })}
                </Text>
              </BlockStack>
            </div>
          </InlineStack>
        </Card>
      </Element>
      {isSupportDetailsOpen && (
        <SupportDetailsModal
          isOpen={isSupportDetailsOpen}
          onRefetch={refetchWidgetInstallation}
          onClose={toggleSupportModal}
        />
      )}
    </>
  );
};
