import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { BlockStack, Card, Checkbox, Link, Text } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import {
  DeviceDisplayDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  NotificationShopSettingsDto,
  WidgetSettingsDtoNotificationShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import {
  PositionDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import {
  DeviceDisplaySetting,
  IconSetting,
  MessageCountSetting,
  NotificationAnimationsSetting,
  OffsetSetting,
  PageDisplaySetting,
  PositionSetting,
  StyleSetting,
} from '../../components';
import { setShowValidation } from 'core/store/promotionsSlice';
import { useSettingsFormValidity } from 'features/promotions/hooks/useSettingsFormValidity';

type NotificationSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotificationSettings = forwardRef<
  WidgetSavebarRef,
  NotificationSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const dispatch = useDispatch();
  const [i18n] = useI18n();
  const {
    notificationData,
    saveNotification,
    saveNotificationIsLoading,
    notificationDataIsFetching,
    // refetchNotificationData,
  } = useWidget(WidgetTypeDtoEnum.NOTIFICATIONS);

  const { validityChangeHandler } = useSettingsFormValidity();
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoNotificationShopSettingsDto>({});
  const [notificationState, setNotificationState] =
    useState<WidgetSettingsDtoNotificationShopSettingsDto>({});

  const updateNotificationState = useCallback(
    (
      field: keyof NotificationShopSettingsDto,
      data: NotificationShopSettingsDto[keyof NotificationShopSettingsDto]
    ) =>
      setNotificationState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [notificationState]
  );

  const handleSave = useCallback(
    () =>
      saveNotification(notificationState.settings || null, () => {
        setSavedData(notificationState);
        dispatch(setShowValidation(false));
      }),
    [notificationState]
  );

  useEffect(() => {
    if (!isEmpty(notificationData)) {
      setNotificationState(notificationData);
      setSavedData(notificationData);
    }
  }, [notificationData]);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(notificationState)) {
      dispatch(setIsUnsavedChanges(!isEqual(notificationState, savedData)));
    }
  }, [savedData, notificationState]);

  useEffect(() => {
    setIsLoading(notificationDataIsFetching);
  }, [notificationDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveNotificationIsLoading);
  }, [saveNotificationIsLoading]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setNotificationState(savedData),
  }));

  return !notificationDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!notificationData?.isShopLevelWidget} />
      <DeviceDisplaySetting
        deviceDisplay={
          notificationState.settings?.deviceDisplay as DeviceDisplayDtoEnum
        }
        setDeviceDisplay={(value) =>
          updateNotificationState('deviceDisplay', value)
        }
      />
      <PageDisplaySetting
        pageDisplay={notificationState.settings?.pageDisplay || {}}
        setPageDisplay={(data) => updateNotificationState('pageDisplay', data)}
      />
      <PositionSetting
        position={notificationState.settings?.position as PositionDtoEnum}
        positionSides={[
          PositionDtoEnum.BOTTOM_LEFT,
          PositionDtoEnum.BOTTOM_RIGHT,
          PositionDtoEnum.TOP_LEFT,
          PositionDtoEnum.TOP_RIGHT,
        ]}
        title={i18n.translate('PagePosition')}
        subtitle={i18n.translate('AdjustPage')}
        setPosition={(value) => updateNotificationState('position', value)}
      />
      <OffsetSetting
        offset={notificationState.settings?.offset || {}}
        setOffset={(data) => updateNotificationState('offset', data)}
      />
      <div className='OffsetSetting'>
        <Card>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='p' fontWeight='bold'>
                {i18n.translate('MinimizingNotification')}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('DefineIfTheNotification')}
              </Text>
            </BlockStack>
            <Checkbox
              checked={
                !!notificationState.settings?.minimizingBehavior
                  ?.minimizeAfterFirstView
              }
              onChange={(value) =>
                updateNotificationState('minimizingBehavior', {
                  ...notificationState.settings?.minimizingBehavior,
                  minimizeAfterFirstView: value,
                })
              }
              label={i18n.translate('MinimizeAfterFirstView')}
              helpText={i18n.translate('MinimizeAfterFirstViewDescription')}
            />
            <Checkbox
              checked={
                !!notificationState.settings?.minimizingBehavior
                  ?.automicallyMinimize
              }
              onChange={(value) =>
                updateNotificationState('minimizingBehavior', {
                  ...notificationState.settings?.minimizingBehavior,
                  automicallyMinimize: value,
                })
              }
              label={i18n.translate('AutomicallyMinimize')}
              helpText={i18n.translate('AutomicallyMinimizeDescription')}
            />
          </BlockStack>
        </Card>
      </div>
      <IconSetting
        icon={notificationState.settings?.minimizingIcon || {}}
        setIcon={(data) => updateNotificationState('minimizingIcon', data)}
        bannerContent={
          <>
            {i18n.translate('MinimizingIconBannerText')}
            <Link>{i18n.translate('PresetEditor')}</Link>.
          </>
        }
        enableText={i18n.translate('EnableMinimizingIcon')}
        groupKeys={[ResourceGroupKeyDtoEnum.CHEVRONS]}
        title={i18n.translate('MinimizingIcon')}
        subtitle={i18n.translate('MinimizingIconSubtitle')}
        onFormValidityChange={validityChangeHandler}
      />
      <NotificationAnimationsSetting
        animations={notificationState.settings?.animations || {}}
        setAnimations={(data) => updateNotificationState('animations', data)}
      />
      <MessageCountSetting
        messageCount={!!notificationState.settings?.messageCountEnabled}
        setMessageCount={(value) =>
          updateNotificationState('messageCountEnabled', value)
        }
      />
    </>
  ) : null;
});

NotificationSettings.displayName = 'NotificationSettings';
