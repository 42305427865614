import React, { useMemo } from 'react';
import {
  Card,
  BlockStack,
  Text,
  Checkbox,
  Divider,
  ChoiceList,
  Banner,
  Link,
  Bleed,
  Box,
  InlineStack,
} from '@shopify/polaris';

import { useI18n } from '@shopify/react-i18n';
import { SubscriptionProductsCheckoutIntegrationsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { SubscriptionProductsCheckoutModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type SubscriptionProductsCheckout = {
  productsCheckout?: SubscriptionProductsCheckoutIntegrationsSettingsDto;
  setProductsCheckout: (
    data: SubscriptionProductsCheckoutIntegrationsSettingsDto
  ) => void;
};

export const SubscriptionProductsCheckout: React.FC<
  SubscriptionProductsCheckout
> = ({ productsCheckout, setProductsCheckout }) => {
  const [i18n] = useI18n();

  const productOptions = useMemo(
    () =>
      Object.values(SubscriptionProductsCheckoutModeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
        helpText:
          value === SubscriptionProductsCheckoutModeDtoEnum.ALLOW
            ? i18n.translate(`${value}_HelpText`)
            : '',
      })),
    [i18n]
  );

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('SubscriptionProductsCheckoutTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('SubscriptionProductsCheckoutDescription')}
          </Text>
        </BlockStack>
        <Checkbox
          label={i18n.translate('AllowDiscounting')}
          checked={productsCheckout?.allowMixedCheckouts}
          onChange={(value) =>
            setProductsCheckout({
              ...productsCheckout,
              allowMixedCheckouts: value,
            })
          }
        />
        {!productsCheckout?.allowMixedCheckouts && (
          <>
            <Divider />
            <ChoiceList
              title=''
              titleHidden
              choices={productOptions}
              selected={[`${productsCheckout?.checkoutMode}`]}
              onChange={(value) =>
                setProductsCheckout({
                  ...productsCheckout,
                  checkoutMode:
                    value[0] as SubscriptionProductsCheckoutModeDtoEnum,
                })
              }
            />
            <Banner tone='warning'>
              {i18n.translate('WarningBanner', {
                link: (
                  <Link url='http://www.google.com' target='_blank'>
                    {i18n.translate('ThisArticle')}
                  </Link>
                ),
              })}
            </Banner>
            <Bleed marginInline='400' marginBlockEnd='400'>
              <Box background='bg-surface-secondary' padding='400'>
                <InlineStack gap='100'>
                  <Text as='p' tone='subdued'>
                    {i18n.translate('Footer')}
                  </Text>

                  <Link url='http://www.google.com' target='_blank'>
                    {i18n.translate('FooterLink')}
                  </Link>
                </InlineStack>
              </Box>
            </Bleed>
          </>
        )}
      </BlockStack>
    </Card>
  );
};
