import React, { ReactNode, useCallback } from 'react';
import './ModalCustom.scss';
import {
  Button,
  ButtonGroup,
  LegacyCard,
  IconSource,
  Text,
  InlineStack,
  Icon,
} from '@shopify/polaris';
import Modal from 'react-modal';
import classNames from 'classnames';
import { XIcon, ArrowLeftIcon } from '@shopify/polaris-icons';

export type ModalCustomButtonType = {
  content: string;
  destructive?: boolean;
  primary: boolean;
  disabled?: boolean;
  outline?: boolean;
  action: () => void;
  icon?: IconSource;
  iconAsSuffix?: boolean;
  loading?: boolean;
};

export type ModalCustomProps = {
  isOpen: boolean;
  buttons?: ModalCustomButtonType[];
  modalClass?: string;
  portalClass?: string;
  overlayClass?: string;
  title?: string;
  showCloseButton?: boolean;
  shouldChangeTopPosition?: boolean;
  bottomSectionText?: React.ReactNode;
  onClose?: () => void;
  overlayClose?: boolean;
  centerButtons?: boolean;
  children?: ReactNode;
  onBackButton?: () => void;
};

export const ModalCustom: React.FC<ModalCustomProps> = (props) => {
  const {
    isOpen,
    onClose,
    buttons,
    children,
    overlayClass,
    portalClass,
    modalClass,
    bottomSectionText,
    title,
    showCloseButton = true,
    overlayClose = true,
    shouldChangeTopPosition = true,
    centerButtons,
    onBackButton,
  } = props;

  const handleRequestClose = useCallback(() => {
    if (!overlayClose) {
      return;
    }
    onClose?.();
  }, [overlayClose, onClose]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={handleRequestClose}
        onAfterOpen={() => {
          if (shouldChangeTopPosition) {
            document.body.style.top = `-${window.scrollY}px`;
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
          }
        }}
        onAfterClose={() => {
          if (shouldChangeTopPosition) {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
          }
        }}
        overlayClassName={classNames('CustomModalOverlay', {
          [overlayClass ? overlayClass : '']: overlayClass,
        })}
        portalClassName={classNames('CustomModalPortal', {
          [portalClass ? portalClass : '']: portalClass,
        })}
        className={classNames('CustomModal', {
          [modalClass ? modalClass : '']: modalClass,
        })}
      >
        <LegacyCard>
          {title && (
            <LegacyCard.Section>
              <div className='Title'>
                <InlineStack blockAlign='center' gap='200'>
                  {onBackButton && (
                    <Button
                      variant='monochromePlain'
                      icon={ArrowLeftIcon}
                      onClick={onBackButton}
                    />
                  )}
                  <Text as='h2' fontWeight='semibold'>
                    {title}
                  </Text>
                </InlineStack>
                {showCloseButton && (
                  <Button
                    onClick={onClose}
                    icon={<Icon source={XIcon} />}
                    variant='monochromePlain'
                  ></Button>
                )}
              </div>
            </LegacyCard.Section>
          )}
          {children}
          {(buttons?.length || bottomSectionText) && (
            <LegacyCard.Section>
              <div
                style={{ justifyContent: centerButtons ? 'center' : 'end' }}
                className='Buttons'
              >
                <Text as='span' tone='subdued'>
                  {bottomSectionText}
                </Text>
                <ButtonGroup>
                  {buttons?.map((ele: ModalCustomButtonType, index: number) => (
                    <div
                      className={classNames('ModalActionButton', {
                        HasSuffixIcon: ele.iconAsSuffix,
                      })}
                      key={index}
                    >
                      <Button
                        icon={ele.icon}
                        tone={ele.destructive ? 'critical' : undefined}
                        variant={ele.primary ? 'primary' : undefined}
                        disabled={ele.disabled}
                        loading={ele.loading}
                        onClick={ele.action}
                      >
                        {ele.content}
                      </Button>
                    </div>
                  ))}
                </ButtonGroup>
              </div>
            </LegacyCard.Section>
          )}
        </LegacyCard>
      </Modal>
    </>
  );
};
