import React from 'react';
import styles from './Knob.module.scss';

type KnobProps = {
  ariaLabel: string;
  selected: boolean;
  onClick: () => void;
};

export const Knob: React.FC<KnobProps> = ({ ariaLabel, selected, onClick }) => {
  return (
    <div>
      <button
        id=':rgi:'
        className={`${styles.track} ${selected ? styles.track_on : ''}`}
        aria-label={ariaLabel}
        role='switch'
        type='button'
        aria-checked={selected}
        onClick={onClick}
      >
        <div className={`${styles.knob} ${selected ? styles.knob_on : ''}`} />
      </button>
    </div>
  );
};
