import React, { useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { BlockStack, Card, ChoiceList, Text, Banner } from '@shopify/polaris';
import { FrequentBoughtTogetherProductSettingsDto } from 'core/api/adminSettings/adminSettingsApi';

type ProductSettingProps = {
  productSettings?: FrequentBoughtTogetherProductSettingsDto;
  setProductSettings: (data: FrequentBoughtTogetherProductSettingsDto) => void;
};

export const ProductSetting: React.FC<ProductSettingProps> = ({
  productSettings = {},
  setProductSettings,
}) => {
  const [i18n] = useI18n();

  const options = useMemo(
    () => [
      {
        label: i18n.translate('productSetting.showProductVariantsCards'),
        value: 'showProductVariantsCards',
      },
      {
        label: i18n.translate('productSetting.dontSuggestProductsCart'),
        value: 'dontSuggestProductsCart',
      },
    ],
    [i18n]
  );

  const selectedValues = useMemo(
    () =>
      Object.entries(productSettings)
        .filter(([_, value]) => value)
        .map(([key]) => key),
    [productSettings]
  );

  const handleChange = (selected: string[]) => {
    const updatedSettings: FrequentBoughtTogetherProductSettingsDto = {
      showProductVariantsCards: selected.includes('showProductVariantsCards'),
      dontSuggestProductsCart: selected.includes('dontSuggestProductsCart'),
    };
    setProductSettings(updatedSettings);
  };

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='bold'>
            {i18n.translate('productSetting.title')}
          </Text>
          <Text as='span' tone='subdued'>
            {i18n.translate('productSetting.description')}
          </Text>
        </BlockStack>

        <ChoiceList
          title=''
          allowMultiple
          choices={options}
          selected={selectedValues}
          onChange={handleChange}
        />

        <Banner tone='info'>
          {i18n.translate('productSetting.alwaysShowBanner')}
        </Banner>
      </BlockStack>
    </Card>
  );
};
