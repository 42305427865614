import React, { useState, useMemo, useCallback, useEffect } from 'react';
import {
  EditorContent,
  Extensions,
  Editor,
  useEditor,
  BubbleMenu,
} from '@tiptap/react';
// import { autoCloseTags, html } from '@codemirror/lang-html';
// import { EditorView } from '@codemirror/view';
import {
  ExtensionFontFamily,
  ExtensionSmartTag,
  ExtensionBold,
  ExtensionItalic,
  ExtensionUnderline,
  CoreExtensionDocument,
  CoreExtensionParagraph,
  CoreExtensionText,
  CharacterCount,
  ExtensionFontSize,
  ExtensionColor,
  ExtensionStrike,
  ExtensionTextAlign,
  ExtensionLink,
  CoreExtensionHistory,
  CoreExtensionDropcursor,
} from './extensions';
import { Toolbar } from './Toolbar';
import classNames from 'classnames';
import {
  WidgetsFetchTypeEnum,
  useConfigureWidgets,
} from 'features/settings/hooks/useConfigureWidgets';
import SmartTagSettings from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components/SmartTagSettings/SmartTagSettings';
import { useAppSelector } from 'core/hooks';
import { smartTagIconToolbar } from './assets/iconsToolbar';
import { useI18n } from '@shopify/react-i18n';
import { Icon, SkeletonBodyText } from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';
import { ToolbarButton } from './ToolbarButton';
import { getCurrentSmartTagsList } from 'features/settings/components/EditPresetSkeleton/utils/utils';
import { usePrevious } from 'core/hooks/usePrevious';
import { isEqual } from 'lodash';
import { Feature } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import { SmartTagTypeEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

type RichTextEditorProps = {
  features: Feature[];
  value?: string | null;
  limit?: number | { value: number; visual: boolean };
  disabled?: boolean;
  offerId?: string;
  priceOnly?: boolean;
  regularSmartTags?: SmartTagTypeEnum[];
  onChange?: (value: string) => void;
  onEditorViewChange?: (isCodeView: boolean) => void;
};

type CurrentSelectedSmartTagNodeProps = {
  node: any;
  position?: { from: number; to: number } | undefined;
};

export enum PlatformEnum {
  IOS = 'IOS',
  WINDOWS = 'WINDOWS',
  LINUX = 'LINUX',
}

export const defaultFontSize = 13;

export const RichTextEditor: React.FC<RichTextEditorProps> = (props) => {
  const {
    features,
    value,
    limit,
    onChange,
    onEditorViewChange,
    disabled,
    offerId,
    priceOnly,
    regularSmartTags,
  } = props;

  const { fontListData } = useConfigureWidgets(
    WidgetsFetchTypeEnum.PRESET_FONT_LIST
  );

  const [i18n] = useI18n();

  const { smartTagsList } = useAppSelector((state) => state.offersWizard);

  const [codeView, setCodeView] = useState<boolean>(false);
  const [contentCode, setContentCode] = useState<string>('');
  const [platform, setPlatform] = useState<PlatformEnum>(PlatformEnum.IOS);
  const [currentSelectedSmartTagNode, setCurrentSelectedSmartTagNode] =
    useState<CurrentSelectedSmartTagNodeProps | null>(null);
  const [isSmartTagSettingsOpen, setIsSmartTagSettingsOpen] =
    useState<boolean>(false);

  const prevSelectedSmartTagNode = usePrevious(currentSelectedSmartTagNode);

  const currentList = useMemo(
    () => getCurrentSmartTagsList(smartTagsList, offerId),
    [smartTagsList, offerId]
  );

  const isLimitVisual = useMemo(
    () =>
      !!(
        (limit && typeof limit === 'object' && limit.visual) ||
        (typeof limit === 'number' && limit > 0)
      ),
    [limit]
  );

  const limitValue = useMemo(
    () => (limit ? (typeof limit === 'object' ? limit.value : limit) : 0),
    [limit]
  );

  const extensions: Extensions = useMemo(() => {
    const activeExtensions: Extensions = [
      CoreExtensionDocument,
      CoreExtensionHistory,
      CoreExtensionParagraph.extend({
        addAttributes() {
          return {
            style: {
              default:
                'font-family: inherit; font-size: inherit; color: inherit; font-weight: inherit; line-height: inherit; margin: 0; width: 100%',
              parseHTML: (element) => ({
                style: element.getAttribute('style') || '',
              }),
              renderHTML: () => {
                return {
                  style: `font-family: inherit; font-size: inherit; color: inherit; font-weight: inherit; line-height: inherit; margin: 0; width: 100%`,
                };
              },
            },
          };
        },
      }),
      CoreExtensionText,
      CoreExtensionDropcursor,
    ];

    const ConfiguredExtensionTextAlign = ExtensionTextAlign.configure({
      types: ['paragraph'],
    });

    const ConfiguredExtensionLink = ExtensionLink.configure({
      openOnClick: false,
      autolink: false,
    });

    if (features.includes('bold')) activeExtensions.push(ExtensionBold);
    if (features.includes('italic')) activeExtensions.push(ExtensionItalic);
    if (features.includes('strike')) activeExtensions.push(ExtensionStrike);
    if (features.includes('text-align'))
      activeExtensions.push(ConfiguredExtensionTextAlign);
    if (features.includes('link'))
      activeExtensions.push(ConfiguredExtensionLink);
    if (features.includes('underline'))
      activeExtensions.push(ExtensionUnderline);
    if (features.includes('font-family')) {
      activeExtensions.push(ExtensionFontFamily);
    }
    if (features.includes('font-size')) {
      activeExtensions.push(ExtensionFontSize);
    }
    if (features.includes('color')) {
      activeExtensions.push(ExtensionColor);
    }
    if (features.includes('smartTag') && currentList) {
      activeExtensions.push(
        ExtensionSmartTag.configure({
          tags: currentList,
        })
      );
    }
    if (isLimitVisual && limitValue > 0) {
      activeExtensions.push(
        CharacterCount.configure({
          limit: limitValue,
        })
      );
    }
    return activeExtensions;
  }, [features, isLimitVisual, limitValue, fontListData, currentList]);

  const getContentWithSugarSyntax = useCallback((value: string): string => {
    //variables: $1 - html tag name | $2 - smart tag name | $3 - content
    const regex = /<([A-Za-z0-9\-?_]+)(?:\s+([^>]+))?>\s*([^<]*)\s*<\/\1>/gm;
    return value.replaceAll(regex, '{{$2}}');
  }, []);

  const handleContentChange = useCallback(
    (editor: Editor) => {
      const html = editor?.getHTML() ?? '';
      setContentCode(getContentWithSugarSyntax(html ?? ''));
      onChange?.(html);
    },
    [getContentWithSugarSyntax, onChange]
  );

  const editor = useEditor(
    {
      extensions,
      content: value,
      enablePasteRules: true,
      onUpdate: ({ editor }) => {
        const { $from, $to } = editor.state.selection;
        // reselect node after update
        handleContentChange(editor as Editor);
        if (
          currentSelectedSmartTagNode &&
          $from.pos === $to.pos &&
          currentSelectedSmartTagNode?.position?.from === $from.pos
        ) {
          try {
            editor.commands.setNodeSelection(
              currentSelectedSmartTagNode?.position?.from
            );
          } catch (error) {
            console.error('Failed to set node selection:', error);
          }
        }
      },
      onSelectionUpdate: ({ editor }) => {
        const { $from, $to } = editor.state.selection;
        // Check if the selection is a node selection
        if ($from.pos === $to.pos - 1) {
          // Get the selected node
          const node = editor.state.doc.nodeAt($from.pos);
          // Check if the selected node is of type 'smart-tag'
          if (node && node.type.name === 'la-dn-smart-tag') {
            setCurrentSelectedSmartTagNode({
              position: {
                from: $from.pos,
                to: $to.pos,
              },
              node: node,
            });
          }
        }
      },
      editable: !disabled,
    },
    [disabled]
  );

  const hasSmartTag = useMemo(() => {
    if (!editor || !regularSmartTags?.length) return false;

    let containsSmartTag = false;
    editor.state.doc.descendants((node) => {
      if (node.type.name === 'la-dn-smart-tag') {
        containsSmartTag = true;
      }
    });

    return containsSmartTag;
  }, [editor, editor?.state.doc, regularSmartTags]);

  const handleDeleteSmartTag = useCallback(() => {
    if (currentSelectedSmartTagNode && editor) {
      editor.commands.deleteRange({
        from: currentSelectedSmartTagNode?.position?.from || 0,
        to: currentSelectedSmartTagNode?.position?.to || 0,
      });
      setCurrentSelectedSmartTagNode(null);
    }
  }, [currentSelectedSmartTagNode, editor]);

  // const getContentWithSugarSyntaxPrecompiled = useCallback(
  //   (value: string): string => {
  //     if (!value) return '';
  //     const regex = /<([A-Za-z0-9\-?_]+)(?:\s+([^>]+))?>\s*([^<]*)\s*<\/\1>/gm;
  //     return value.replaceAll(regex, '<smart-tag $2></smart-tag>');
  //   },
  //   []
  // );

  // const getContentWithoutSugarSyntax = useCallback(
  //   (value: string): string => {
  //     if (!smartTags) return value;
  //     // variables: $1 - smart tag name
  //     const availableTags: string = smartTags
  //       ?.map((tag) => (typeof tag === 'string' ? tag : tag.name))
  //       .join('|');
  //     const regex = new RegExp(`{{\\s*(${availableTags})\\s*}}`, 'gm');
  //     return value.replaceAll(regex, '<smart-tag name="$1"></smart-tag>');
  //   },
  //   [smartTags]
  // );
  // const handleCodeEditorChange = useCallback(
  //   (value: string) => {
  //     editor
  //       ?.chain()
  //       .setContent(getContentWithoutSugarSyntax(value), true)
  //       .run();
  //     onChange?.(editor?.getHTML() ?? '');
  //   },
  //   [editor, getContentWithoutSugarSyntax, onChange]
  // );

  // for code Editor (right now not supported)
  const handleEditorViewChange = useCallback(
    (isCodeView: boolean) => {
      const html = editor?.getHTML() ?? '';
      setContentCode(getContentWithSugarSyntax(html));
      setCodeView(isCodeView);
      onEditorViewChange?.(isCodeView);
    },
    [editor, getContentWithSugarSyntax, onEditorViewChange]
  );

  const detectPlatform = useCallback(() => {
    const { userAgent } = navigator;
    if (/iPhone|iPad|iPod|Macintosh/i.test(userAgent)) {
      setPlatform(PlatformEnum.IOS);
    } else if (/Windows/i.test(userAgent)) {
      setPlatform(PlatformEnum.WINDOWS);
    } else if (/Linux/i.test(userAgent)) {
      setPlatform(PlatformEnum.LINUX);
    } else {
      setPlatform(PlatformEnum.WINDOWS);
    }
  }, [setPlatform]);

  const handleUpdateSmartTag = useCallback((data: any) => {
    setCurrentSelectedSmartTagNode(
      (prev: CurrentSelectedSmartTagNodeProps | null) => ({
        ...prev,
        node: {
          ...prev?.node,
          attrs: data,
        },
      })
    );
  }, []);

  useEffect(() => {
    detectPlatform();
  }, []);

  useEffect(() => {
    // update the editor's content if the value from prop has changed
    // and is different from the current editor's content value
    // convert html to precompiled version readable by tiptap:
    // e.g. <span data-smart-tag='today'></span> becomes <smart-tag name='today'></smart-tag>

    if (value === editor?.getHTML()) return;
    // const precompiledWithoutSugarSyntax = value
    //   ? getContentWithSugarSyntaxPrecompiled(value)
    //   : '';
    setTimeout(() => {
      value && editor?.commands.setContent(value);
    }, 0);
  }, [value]);

  useEffect(() => {
    if (
      currentSelectedSmartTagNode &&
      prevSelectedSmartTagNode &&
      !isEqual(
        currentSelectedSmartTagNode?.node?.attrs,
        prevSelectedSmartTagNode?.node?.attrs
      )
    ) {
      setTimeout(() => {
        editor?.commands.updateAttributes(
          currentSelectedSmartTagNode.node?.type,
          currentSelectedSmartTagNode.node?.attrs
        );
      }, 0);
    }
  }, [currentSelectedSmartTagNode]);

  return (
    <>
      {!currentList?.length && features.includes('smartTag') ? (
        <SkeletonBodyText lines={2} />
      ) : (
        <div className='rteWrapper'>
          <div className='rte'>
            <Toolbar
              editor={editor}
              features={features}
              codeView={codeView}
              currentList={currentList}
              onEditorViewChange={handleEditorViewChange}
              platform={platform}
              disableToolbar={!!disabled || hasSmartTag}
              priceOnly={priceOnly}
              regularSmartTags={regularSmartTags}
            />
            {editor && (
              <BubbleMenu
                editor={editor}
                tippyOptions={{ zIndex: 99, placement: 'bottom' }}
                shouldShow={({ state, from, to }) => {
                  // only show the bubble menu for smart tags
                  if (from !== to - 1 || isSmartTagSettingsOpen) {
                    return false;
                  }
                  // Get the selected node
                  const node = state.doc.nodeAt(from);
                  // Check if the selected node is of type 'smart-tag'
                  return !!(node && node.type.name === 'la-dn-smart-tag');
                }}
              >
                <div className='rte__bubbleMenu'>
                  <div className='rte__bubbleMenu__code'>
                    {smartTagIconToolbar(false, 'var(--p-color-icon-emphasis)')}
                    <span title={currentSelectedSmartTagNode?.node?.attrs.tag}>
                      {currentSelectedSmartTagNode?.node?.attrs.tag}
                    </span>
                  </div>
                  <div className='rte__bubbleMenu__actions'>
                    {!regularSmartTags && (
                      <span
                        className='editBtn'
                        onClick={() => setIsSmartTagSettingsOpen(true)}
                      >
                        {i18n.translate('Edit')}
                      </span>
                    )}
                    <ToolbarButton
                      name='textColor' //just for not showing tooltip
                      icon={<Icon source={DeleteIcon} tone='emphasis' />}
                      active={false}
                      onClick={handleDeleteSmartTag}
                      disabled={false}
                    />
                  </div>
                </div>
              </BubbleMenu>
            )}
            <EditorContent
              editor={editor}
              className={classNames('rte__editor', {
                Disabled: disabled,
              })}
              // disabled={!disabled}
            />
            {/* {!codeView ? (
        <EditorContent
          editor={editor}
          className={classNames('rte__editor', {
            Disabled: disabled,
          })}
          disabled={!disabled}
        />
      ) : (
        <CodeEditor
          basicSetup={true}
          editable={!disabled}
          extensions={[html(), autoCloseTags, EditorView.lineWrapping]}
          value={contentCode}
          onChange={handleCodeEditorChange}
          lang='html'
        />
      )} */}
          </div>
          {limitValue > 0 && isLimitVisual && (
            <div
              className={classNames('rteWrapper__character-count', {
                'rteWrapper__character-count--invalid':
                  editor?.storage.characterCount?.characters() > limitValue,
              })}
            >
              {editor?.storage.characterCount?.characters()}/{limitValue}
            </div>
          )}
        </div>
      )}

      {isSmartTagSettingsOpen && currentSelectedSmartTagNode?.node?.attrs && (
        <SmartTagSettings
          attrs={currentSelectedSmartTagNode?.node?.attrs}
          onClose={() => setIsSmartTagSettingsOpen(false)}
          onUpdate={handleUpdateSmartTag}
          onDelete={handleDeleteSmartTag}
        />
      )}
    </>
  );
};
