import React, { useCallback, useMemo } from 'react';
import {
  Badge,
  Banner,
  BlockStack,
  Card,
  Checkbox,
  ChoiceList,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { Element } from 'react-scroll';
import { PageDisplayComponentDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  PageDisplayTypeDtoEnum,
  PageTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type PageDisplaySettingProps = {
  pageDisplay: PageDisplayComponentDto;
  setPageDisplay: (value: PageDisplayComponentDto) => void;
};
export const PageDisplaySetting: React.FC<PageDisplaySettingProps> = ({
  pageDisplay,
  setPageDisplay,
}) => {
  const [i18n] = useI18n();
  const pageDisplayTypeOptions = useMemo(
    () =>
      Object.values(PageDisplayTypeDtoEnum).map((value) => ({
        label: (
          <InlineStack gap='200'>
            {i18n.translate(`${value}_TYPE`)}
            {value === PageDisplayTypeDtoEnum.DEFAULT && (
              <Badge>{i18n.translate('Recommended')}</Badge>
            )}
          </InlineStack>
        ),
        value: value,
      })),
    []
  );

  const updateDisplayPages = useCallback(
    (value: boolean, page: PageTypeDtoEnum) => {
      if (value) {
        setPageDisplay({
          ...pageDisplay,
          pages: [...(pageDisplay.pages || []), page],
        });
      } else {
        setPageDisplay({
          ...pageDisplay,
          pages: pageDisplay.pages?.filter((item) => item !== page),
        });
      }
    },
    [pageDisplay, setPageDisplay]
  );

  return (
    <Element className='PageDisplaySetting' name='PageDisplaySetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('PageDisplay')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('SelectOnWhich')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={pageDisplayTypeOptions}
            selected={[pageDisplay.type || PageDisplayTypeDtoEnum.DEFAULT]}
            onChange={(value) =>
              setPageDisplay({
                ...pageDisplay,
                type: value[0] as PageDisplayTypeDtoEnum,
              })
            }
          />
          <BlockStack gap='200'>
            <Text as='p'>{i18n.translate('Pages')}</Text>
            {pageDisplay.availablePages?.map((item) => {
              const { type, defaultPages, pages } = pageDisplay;
              const isDefault = type === PageDisplayTypeDtoEnum.DEFAULT;
              return (
                <Checkbox
                  key={item}
                  label={i18n.translate(`${item}`)}
                  checked={
                    isDefault
                      ? defaultPages?.includes(item)
                      : pages?.includes(item)
                  }
                  onChange={(value) =>
                    updateDisplayPages(value, item as PageTypeDtoEnum)
                  }
                  disabled={isDefault}
                />
              );
            })}
          </BlockStack>
          <Banner tone='info'>{i18n.translate('BannerText')}</Banner>
        </BlockStack>
      </Card>
    </Element>
  );
};
