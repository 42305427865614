import React, { forwardRef, useEffect } from 'react';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { StyleSetting, SubTypesNavigation } from '../../components';
import { useI18n } from '@shopify/react-i18n';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';

type EssentialsSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EssentialsSettings = forwardRef<
  WidgetSavebarRef,
  EssentialsSettingsProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ setIsLoading }, ref) => {
  const [i18n] = useI18n();
  const {
    essentialsData,
    essentialsDataIsFetching,
    // refetchEssentialsData,
  } = useWidget(WidgetTypeDtoEnum.ESSENTIALS);

  useEffect(() => {
    setIsLoading(essentialsDataIsFetching);
  }, [essentialsDataIsFetching]);

  return !essentialsDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <SubTypesNavigation subTypes={essentialsData?.settings?.children} />
      <StyleSetting styled={!!essentialsData?.isShopLevelWidget} />
    </>
  ) : null;
});

EssentialsSettings.displayName = 'EssentialsSettings';
