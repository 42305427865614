import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  OfferRulePopupShopSettingsDto,
  WidgetSettingsDtoOfferRulePopupShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  OffsetTypeDtoEnum,
  PositionDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  StyleSetting,
  PositionSetting,
  OffsetSetting,
  OverlaySettings,
  IconSetting,
} from '../../components';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { Link } from '@shopify/polaris';

type OfferRulePopupSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OfferRulePopupSettings = forwardRef<
  WidgetSavebarRef,
  OfferRulePopupSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const {
    offerRulePopupData,
    saveOfferRulePopup,
    offerRulePopupDataIsFetching,
    saveOfferRulePopupIsLoading,
    // refetchOfferRulePopupData,
  } = useWidget(WidgetTypeDtoEnum.OFFER_RULE_POPUP);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoOfferRulePopupShopSettingsDto>({});
  const [offerRulePopupState, setOfferRulePopupState] =
    useState<WidgetSettingsDtoOfferRulePopupShopSettingsDto>({});

  const updateOfferRulePopupState = useCallback(
    (
      field: keyof OfferRulePopupShopSettingsDto,
      data: OfferRulePopupShopSettingsDto[keyof OfferRulePopupShopSettingsDto]
    ) =>
      setOfferRulePopupState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setOfferRulePopupState]
  );

  const handleSave = useCallback(
    () =>
      saveOfferRulePopup(offerRulePopupState.settings || null, () =>
        setSavedData(offerRulePopupState)
      ),
    [offerRulePopupState]
  );

  useEffect(() => {
    if (!isEmpty(offerRulePopupData)) {
      setOfferRulePopupState(offerRulePopupData);
      setSavedData(offerRulePopupData);
    }
  }, [offerRulePopupData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(offerRulePopupState)) {
      dispatch(setIsUnsavedChanges(!isEqual(offerRulePopupState, savedData)));
    }
  }, [savedData, offerRulePopupState]);

  useEffect(() => {
    setIsLoading(offerRulePopupDataIsFetching);
  }, [offerRulePopupDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveOfferRulePopupIsLoading);
  }, [saveOfferRulePopupIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setOfferRulePopupState(savedData),
  }));

  return !offerRulePopupDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!offerRulePopupData?.isShopLevelWidget} />
      <PositionSetting
        title={i18n.translate('Position')}
        position={offerRulePopupState?.settings?.position as PositionDtoEnum}
        positionSides={[
          PositionDtoEnum.CENTER,
          PositionDtoEnum.LEFT,
          PositionDtoEnum.RIGHT,
        ]}
        setPosition={(value) => updateOfferRulePopupState('position', value)}
      />
      <OffsetSetting
        subtitle={i18n.translate('Subtitle')}
        offsetSizes={Object.values(OffsetTypeDtoEnum)}
        offset={offerRulePopupState.settings?.offset || {}}
        setOffset={(value) => updateOfferRulePopupState('offset', value)}
      />
      <OverlaySettings
        overlay={offerRulePopupState.settings?.overlay || {}}
        setOverlay={(value) => updateOfferRulePopupState('overlay', value)}
      />
      <IconSetting
        icon={offerRulePopupState.settings?.closeButtonIcon || {}}
        setIcon={(data) => updateOfferRulePopupState('closeButtonIcon', data)}
        bannerContent={
          <>
            {i18n.translate('CloseBtnBannerText')}
            <Link>{i18n.translate('PresetEditor')}</Link>.
          </>
        }
        enableText={i18n.translate('EnableCloseBtn')}
        groupKeys={[ResourceGroupKeyDtoEnum.CLOSE_BUTTON]}
        title={i18n.translate('CloseBtn')}
        subtitle={i18n.translate('CloseBtnSubtitle')}
      />
    </>
  ) : null;
});

OfferRulePopupSettings.displayName = 'OfferRulePopupSettings';
