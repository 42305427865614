import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Card,
  ChoiceList,
  Divider,
  InlineStack,
  Link,
  Page,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { DiscountLinksSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  useConfigureSettings,
  SettingsFetchTypeEnum,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';
import {
  DiscountLinksBehaviorTypeDtoEnum,
  DiscountLinksExpirationDurationDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { useSearchParams } from 'react-router-dom';
import { transformToKebabOrSnakeCase } from 'core/utils/transformToKebabOrSnakeCase';
import { NavPaths } from 'core/enums/NavPathsEnum';
import {
  SettingsModalPathsEnums,
  SettingsModalSubPathsEnums,
} from 'pages/enums/PagesEnums';

export const DiscountLinks = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const pageBackAction = useSettingsPageBackAction();

  const {
    discountLinksData,
    discountLinksIsFetching,
    discountLinksDataChange,
  } = useConfigureSettings(SettingsFetchTypeEnum.DISCOUNT_LINKS);

  const [discountLinksState, setDiscountLinksState] =
    useState<DiscountLinksSettingsDto>({});
  const [savedData, setSavedData] = useState<DiscountLinksSettingsDto>({});

  const behaviorOptions = useMemo(
    () =>
      Object.values(DiscountLinksBehaviorTypeDtoEnum).map((value) => ({
        label: (
          <InlineStack gap='100'>
            <Text as='p'>{i18n.translate(value)}</Text>
            {value ===
              DiscountLinksBehaviorTypeDtoEnum.ANY_TAB_UNTIL_LINK_EXPIRES && (
              <Badge>{i18n.translate('Recommended')}</Badge>
            )}
          </InlineStack>
        ),
        value: value,
        helpText: i18n.translate(`${value}_HELPTEXT`),
      })),
    [i18n]
  );

  const expirationDurationOptions = useMemo(
    () =>
      Object.values(DiscountLinksExpirationDurationDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(discountLinksState);
    return discountLinksDataChange(discountLinksState, onSuccess);
  }, [discountLinksState]);

  const handleDiscountLinksState = useCallback(
    (
      field: keyof DiscountLinksSettingsDto,
      data: DiscountLinksSettingsDto[keyof DiscountLinksSettingsDto]
    ) => setDiscountLinksState((prev) => ({ ...prev, [field]: data })),
    []
  );

  const navigateToDiscountCodes = useCallback(
    async (
      page:
        | SettingsModalSubPathsEnums.DNPromocodes
        | SettingsModalSubPathsEnums.ShopifyDiscountCodes
    ) => {
      !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
      setSearchParams({
        path: SettingsModalPathsEnums.DiscountCodes,
        subPath: page,
      });
    },
    []
  );

  useImperativeHandle(ref, () => ({
    discardChanges: () => setDiscountLinksState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, discountLinksState)));
  }, [discountLinksState, savedData]);

  useEffect(() => {
    if (!isEmpty(discountLinksData)) {
      setDiscountLinksState(discountLinksData);
      setSavedData(discountLinksData);
    }
  }, [discountLinksData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <>
      {!discountLinksIsFetching && discountLinksState ? (
        <Page
          backAction={pageBackAction}
          title={i18n.translate('DiscountLinks')}
        >
          <Card roundedAbove='sm' padding='400'>
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate('DiscountLinks')}
                </Text>
                <Text as='p' tone='subdued'>
                  {i18n.translate('DiscountLinksDescription')}
                </Text>
              </BlockStack>
              <ChoiceList
                title=''
                titleHidden
                choices={behaviorOptions}
                selected={[discountLinksState.behavior || '']}
                onChange={(value) =>
                  handleDiscountLinksState(
                    'behavior',
                    value[0] as DiscountLinksBehaviorTypeDtoEnum
                  )
                }
              />
              {discountLinksState.behavior ===
                DiscountLinksBehaviorTypeDtoEnum.ANY_TAB_UNTIL_LINK_EXPIRES && (
                <>
                  <Divider />
                  <ChoiceList
                    title=''
                    titleHidden
                    choices={expirationDurationOptions}
                    selected={[discountLinksState.expirationDuration || '']}
                    onChange={(value) =>
                      handleDiscountLinksState(
                        'expirationDuration',
                        value[0] as DiscountLinksExpirationDurationDtoEnum
                      )
                    }
                  />
                </>
              )}
              <Bleed marginInline='400' marginBlockEnd='400'>
                <Box background='bg-surface-secondary' padding='400'>
                  <InlineStack gap='100'>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('Footer.0', {
                        returnObjects: true,
                      })}
                    </Text>

                    <Link
                      onClick={() =>
                        navigateToDiscountCodes(
                          SettingsModalSubPathsEnums.DNPromocodes
                        )
                      }
                    >
                      {i18n.translate('Footer.1', {
                        returnObjects: true,
                      })}
                    </Link>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('Footer.2', {
                        returnObjects: true,
                      })}
                    </Text>

                    <Link
                      onClick={() =>
                        navigateToDiscountCodes(
                          SettingsModalSubPathsEnums.ShopifyDiscountCodes
                        )
                      }
                    >
                      {i18n.translate('Footer.3', {
                        returnObjects: true,
                      })}
                    </Link>
                  </InlineStack>
                </Box>
              </Bleed>
            </BlockStack>
          </Card>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
DiscountLinks.displayName = 'DiscountLinks';
