import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  CountdownClockShopSettingsDto,
  WidgetSettingsDtoCountdownClockShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  CountdownClockTimerUrgencyTypeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { StyleSetting } from '../../components';
import {
  Badge,
  Banner,
  BlockStack,
  Card,
  ChoiceList,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';

type CountdownClockSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CountdownClockSettings = forwardRef<
  WidgetSavebarRef,
  CountdownClockSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const {
    countdownClockData,
    saveCountdownClock,
    countdownClockDataIsFetching,
    saveCountdownClockIsLoading,
    // refetchCountdownClockData,
  } = useWidget(WidgetTypeDtoEnum.COUNTDOWN_CLOCK);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoCountdownClockShopSettingsDto>({});
  const [countdownClockState, setCountdownClockState] =
    useState<WidgetSettingsDtoCountdownClockShopSettingsDto>({});

  const choices = useMemo(
    () =>
      Object.values(CountdownClockTimerUrgencyTypeDtoEnum).map(
        (value, idx) => ({
          label: (
            <InlineStack gap='200'>
              {i18n.translate(value)}
              {!idx && <Badge>{i18n.translate('Recommended')}</Badge>}
            </InlineStack>
          ),
          value: value,
          helpText: i18n.translate(`${value}_HELP_TEXT`),
        })
      ),
    [i18n]
  );

  const updateCountdownClockState = useCallback(
    (
      field: keyof CountdownClockShopSettingsDto,
      data: CountdownClockShopSettingsDto[keyof CountdownClockShopSettingsDto]
    ) =>
      setCountdownClockState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setCountdownClockState]
  );

  const handleSave = useCallback(
    () =>
      saveCountdownClock(countdownClockState.settings || null, () =>
        setSavedData(countdownClockState)
      ),
    [countdownClockState]
  );

  useEffect(() => {
    if (!isEmpty(countdownClockData)) {
      setCountdownClockState(countdownClockData);
      setSavedData(countdownClockData);
    }
  }, [countdownClockData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(countdownClockState)) {
      dispatch(setIsUnsavedChanges(!isEqual(countdownClockState, savedData)));
    }
  }, [savedData, countdownClockState]);

  useEffect(() => {
    setIsLoading(countdownClockDataIsFetching);
  }, [countdownClockDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveCountdownClockIsLoading);
  }, [saveCountdownClockIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setCountdownClockState(savedData),
  }));

  return !countdownClockDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!countdownClockData?.isShopLevelWidget} />
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('UrgencyTitle')}
            </Text>
            <Text as='span' tone='subdued'>
              {i18n.translate('UrgencyDescription')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            titleHidden
            choices={choices}
            selected={[
              countdownClockState.settings?.timerUrgencyType ||
                CountdownClockTimerUrgencyTypeDtoEnum.REAL,
            ]}
            onChange={(value) =>
              updateCountdownClockState(
                'timerUrgencyType',
                value[0] as CountdownClockTimerUrgencyTypeDtoEnum
              )
            }
          />
          <Banner tone='info'>
            {i18n.translate('Banner', {
              link: (
                <Link url='http://www.google.com' target='_blank'>
                  {i18n.translate('Here')}
                </Link>
              ),
            })}
          </Banner>
        </BlockStack>
      </Card>
    </>
  ) : null;
});

CountdownClockSettings.displayName = 'CountdownClockSettings';
