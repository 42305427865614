import React, { useCallback, useEffect, useMemo } from 'react';
import { BlockStack, Box, Button, InlineStack, Text } from '@shopify/polaris';
import { PlusIcon, DeleteIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { PromotionScheduleDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { DateTimePicker } from 'core/components';
import moment from 'moment';

type SingleScheduleProps = {
  scheduleSetup: PromotionScheduleDto;
  timezone: string;
  handleScheduleSetup: (
    field: keyof PromotionScheduleDto,
    value: PromotionScheduleDto[keyof PromotionScheduleDto]
  ) => void;
  onFormValidityChange: (isValid: boolean) => void;
};

export const SingleSchedule: React.FC<SingleScheduleProps> = ({
  scheduleSetup,
  timezone,
  handleScheduleSetup,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();

  const isStartDateValid = useMemo(
    () =>
      scheduleSetup.endsAt
        ? new Date(scheduleSetup.startsAt as number) <
          new Date(scheduleSetup.endsAt as number)
        : true,
    [scheduleSetup]
  );

  const isEndDateValid = useMemo(
    () =>
      new Date(scheduleSetup.startsAt as number) <
      new Date(scheduleSetup.endsAt as number),
    [scheduleSetup]
  );

  const isStartDateInPast = useMemo(
    () => new Date(scheduleSetup.startsAt || 0) < new Date(),
    [scheduleSetup.startsAt]
  );

  const isDateValid = useMemo(
    () =>
      (scheduleSetup.endsAt ? isEndDateValid : true) &&
      isStartDateValid &&
      !isStartDateInPast,
    [isStartDateValid, isEndDateValid, isStartDateInPast]
  );

  const initialDate = useMemo(() => {
    const currentDate = new Date();
    return currentDate.setHours(currentDate.getHours() + 24);
  }, []);

  const handleAddEndDate = useCallback(() => {
    const startDate = scheduleSetup.startsAt || initialDate;
    const newEndDate = startDate + 24 * 60 * 60 * 1000;
    handleScheduleSetup('endsAt', newEndDate);
  }, [scheduleSetup.startsAt, initialDate, handleScheduleSetup]);

  useEffect(() => {
    onFormValidityChange(isDateValid);
  }, [isDateValid]);

  return (
    <BlockStack gap='400'>
      <DateTimePicker
        dateLabel={i18n.translate('StartDate')}
        timeLabel={i18n.translate('Time')}
        timestampValue={scheduleSetup?.startsAt || initialDate}
        error={isStartDateInPast ? i18n.translate('DateInPastError') : ''}
        setTimestampValue={(value) => {
          handleScheduleSetup('startsAt', value);
        }}
      />
      {scheduleSetup.endsAt ? (
        <InlineStack gap='400' blockAlign='start' wrap={false}>
          <DateTimePicker
            dateLabel={i18n.translate('EndDate')}
            timeLabel={i18n.translate('Time')}
            timestampValue={scheduleSetup?.endsAt}
            error={!isEndDateValid ? i18n.translate('EndDateError') : ''}
            minDate={scheduleSetup.startsAt || undefined}
            setTimestampValue={(value) => {
              handleScheduleSetup('endsAt', value);
            }}
          />
          <Box paddingBlockStart='800'>
            <Button
              onClick={() => handleScheduleSetup('endsAt', null)}
              icon={DeleteIcon}
              variant='plain'
              tone='critical'
            >
              {i18n.translate('RemoveEndDate')}
            </Button>
          </Box>
        </InlineStack>
      ) : (
        <Button
          onClick={handleAddEndDate}
          variant='plain'
          textAlign='start'
          icon={PlusIcon}
        >
          {i18n.translate('EndDate')}
        </Button>
      )}
      <Box padding='300' borderRadius='200' background='bg-surface-secondary'>
        <Text as='p'>
          {scheduleSetup.endsAt &&
            i18n.translate('FullSummary', {
              from: (
                <Text as='span' fontWeight='semibold'>
                  {moment(scheduleSetup.startsAt).format('MMMM Do YYYY h:mm A')}
                </Text>
              ),
              to: (
                <Text as='span' fontWeight='semibold'>
                  {moment(scheduleSetup.endsAt).format('MMMM Do YYYY h:mm A')}
                </Text>
              ),
            })}
          {!scheduleSetup.endsAt &&
            i18n.translate('StartDateSummary', {
              date: (
                <Text as='span' fontWeight='semibold'>
                  {moment(scheduleSetup.startsAt).format('MMMM Do YYYY h:mm A')}
                </Text>
              ),
            })}
        </Text>
      </Box>
      <Text as='p' tone='subdued'>
        {i18n.translate('Timezone', {
          timezone: timezone,
        })}
      </Text>
    </BlockStack>
  );
};
