import React, { useCallback } from 'react';
import { NotificationExpandedStateOfferPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { Icon } from '../../../../../components/Icon/Icon';
import { TextStyle } from '../../../../../components';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type NotificationPresetOfferProps = {
  offer: NotificationExpandedStateOfferPresetDto;
  defaultOffer: NotificationExpandedStateOfferPresetDto;
  shopDefaultOffer: NotificationExpandedStateOfferPresetDto;
  setOffer: (data: NotificationExpandedStateOfferPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const NotificationPresetOffer: React.FC<NotificationPresetOfferProps> = ({
  offer,
  defaultOffer,
  shopDefaultOffer,
  setOffer,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const resetAllToSystemDefault = useCallback(() => {
    const { icon, textStyle } = shopDefaultOffer;
    setOffer({
      ...offer,
      textStyle,
      icon: {
        color: icon?.color,
      },
    });
  }, [shopDefaultOffer, offer]);

  return (
    <div className='NotificationPresetOffer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setOffer(defaultOffer)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.OFFER}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Icon
          onSystemReset={() =>
            setOffer({
              ...offer,
              icon: {
                color: shopDefaultOffer.icon?.color,
              },
            })
          }
          offersNote
          icon={offer.icon || {}}
          setIcon={(data) => setOffer({ ...offer, icon: data })}
          groupKeys={[ResourceGroupKeyDtoEnum.OFFER]}
        />
      </div>
      <div className='RightSideSection'>
        <TextStyle
          offersNote
          textStyle={offer.textStyle || {}}
          onSystemReset={() =>
            setOffer({ ...offer, textStyle: shopDefaultOffer.textStyle })
          }
          setTextStyle={(data) => setOffer({ ...offer, textStyle: data })}
        />
      </div>
    </div>
  );
};

export default NotificationPresetOffer;
