import React, { useEffect, useMemo, useState } from 'react';
import {
  ActionItem,
  ActionListInPopover,
} from 'features/promotions/components/ActionListInPopover/ActionListInPopover';
import { Icon, InlineStack, List } from '@shopify/polaris';
import { CartIcon } from '@shopify/polaris-icons';
import {
  boldMessageParts,
  generateMessage,
  generateRules,
  SectionTypeEnum,
} from '../../utils/utils';
import { I18n } from '@shopify/react-i18n';
import {
  OfferCartRuleDto,
  OfferCartRuleGroupConnectorDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferTargetTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { headerTitle } from 'core/components/SearchFieldWithGrayBoxOfferWizard/utils/utils';
import { ResourceSelectionProps } from 'core/components/SearchFieldWithGrayBox';
import classNames from 'classnames';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';

type RuleInfoProps = {
  actionList: ActionItem[];
  i18n: I18n;
  rule: OfferCartRuleDto;
  ruleIndex: number;
  activePopoverId: string;
  connector?: OfferCartRuleGroupConnectorDto;
  singleRule: boolean;
  setActivePopoverId: (id: string) => void;
  onRuleClick: () => void;
};

export const RuleInfo: React.FC<RuleInfoProps> = ({
  actionList,
  i18n,
  rule,
  ruleIndex,
  activePopoverId,
  connector,
  singleRule,
  setActivePopoverId,
  onRuleClick,
}) => {
  const { getLookupShopifyDetailedObjectsDetails } = useConfigureOffers();
  const { exclusionsData, exclusionsIsFetching } = useConfigurePromotions(
    PromotionFetchTypeEnum.EXCLUSIONS
  );

  const [currentSelections, setCurrentSelections] = useState<string>('');
  const [bullets, setBullets] = useState<JSX.Element[]>([]);

  const resourceType = useMemo(() => {
    switch (rule.appliesTo?.type) {
      case OfferTargetTypeDtoEnum.COLLECTIONS:
        return ShopifyObjectTypeDtoEnum.COLLECTION;
      case OfferTargetTypeDtoEnum.PRODUCTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT;
      case OfferTargetTypeDtoEnum.VARIANTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT;
      default:
        return null;
    }
  }, [rule.appliesTo?.type]);

  const ruleCurrentList = useMemo(
    () =>
      rule.appliesTo?.type !== OfferTargetTypeDtoEnum.ALL
        ? ((rule.appliesTo?.[
            rule.appliesTo?.type?.toLowerCase() as keyof typeof rule.appliesTo
          ] || []) as ShopifyObjectDto[])
        : null,
    [rule.appliesTo]
  );

  useEffect(() => {
    if (resourceType && ruleCurrentList?.length) {
      getLookupShopifyDetailedObjectsDetails({
        type: resourceType,
        objects: ruleCurrentList,
      }).then((res) => {
        setCurrentSelections(
          headerTitle(res as ResourceSelectionProps[], resourceType, i18n)
        );
      });
    }
  }, [resourceType, ruleCurrentList]);

  // for tags and vendors
  useEffect(() => {
    if (
      rule.appliesTo?.type &&
      ruleCurrentList &&
      [OfferTargetTypeDtoEnum.TAGS, OfferTargetTypeDtoEnum.VENDORS].includes(
        rule.appliesTo?.type as OfferTargetTypeDtoEnum
      )
    ) {
      setCurrentSelections(
        headerTitle(ruleCurrentList as string[], rule.appliesTo?.type, i18n)
      );
    }
  }, [rule.appliesTo?.type, ruleCurrentList]);

  useEffect(() => {
    const fetchBullets = async () => {
      const result = await generateRules(
        rule,
        i18n,
        getLookupShopifyDetailedObjectsDetails,
        exclusionsData?.cartRules
      );
      setBullets(result);
    };

    fetchBullets();
  }, [
    exclusionsData?.cartRules,
    rule,
    i18n,
    getLookupShopifyDetailedObjectsDetails,
  ]);

  return (
    <div
      className={classNames('specificRule', {
        noBorder: singleRule,
      })}
      onClick={onRuleClick}
    >
      <InlineStack wrap={false} align='space-between' blockAlign='center'>
        <InlineStack wrap={false} gap='200' blockAlign='center'>
          <div style={{ width: 20, height: 20 }}>
            <Icon source={CartIcon} tone='base' />
          </div>
          {boldMessageParts(
            generateMessage(
              SectionTypeEnum.CART_RULES,
              rule,
              ruleIndex,
              currentSelections,
              i18n,
              connector,
              singleRule
            )
          )}
        </InlineStack>
        <ActionListInPopover
          id={rule.id || ''}
          btnContent=''
          actionList={actionList}
          activePopoverId={activePopoverId}
          setActivePopoverId={setActivePopoverId}
        />
      </InlineStack>
      {bullets.length ? (
        <div className='bullet'>
          <List>
            {bullets.map((bullet, index) => (
              <List.Item key={index}>{bullet}</List.Item>
            ))}
          </List>
        </div>
      ) : null}
    </div>
  );
};
