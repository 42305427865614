import React, { useCallback, useMemo } from 'react';
import {
  BlockStack,
  Card,
  Checkbox,
  ChoiceList,
  InlineStack,
  Text,
  TextField,
} from '@shopify/polaris';
import './OffsetSetting.scss';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoOffsetComponentDto } from 'core/api/adminSettings/adminSettingsApi';
import { OffsetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { Element } from 'react-scroll';

type OffsetSettingProps = {
  offset: OptionDtoOffsetComponentDto;
  subtitle?: string;
  offsetSizes?: OffsetTypeDtoEnum[];
  setOffset: (value: OptionDtoOffsetComponentDto) => void;
};
export const OffsetSetting: React.FC<OffsetSettingProps> = ({
  offset,
  subtitle,
  offsetSizes,
  setOffset,
}) => {
  const [i18n] = useI18n();

  const offsetOptions = useMemo(
    () =>
      (offsetSizes?.length
        ? offsetSizes
        : Object.values(OffsetTypeDtoEnum)
      ).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [offsetSizes, i18n]
  );

  const onCustomOffsetValdiate = useCallback(
    (field: 'horizontal' | 'vertical') => {
      const customValue = offset.value?.[field] || 0;
      setOffset({
        ...offset,
        value: {
          ...offset.value,
          [field]: customValue > 24 ? 24 : customValue < 0 ? 0 : customValue,
        },
      });
    },
    [setOffset, offset]
  );

  return (
    <Element name='OffsetSetting' className='OffsetSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Offset')}
            </Text>
            <Text as='p' tone='subdued'>
              {subtitle ? subtitle : i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <Checkbox
            checked={offset.enabled}
            onChange={(value) => setOffset({ ...offset, enabled: value })}
            label={i18n.translate('EnableOffset')}
          />
          {offset.enabled && (
            <>
              <ChoiceList
                title={i18n.translate('OffsetValue')}
                choices={offsetOptions}
                selected={[offset.value?.type || OffsetTypeDtoEnum.MEDIUM]}
                onChange={(value) =>
                  setOffset({
                    ...offset,
                    value: {
                      ...offset.value,
                      type: value[0] as OffsetTypeDtoEnum,
                    },
                  })
                }
              />
              {offset.value?.type === OffsetTypeDtoEnum.CUSTOM && (
                <InlineStack wrap={false} gap='300'>
                  <div className='OffsetInput'>
                    <TextField
                      type='number'
                      value={offset.value?.horizontal?.toString()}
                      autoComplete=''
                      label={i18n.translate('HorizontalOffset')}
                      suffix='px'
                      onChange={(value) =>
                        setOffset({
                          ...offset,
                          value: {
                            ...offset.value,
                            horizontal: +value,
                          },
                        })
                      }
                      onBlur={() => onCustomOffsetValdiate('horizontal')}
                    />
                  </div>
                  <div className='OffsetInput'>
                    <TextField
                      type='number'
                      value={offset.value?.vertical?.toString()}
                      autoComplete=''
                      label={i18n.translate('VerticalOffset')}
                      suffix='px'
                      onChange={(value) =>
                        setOffset({
                          ...offset,
                          value: {
                            ...offset.value,
                            vertical: +value,
                          },
                        })
                      }
                      onBlur={() => onCustomOffsetValdiate('vertical')}
                    />
                  </div>
                </InlineStack>
              )}
            </>
          )}
        </BlockStack>
      </Card>
    </Element>
  );
};
