import React, { useCallback, useMemo } from 'react';
import {
  Icon,
  SingleInputSelection,
  WidgetOptionHeader,
} from '../../../../../../../../../../../components';
import { NinjaCartCartItemGeneralPresetDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import {
  ImageSizeDtoEnum,
  NinjaCartCartItemQuantitySelectorTypeDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';

type Preset_NDC_CartItemGeneralProps = {
  general: NinjaCartCartItemGeneralPresetDto;
  defaultGeneral: NinjaCartCartItemGeneralPresetDto;
  shopDefaultGeneral: NinjaCartCartItemGeneralPresetDto;
  setGeneral: (data: NinjaCartCartItemGeneralPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_CartItemGeneral: React.FC<Preset_NDC_CartItemGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  setGeneral,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const imageSizeOptions = useMemo(
    () =>
      Object.values(ImageSizeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );
  const quantitySelectorOptions = useMemo(
    () =>
      Object.values(NinjaCartCartItemQuantitySelectorTypeDtoEnum).map(
        (value) => ({
          label: i18n.translate(value),
          value: value,
        })
      ),
    [i18n]
  );
  const handleUpdateGeneral = useCallback(
    (
      field: keyof NinjaCartCartItemGeneralPresetDto,
      data: NinjaCartCartItemGeneralPresetDto[keyof NinjaCartCartItemGeneralPresetDto]
    ) => setGeneral({ ...general, [field]: data }),
    [general]
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={() => setGeneral(shopDefaultGeneral)}
        name={WidgetListOptionsDtoEnum.CART_ITEM}
        onShowHide={onShowHide}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SingleInputSelection
          options={imageSizeOptions}
          label={i18n.translate('ImageSize')}
          selectedOption={general.imageSize as string}
          setOption={(value) =>
            handleUpdateGeneral('imageSize', value as ImageSizeDtoEnum)
          }
          onSystemReset={() =>
            handleUpdateGeneral('imageSize', shopDefaultGeneral.imageSize)
          }
        />
      </div>
      <div className='RightSideSection'>
        <Icon
          groupKeys={[ResourceGroupKeyDtoEnum.OFFER]}
          icon={general.deleteButtonIcon?.value || {}}
          enabled={general.deleteButtonIcon?.enabled}
          enabledText={i18n.translate('EnableDeleteButton')}
          showIcon
          setIcon={(data) =>
            handleUpdateGeneral('deleteButtonIcon', {
              ...general.deleteButtonIcon,
              value: data,
            })
          }
          onSystemReset={() =>
            handleUpdateGeneral(
              'deleteButtonIcon',
              shopDefaultGeneral.deleteButtonIcon
            )
          }
          setEnabled={(value) =>
            handleUpdateGeneral('deleteButtonIcon', {
              ...general.deleteButtonIcon,
              enabled: value,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <SingleInputSelection
          options={quantitySelectorOptions}
          label={i18n.translate('QuantitySelector')}
          selectedOption={general.quantitySelectorType as string}
          setOption={(value) =>
            handleUpdateGeneral(
              'quantitySelectorType',
              value as NinjaCartCartItemQuantitySelectorTypeDtoEnum
            )
          }
          onSystemReset={() =>
            handleUpdateGeneral(
              'quantitySelectorType',
              shopDefaultGeneral.quantitySelectorType
            )
          }
        />
      </div>
    </>
  );
};

export default Preset_NDC_CartItemGeneral;
