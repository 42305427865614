import React, { useCallback } from 'react';
import './AnnouncementBarGeneral.scss';
import { Order, ShopSettingsWrapper, Size } from '../../../../../components';
import { Text } from '@shopify/polaris';
import {
  AnnouncementBarPagePositionDto,
  AnnouncementBarPromotionDto,
  AnnouncementBarSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { AnnouncementBarPosition } from './components/AnnouncementBarPosition/AnnouncementBarPosition';
import { AnnouncementBarBehavior } from './components/AnnouncementBarBehavior/AnnouncementBarBehavior';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { useAppDispatch } from 'core/hooks';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type AnnouncementBarGeneralProps = {
  general: AnnouncementBarPromotionDto;
  defaultGeneral: AnnouncementBarPromotionDto;
  shopDefaultGeneral: AnnouncementBarSystemDefaultsDto;
  isPresetEditor?: boolean;
  setGeneral: (data: AnnouncementBarPromotionDto) => void;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const AnnouncementBarGeneral: React.FC<AnnouncementBarGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  isPresetEditor,
  setGeneral,
  onShowHide,
  onRemove,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const resetAllToSystemDefault = useCallback(() => {
    const { stylePriority } = shopDefaultGeneral;
    setGeneral({
      ...general,
      stylePriority,
    });
  }, [general, shopDefaultGeneral]);

  return (
    <div className='AnnouncementBarGeneral'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onRemove={onRemove}
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListTypeDtoEnum.ANNOUNCEMENT_BAR}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {!isPresetEditor && (
        <div className='RightSideSection'>
          <Order
            order={general.stylePriority || {}}
            setOrder={(data) => setGeneral({ ...general, stylePriority: data })}
            title={i18n.translate('StylePriority')}
            onSystemReset={() =>
              setGeneral({
                ...general,
                stylePriority: shopDefaultGeneral.stylePriority,
              })
            }
          />
        </div>
      )}
      {isPresetEditor && (
        <div className='EmptyRightSideBanner'>
          <div className='RightSideSection'>
            <Text as='p' tone='subdued'>
              {i18n.translate('NoCustomizable')}
            </Text>
          </div>
        </div>
      )}

      <ShopSettingsWrapper
        onOpen={() =>
          dispatch(
            setToggleSettingsConfig({
              action: 'open',
              widgetType: WidgetTypeDtoEnum.ANNOUNCEMENT_BAR,
            })
          )
        }
      >
        <div className='RightSideSection'>
          <AnnouncementBarPosition
            position={
              general.shopSettings
                ?.pagePosition as AnnouncementBarPagePositionDto
            }
            disabled
          />
        </div>
        <div className='RightSideSection'>
          <Size size={general.shopSettings?.size || {}} disabled />
        </div>
        <div className='RightSideSection'>
          <AnnouncementBarBehavior
            behavior={general.shopSettings || {}}
            disabled
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default AnnouncementBarGeneral;
