import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './RequestWidgetSupport.scss';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  ChoiceList,
  Collapsible,
  InlineStack,
  Text,
  TextField,
} from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  InstallationSupportRequestDto,
  WidgetTypeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  InstallationSupportRequestDemandDtoEnum as DemandDtoEnum,
  InstallationSupportRequestDemandDtoEnum,
  InstallationSupportRequestPriorityDtoEnum,
  WidgetStatusDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty } from 'lodash';
import { Loader, SetupStepBox } from 'core/components';
import { useWidgetsControlCenter } from 'core/hooks/useWidgetsControlCenter';
import { WidgetsControlCenterModalsEnum } from 'features/widgets/enums/WidgetsControlCenterModalsType';
import { useAppSelector } from 'core/hooks';
import { updateFirstLetter } from 'core/utils/updateFirstLetter';
import { SuccessSupportRequestModalState } from 'features/widgets/WidgetsControlCenter';

type RequestWidgetSupportProps = {
  widgetType?: WidgetTypeDtoEnum;
  skipUnnecessaryRequests?: boolean;
  currentThemeName?: string;
  setSuccessModal?:
    | React.Dispatch<React.SetStateAction<SuccessSupportRequestModalState>>
    | undefined;
  setIsWidgetRequestSent?: (value: boolean) => void;
  refetchWidget?: () => void;
  onClose: () => void;
};

const RequestWidgetSupport: React.FC<RequestWidgetSupportProps> = ({
  widgetType,
  skipUnnecessaryRequests,
  currentThemeName,
  setSuccessModal,
  setIsWidgetRequestSent,
  refetchWidget,
  onClose,
}) => {
  const { createSupportRequest, createSupportRequestIsLoading } =
    useConfigureSettings(
      SettingsFetchTypeEnum.WIDGET_SUPPORT_REQUEST,
      skipUnnecessaryRequests
    );
  const { requestSupportData, requestSupportIsFetching } =
    useWidgetsControlCenter(
      WidgetsControlCenterModalsEnum.REQUEST_SUPPORT_DETAILS_MODAL
    );

  const { widgetThemeType } = useAppSelector((store) => store.settings);
  // const { themesData, themesIsFetching, refetchThemesData } =
  //   useWidgetsControlCenter(WidgetsControlCenterModalsEnum.MANAGE_THEMES_MODAL);
  const [i18n] = useI18n();
  const [requestSetup, setRequestSetup] =
    useState<InstallationSupportRequestDto>({});
  const [allWidgetCollapsed, setAllWidgetCollapsed] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [dismissed, setDismissed] = useState<boolean>(false);
  const [addMore, setAddMore] = useState<boolean>(false);

  const enabledStatuses = [
    WidgetStatusDtoEnum.ENABLED,
    WidgetStatusDtoEnum.ENABLED_VIA_CODE,
    WidgetStatusDtoEnum.ENABLED_VIA_CSS,
    WidgetStatusDtoEnum.ENABLED_BUILT_IN,
  ];

  const modeNeeded = useMemo(
    () =>
      requestSupportData?.widgets?.find((widget) => widget.type === widgetType)
        ?.installationMode,
    [requestSupportData?.widgets, widgetType]
  );
  const filteredWidgets = useMemo(
    () =>
      requestSupportData?.widgets?.filter(
        (widget) =>
          widget.installationMode === modeNeeded &&
          !enabledStatuses.includes(widget.status as WidgetStatusDtoEnum)
      ),
    [requestSupportData?.widgets, modeNeeded, enabledStatuses]
  );

  const prioriyOptions = useMemo(
    () =>
      Object.values(InstallationSupportRequestPriorityDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
        helpText: i18n.translate(`${value}_HELP`, {
          charge: requestSupportData?.highPriorityPrice,
        }),
      })),
    [i18n, requestSupportData?.highPriorityPrice]
  );
  const isLoading = !(
    (requestSupportData && !requestSupportIsFetching && !isEmpty(requestSetup))
    // themesData
  );

  // const themesOptions = useMemo(
  //   () =>
  //     themesData?.map((theme) => ({
  //       value: theme.id?.toString(),
  //       label: theme.name || '',
  //     })) as ComboboxOption[],
  //   [themesData]
  // );
  const availableWidgetTypes = useMemo(
    () =>
      filteredWidgets
        ?.filter((widget) => widget.status !== WidgetStatusDtoEnum.LOCKED)
        .map((widget) => widget.type) || [],
    [filteredWidgets]
  );

  const allWidgetsChecked = useMemo(
    () =>
      requestSetup.selectedWidgets?.length === availableWidgetTypes.length
        ? true
        : requestSetup.selectedWidgets?.length
        ? 'indeterminate'
        : false,
    [requestSetup.selectedWidgets, availableWidgetTypes]
  );

  const updateRequestSetup = useCallback(
    (
      field: keyof InstallationSupportRequestDto,
      data: InstallationSupportRequestDto[keyof InstallationSupportRequestDto]
    ) => setRequestSetup({ ...requestSetup, [field]: data }),
    [requestSetup]
  );

  const widgetOptions = useMemo(() => {
    return (
      filteredWidgets?.map((widget) => ({
        value: widget.type as WidgetTypeDtoEnum,
        label: (
          <InlineStack gap='200'>
            {i18n.translate(`${widget.type}`)}
            {widget.planSupportingType && (
              <Badge tone='attention'>{widget.planSupportingType.name}</Badge>
            )}
          </InlineStack>
        ),
        disabled: widget.status === WidgetStatusDtoEnum.LOCKED,
      })) || []
    );
  }, [filteredWidgets, i18n]);

  const sendSupportRequest = useCallback(() => {
    const onSuccess = () => {
      setIsWidgetRequestSent?.(true);
      refetchWidget?.();
      onClose();
    };
    createSupportRequest(requestSetup, onSuccess).then((data) => {
      if (data && !!requestSupportData?.highPriorityPrice) {
        setSuccessModal?.({
          ...data,
          priority: requestSetup.priority,
          highPriorityPrice: requestSupportData?.highPriorityPrice,
        });
      }
    });
  }, [
    requestSetup,
    requestSupportData?.highPriorityPrice,
    createSupportRequest,
    refetchWidget,
    onClose,
    setIsWidgetRequestSent,
  ]);

  const handleAllWidgtes = useCallback(() => {
    const hasSelected = !!requestSetup.selectedWidgets?.length;
    const mergedSelection = Array.from(
      new Set([
        ...(requestSetup.selectedWidgets || []),
        ...availableWidgetTypes,
      ])
    ) as WidgetTypeDto[];

    const clearedSelection = requestSetup.selectedWidgets?.filter(
      (widget) => !availableWidgetTypes.includes(widget)
    );
    updateRequestSetup(
      'selectedWidgets',
      hasSelected ? clearedSelection : mergedSelection
    );
  }, [requestSetup.selectedWidgets, availableWidgetTypes]);

  useEffect(() => {
    if (requestSupportData && isEmpty(requestSetup)) {
      setRequestSetup({
        ...requestSupportData.supportRequest,
        ...(widgetType && { selectedWidgets: [widgetType] }),
        priority: InstallationSupportRequestPriorityDtoEnum.NORMAL,
      });
    }
  }, [requestSupportData]);

  return (
    <div className='RequestWidgetSupport'>
      <div className='ToggleWidgetModalContent'>
        {!isLoading ? (
          <BlockStack gap='400'>
            {/* <div className='ThemeSelectorInputBox'>
              <SelectOptions
                options={themesOptions}
                onOptionSelect={(value) => updateRequestSetup('themeId', value)}
                selectedOption={requestSetup.themeId?.toString() || ''}
                label=''
                isLoading={themesIsFetching}
                prefix={i18n.translate('Theme')}
                placeholder={i18n.translate('Select')}
              />
              <Button disabled={themesIsFetching} onClick={refetchThemesData}>
                {i18n.translate('Refresh')}
              </Button>
            </div> */}
            <BlockStack gap='200'>
              <span>
                {i18n.translate('SelectWidgets', {
                  themeType: (
                    <Text as='span' fontWeight='medium'>
                      {updateFirstLetter(
                        i18n.translate(widgetThemeType),
                        false
                      )}
                    </Text>
                  ),
                  themeName: (
                    <Text as='span' fontWeight='medium'>
                      {currentThemeName}
                    </Text>
                  ),
                })}
              </span>

              <Box
                padding='200'
                borderWidth='025'
                borderColor='border'
                borderRadius='300'
              >
                <SetupStepBox
                  label={i18n.translate(widgetType as WidgetTypeDtoEnum)}
                  helpText={i18n.translate('HelpText')}
                  isLoading={requestSupportIsFetching}
                  button={{
                    children: i18n.translate('LearnMore'),
                    variant: 'secondary',
                  }}
                  checked={
                    !!requestSetup.selectedWidgets?.includes(
                      widgetType as WidgetTypeDtoEnum
                    )
                  }
                  expanded={true}
                  forceShowButtons
                />
              </Box>
              {addMore && (
                <Box
                  paddingInline='300'
                  paddingBlockEnd='300'
                  borderWidth='025'
                  borderColor='border'
                  borderRadius='300'
                >
                  <Box paddingBlock='300'>
                    <InlineStack align='space-between' blockAlign='center'>
                      <Checkbox
                        label={i18n.translate('AllWidgets', {
                          quantity: requestSetup?.selectedWidgets?.length,
                        })}
                        checked={allWidgetsChecked}
                        onChange={handleAllWidgtes}
                      />
                      <Button
                        onClick={() => setAllWidgetCollapsed((prev) => !prev)}
                        variant='monochromePlain'
                        icon={
                          allWidgetCollapsed ? ChevronDownIcon : ChevronUpIcon
                        }
                      />
                    </InlineStack>
                  </Box>
                  <Collapsible id='WidgetModeLists' open={!allWidgetCollapsed}>
                    <ChoiceList
                      title=''
                      onChange={(value) =>
                        updateRequestSetup(
                          'selectedWidgets',
                          value as WidgetTypeDto[]
                        )
                      }
                      choices={widgetOptions}
                      selected={
                        (requestSetup.selectedWidgets || [
                          widgetType,
                        ]) as string[]
                      }
                      allowMultiple
                    />
                  </Collapsible>
                </Box>
              )}
            </BlockStack>
            {requestSupportData?.demand !==
            InstallationSupportRequestDemandDtoEnum.REGULAR ? (
              <Banner tone='warning'>
                {i18n.translate(`${requestSupportData?.demand}_BANNER`)}
              </Banner>
            ) : (
              <>
                {!dismissed && !addMore && (
                  <Banner
                    tone='info'
                    onDismiss={() => setDismissed(true)}
                    action={{
                      content: i18n.translate('AddMoreWidgets'),
                      onAction: () => setAddMore(true),
                    }}
                  >
                    {i18n.translate('AddMoreWidgetsQuestion')}
                  </Banner>
                )}
                <ChoiceList
                  title={i18n.translate('Priority')}
                  choices={prioriyOptions}
                  selected={[requestSetup.priority] as string[]}
                  onChange={(value) =>
                    updateRequestSetup(
                      'priority',
                      value[0] as InstallationSupportRequestPriorityDtoEnum
                    )
                  }
                />
                <Banner tone='info'>{i18n.translate('NoteBannerText')}</Banner>
              </>
            )}
            <TextField
              autoComplete=''
              label={i18n.translate('OptionalNote')}
              placeholder={i18n.translate('TypeHere')}
              value={requestSetup.note || ''}
              onChange={(value) => updateRequestSetup('note', value)}
            />
            <Checkbox
              checked={isConfirmed}
              onChange={setIsConfirmed}
              label={i18n.translate('Confirmation')}
            />
          </BlockStack>
        ) : (
          <Loader fullWidth />
        )}
      </div>
      <div className='ModalFooterActions'>
        <ButtonGroup>
          <Button onClick={onClose}>{i18n.translate('Cancel')}</Button>
          <Button
            onClick={sendSupportRequest}
            variant='primary'
            disabled={!isConfirmed || !requestSetup.selectedWidgets?.length}
            loading={createSupportRequestIsLoading}
          >
            {i18n.translate('SendRequest')}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default RequestWidgetSupport;
