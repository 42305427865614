import React from 'react';
import { useGetPromotionsV6PromotionByIdOffersQuery } from 'core/api/adminPromotions/adminPromotionsApi';
import resolveEnvVar from 'env-var-resolver';

export const useConfigureOfferList = (
  promotionId: string,
  offerPage: number
) => {
  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const {
    data: offerListData,
    isFetching: offerListIsFetching,
    refetch: refetchOfferList,
  } = useGetPromotionsV6PromotionByIdOffersQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: promotionId,
      itemsPerPage: 5,
      page: offerPage,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return {
    offerListData,
    offerListIsFetching,
    refetchOfferList,
  };
};
