import React from 'react';
import './StyledTabs.scss';
import { TabProps, Tabs } from '@shopify/polaris';

type StyledTabsProps = {
  tabs: TabProps[];
  selected: number;
  onSelect: (value: number) => void;
};

export const StyledTabs: React.FC<StyledTabsProps> = ({
  tabs,
  selected,
  onSelect,
}) => {
  return (
    <div className='StyledTabsWrapper'>
      <Tabs tabs={tabs} selected={selected} onSelect={onSelect} fitted />
    </div>
  );
};
