import React, { useCallback, useMemo, useState } from 'react';
import { Icon, Box, Popover, TextField, Text } from '@shopify/polaris';
import { SelectIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import './DaysSelector.scss';
import classNames from 'classnames';

type DaysSelectorProps = {
  selectedOptions?: number[];
  disabled?: boolean;
  id?: string;
  error?: string | boolean;
  onChange: (value: number[]) => void;
};

export const DaysSelector: React.FC<DaysSelectorProps> = ({
  disabled,
  selectedOptions,
  id,
  error,
  onChange,
}) => {
  const [i18n] = useI18n();
  const [isPopoverActive, setIsPopoverActive] = useState<boolean>(false);

  const dayOptions = useMemo(() => {
    const numberArray = Array.from({ length: 31 }, (_, index) => index + 1);
    return numberArray.map((value) => ({
      label: value >= 29 ? `${value}*` : value,
      value,
    }));
  }, []);

  const updateSelection = useCallback(
    (selected: number) => {
      if (selectedOptions?.includes(selected)) {
        onChange(selectedOptions.filter((option) => option !== selected));
      } else {
        onChange([...(selectedOptions || []), selected]);
      }
    },
    [selectedOptions, onChange]
  );

  const inputPreview = useMemo(() => {
    const selected = [...(selectedOptions || [])]
      .sort((a, b) => a - b)
      .map((value) => (value === 1 ? `${value}st` : `${value}th`));
    return selected?.join(', ');
  }, [selectedOptions]);

  return (
    <Box id={id}>
      <Popover
        active={isPopoverActive}
        activator={
          <div onClick={() => setIsPopoverActive(!isPopoverActive)}>
            <TextField
              suffix={<Icon source={SelectIcon} />}
              label={i18n.translate('SelectDays')}
              value={inputPreview}
              placeholder={i18n.translate('SelectDays') + '...'}
              autoComplete='off'
              error={error}
              disabled={disabled}
            />
          </div>
        }
        onClose={() => setIsPopoverActive(false)}
      >
        <Box padding='400'>
          <div className='DaysSelectorOptionBox'>
            {dayOptions.map((item) => (
              <div
                className={classNames('DaysSelectorOption', {
                  IsSelected: selectedOptions?.includes(item.value),
                })}
                onClick={() => updateSelection(item.value)}
                key={item.value}
              >
                <Text as='p' variant='bodySm'>
                  {item.label}
                </Text>
              </div>
            ))}
          </div>
        </Box>
      </Popover>
    </Box>
  );
};
