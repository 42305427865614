import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  WidgetSettingsFormValidity,
  setSettingsFormValidity,
} from 'core/store/settingsSlice';

export const useSettingsFormValidity = () => {
  const dispatch = useDispatch();

  const [formValidityControlArray, setFormValidityControlArray] = useState<
    WidgetSettingsFormValidity[]
  >([]);

  const validityChangeHandler = (sectionName: string, isValid: boolean) => {
    setFormValidityControlArray((prev) => {
      const index = prev.findIndex((el) => el.sectionName === sectionName);
      if (index > -1) {
        return prev.map((item) =>
          item.sectionName === sectionName ? { ...item, isValid } : item
        );
      }
      return [...prev, { sectionName, isValid }];
    });
  };

  useEffect(() => {
    dispatch(setSettingsFormValidity(formValidityControlArray));
  }, [formValidityControlArray]);

  return {
    validityChangeHandler,
    formValidityControlArray,
  };
};
