export const enum AnalyticsPageSubPathsEnums {
  TotalDnRevenue = 'total-dn-revenue',
  DnConversionRate = 'dn-conversion-rate',
  TotalDnOrders = 'total-dn-orders',
  DnSessions = 'dn-sessions',
  PromotionActivityReport = 'promotion-activity-report',
  OrderReport = 'order-report',
  PromotionCodeReport = 'promotion-code-report',
}

export const enum PromotionPageSubPathsEnums {
  Settings = 'settings',
  SettingsDiscountLinks = 'settings/discount-links',
  SettingsPromotionCodes = 'settings/promotion-codes',
  SettingsAudience = 'settings/audience',
  CreateOfferTemplate = 'create-offer-template',
}

export const enum SettingsModalPathsEnums {
  Account = 'account',
  Plan = 'plan',
  Style = 'style',
  Translations = 'translations',
  CartBehavior = 'cart-behavior',
  Gifts = 'gifts',
  CheckoutOptions = 'checkout-options',
  CustomShippingRates = 'custom-shipping-rates',
  DiscountCodes = 'discount-codes',
  Notifications = 'notifications',
  Integrations = 'integrations',
  AutoTagging = 'auto-tagging',
  DiscountLinks = 'discount-links',
  ImportExport = 'import-export',
  Testing = 'testing',
  Exclusions = 'exclusions',
}

export const enum SettingsModalSubPathsEnums {
  DNPromocodes = 'dn-promocodes',
  ShopifyDiscountCodes = 'shopify-discount-codes',
  ActivityLog = 'activity-log',
  PickYourPlan = 'pick-your-plan',
  ShopLevelStylePresets = 'shop-level_style_presets',
  PromotionLevelStylePresets = 'promotion-level_style_presets',
  PastBills = 'past-bills',
  TranslationsSettings = 'translations-settings',
}

export enum ControlPageTypeEnum {
  WIDGETS_CONTROL_CENTER = 'WidgetsControlCenter',
  NINJA_CART_CONTROL_CENTER = 'NinjaCartControlCenter',
}
