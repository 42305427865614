import React, { useEffect, useMemo } from 'react';
import { I18n } from '@shopify/react-i18n';
import {
  Button,
  ButtonGroup,
  Text,
  Icon,
  IconSource,
  InlineStack,
  BlockStack,
  Box,
} from '@shopify/polaris';
import classNames from 'classnames';
import { AlertCircleIcon } from '@shopify/polaris-icons';
import { ShippingValueTypeEnum } from 'features/promotions/components/SelectedOfferTypeSettings/enums/ShippingValueType';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import useMaxAllowedValueRule from 'features/promotions/hooks/useMaxAllowedValueRule';
import { getCurrencySymbol } from 'core/utils';
type LocaleNumberFormattingSettingsModel = {
  thousandSymbol: string;
  decimalSymbol: string;
};
type ShippingTypeSectionProps = {
  i18n: I18n;
  textFieldValue: NumberFormatValues;
  setTextFieldValue: (data: NumberFormatValues) => void;
  updateValidity?(index: number, isValid: boolean): void;
  activeShippingValueType: ShippingValueTypeEnum;
  onShippingTypeClick: (data: string) => void;
};

// Needs to be refactored (Yevhen)
export const ShippingTypeSection: React.FC<ShippingTypeSectionProps> = (
  props
) => {
  const {
    i18n,
    setTextFieldValue,
    textFieldValue,
    updateValidity,
    onShippingTypeClick,
    activeShippingValueType,
  } = props;

  const inputValueNotValid: boolean = useMemo(
    () => textFieldValue.value === '',
    [textFieldValue.value]
  );

  useEffect(() => {
    if (activeShippingValueType === ShippingValueTypeEnum.FreeShipping) {
      updateValidity ? updateValidity(0, true) : null;
    } else {
      updateValidity ? updateValidity(0, !inputValueNotValid) : null;
    }
  }, [inputValueNotValid, activeShippingValueType]);

  const localeNumberFormattingSettings: LocaleNumberFormattingSettingsModel =
    i18n.numberSymbols();

  const maxAllowedValueRule = useMaxAllowedValueRule();
  return (
    <>
      <BlockStack gap='400'>
        <InlineStack gap='200' blockAlign='center'>
          <Text as='p'>{i18n.translate(`Shipping.Apply`)}</Text>
          <ButtonGroup variant='segmented'>
            {Object.entries(ShippingValueTypeEnum).map(
              ([key, value]: [string, ShippingValueTypeEnum]) => {
                return (
                  <Button
                    key={key}
                    onClick={() => onShippingTypeClick(value)}
                    pressed={activeShippingValueType === value}
                  >
                    {i18n.translate(`Shipping.${key}`)}
                  </Button>
                );
              }
            )}
          </ButtonGroup>
          {activeShippingValueType === 'Flat fee shipping rate' && (
            <div>
              <div
                className={classNames(
                  'Polaris-TextField Polaris-TextField--hasValue',
                  {
                    'Polaris-TextField--error': inputValueNotValid,
                  }
                )}
              >
                <div className='Polaris-TextField__Prefix'>
                  <span className='Polaris-Text--root Polaris-Text--bodyMd'>
                    {getCurrencySymbol()}
                  </span>
                </div>
                <NumberFormat
                  className='Polaris-TextField__Input'
                  value={textFieldValue.value}
                  decimalSeparator={
                    localeNumberFormattingSettings.decimalSymbol
                  }
                  thousandSeparator={
                    localeNumberFormattingSettings.thousandSymbol
                  }
                  allowNegative={false}
                  allowEmptyFormatting
                  isNumericString
                  decimalScale={2}
                  onValueChange={setTextFieldValue}
                  isAllowed={maxAllowedValueRule}
                />
                <div className='Polaris-TextField__Backdrop'></div>
              </div>
              {inputValueNotValid && (
                <div style={{ position: 'absolute', bottom: '16px' }}>
                  <Text as='p' variant='bodyMd' tone='critical'>
                    <InlineStack gap='100'>
                      <span>
                        <Icon
                          source={AlertCircleIcon as IconSource}
                          tone='critical'
                        />
                      </span>
                      {i18n.translate('Shipping.validationMsg') ||
                        'Value is required'}
                    </InlineStack>
                  </Text>
                </div>
              )}
            </div>
          )}
        </InlineStack>
        {inputValueNotValid &&
          activeShippingValueType ===
            ShippingValueTypeEnum.FlatFeeShippingRate && (
            <Box paddingBlockEnd='200'></Box>
          )}
      </BlockStack>
    </>
  );
};
