import React, { useCallback, useMemo, useState } from 'react';
import { BlockStack, Modal, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector } from 'core/hooks';
import { DiscountNinjaTutorial } from 'features/dashboard/components/DiscountNinjaTutorial/DiscountNinjaTutorial';
import { ThemeBox } from 'features/onboarding/components/EnableDNStep/components/ThemeBox/ThemeBox';
import {
  EngineStatusesDto,
  SelectedThemesType,
} from 'features/onboarding/Onboarding';
import useFetchEngineAndPlanCheck from 'features/promotions/components/PromotionOverview/utils/useFetchEngineAndPlanCheck/useFetchEngineAndPlanCheck';
import { ThemeTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type DiscountNinjaAppModalProps = {
  open: boolean;
  forceEnableAppOnCurrentTheme: boolean;
  onClose: () => void;
  handleRefresh: () => Promise<void>;
};

export const DiscountNinjaAppModal: React.FC<DiscountNinjaAppModalProps> = (
  props
) => {
  const [i18n] = useI18n();

  const { open, forceEnableAppOnCurrentTheme, onClose, handleRefresh } = props;

  const { engineAndPlanCheckIsFetching, fetchEngineAndPlanCheck } =
    useFetchEngineAndPlanCheck();

  const { widgetThemeType } = useAppSelector((store) => store.settings);

  const [selectedThemes, setSelectedThemes] = useState<SelectedThemesType>({
    currentId: '',
    testId: '',
  });

  const [engineStatuses, setEngineStatuses] = useState<EngineStatusesDto>();
  const [testThemeModal, setTestThemeModal] = useState<boolean | null>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forceOpenThemeEditor, setForceOpenThemeEditor] = useState<number>(0);

  const widgetThemeTypeNeeded = useMemo(
    () =>
      forceEnableAppOnCurrentTheme ? ThemeTypeDtoEnum.CURRENT : widgetThemeType,
    [widgetThemeType, forceEnableAppOnCurrentTheme]
  );

  const selectedTheme = useMemo(
    () =>
      widgetThemeTypeNeeded === ThemeTypeDtoEnum.CURRENT
        ? selectedThemes.currentId
        : selectedThemes.testId,
    [selectedThemes, widgetThemeTypeNeeded]
  );

  const refetchThemeEngines = useCallback(
    (themeId?: string) => {
      fetchEngineAndPlanCheck({
        ...(themeId ? { testThemeId: +themeId } : {}),
        themeType: widgetThemeTypeNeeded,
      }).then((data) =>
        setEngineStatuses(() => ({
          ...(themeId ? { test: data } : { current: data }),
        }))
      );
    },
    [setEngineStatuses, widgetThemeTypeNeeded]
  );

  const handleThemeType = useCallback(
    (type: ThemeTypeDtoEnum) => {
      if (type === ThemeTypeDtoEnum.TEST && testThemeModal !== null) {
        setTestThemeModal(true);
        return;
      }
    },
    [testThemeModal]
  );

  const handleForceRefresh = useCallback(() => {
    onClose();
    handleRefresh();
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={i18n.translate('Title')}
      primaryAction={{
        content: i18n.translate('PrimaryActionBtn'),
        onAction: () => setForceOpenThemeEditor((prev) => prev + 1),
        disabled: !selectedTheme || isLoading,
      }}
      secondaryActions={[
        {
          content: i18n.translate('SecondaryActionBtn'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <Text as='p'>{i18n.translate('MainContent')}</Text>
          <DiscountNinjaTutorial
            noPopoverActions
            content={{
              title: i18n.translate('MediaCardTitle'),
              description: i18n.translate('MediaCardDescription'),
              button: i18n.translate('MediaCardButton'),
            }}
          />
          <ThemeBox
            isInModal
            type={widgetThemeTypeNeeded}
            selectedThemes={selectedThemes}
            engineStatuses={engineStatuses}
            engineStatusIsFetching={engineAndPlanCheckIsFetching}
            forceOpenThemeEditor={forceOpenThemeEditor}
            setSelectedThemes={setSelectedThemes}
            refetchThemeEngines={refetchThemeEngines}
            onChange={handleThemeType}
            setIsLoading={setIsLoading}
            handleForceRefresh={handleForceRefresh}
          />
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
