export enum WidgetsControlCenterModalsEnum {
  WIDGETS_CONTROL_CENTER_MAIN_PAGE = 'WIDGETS_CONTROL_CENTER_MAIN_PAGE',
  NINJA_CART_CONTROL_CENTER_MAIN_PAGE = 'NINJA_CART_CONTROL_CENTER_MAIN_PAGE',
  MANAGE_THEMES_MODAL = 'MANAGE_THEMES_MODAL',
  REQUEST_SUPPORT_DETAILS_MODAL = 'REQUEST_SUPPORT_DETAILS_MODAL',
  TOGGLE_WIDGETS_MODAL = 'TOGGLE_WIDGETS_MODAL',
  ENABLE_NINJA_CART_STEP_MODAL = 'ENABLE_NINJA_CART_STEP_MODAL',
  ENABLE_APP_BLOCK_MODAL = 'ENABLE_APP_BLOCK_MODAL',
  NON_MODAL = 'NON_MODAL',
}
