import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './MyWidgets.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  BlockStack,
  Box,
  Button,
  Card,
  EmptyState,
  InlineStack,
  ResourceList,
  SkeletonBodyText,
  Tabs,
  Text,
  TextField,
} from '@shopify/polaris';
import {
  InstallationSupportRequestSummaryDto,
  NinjaCartWidgetInstallationDto,
  WidgetInstallationDto,
  WidgetTypeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  InstallationSupportRequestGeneralStatusDtoEnum,
  NinjaCartWidgetGroupDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { useAppSelector } from 'core/hooks';
import { useToggleWidget } from 'features/widgets/hooks/useToggleWidget';
import { WidgetCard } from '../WidgetCard/WidgetCard';

type MyWidgetsProps = {
  isWidgetControlCenterPage: boolean;
  isEnabled: boolean;
  isEmpty: boolean;
  isLoading: boolean;
  widgets: NinjaCartWidgetInstallationDto[] | WidgetInstallationDto[];
  supportRequest?: InstallationSupportRequestSummaryDto;
  themeName: string;
  handleExploreAllWidgets: () => void;
  handleEnableAppModal: () => void;
  setActionWidget: React.Dispatch<
    React.SetStateAction<
      WidgetInstallationDto | NinjaCartWidgetInstallationDto | null
    >
  >;
  refetchWidgetInstallationData: () => void;
  setConfigureStyleModal: React.Dispatch<
    React.SetStateAction<WidgetTypeDto | 'GENERAL'>
  >;
  setIsWidgetsListUpdating: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MyWidgets: React.FC<MyWidgetsProps> = (props) => {
  const {
    widgets,
    isEmpty = false,
    isWidgetControlCenterPage,
    isEnabled,
    isLoading,
    supportRequest,
    themeName,
    handleExploreAllWidgets,
    handleEnableAppModal,
    setActionWidget,
    refetchWidgetInstallationData,
    setConfigureStyleModal,
    setIsWidgetsListUpdating,
  } = props;
  const [i18n] = useI18n();

  const { widgetThemeType } = useAppSelector((store) => store.settings);

  const { toggleWidget, loading } = useToggleWidget({
    widgetThemeType,
    isWidgetControlCenterPage,
    setActionWidget,
    refetchWidgetInstallationData,
  });

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [queryValue, setQueryValue] = useState<string>('');

  const tabs = useMemo(
    () => [
      { id: 'all', content: i18n.translate('All'), accessibilityLabel: 'All' },
      ...Object.values(
        isWidgetControlCenterPage
          ? WidgetInstallationModeDtoEnum
          : NinjaCartWidgetGroupDtoEnum
      ).map((value) => ({
        id: value,
        content: i18n.translate(`${value}_MODE`),
        accessibilityLabel: value,
      })),
    ],
    [i18n, isWidgetControlCenterPage]
  );

  const enabledWidgets = useMemo(() => {
    const enabledStatuses = new Set<WidgetStatusDtoEnum>([
      WidgetStatusDtoEnum.ENABLED,
      WidgetStatusDtoEnum.ENABLED_VIA_CODE,
      WidgetStatusDtoEnum.ENABLED_VIA_CSS,
      WidgetStatusDtoEnum.ENABLED_BUILT_IN,
    ]);

    const widgetList: (
      | WidgetInstallationDto
      | NinjaCartWidgetInstallationDto
    )[] = widgets as (WidgetInstallationDto | NinjaCartWidgetInstallationDto)[];

    return widgetList.filter((widget) =>
      enabledStatuses.has(widget.status as WidgetStatusDtoEnum)
    );
  }, [widgets]);

  const filteredWidgets = useMemo(() => {
    return enabledWidgets.filter((widget) => {
      const matchesSearch =
        queryValue.trim() === '' ||
        (widget.type?.toLowerCase() || '').includes(
          queryValue.trim().toLowerCase()
        );

      const matchesTab =
        selectedTab === 0 || widget.installationMode === tabs[selectedTab]?.id;

      return matchesSearch && matchesTab;
    });
  }, [enabledWidgets, queryValue, selectedTab]);

  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
  }, []);

  const itemsArray = useCallback(
    (number: number) =>
      Array.from({ length: number }, (_, index) => ({
        type: `dummy${index + 1}`,
      })),
    []
  );

  useEffect(() => {
    setIsWidgetsListUpdating(loading);
  }, [loading]);

  return (
    <div className='myWidgets'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <BlockStack gap='200'>
            <InlineStack align='space-between' blockAlign='center'>
              <InlineStack align='center' blockAlign='center' gap='100'>
                <Text as='h2' variant='headingSm'>
                  {isWidgetControlCenterPage
                    ? i18n.translate('Title', {
                        theme: i18n.translate(widgetThemeType),
                      })
                    : i18n.translate('NinjaCartTitle', {
                        theme: i18n.translate(widgetThemeType),
                      })}
                </Text>
                {isEmpty && (
                  <Box width='20px'>
                    <SkeletonBodyText lines={1} />
                  </Box>
                )}
                {isEnabled && !!enabledWidgets.length && (
                  <Text as='span' tone='subdued'>
                    ({enabledWidgets.length})
                  </Text>
                )}
              </InlineStack>
              {isEmpty && (
                <Box width='90px'>
                  <SkeletonBodyText lines={1} />
                </Box>
              )}
              {isEnabled && (
                <Button
                  variant='plain'
                  onClick={() => setConfigureStyleModal('GENERAL')}
                >
                  {i18n.translate('ConfigureStyle')}
                </Button>
              )}
            </InlineStack>
            {(isEnabled || isEmpty) && (
              <Text as='p' tone='subdued'>
                {i18n.translate('Description', {
                  theme: i18n.translate(widgetThemeType),
                  name: isEmpty ? (
                    <span style={{ display: 'inline-block', width: '120px' }}>
                      <SkeletonBodyText lines={1} />
                    </span>
                  ) : (
                    themeName
                  ),
                })}
              </Text>
            )}
          </BlockStack>
          {isEnabled || isEmpty ? (
            enabledWidgets.length || isEmpty ? (
              <>
                <ResourceList
                  resourceName={{
                    singular: i18n.translate('Singular'),
                    plural: i18n.translate('Plural'),
                  }}
                  loading={isLoading || isEmpty}
                  filterControl={
                    <BlockStack gap='200'>
                      <TextField
                        label=''
                        labelHidden
                        loading={isLoading}
                        disabled={isEmpty}
                        autoComplete='false'
                        placeholder={i18n.translate('SearchWidget')}
                        value={queryValue}
                        onChange={(value) => setQueryValue(value)}
                        clearButton
                        onClearButtonClick={() => setQueryValue('')}
                      />
                      <Tabs
                        tabs={tabs}
                        selected={selectedTab}
                        onSelect={handleTabChange}
                        disabled={isEmpty}
                      />
                    </BlockStack>
                  }
                  items={(isEmpty
                    ? itemsArray(6)
                    : filteredWidgets.slice(0, 9)
                  ).map(
                    (
                      widget:
                        | WidgetInstallationDto
                        | NinjaCartWidgetInstallationDto
                        | {
                            type: string;
                          }
                    ) => ({
                      ...widget,
                      type: widget.type ?? `dummy-${Math.random()}`, // Ensure `type` is always a string
                    })
                  )}
                  renderItem={(widget) => {
                    return (
                      <WidgetCard
                        key={widget.type}
                        isEnabled={isEnabled}
                        widget={{
                          ...widget,
                          type: widget.type as WidgetTypeDto,
                        }}
                        isSupportRequested={
                          supportRequest?.status ===
                            InstallationSupportRequestGeneralStatusDtoEnum.SUBMITTED &&
                          Array.isArray(supportRequest.selectedWidgets) &&
                          supportRequest.selectedWidgets.includes(
                            widget.type as WidgetTypeDto
                          )
                        }
                        toggleWidget={toggleWidget}
                        setConfigureStyleModal={setConfigureStyleModal}
                      />
                    );
                  }}
                />
                {enabledWidgets.length > 9 && (
                  <Button fullWidth onClick={handleExploreAllWidgets}>
                    {i18n.translate('ExploreAllWidgets')}
                  </Button>
                )}
              </>
            ) : (
              <Card
                background='bg-surface-tertiary'
                roundedAbove='xs'
                padding='600'
              >
                <div className='emptyBlock'>
                  <EmptyState
                    image=''
                    fullWidth
                    heading={i18n.translate(
                      isWidgetControlCenterPage
                        ? 'NoWidgetsEnabled'
                        : 'NoCartWidgetsEnabled'
                    )}
                    action={
                      isWidgetControlCenterPage
                        ? {
                            content: i18n.translate('EnableApp'),
                            onAction: handleEnableAppModal,
                          }
                        : undefined
                    }
                    secondaryAction={{
                      content: i18n.translate(
                        isWidgetControlCenterPage
                          ? 'ExploreWidgets'
                          : 'ExploreCartWidgets'
                      ),
                      onAction: handleExploreAllWidgets,
                    }}
                  >
                    <Text variant='bodyMd' as='p' tone='subdued'>
                      {i18n.translate(
                        isWidgetControlCenterPage
                          ? 'WarningBannerContent'
                          : 'CartWarningBannerContent'
                      )}
                    </Text>
                  </EmptyState>
                </div>
              </Card>
            )
          ) : (
            <Card
              background='bg-surface-tertiary'
              roundedAbove='xs'
              padding='600'
            >
              <div className='emptyBlock'>
                <EmptyState
                  image=''
                  fullWidth
                  heading={i18n.translate(
                    isWidgetControlCenterPage
                      ? 'NoWidgetsEnabled'
                      : 'NoCartWidgetsEnabled'
                  )}
                  action={
                    isWidgetControlCenterPage
                      ? {
                          content: i18n.translate('EnableApp'),
                          onAction: handleEnableAppModal,
                        }
                      : undefined
                  }
                  secondaryAction={{
                    content: i18n.translate(
                      isWidgetControlCenterPage
                        ? 'ExploreWidgets'
                        : 'ExploreCartWidgets'
                    ),
                    onAction: handleExploreAllWidgets,
                  }}
                >
                  <Text variant='bodyMd' as='p' tone='subdued'>
                    {i18n.translate(`EmptyStateContent_${widgetThemeType}`)}
                  </Text>
                </EmptyState>
              </div>
            </Card>
          )}
        </BlockStack>
      </Card>
    </div>
  );
};
