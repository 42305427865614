import React from 'react';
import { useI18n } from '@shopify/react-i18n';
import { OfferRuleTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { OfferRuleTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { BlockStack, Box, RadioButton } from '@shopify/polaris';

export type CartRulesRadiosProps = {
  selectedOption?: OfferRuleTypeDto;
  handleSetSelectedOption: (
    checked: boolean,
    value: OfferRuleTypeDtoEnum
  ) => void;
  specialOfferType?: OfferTypeSpecialCases;
};

const ruleOptions = [
  {
    id: OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY,
    ruleKey: 'ContentRule',
  },
  {
    id: OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT,
    ruleKey: 'ValueRule',
  },
];

export const CartRulesRadios: React.FC<CartRulesRadiosProps> = ({
  handleSetSelectedOption,
  selectedOption,
  specialOfferType,
}) => {
  const [i18n] = useI18n();

  if (
    specialOfferType === OfferTypeSpecialCases.BogoMixMatch ||
    specialOfferType === OfferTypeSpecialCases.BogoStrictMatch ||
    specialOfferType === OfferTypeSpecialCases.SpendXGetX
  ) {
    return null;
  }

  return (
    <Box width='130px'>
      <BlockStack gap='100'>
        {ruleOptions.map(({ id, ruleKey }) => (
          <RadioButton
            key={id}
            label={i18n.translate(ruleKey)}
            checked={selectedOption === id}
            id={id}
            onChange={handleSetSelectedOption}
          />
        ))}
      </BlockStack>
    </Box>
  );
};
