import React, { useCallback } from 'react';
import { Card, Icon, InlineStack, Text } from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { ShopifyPageTemplateDto } from 'core/api/adminSettings/adminSettingsApi';
import { CheckCircle } from 'core/components/SetupStepBox/assets/icons';
import { Loader } from 'core/components';
import { updateFirstLetter } from 'core/utils/updateFirstLetter';

type NavTemplateCardProps = {
  template: ShopifyPageTemplateDto;
  pageTemplatesDataIsFetching: boolean;
};

export const NavTemplateCard: React.FC<NavTemplateCardProps> = ({
  template,
  pageTemplatesDataIsFetching,
}) => {
  const { isEnabled, installationDeepLink, name } = template;

  const handleTemplateOpen = useCallback(() => {
    if (!pageTemplatesDataIsFetching) {
      window.open(installationDeepLink, '_blank');
    }
  }, [installationDeepLink, pageTemplatesDataIsFetching]);

  return (
    <div
      onClick={pageTemplatesDataIsFetching ? undefined : handleTemplateOpen}
      style={{
        cursor: pageTemplatesDataIsFetching ? 'not-allowed' : 'pointer',
      }}
    >
      <Card>
        <InlineStack blockAlign='center' align='space-between'>
          <InlineStack align='center' gap='200'>
            <div style={{ maxHeight: 20 }}>
              {pageTemplatesDataIsFetching ? (
                <Loader fullWidth size='small' />
              ) : (
                <CheckCircle checked={!!isEnabled} />
              )}
            </div>
            <Text as='p'>{updateFirstLetter(name || '')}</Text>
          </InlineStack>
          <div>
            <Icon source={ChevronRightIcon} />
          </div>
        </InlineStack>
      </Card>
    </div>
  );
};
