export const transformToKebabOrSnakeCase = (
  type: string,
  toFormat: 'snake_case' | 'kebab-case'
): string => {
  if (toFormat === 'kebab-case') {
    return type.toLowerCase().replace(/_/g, '-');
  } else if (toFormat === 'snake_case') {
    return type.toUpperCase().replace(/-/g, '_');
  }
  return type;
};
