import React, { useEffect, useMemo } from 'react';
import {
  BlockStack,
  Card,
  ChoiceList,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { Element } from 'react-scroll';
import { AnnouncementBarStickyScrollDto } from 'core/api/adminSettings/adminSettingsApi';
import { useAppSelector } from 'core/hooks';

type BehaviorSettingProps = {
  behavior: AnnouncementBarStickyScrollDto;
  onFormValidityChange?: (sectionName: string, isValid: boolean) => void;
  setBehavior: (value: AnnouncementBarStickyScrollDto) => void;
};
export const BehaviorSetting: React.FC<BehaviorSettingProps> = ({
  behavior,
  onFormValidityChange,
  setBehavior,
}) => {
  const [i18n] = useI18n();
  const { showValidation } = useAppSelector((store) => store.promotions);
  const maxZIndexNumber = 2147483647;
  const stickyOptions = useMemo(
    () => [
      {
        label: i18n.translate('Yes'),
        value: '1',
      },
      {
        label: i18n.translate('No'),
        value: '',
      },
    ],
    []
  );

  const isZIndexValid = useMemo(
    () => !!behavior.zIndex || behavior.zIndex === 0,
    [behavior.zIndex]
  );

  useEffect(() => {
    onFormValidityChange?.('BehaviorSetting', isZIndexValid);
  }, [isZIndexValid]);

  return (
    <Element className='BehaviorSetting' name='BehaviorSetting'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Behavior')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <ChoiceList
            title={i18n.translate('StickyOnScroll')}
            choices={stickyOptions}
            selected={[behavior.isSticky ? '1' : '']}
            onChange={(value) =>
              setBehavior({ ...behavior, isSticky: !!value[0] })
            }
          />
          <TextField
            autoComplete=''
            label={i18n.translate('ZIndex')}
            helpText={i18n.translate('ValuesBetween')}
            type='number'
            error={
              showValidation && !isZIndexValid
                ? i18n.translate('ValidationError')
                : ''
            }
            value={behavior.zIndex?.toString()}
            onChange={(value) => setBehavior({ ...behavior, zIndex: +value })}
            onBlur={() => {
              (behavior.zIndex || 0) > maxZIndexNumber
                ? setBehavior({ ...behavior, zIndex: maxZIndexNumber })
                : (behavior.zIndex || 0) < 0
                ? setBehavior({ ...behavior, zIndex: 0 })
                : null;
            }}
          />
        </BlockStack>
      </Card>
    </Element>
  );
};
