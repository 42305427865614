import React, { useCallback, useEffect, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  Modal,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
  Text,
} from '@shopify/polaris';
import { DiscountIcon, StoreIcon } from '@shopify/polaris-icons';
import { WidgetTypeDto } from 'core/api/adminSettings/adminSettingsApi';
import { useNavigate } from 'react-router-dom';
import { NavPaths } from 'core/enums/NavPathsEnum';
import useFetchPresetList from 'features/settings/hooks/useFetchPresetList/useFetchPresetList';
import { useAppSelector } from 'core/hooks';
import { PresetBoundariesTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import resolveEnvVar from 'env-var-resolver';
import ColorBlock from 'features/settings/components/GeneralSettings/Style/components/ColorBlock/ColorBlock';
import moment from 'moment';

type ConfigureWidgetsStyleModalProps = {
  type: WidgetTypeDto | 'GENERAL';
  redirectManageStylePresets: () => void;
  onClose: () => void;
};

export const ConfigureWidgetsStyleModal: React.FC<
  ConfigureWidgetsStyleModalProps
> = (props) => {
  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const [i18n] = useI18n();

  const { type, onClose, redirectManageStylePresets } = props;

  const navigate = useNavigate();

  const {
    stylePresetsLibraryIsFetching,
    getCurrentShopLevelDefaultStylePreview,
    getCurrentPromotionLevelDefaultStylePreview,
  } = useFetchPresetList();

  const { defaultShopLevelStylePreset, defaultPromotionLevelStylePreset } =
    useAppSelector((state) => state.widgets);

  const cards = useMemo(
    () => [
      {
        type: PresetBoundariesTypeDtoEnum.PROMOTION_LEVEL,
        title: i18n.translate('CardTitlePL'),
        description: i18n.translate('CardDescriptionPL'),
        iconSrc: DiscountIcon,
        link: i18n.translate('ManageStylePresets'),
        presetData: defaultPromotionLevelStylePreset,
      },
      {
        type: PresetBoundariesTypeDtoEnum.SHOP_LEVEL,
        title: i18n.translate('CardTitleSL'),
        description: i18n.translate('CardDescriptionSL'),
        iconSrc: StoreIcon,
        presetData: defaultShopLevelStylePreset,
      },
    ],
    [i18n, defaultShopLevelStylePreset, defaultPromotionLevelStylePreset]
  );

  const handleManageStylePresets = useCallback(() => {
    redirectManageStylePresets();
    onClose();
  }, []);

  const navigateToEditor = useCallback(
    (id?: string) => {
      navigate(`/${NavPaths.ConfigureWidgets}/${id}`);
    },
    [navigate]
  );

  useEffect(() => {
    getCurrentShopLevelDefaultStylePreview({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    });
    getCurrentPromotionLevelDefaultStylePreview({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    });
  }, []);

  return (
    <Modal
      open={!!type}
      onClose={onClose}
      title={i18n.translate('Title')}
      secondaryActions={[
        {
          content: i18n.translate('Close'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='300'>
          {cards.map((card, idx) => (
            <Card key={idx} background='bg-surface-secondary'>
              <InlineStack gap='300' wrap={false}>
                <div>
                  <Icon source={card.iconSrc} tone='base' />
                </div>
                <BlockStack gap='400'>
                  <BlockStack gap='100'>
                    <Text as='h2' variant='headingSm'>
                      {card.title}
                    </Text>
                    <Text as='p' tone='subdued'>
                      {card.description}
                    </Text>
                  </BlockStack>
                  <Card roundedAbove='sm'>
                    <InlineStack
                      align='space-between'
                      blockAlign='center'
                      wrap={false}
                    >
                      {stylePresetsLibraryIsFetching ? (
                        <>
                          <InlineStack
                            gap='300'
                            blockAlign='center'
                            wrap={false}
                          >
                            <SkeletonThumbnail size='small' />
                            <BlockStack gap='400'>
                              <Box width='10rem'>
                                <SkeletonBodyText lines={1} />
                              </Box>
                              <Box width='15rem'>
                                <SkeletonBodyText lines={1} />
                              </Box>
                            </BlockStack>
                          </InlineStack>
                          <Box width='82px'>
                            <SkeletonDisplayText size='small' />
                          </Box>
                        </>
                      ) : (
                        <InlineStack gap='300' blockAlign='center' wrap={false}>
                          <ColorBlock colors={card.presetData?.colors || []} />
                          <BlockStack>
                            <InlineStack
                              gap='200'
                              align='start'
                              blockAlign='center'
                            >
                              <Text fontWeight='regular' as='p'>
                                {card.presetData?.name}
                              </Text>
                              {card.presetData?.isDefault && (
                                <Badge
                                  tone={
                                    card.type ==
                                    PresetBoundariesTypeDtoEnum.SHOP_LEVEL
                                      ? 'success'
                                      : undefined
                                  }
                                >
                                  {i18n.translate(card.type)}
                                </Badge>
                              )}
                            </InlineStack>

                            <Text as='p' tone='subdued'>
                              {`${
                                card.presetData?.type &&
                                i18n.translate(card.presetData?.type)
                              } ${
                                card.presetData?.lastModifiedAt
                                  ? `• ${i18n.translate('Edited')} ${moment(
                                      card.presetData?.lastModifiedAt
                                    ).fromNow()}`
                                  : ''
                              } ${
                                card.presetData?.affectedPromotionsCount
                                  ? `• ${i18n.translate('OtherPromotions', {
                                      count:
                                        card.presetData
                                          ?.affectedPromotionsCount,
                                    })}`
                                  : ''
                              }`}
                            </Text>
                          </BlockStack>
                        </InlineStack>
                      )}
                      {!stylePresetsLibraryIsFetching && (
                        <Button
                          variant='primary'
                          size='medium'
                          onClick={() => navigateToEditor(card.presetData?.id)}
                        >
                          {i18n.translate('Configure')}
                        </Button>
                      )}
                    </InlineStack>
                  </Card>

                  {card.link && (
                    <div>
                      <Button
                        variant='plain'
                        onClick={handleManageStylePresets}
                      >
                        {card.link}
                      </Button>
                    </div>
                  )}
                </BlockStack>
              </InlineStack>
            </Card>
          ))}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
