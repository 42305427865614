import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { PositionDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  NinjaCartProductBadgeWidgetSettingsDto,
  WidgetSettingsDtoNinjaCartProductBadgeWidgetSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import {
  OffsetTypeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  OffsetSetting,
  PositionSetting,
  StackingSetting,
  StyleSetting,
} from '../../components';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';

type NinjaCartProductBadgeSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NinjaCartProductBadgeSettings = forwardRef<
  WidgetSavebarRef,
  NinjaCartProductBadgeSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const {
    ninjaCartProductBadgeData,
    ninjaCartProductBadgeDataIsFetching,
    saveNinjaCartProductBadgeIsLoading,
    saveNinjaCartProductBadge,
    // refetchNinjaCartProductBadgeData
  } = useNinjaCartWidget(WidgetTypeDtoEnum.NINJA_CART_PRODUCT_BADGE);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoNinjaCartProductBadgeWidgetSettingsDto>({});
  const [productBadgeState, setProductBadgeState] =
    useState<WidgetSettingsDtoNinjaCartProductBadgeWidgetSettingsDto>({});

  const updateProductBadgeState = useCallback(
    (
      field: keyof NinjaCartProductBadgeWidgetSettingsDto,
      data: NinjaCartProductBadgeWidgetSettingsDto[keyof NinjaCartProductBadgeWidgetSettingsDto]
    ) =>
      setProductBadgeState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setProductBadgeState]
  );

  const handleSave = useCallback(
    () =>
      saveNinjaCartProductBadge(productBadgeState.settings || null, () =>
        setSavedData(productBadgeState)
      ),
    [productBadgeState]
  );

  useEffect(() => {
    if (!isEmpty(ninjaCartProductBadgeData)) {
      setProductBadgeState(ninjaCartProductBadgeData);
      setSavedData(ninjaCartProductBadgeData);
    }
  }, [ninjaCartProductBadgeData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(productBadgeState)) {
      dispatch(setIsUnsavedChanges(!isEqual(productBadgeState, savedData)));
    }
  }, [savedData, productBadgeState]);

  useEffect(() => {
    setIsLoading(ninjaCartProductBadgeDataIsFetching);
  }, [ninjaCartProductBadgeDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveNinjaCartProductBadgeIsLoading);
  }, [saveNinjaCartProductBadgeIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setProductBadgeState(savedData),
  }));

  return !ninjaCartProductBadgeDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!ninjaCartProductBadgeData?.isShopLevelWidget} />

      <PositionSetting
        position={productBadgeState.settings?.position as PositionDtoEnum}
        positionSides={[
          PositionDtoEnum.BOTTOM_LEFT,
          PositionDtoEnum.BOTTOM_RIGHT,
          PositionDtoEnum.TOP_LEFT,
          PositionDtoEnum.TOP_RIGHT,
        ]}
        title={i18n.translate('Position')}
        subtitle={i18n.translate('PositionSubtitle')}
        setPosition={(value) => updateProductBadgeState('position', value)}
      />
      <OffsetSetting
        offset={productBadgeState.settings?.offset || {}}
        offsetSizes={[
          OffsetTypeDtoEnum.SMALL,
          OffsetTypeDtoEnum.MEDIUM,
          OffsetTypeDtoEnum.LARGE,
          OffsetTypeDtoEnum.CUSTOM,
        ]}
        setOffset={(data) => updateProductBadgeState('offset', data)}
        subtitle={i18n.translate('OffsetSubtitle')}
      />
      <StackingSetting
        stacking={productBadgeState.settings?.stacking || {}}
        setStacking={(data) => updateProductBadgeState('stacking', data)}
        subtitle={i18n.translate('StackingSubtitle')}
        maxCustomSpacing={20}
      />
    </>
  ) : null;
});

NinjaCartProductBadgeSettings.displayName = 'NinjaCartProductBadgeSettings';
