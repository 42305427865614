import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { Profile } from './components/Profile/Profile';
import { AccountNotifications } from './components/AccountNotifications/AccountNotifications';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { Timezone } from './components/Timezone/Timezone';
import { AccountSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import {
  setIsUnsavedChanges,
  setIsValidationError,
} from 'core/store/settingsSlice';
import { isEmpty, isEqual } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import { SettingsModalSubPathsEnums } from 'pages/enums/PagesEnums';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';
import { setShowValidation } from 'core/store/promotionsSlice';

export const Account = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const isDebugOrLocal = useIsDebugOrLocal();
  const pageBackAction = useSettingsPageBackAction();
  const dispatch = useAppDispatch();

  const { timezoneData, timezoneIsFetching } = useConfigureSettings(
    SettingsFetchTypeEnum.TIMEZONE
  );
  const { accountData, accountDataIsFetching, accountDataChange } =
    useConfigureSettings(SettingsFetchTypeEnum.ACCOUNT);

  const [savedData, setSavedData] = useState<AccountSettingsDto>({});
  const [accountDataState, setAccountDataState] = useState<AccountSettingsDto>(
    {}
  );

  const hasValidationError = useMemo(
    () =>
      !accountDataState.primaryUser?.firstName ||
      !accountDataState.primaryUser?.lastName,
    [accountDataState.primaryUser]
  );

  const handleAccountLocaleState = useCallback(
    (
      field: keyof AccountSettingsDto,
      data: AccountSettingsDto[keyof AccountSettingsDto]
    ) => {
      setAccountDataState((prevState) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [accountDataState]
  );

  const onLevelClick = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    setSearchParams((params) => {
      params.set('subPath', SettingsModalSubPathsEnums.ActivityLog);
      return params;
    });
  }, [setSearchParams]);

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(accountDataState);
    return accountDataChange(accountDataState, onSuccess);
  }, [accountDataState]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => setAccountDataState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, accountDataState)));
  }, [accountDataState, savedData]);

  useEffect(() => {
    if (!isEmpty(accountData)) {
      setAccountDataState(accountData);
      setSavedData(accountData);
    }
  }, [accountData]);

  useEffect(() => {
    dispatch(setIsValidationError(hasValidationError));
  }, [hasValidationError]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
      dispatch(setIsValidationError(false));
      dispatch(setShowValidation(false));
    };
  }, []);

  return (
    <>
      {!timezoneIsFetching && !accountDataIsFetching && accountDataState ? (
        <Page
          title={i18n.translate('Account')}
          backAction={pageBackAction}
          secondaryActions={[
            { content: i18n.translate('ActivityLog'), onAction: onLevelClick },
          ]}
        >
          <BlockStack gap='400'>
            <Profile
              data={accountDataState.primaryUser || {}}
              handleAccountLocaleState={(data) =>
                handleAccountLocaleState('primaryUser', data)
              }
            />
            <AccountNotifications
              data={accountDataState.notifications || {}}
              handleAccountLocaleState={(data) =>
                handleAccountLocaleState('notifications', data)
              }
            />
            <Timezone
              timezoneData={timezoneData || []}
              handleAccountLocaleState={(data) =>
                handleAccountLocaleState('timezone', data)
              }
              data={accountDataState.timezone || ''}
            />
            <PrivacyPolicy />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
Account.displayName = 'Account';
