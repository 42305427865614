import React, { useCallback } from 'react';
import {
  Icon,
  SimplifiedTextEntries,
  TextStyle,
  WidgetOptionHeader,
} from '../../../../../../../../../components';
import { NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { ResourceGroupKeyDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type Preset_NDC_TermsAndConditionsProps = {
  termsAndConditions: NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead;
  defaultTermsAndConditions: NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead;
  shopDefaultTermsAndConditions: NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead;
  defaultLanguage: string;
  setTermsAndConditions: (
    data: NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead
  ) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const Preset_NDC_TermsAndConditions: React.FC<
  Preset_NDC_TermsAndConditionsProps
> = ({
  termsAndConditions,
  defaultTermsAndConditions,
  shopDefaultTermsAndConditions,
  defaultLanguage,
  setTermsAndConditions,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();

  const handleUpdateOptions = useCallback(
    (
      field: keyof NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead,
      data: NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead[keyof NinjaCartDefaultStateFooterTermsAndConditionsPresetDtoRead]
    ) => setTermsAndConditions({ ...termsAndConditions, [field]: data }),
    [termsAndConditions]
  );

  return (
    <>
      <WidgetOptionHeader
        onDiscardChanges={() =>
          setTermsAndConditions(defaultTermsAndConditions)
        }
        onResetToSystem={() =>
          setTermsAndConditions(shopDefaultTermsAndConditions)
        }
        name={WidgetListOptionsDtoEnum.TERMS_AND_CONDITIONS}
        onShowHide={
          termsAndConditions.canBeHidden
            ? () =>
                handleUpdateOptions('isHidden', !termsAndConditions.isHidden)
            : undefined
        }
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <SimplifiedTextEntries
          message={termsAndConditions.text?.message || {}}
          defaultLanguage={defaultLanguage}
          title={i18n.translate('Text')}
          setMessage={(data) =>
            handleUpdateOptions('text', {
              ...termsAndConditions.text,
              message: data,
            })
          }
          onSystemReset={() =>
            handleUpdateOptions('text', shopDefaultTermsAndConditions.text)
          }
        />
        <TextStyle
          textStyle={termsAndConditions.text?.style || {}}
          setTextStyle={(data) =>
            handleUpdateOptions('text', {
              ...termsAndConditions.text,
              style: data,
            })
          }
          hideHeader
        />
      </div>
      <div className='RightSideSection'>
        <Icon
          groupKeys={[ResourceGroupKeyDtoEnum.OFFER]}
          icon={termsAndConditions.icon || {}}
          onlyColor
          title={i18n.translate('CheckboxIcon')}
          setIcon={(data) => handleUpdateOptions('icon', data)}
          onSystemReset={() =>
            handleUpdateOptions('icon', shopDefaultTermsAndConditions.icon)
          }
        />
      </div>
    </>
  );
};

export default Preset_NDC_TermsAndConditions;
