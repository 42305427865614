import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BlockStack,
  Button,
  Card,
  Checkbox,
  Icon,
  InlineStack,
  List,
  Page,
  Text,
} from '@shopify/polaris';
import { ProductIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import './Products.scss';
import { AppliesTo } from './components/AppliesTo/AppliesTo';
import {
  OfferProductLimitDto,
  OfferPrerequisiteEntitledAppliesToDto,
  OfferProductsDto,
  OfferRuleAppliesToDto,
  OfferTargetTypeDto,
  ShopifyObjectDto,
  OfferCartRuleDto,
  PrerequisiteEntitledExclusionsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferCartContentRequirementProductMatchTypeDtoEnum,
  OfferRuleTypeDtoEnum,
  OfferTargetTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useAppDispatch, useIsDebugOrLocal, useLazyEffect } from 'core/hooks';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import { ResourceSelectionProps } from 'core/components/SearchFieldWithGrayBox';
import { setCurrentConfigPage } from 'core/store/offersWizardSlice';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { headerTitle } from 'core/components/SearchFieldWithGrayBoxOfferWizard/utils/utils';
import {
  boldMessageParts,
  generateMessage,
  generateRules,
  SectionTypeEnum,
} from '../CartRules/utils/utils';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { isEqual, omit } from 'lodash';
import { GrayBoxExclusions } from 'core/components/GrayBox/components/GrayBoxExclusions/GrayBoxExclusions';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';

const entitledProductsInitialValues: OfferProductsDto = {
  appliesTo: {
    type: OfferTargetTypeDtoEnum.ALL,
    collections: [],
    products: [],
    variants: [],
    productMatchType: {
      enabled: false,
      value: OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT,
    },
  },
  exclusions: {
    collections: {
      enabled: false,
      value: [],
    },
  },
  limit: {
    perOrder: {
      enabled: false,
      value: 1,
    },
  },
};

export const prerequisiteProductsInitialValues: OfferCartRuleDto = {
  applyAfterDiscounts: true,
  id: 'prerequisiteProductsInitialValuesId',
  type: OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT,
  appliesTo: {
    type: OfferTargetTypeDtoEnum.ALL,
    collections: [],
    products: [],
    variants: [],
    productMatchType: {
      enabled: false,
      value: OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT,
    },
  },
  exclusions: {
    collections: {
      enabled: false,
      value: [],
    },
  },
};

const filterFields = (obj: any) => omit(obj, ['value', 'id']); // Exclude "value" and "id" fields

type ProductsProps = {
  products?: OfferProductsDto | OfferCartRuleDto;
  type?: 'Entitled' | 'Prerequisite';
  specialOfferType?: OfferTypeSpecialCases;
  configureComponent: boolean;
  savedData?: OfferProductsDto;
  applySameSetAsTargetProducts?: boolean | null;
  onAppliesToUpdate(data: OfferPrerequisiteEntitledAppliesToDto): void;
  onLimitUpdate(data: OfferProductLimitDto): void;
  onFormValidityChange(formIsValid: boolean): void;
  onPrerequisiteProductsCheckboxUpdate?: (value: boolean) => void;
  setConfigureComponent: (data: boolean) => void;
  onProductsExclusionsUpdate: (data: PrerequisiteEntitledExclusionsDto) => void;
};

export const Products: React.FC<ProductsProps> = (props) => {
  const {
    type,
    products,
    specialOfferType,
    savedData,
    configureComponent,
    applySameSetAsTargetProducts,
    onProductsExclusionsUpdate,
    onAppliesToUpdate,
    onFormValidityChange,
    setConfigureComponent,
    onLimitUpdate,
    onPrerequisiteProductsCheckboxUpdate,
  } = props;
  const [i18n] = useI18n();

  const dispatch = useAppDispatch();

  const isDebugOrLocal = useIsDebugOrLocal();

  const { getLookupShopifyDetailedObjectsDetails } = useConfigureOffers();
  const { exclusionsData, exclusionsIsFetching } = useConfigurePromotions(
    PromotionFetchTypeEnum.EXCLUSIONS
  );

  const [selectedProductsApplication, setSelectedProductsApplication] =
    useState<OfferTargetTypeDto>(
      products?.appliesTo?.type || OfferTargetTypeDtoEnum.ALL
    );
  const [currentSelections, setCurrentSelections] = useState<string>('');
  const [isAppliesToValid, setIsAppliesToValid] = useState<boolean>(true);
  const [isExclusionsValid, setIsExclusionsValid] = useState<boolean>(true);
  const [bullets, setBullets] = useState<JSX.Element[]>([]);

  const oneRuleIsRequired = useMemo(
    () =>
      specialOfferType &&
      [
        OfferTypeSpecialCases.CrossSell,
        OfferTypeSpecialCases.BundleOrderDiscount,
      ].includes(specialOfferType),
    [specialOfferType]
  );

  const initialState = useMemo(
    () =>
      type === 'Prerequisite'
        ? prerequisiteProductsInitialValues
        : entitledProductsInitialValues,
    [prerequisiteProductsInitialValues, entitledProductsInitialValues, type]
  );

  const isEmpty = useMemo(() => {
    if (!products || Object.keys(products).length === 0) {
      return true; // Handle null, undefined, or empty objects
    }

    const filteredInitialState = filterFields(initialState);
    const filteredProducts = filterFields(products);

    return isEqual(filteredInitialState, filteredProducts);
  }, [products, initialState]);

  const [checked, setChecked] = useState<boolean>(
    !!applySameSetAsTargetProducts
  );

  const resourceType = useMemo(() => {
    switch (products?.appliesTo?.type) {
      case OfferTargetTypeDtoEnum.COLLECTIONS:
        return ShopifyObjectTypeDtoEnum.COLLECTION;
      case OfferTargetTypeDtoEnum.PRODUCTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT;
      case OfferTargetTypeDtoEnum.VARIANTS:
        return ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT;
      default:
        return null;
    }
  }, [products?.appliesTo?.type]);

  const productCurrentList = useMemo(
    () =>
      products?.appliesTo?.type !== OfferTargetTypeDtoEnum.ALL
        ? ((products?.appliesTo?.[
            products?.appliesTo?.type?.toLowerCase() as keyof typeof products.appliesTo
          ] || []) as ShopifyObjectDto[])
        : null,
    [products?.appliesTo]
  );

  const appliesToList = useMemo(
    () =>
      selectedProductsApplication === savedData?.appliesTo?.type
        ? savedData?.appliesTo?.[
            selectedProductsApplication?.toLocaleLowerCase() as keyof typeof selectedProductsApplication
          ]
        : products?.appliesTo?.[
            selectedProductsApplication?.toLocaleLowerCase() as keyof typeof selectedProductsApplication
          ],
    [selectedProductsApplication, products?.appliesTo, savedData?.appliesTo]
  );

  const renderBullets = useMemo(
    () => !configureComponent && !isEmpty && !checked,
    [configureComponent, isEmpty, checked]
  );

  useLazyEffect(() => {
    const data: OfferPrerequisiteEntitledAppliesToDto =
      selectedProductsApplication === savedData?.appliesTo?.type
        ? { ...savedData?.appliesTo }
        : {
            ...products?.appliesTo,
            type: selectedProductsApplication,
          };

    onAppliesToUpdate(data);
  }, [selectedProductsApplication]);

  const handleCheck = useCallback(
    (newChecked: boolean) => setChecked(newChecked),
    []
  );

  const setSelectedResources = useCallback(
    (data: ResourceSelectionProps[]) => {
      const requestData: OfferRuleAppliesToDto = {
        ...products?.appliesTo,
        type: selectedProductsApplication,
        [selectedProductsApplication?.toLowerCase() as keyof typeof selectedProductsApplication]:
          formateList(data, selectedProductsApplication),
      };
      onAppliesToUpdate(requestData);
    },
    [
      selectedProductsApplication,
      onAppliesToUpdate,
      formateList,
      products?.appliesTo,
      type,
    ]
  );

  const handleConfiguration = useCallback(
    async (value: boolean) => {
      !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
      setConfigureComponent(value);
    },
    [isDebugOrLocal]
  );

  useEffect(() => {
    onFormValidityChange(isAppliesToValid && isExclusionsValid);
  }, [isAppliesToValid, isExclusionsValid]);

  useEffect(() => {
    if (configureComponent) {
      window.scrollTo(0, 0);
      dispatch(
        setCurrentConfigPage(type === 'Prerequisite' ? 'cartRules' : 'products')
      );
    }

    return () => {
      dispatch(setCurrentConfigPage(null));
    };
  }, [configureComponent, type]);

  useEffect(() => {
    if (!configureComponent && resourceType && productCurrentList?.length) {
      getLookupShopifyDetailedObjectsDetails({
        type: resourceType,
        objects: productCurrentList,
      }).then((res) => {
        setCurrentSelections(
          headerTitle(res as ResourceSelectionProps[], resourceType, i18n)
        );
      });
    }
  }, [resourceType, productCurrentList]);

  useEffect(() => {
    type === 'Prerequisite' && onPrerequisiteProductsCheckboxUpdate?.(checked);
  }, [checked]);

  useEffect(() => {
    const fetchBullets = async () => {
      const result = await generateRules(
        products,
        i18n,
        getLookupShopifyDetailedObjectsDetails,
        exclusionsData?.products
      );
      setBullets(result);
    };

    renderBullets && fetchBullets();
  }, [
    exclusionsData?.products,
    products,
    renderBullets,
    i18n,
    getLookupShopifyDetailedObjectsDetails,
  ]);

  // for tags and vendors
  useEffect(() => {
    if (
      products?.appliesTo?.type &&
      productCurrentList &&
      [OfferTargetTypeDtoEnum.TAGS, OfferTargetTypeDtoEnum.VENDORS].includes(
        products?.appliesTo?.type as OfferTargetTypeDtoEnum
      )
    ) {
      setCurrentSelections(
        headerTitle(
          productCurrentList as string[],
          products?.appliesTo?.type,
          i18n
        )
      );
    }
  }, [products?.appliesTo?.type, productCurrentList]);

  return (
    <>
      {configureComponent ? (
        <Page
          backAction={{
            id: 'OfferProductsPageBackNav',
            onAction: () => handleConfiguration(false),
          }}
          title={i18n.translate('Products.ConfigureProducts')}
        >
          <BlockStack gap='600'>
            <AppliesTo
              selectList={appliesToList || []}
              selectedProductsApplication={selectedProductsApplication}
              setSelectedProductsApplication={setSelectedProductsApplication}
              onResourceSelection={setSelectedResources}
              onFormValidityChange={(formIsInvalid) =>
                setIsAppliesToValid(!formIsInvalid)
              }
              variant={specialOfferType}
              data={specialOfferType && products}
              onAppliesToUpdate={onAppliesToUpdate}
              onLimitUpdate={onLimitUpdate}
            />

            <GrayBoxExclusions
              data={products || {}}
              productsExclusionsUpdate={onProductsExclusionsUpdate}
              onFormValidityChange={(formIsInvalid) =>
                setIsExclusionsValid(!formIsInvalid)
              }
            />
          </BlockStack>
        </Page>
      ) : (
        <div className='Products'>
          <Card roundedAbove='sm' padding='400'>
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate(`Products.${type ? type : ''}Title`)}
                </Text>
                <Text as='span' tone='subdued'>
                  {i18n.translate(`Products.${type ? type : ''}Subtitle`)}
                </Text>
              </BlockStack>
              {type === 'Prerequisite' && (
                <Checkbox
                  label={i18n.translate('Products.UseEntitledProducts')}
                  checked={checked}
                  onChange={handleCheck}
                />
              )}
              {!isEmpty
                ? !checked && (
                    <Card roundedAbove='sm' padding='400'>
                      <InlineStack
                        align='space-between'
                        wrap={false}
                        blockAlign='center'
                      >
                        <InlineStack wrap={false} gap='200' blockAlign='center'>
                          <div style={{ width: 20, height: 20 }}>
                            <Icon source={ProductIcon} tone='base' />
                          </div>
                          {boldMessageParts(
                            generateMessage(
                              SectionTypeEnum.PRODUCTS,
                              products,
                              0,
                              currentSelections,
                              i18n,
                              undefined,
                              oneRuleIsRequired,
                              type === 'Prerequisite'
                            )
                          )}
                        </InlineStack>
                        <BlockStack align='center'>
                          <Button
                            variant='plain'
                            id='OfferProductsConfigureButton'
                            onClick={() => handleConfiguration(true)}
                          >
                            {i18n.translate('Products.Configure')}
                          </Button>
                        </BlockStack>
                      </InlineStack>
                      {bullets.length ? (
                        <div
                          data-cy='ProductsSummaryBulletList'
                          className='bullet'
                        >
                          <List>
                            {bullets.map((bullet, index) => (
                              <List.Item key={index}>{bullet}</List.Item>
                            ))}
                          </List>
                        </div>
                      ) : null}
                    </Card>
                  )
                : !checked && (
                    <Card
                      background='bg-surface-secondary'
                      padding='600'
                      roundedAbove='xs'
                    >
                      <BlockStack
                        id='EmptyOfferProductsSection'
                        gap='300'
                        inlineAlign='center'
                      >
                        <Text as='p' tone='subdued'>
                          {i18n.translate('Products.AppliesToAllProducts')}
                        </Text>
                        <Button
                          id='AddProductsButton'
                          onClick={() => handleConfiguration(true)}
                        >
                          {i18n.translate('Products.SelectProducts')}
                        </Button>
                      </BlockStack>
                    </Card>
                  )}
            </BlockStack>
          </Card>
        </div>
      )}
    </>
  );
};
