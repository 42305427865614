import React from 'react';
import { Box, Button, Icon, InlineStack, Text } from '@shopify/polaris';
import {
  DisabledIcon,
  DeleteIcon,
  AlertTriangleIcon,
} from '@shopify/polaris-icons';

type SimpleExclusionProps = {
  label: string;
  conflict?: boolean;
  onRemove: () => void;
};

export const SimpleExclusion: React.FC<SimpleExclusionProps> = ({
  label,
  conflict,
  onRemove,
}) => {
  return (
    <InlineStack blockAlign='start' gap='200' wrap={false}>
      <Box
        width='100%'
        padding='400'
        borderColor='border'
        borderWidth='025'
        borderRadius='200'
      >
        <InlineStack gap='200' wrap={false}>
          <Box>
            <Icon
              tone={conflict ? 'textCritical' : 'base'}
              source={conflict ? AlertTriangleIcon : DisabledIcon}
            />
          </Box>
          <Text tone={conflict ? 'critical' : 'base'} as='p'>
            {label}
          </Text>
        </InlineStack>
      </Box>
      <Box paddingBlockStart='400'>
        <Button variant='tertiary' icon={DeleteIcon} onClick={onRemove} />
      </Box>
    </InlineStack>
  );
};
