import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './ToggleWidgetManually.scss';
import {
  Badge,
  Banner,
  BlockStack,
  Button,
  ButtonGroup,
  ChoiceList,
  InlineStack,
  Link,
  TextField,
  Text,
  Card,
  SkeletonBodyText,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  InstallWidgetRequestDto,
  WidgetInstallationCustomPropertiesDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  WidgetInstallationCustomWidgetCssSelectorDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { ComboboxOption } from 'core/components';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { setIsRefreshModalOpen } from 'core/store/settingsSlice';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { useWidgetsControlCenter } from 'core/hooks/useWidgetsControlCenter';
import { WidgetsControlCenterModalsEnum } from 'features/widgets/enums/WidgetsControlCenterModalsType';
import { isEqual } from 'lodash';
import { NavTemplateCard } from 'features/widgets/components/NavTemplateCard/NavTemplateCard';

type ToggleWidgetManuallyProps = {
  installationLink?: string;
  installationMode?: WidgetInstallationModeDtoEnum;
  widgetType: WidgetTypeDtoEnum;
  onClose: () => void;
  refetchWidget: () => void;
  setTemplatesUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWidgetsListUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
};
const ToggleWidgetManually: React.FC<ToggleWidgetManuallyProps> = ({
  installationLink,
  installationMode,
  widgetType,
  onClose,
  refetchWidget,
  setTemplatesUpdated,
  setIsWidgetsListUpdating,
}) => {
  //Mocked
  const widgetInstallationAssetsData: any = [];
  const widgetInstallationAssetsIsFetching = false;
  const {
    turnWidgetOnManuallyIsLoading,
    pageTemplatesData,
    pageTemplatesDataIsFetching,
    refetchPageTemplatesData,
    turnWidgetOnManually,
  } = useWidgetsControlCenter(
    installationMode === WidgetInstallationModeDtoEnum.APP_BLOCKS
      ? WidgetsControlCenterModalsEnum.ENABLE_APP_BLOCK_MODAL
      : undefined,
    true,
    installationMode === WidgetInstallationModeDtoEnum.APP_BLOCKS
      ? widgetType
      : undefined
  );
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const { widgetThemeType } = useAppSelector((store) => store.settings);
  const initialPageTemplatesData = useRef(pageTemplatesData);
  const [requestSetup, setRequestSetup] = useState<InstallWidgetRequestDto>({
    type: widgetType,
    status:
      installationMode === WidgetInstallationModeDtoEnum.CUSTOM
        ? WidgetStatusDtoEnum.ENABLED_VIA_CODE
        : WidgetStatusDtoEnum.ENABLED,
  });

  const [assetAgreed, setAssetAgreed] = useState<boolean>(false);
  const [isAssetValid, setIsAssetValid] = useState<boolean>(true);

  const statuses = [
    WidgetStatusDtoEnum.ENABLED_VIA_CODE,
    WidgetStatusDtoEnum.ENABLED_VIA_CSS,
  ];

  const statusOptions = useMemo(() => {
    return statuses.map((value) => ({
      label: (
        <InlineStack gap='200'>
          {i18n.translate(value)}
          {value === WidgetStatusDtoEnum.ENABLED_VIA_CODE &&
            installationMode === WidgetInstallationModeDtoEnum.CUSTOM && (
              <Badge>{i18n.translate('Recommended')}</Badge>
            )}
        </InlineStack>
      ),
      value: value,
    }));
  }, [statuses, installationMode]);

  const assetOptions = useMemo(
    () =>
      (widgetInstallationAssetsData
        ? widgetInstallationAssetsData?.map((asset: any) => ({
            label: asset.name,
            value: asset.id,
          }))
        : []) as ComboboxOption[],
    [widgetInstallationAssetsData]
  );
  const cssSelectorOptions = useMemo(
    () =>
      Object.values(WidgetInstallationCustomWidgetCssSelectorDtoEnum)?.map(
        (value) => ({
          label: i18n.translate(value),
          value: value,
        })
      ),
    [i18n]
  );
  const updateCustomProperties = useCallback(
    (
      field: keyof WidgetInstallationCustomPropertiesDto,
      data: WidgetInstallationCustomPropertiesDto[keyof WidgetInstallationCustomPropertiesDto]
    ) => {
      setRequestSetup((prev) => ({
        ...prev,
        customProperties: {
          ...prev.customProperties,
          [field]: data,
        },
      }));
    },
    [setRequestSetup]
  );

  const sendSaveRequest = useCallback(async () => {
    const onSuccess = () => {
      onClose();
      refetchWidget();
    };
    const onError = () => {
      setIsAssetValid(false);
    };
    setIsAssetValid(true);
    setIsWidgetsListUpdating?.(true);
    try {
      await turnWidgetOnManually(
        requestSetup,
        widgetThemeType,
        onSuccess,
        onError
      );
    } finally {
      setIsWidgetsListUpdating?.(false);
    }
  }, [requestSetup, turnWidgetOnManually]);

  const openThemeEditor = useCallback(() => {
    window.open(installationLink, '_blank');
    onClose();
    dispatch(setIsRefreshModalOpen(true));
  }, [installationLink, dispatch]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && pageTemplatesData) {
        refetchPageTemplatesData();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [pageTemplatesData]);

  useEffect(() => {
    if (!initialPageTemplatesData.current && pageTemplatesData) {
      initialPageTemplatesData.current = pageTemplatesData;
    }
  }, [pageTemplatesData]);

  useEffect(() => {
    if (!initialPageTemplatesData.current || !pageTemplatesData) return;

    setTemplatesUpdated?.(
      !isEqual(initialPageTemplatesData.current, pageTemplatesData)
    );
  }, [pageTemplatesData]);

  return (
    <div className='ToggleWidgetManually'>
      <div className='ToggleWidgetModalContent'>
        <BlockStack gap='300'>
          {installationMode !== WidgetInstallationModeDtoEnum.APP_BLOCKS && (
            <ChoiceList
              title=''
              choices={statusOptions}
              selected={[requestSetup.status as WidgetStatusDtoEnum]}
              onChange={(value) =>
                setRequestSetup({
                  ...requestSetup,
                  status: value[0] as WidgetStatusDtoEnum,
                })
              }
            />
          )}
          {requestSetup.status === WidgetStatusDtoEnum.ENABLED_VIA_CODE && (
            <>
              <Banner tone='info'>
                {i18n.translate('EditCodeBanner')}
                <Link
                  target='_blank'
                  url='https://support.discountninja.io/en/collections/1430668-setting-up-dynamic-pricing'
                >
                  {i18n.translate('ReadDocumentation')}
                </Link>
              </Banner>
              {/* <Checkbox
                label={i18n.translate('AssetAgreement')}
                checked={assetAgreed}
                onChange={setAssetAgreed}
              />
              <SelectOptions
                options={assetOptions}
                isLoading={widgetInstallationAssetsIsFetching}
                onOptionSelect={(value) => {
                  setIsAssetValid(true);
                  updateCustomProperties('selectedAssetId', value);
                }}
                selectedOption={
                  requestSetup.customProperties?.selectedAssetId || ''
                }
                label={i18n.translate('ThemeAsset')}
                helpText={isAssetValid ? i18n.translate('SelectTheAsset') : ''}
                placeholder={i18n.translate('Select')}
                disabled={!assetAgreed}
                error={
                  isAssetValid
                    ? false
                    : `${
                        i18n.translate('AssetErrorPart1') +
                        i18n.translate(widgetType) +
                        i18n.translate('AssetErrorPart2') +
                        widgetInstallationAssetsData?.find(
                          (asset: any) =>
                            asset.id ===
                            requestSetup.customProperties?.selectedAssetId
                        )?.name
                      }`
                }
              /> */}
            </>
          )}
          {requestSetup.status === WidgetStatusDtoEnum.ENABLED_VIA_CSS && (
            <>
              <SelectOptions
                options={cssSelectorOptions}
                onOptionSelect={(value) =>
                  updateCustomProperties('cssSelector', value)
                }
                selectedOption={
                  requestSetup.customProperties?.cssSelector || ''
                }
                label={i18n.translate('CSSSelector')}
                placeholder={i18n.translate('Select')}
              />
              {requestSetup.customProperties?.cssSelector ===
                WidgetInstallationCustomWidgetCssSelectorDtoEnum.OTHER && (
                <>
                  <TextField
                    label={i18n.translate('CustomSelector')}
                    autoComplete=''
                    value={
                      requestSetup.customProperties.customCssSelector || ''
                    }
                    onChange={(value) =>
                      updateCustomProperties('customCssSelector', value)
                    }
                  />
                  <div className='ListOfNonCss'>
                    <p>{i18n.translate('NonCssSelecorTitle')}</p>
                    <ul>
                      <li>
                        <span>{'[[WRAP]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor1')}
                      </li>
                      <li>
                        <span>{'[[PREPEND]], [[APPEND]] '}</span>
                        {i18n.translate('NonCssSelecor2')}
                      </li>
                      <li>
                        <span>{'[[BEFORE]], [[AFTER]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor3')}
                      </li>
                      <li>
                        <span>{'[[REPLACE]], [[REPLACECONTENT]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor4')}
                      </li>
                      <li>
                        <span>{'[[CARTITEMKEY]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor5')}
                      </li>
                      <li>
                        <span>{'[[HANDLE:href]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor6')}
                      </li>
                      <li>
                        <span>{'[[LEFT]], [[CENTER]], [[RIGHT]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor7')}
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </>
          )}
          {requestSetup.status === WidgetStatusDtoEnum.ENABLED && (
            <>
              <Banner tone='info'>
                {i18n.translate('AppBlockBanner', {
                  widget: i18n.translate(widgetType),
                  link: <Link>{i18n.translate('ReadDocumentation')}</Link>,
                })}
              </Banner>
              <BlockStack gap='100'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate('SelectTemplate')}
                </Text>
                <Text as='span' tone='subdued'>
                  {i18n.translate('SelectWhichPage')}
                </Text>
              </BlockStack>
              {!pageTemplatesData && (
                <Card>
                  <SkeletonBodyText lines={2} />
                </Card>
              )}
              {pageTemplatesData &&
                pageTemplatesData?.map((template) => (
                  <NavTemplateCard
                    key={template.id}
                    template={template}
                    pageTemplatesDataIsFetching={pageTemplatesDataIsFetching}
                  />
                ))}
            </>
          )}
        </BlockStack>
      </div>
      <div className='ModalFooterActions'>
        <ButtonGroup>
          <Button onClick={onClose}>
            {i18n.translate(
              installationMode !== WidgetInstallationModeDtoEnum.APP_BLOCKS
                ? 'Cancel'
                : 'Close'
            )}
          </Button>
          {installationMode !== WidgetInstallationModeDtoEnum.APP_BLOCKS && (
            <Button
              onClick={
                requestSetup.status === WidgetStatusDtoEnum.ENABLED
                  ? openThemeEditor
                  : sendSaveRequest
              }
              variant='primary'
              loading={turnWidgetOnManuallyIsLoading}
            >
              {i18n.translate(`${requestSetup.status}_BUTTON`)}
            </Button>
          )}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ToggleWidgetManually;
