import React, { useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  BlockStack,
  Card,
  ChoiceList,
  Text,
  Banner,
  Link,
} from '@shopify/polaris';
import { PageTypeDto } from 'core/api/adminSettings/adminSettingsApi';
import { PageTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type PageDisplayCheckboxSettingProps = {
  pageDisplay?: PageTypeDto[] | null;
  setPageDisplay: (value: PageTypeDto[]) => void;
};

export const PageDisplayCheckboxSetting: React.FC<
  PageDisplayCheckboxSettingProps
> = ({ pageDisplay = [], setPageDisplay }) => {
  const [i18n] = useI18n();

  const pageOptions = useMemo(
    () =>
      Object.values(PageTypeDtoEnum).map((value) => ({
        label: i18n.translate(`pageType.${value.toLowerCase()}`),
        value,
      })),
    [i18n]
  );

  const selectedValues: string[] = useMemo(
    () =>
      pageDisplay?.filter(
        (value): value is Exclude<PageTypeDto, null> => value !== null
      ) || [],
    [pageDisplay]
  );

  const isAllSelected = useMemo(
    () => selectedValues.length === Object.values(PageTypeDtoEnum).length,
    [selectedValues]
  );

  const handleChange = (selected: string[]) => {
    setPageDisplay(selected as PageTypeDto[]);
  };

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='bold'>
            {i18n.translate('pageDisplay.title')}
          </Text>
          <Text as='span' tone='subdued'>
            {i18n.translate('pageDisplay.description')}
          </Text>
        </BlockStack>

        <ChoiceList
          title=''
          allowMultiple
          choices={pageOptions}
          selected={selectedValues}
          onChange={handleChange}
        />

        {!isAllSelected && (
          <Banner tone='info'>
            {i18n.translate('pageDisplay.partialSelectionWarning', {
              link: (
                <Link url='http://www.google.com' target='_blank'>
                  {i18n.translate('pageDisplay.readDocumentation')}
                </Link>
              ),
            })}
          </Banner>
        )}
      </BlockStack>
    </Card>
  );
};
