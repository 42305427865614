import {
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Icon,
  InlineStack,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import './GrayBox.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { DeleteIcon } from '@shopify/polaris-icons';
import { GrayBoxExclusions } from './components/GrayBoxExclusions/GrayBoxExclusions';
import { GrayBoxBogoOptions } from './components/GrayBoxBogoOptions/GrayBoxBogoOptions';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { GrayBoxFirstLineContent } from './components/GrayBoxFirstLineContent/GrayBoxFirstLineContent';
import { ResourceSelectionProps } from '../SearchFieldWithGrayBox';
import { SearchFieldWithGrayBox } from '../SearchFieldWithGrayBoxOfferWizard';
import {
  OfferCartRuleDto,
  OfferRuleAppliesToDto,
  OfferTargetTypeDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferCartContentRequirementProductMatchTypeDtoEnum,
  OfferRuleTypeDtoEnum,
  OfferRuleValueTypeDtoEnum,
  OfferTargetTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { TotalCartValueType } from 'features/promotions/components/SelectedOfferTypeSettings/enums/ExceptionRulesType';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import SelectOptions from '../SelectOptions/SelectOptions';
import { ExclusionsPhrasePicker } from '../ExclusionsBox/components/ExclusionsPhrasePicker/ExclusionsPhrasePicker';

//TODO load initial values from template
export const grayBoxInitialValues: OfferCartRuleDto = {
  type: OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT,
  value: {
    type: OfferRuleValueTypeDtoEnum.AT_LEAST,
    from: 1,
    to: null,
    exactly: 1,
  },
  appliesTo: {
    type: OfferTargetTypeDtoEnum.ALL,
    collections: null,
    products: null,
    productMatchType: {
      enabled: false,
      value: OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT,
    },
    variants: null,
  },
  exclusions: {
    collections: {
      enabled: false,
      value: [],
    },
  },
  applyAfterDiscounts: true,
};

export type GrayBoxProps = {
  specialOfferType?: OfferTypeSpecialCases;
  isMoney?: boolean;
  visitorsAlsoAdd?: boolean;
  data: OfferCartRuleDto;
  onDeleteRule?: () => void;
  updateValue: (data: OfferCartRuleDto) => void;
  handleRuleValidityChange: (id: string, isValid: boolean) => void;
  title?: string;
  chidren?: React.ReactNode;
  setConfiguredCollections?: (value: string) => void;
  hideComponent?: boolean;
};

export const GrayBox: React.FC<GrayBoxProps> = (props) => {
  const {
    isMoney = false,
    visitorsAlsoAdd,
    data,
    specialOfferType,
    onDeleteRule,
    updateValue,
    handleRuleValidityChange,
    title,
    chidren,
    setConfiguredCollections,
    hideComponent,
  } = props;

  const [i18n] = useI18n();

  const listNeeded = useMemo<keyof OfferRuleAppliesToDto>(
    () =>
      data.appliesTo?.type === OfferTargetTypeDtoEnum.ALL
        ? 'collections'
        : (data.appliesTo?.type?.toLowerCase() as keyof OfferRuleAppliesToDto) ??
          'collections',
    [data.appliesTo?.type]
  );

  const [selectList, setSelectList] = useState<ShopifyObjectDto[] | string[]>(
    []
  );

  const [isAppliesToValid, setIsAppliesToValid] = useState<boolean>(true);
  const [isExclusionsValid, setIsExclusionsValid] = useState<boolean>(true);
  const [isFilterValid, setIsFilterValid] = useState<boolean>(true);
  const [phraseModal, setPhraseModal] = useState<boolean>(false);

  const isSpendXGetX = useMemo(
    () => specialOfferType === OfferTypeSpecialCases.SpendXGetX,
    [specialOfferType]
  );

  const isCrossSellOrBundle = useMemo(
    () =>
      specialOfferType &&
      [
        OfferTypeSpecialCases.CrossSell,
        OfferTypeSpecialCases.BundleOrderDiscount,
      ].includes(specialOfferType),
    [specialOfferType]
  );

  const showGrayBoxBogoOptions = useMemo(
    () => !isMoney && !hideComponent,
    [isMoney, hideComponent]
  );

  const [activeCollectionProductType, setActiveCollectionProductType] =
    useState<OfferTargetTypeDto>(OfferTargetTypeDtoEnum.ALL);
  const [activeTotalCartValueType, setActiveTotalCartValueType] =
    useState<boolean>(false);

  const onCollectionProductTypeClick = useCallback(
    (ruleCollectionType: OfferTargetTypeDtoEnum) => {
      if (activeCollectionProductType !== ruleCollectionType) {
        setActiveCollectionProductType(ruleCollectionType);
        setSelectList(
          (data.appliesTo?.[
            ruleCollectionType.toLowerCase() as keyof typeof data.appliesTo
          ] || []) as ShopifyObjectDto[]
        );

        updateValue({
          ...data,
          appliesTo: {
            ...data.appliesTo,
            type: ruleCollectionType,
          },
        });
      }
    },
    [
      setActiveCollectionProductType,
      setSelectList,
      updateValue,
      data,
      activeCollectionProductType,
    ]
  );

  const onTotalCartValueTypeClick = useCallback(
    (totalCartValueType: boolean) => {
      setActiveTotalCartValueType(totalCartValueType);
      updateValue({
        ...data,
        applyAfterDiscounts: totalCartValueType,
      });
    },
    [setActiveTotalCartValueType, updateValue, data]
  );

  const handleSelectChange = useCallback(
    (list: ResourceSelectionProps[] | string[]) => {
      setSelectList(list);
      updateValue({
        ...data,
        appliesTo: {
          ...data.appliesTo,
          [listNeeded]: formateList(list, activeCollectionProductType),
        },
      });
    },
    [
      setSelectList,
      updateValue,
      formateList,
      activeCollectionProductType,
      data,
      listNeeded,
    ]
  );

  const getCollectionProductEnumKey = useCallback(
    (value: OfferTargetTypeDtoEnum) => {
      return Object.keys(OfferTargetTypeDtoEnum)[
        Object.values(OfferTargetTypeDtoEnum).indexOf(value)
      ];
    },
    []
  );

  const showCartValueLine = useMemo(
    () => isSpendXGetX || isMoney,
    [isSpendXGetX, isMoney]
  );

  const isAllValid: boolean = useMemo(
    () => isFilterValid && isAppliesToValid && isExclusionsValid,
    [isFilterValid, isAppliesToValid, isExclusionsValid]
  );

  const onSearchFieldValidityChange = useCallback(
    (formIsInvalid: boolean, isExclusionsField?: boolean) => {
      isExclusionsField
        ? setIsExclusionsValid(!formIsInvalid)
        : setIsAppliesToValid(!formIsInvalid);
    },
    []
  );

  const onFiltersValidityChange = useCallback((formIsValid: boolean) => {
    setIsFilterValid(formIsValid);
  }, []);

  const togglePhraseModal = useCallback(
    () => setPhraseModal((prev) => !prev),
    []
  );

  useEffect(() => {
    setSelectList(
      (data.appliesTo?.[listNeeded] as ShopifyObjectDto[] | string[]) || []
    );
  }, [listNeeded]);

  useEffect(() => {
    setActiveCollectionProductType(
      data.appliesTo?.type || OfferTargetTypeDtoEnum.ALL
    );
  }, [data.appliesTo?.type]);

  useEffect(() => {
    setActiveTotalCartValueType(data.applyAfterDiscounts || false);
  }, [data.applyAfterDiscounts]);

  useEffect(() => {
    handleRuleValidityChange(data.id || '', isAllValid);
  }, [isAllValid, data.id]);

  const selectedTypeOptions = useMemo(() => {
    switch (activeCollectionProductType) {
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.ALL):
        return (
          <>
            {showGrayBoxBogoOptions && (
              <GrayBoxBogoOptions data={data} updateValue={updateValue} />
            )}
          </>
        );
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.TAGS):
        return (
          <>
            <ExclusionsPhrasePicker
              type='tags'
              label={i18n.translate('GrayBox.tags')}
              phrases={{ enabled: true, value: selectList as string[] }}
              isModalOpen={phraseModal}
              toggleModal={togglePhraseModal}
              setPhrases={(data) => handleSelectChange(data.value || [])}
              onSearchFieldValidityChange={onSearchFieldValidityChange}
            />
            {showGrayBoxBogoOptions && (
              <GrayBoxBogoOptions data={data} updateValue={updateValue} />
            )}
          </>
        );
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.VENDORS):
        return (
          <>
            <ExclusionsPhrasePicker
              type='vendors'
              label={i18n.translate('GrayBox.vendors')}
              phrases={{ enabled: true, value: selectList as string[] }}
              isModalOpen={phraseModal}
              toggleModal={togglePhraseModal}
              setPhrases={(data) => handleSelectChange(data.value || [])}
              onSearchFieldValidityChange={onSearchFieldValidityChange}
            />
            {showGrayBoxBogoOptions && (
              <GrayBoxBogoOptions data={data} updateValue={updateValue} />
            )}
          </>
        );
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.COLLECTIONS):
        return (
          <>
            <div className='SearchboxContainer'>
              <SearchFieldWithGrayBox
                selectList={selectList as ResourceSelectionProps[]}
                resourceType='Collection'
                onSelectedChange={handleSelectChange}
                onFormValidityChange={onSearchFieldValidityChange}
                setConfiguredCollections={setConfiguredCollections}
                hideComponent={hideComponent}
              />
            </div>
            {showGrayBoxBogoOptions && (
              <GrayBoxBogoOptions data={data} updateValue={updateValue} />
            )}
          </>
        );
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.PRODUCTS):
        return (
          <>
            <div className='SearchboxContainer'>
              <SearchFieldWithGrayBox
                selectList={selectList as ResourceSelectionProps[]}
                resourceType='Product'
                onSelectedChange={handleSelectChange}
                onFormValidityChange={onSearchFieldValidityChange}
                setConfiguredCollections={setConfiguredCollections}
                hideComponent={hideComponent}
              />
            </div>
            {showGrayBoxBogoOptions && (
              <GrayBoxBogoOptions data={data} updateValue={updateValue} />
            )}
          </>
        );
      case getCollectionProductEnumKey(OfferTargetTypeDtoEnum.VARIANTS):
        return (
          <>
            <div className='SearchboxContainer'>
              <SearchFieldWithGrayBox
                selectList={selectList as ResourceSelectionProps[]}
                resourceType='Product'
                showVariants
                onSelectedChange={handleSelectChange}
                onFormValidityChange={onSearchFieldValidityChange}
                setConfiguredCollections={setConfiguredCollections}
                hideComponent={hideComponent}
              />
            </div>
            {showGrayBoxBogoOptions && (
              <GrayBoxBogoOptions data={data} updateValue={updateValue} />
            )}
          </>
        );
    }
  }, [
    data,
    hideComponent,
    showGrayBoxBogoOptions,
    selectList,
    activeCollectionProductType,
    phraseModal,
    i18n,
    togglePhraseModal,
    handleSelectChange,
    getCollectionProductEnumKey,
    updateValue,
  ]);

  return (
    <>
      {!hideComponent ? (
        <>
          <BlockStack gap='600'>
            <Card>
              <BlockStack gap='400'>
                {title && (
                  <Text as='h2' variant='headingSm'>
                    {title}
                  </Text>
                )}
                {chidren}
                <InlineStack gap='200' blockAlign='center'>
                  <GrayBoxFirstLineContent
                    isMoney={isMoney}
                    visitorsAlsoAdd={visitorsAlsoAdd}
                    updateValue={updateValue}
                    isCrossSell={isCrossSellOrBundle}
                    data={data}
                    activeRuleOfferType={
                      data.value?.type || OfferRuleValueTypeDtoEnum.AT_LEAST
                    }
                    onFormValidityChange={onFiltersValidityChange}
                  />

                  <InlineStack gap='200' blockAlign='center'>
                    {isMoney ? (
                      <Text as='p'>
                        {i18n.translate(
                          `GrayBox.${isCrossSellOrBundle ? 'SpentOn' : 'On'}`
                        )}
                      </Text>
                    ) : (
                      <Text as='p'>
                        {i18n.translate('GrayBox.TextFrom') || 'from'}
                      </Text>
                    )}
                    <SelectOptions
                      label=''
                      selectedOption={activeCollectionProductType}
                      options={Object.entries(OfferTargetTypeDtoEnum).map(
                        ([key, value]: [string, OfferTargetTypeDtoEnum]) => ({
                          label: i18n.translate(`GrayBox.${key}`) || value,
                          value,
                        })
                      )}
                      onOptionSelect={(value) =>
                        onCollectionProductTypeClick(
                          value as OfferTargetTypeDtoEnum
                        )
                      }
                    />
                  </InlineStack>
                  {showCartValueLine && (
                    <>
                      <InlineStack gap='100' blockAlign='center'>
                        <Text as='p'>
                          {i18n.translate('GrayBox.UsingCartValue.Text') ||
                            'using the'}
                        </Text>
                        <Text as='p'>
                          {i18n.translate('GrayBox.UsingCartValue.Hover') ||
                            'total cart value'}
                        </Text>
                        <Tooltip
                          preferredPosition='mostSpace'
                          content={i18n.translate(
                            'GrayBox.UsingCartValue.HoverText'
                          )}
                        >
                          <span>
                            <Icon source={QuestionCircleIcon} />
                          </span>
                        </Tooltip>
                      </InlineStack>
                      <ButtonGroup variant='segmented'>
                        {Object.values(TotalCartValueType).map(
                          (key: string) => (
                            <Button
                              key={key}
                              pressed={
                                activeTotalCartValueType ===
                                (key === TotalCartValueType.AfterDiscounts)
                              }
                              onClick={() =>
                                onTotalCartValueTypeClick(
                                  key === TotalCartValueType.AfterDiscounts
                                )
                              }
                            >
                              {i18n.translate(
                                `GrayBox.UsingCartValue.${
                                  key === TotalCartValueType.AfterDiscounts
                                    ? 'AfterDiscounts'
                                    : 'BeforeDiscounts'
                                }`
                              ) || key}
                            </Button>
                          )
                        )}
                      </ButtonGroup>
                    </>
                  )}
                </InlineStack>

                {selectedTypeOptions}
              </BlockStack>
            </Card>
            <GrayBoxExclusions
              data={data}
              updateValue={updateValue}
              onFormValidityChange={onSearchFieldValidityChange}
            />
          </BlockStack>
          {onDeleteRule && (
            <Box paddingBlock='400'>
              <BlockStack inlineAlign='start'>
                <Button
                  icon={<Icon source={DeleteIcon} />}
                  onClick={onDeleteRule}
                  tone='critical'
                >
                  {i18n.translate('GrayBox.DeleteRule') || 'Delete rule'}
                </Button>
              </BlockStack>
            </Box>
          )}
        </>
      ) : (
        <>
          {selectedTypeOptions}
          <GrayBoxExclusions
            data={data}
            updateValue={updateValue}
            onFormValidityChange={onSearchFieldValidityChange}
          />
        </>
      )}
    </>
  );
};
