import React, { useCallback, useEffect, useState } from 'react';
import './SelectOptions.scss';
import { Combobox, Icon, IconSource, Listbox } from '@shopify/polaris';
import { ComboboxOption } from 'core/components/ControlLibrary';
import { SelectIcon } from '@shopify/polaris-icons';
import classNames from 'classnames';
export type SelectOptionsProps = {
  options: ComboboxOption[];
  onOptionSelect: (value: string) => void;
  selectedOption?: string | null;
  label: string;
  prefix?: React.ReactNode;
  disabled?: boolean;
  allowManual?: boolean;
  min?: number;
  max?: number;
  placeholder?: string;
  helpText?: string;
  isLoading?: boolean;
  error?: boolean | string;
  connectedRight?: React.ReactNode;
  readOnly?: boolean;
};

const SelectOptions: React.FC<SelectOptionsProps> = ({
  options,
  onOptionSelect,
  selectedOption,
  label,
  prefix,
  disabled,
  allowManual,
  min,
  max,
  placeholder,
  helpText,
  isLoading,
  readOnly,
  error,
  connectedRight,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [optionsData, setOptionsData] = useState<ComboboxOption[]>(options);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === '') {
        setOptionsData(options);
        return;
      }
      // Use allowManual & min & max when you want to have opportunity to change input manually
      if (!allowManual && !min && !max) {
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = options.filter((option) =>
          option.label.match(filterRegex)
        );
        setOptionsData(resultOptions);
      }
    },
    [options]
  );

  const updateInputValue = useCallback(
    (selected: string) => {
      const matchedOption = optionsData.find((option) => {
        return option.value === selected;
      });
      setInputValue((matchedOption && matchedOption.label) || selected || '');
    },
    [optionsData]
  );

  const updateSelection = useCallback(
    (selected?: string) => {
      selected && onOptionSelect(selected);
    },
    [onOptionSelect]
  );
  const optionsMarkup =
    optionsData.length > 0
      ? optionsData.map((option) => {
          const { label, value } = option;
          return (
            <Listbox.Option
              key={`${value}`}
              value={value}
              selected={selectedOption === value}
              accessibilityLabel={label}
            >
              {label}
            </Listbox.Option>
          );
        })
      : null;

  const handleBlur = useCallback(() => {
    if (max && +inputValue > max) {
      updateSelection(max.toString());
    } else if (min && +inputValue < min) {
      updateSelection(min.toString());
    }
  }, [inputValue, max, min]);

  useEffect(() => {
    if (allowManual) {
      updateSelection(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    options.length && setOptionsData(options);
  }, [options]);

  useEffect(() => {
    selectedOption && updateInputValue(selectedOption);
  }, [selectedOption]);

  return (
    <div
      className={classNames('SelectOptions', {
        HideCaret: !allowManual,
      })}
    >
      <Combobox
        activator={
          <Combobox.TextField
            disabled={disabled}
            suffix={!readOnly ? <Icon source={SelectIcon as IconSource} /> : ''}
            onChange={(value) => {
              allowManual && selectedOption !== 'Auto' && updateText(value);
            }}
            label={label}
            value={inputValue}
            autoComplete='off'
            prefix={prefix}
            type={allowManual ? 'number' : 'text'}
            onBlur={handleBlur}
            placeholder={placeholder}
            helpText={helpText}
            loading={isLoading}
            error={error}
            readOnly={readOnly}
            onFocus={(e) =>
              // this code is giving a warning (Andriy)
              e?.target.addEventListener(
                'wheel',
                (e) => {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
            connectedRight={connectedRight}
          />
        }
      >
        {optionsData.length > 0 && !disabled && !readOnly ? (
          <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
        ) : null}
      </Combobox>
    </div>
  );
};

export default SelectOptions;
