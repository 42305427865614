import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './WidgetWrapper.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import {
  BlockStack,
  Box,
  Button,
  Icon,
  InlineGrid,
  MediaCard,
  Page,
} from '@shopify/polaris';
import { transformToKebabOrSnakeCase } from 'core/utils/transformToKebabOrSnakeCase';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { useAppDispatch, useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { SaveBar } from '@shopify/app-bridge-react';
import { WidgetStatus, WidgetWrapperSkeleton } from './components';
import { useWidgetsControlCenter } from 'core/hooks/useWidgetsControlCenter';
import { WidgetsControlCenterModalsEnum } from 'features/widgets/enums/WidgetsControlCenterModalsType';
import { useScreenResolution } from 'core/hooks/useScreenResolution';
import { ImagesIcon } from '@shopify/polaris-icons';
import { ControlPageTypeEnum } from 'pages/enums/PagesEnums';
import {
  AnnouncementBarSettings,
  CustomStrikeThroughPricingSettings,
  EmptyWidgetSettings,
  EssentialsSettings,
  FrequentlyBoughtTogetherSettings,
  NinjaCartGiftPickerSettings,
  NinjaCartPaymentOptionsSettings,
  NinjaCartProductBadgeSettings,
  NotificationSettings,
  OfferRulePopupSettings,
  OrderBumpSettings,
  PopupSettings,
  ProductBannerSettings,
  PromotionCodeFieldSettings,
  PromotionSummarySettings,
  PromotionalBadgeSettings,
  DiscountLabelSettings,
  CheckoutLoadingPopupSettings,
  CountdownClockSettings,
} from './pages';
import { setShowValidation } from 'core/store/promotionsSlice';
import { setSettingsFormValidity } from 'core/store/settingsSlice';
import { scroller } from 'react-scroll';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';

export type WidgetSavebarRef = {
  saveChanges: () => Promise<any>;
  discardChanges: () => void;
};

export const WidgetWrapper = ({
  pageType,
}: {
  pageType: ControlPageTypeEnum;
}) => {
  const params = useParams();
  const [i18n] = useI18n();
  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();
  const { width } = useScreenResolution();
  const dispatch = useAppDispatch();
  const savebarRef = useRef<WidgetSavebarRef>(null);

  const currentWidgetType = useMemo(() => {
    const widgetType = params?.subType || params?.widgetType;
    const transformed = widgetType
      ? transformToKebabOrSnakeCase(widgetType, 'snake_case')
      : undefined;
    return Object.values(WidgetTypeDtoEnum).includes(
      transformed as WidgetTypeDtoEnum
    )
      ? (transformed as WidgetTypeDtoEnum)
      : undefined;
  }, [params?.widgetType, params?.subType]);

  const { isUnsavedChanges, settingsFormValidity } = useAppSelector(
    (store) => store.settings
  );
  const { showValidation } = useAppSelector((store) => store.promotions);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const allowedWidgetTypes = {
    [ControlPageTypeEnum.WIDGETS_CONTROL_CENTER]: [
      WidgetTypeDtoEnum.ANNOUNCEMENT_BAR,
      WidgetTypeDtoEnum.NOTIFICATIONS,
      WidgetTypeDtoEnum.PRODUCT_BANNER,
      WidgetTypeDtoEnum.PROMOTIONAL_BADGE,
      WidgetTypeDtoEnum.POP_UP,
      WidgetTypeDtoEnum.PROMOTION_CODE_FIELD,
      WidgetTypeDtoEnum.PROMOTION_SUMMARY,
      WidgetTypeDtoEnum.CART_TEXT,
      WidgetTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER,
      WidgetTypeDtoEnum.VOLUME_DISCOUNT,
      WidgetTypeDtoEnum.CUSTOM_STRIKETHROUGH_PRICING,
      WidgetTypeDtoEnum.PRODUCT_STRIKETHROUGH_PRICING,
      WidgetTypeDtoEnum.ESSENTIALS,
      WidgetTypeDtoEnum.OFFER_RULE_POPUP,
      WidgetTypeDtoEnum.COUNTDOWN_CLOCK,
      WidgetTypeDtoEnum.DISCOUNT_LABEL,
      WidgetTypeDtoEnum.CHECKOUT_LOADING_POPUP,
    ],
    [ControlPageTypeEnum.NINJA_CART_CONTROL_CENTER]: [
      WidgetTypeDtoEnum.NINJA_CART_PAYMENT_OPTIONS,
      WidgetTypeDtoEnum.NINJA_CART_PRODUCT_BADGE,
      WidgetTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY,
      WidgetTypeDtoEnum.NINJA_CART_CART_TEXT,
      WidgetTypeDtoEnum.NINJA_CART_SUBSCRIPTION_UPSELL,
      WidgetTypeDtoEnum.NINJA_CART_REWARDS_MULTI_GOAL_PROGRESS_BAR,
      WidgetTypeDtoEnum.NINJA_CART_TERMS_AND_CONDITIONS,
      WidgetTypeDtoEnum.NINJA_CART_REWARDS_SINGLE_GOAL_PROGRESS_BAR,
      WidgetTypeDtoEnum.NINJA_CART_FREE_GIFT_PICKER,
      WidgetTypeDtoEnum.NINJA_CART_DRAWER_CART_ANNOUNCEMENT_BAR,
    ],
  };

  const isNinjaCart = allowedWidgetTypes[
    ControlPageTypeEnum.NINJA_CART_CONTROL_CENTER
  ].includes(currentWidgetType as WidgetTypeDtoEnum);

  const widgetComponents = {
    [WidgetTypeDtoEnum.ANNOUNCEMENT_BAR]: AnnouncementBarSettings,
    [WidgetTypeDtoEnum.NOTIFICATIONS]: NotificationSettings,
    [WidgetTypeDtoEnum.PRODUCT_BANNER]: ProductBannerSettings,
    [WidgetTypeDtoEnum.PROMOTIONAL_BADGE]: PromotionalBadgeSettings,
    [WidgetTypeDtoEnum.POP_UP]: PopupSettings,
    [WidgetTypeDtoEnum.PROMOTION_CODE_FIELD]: PromotionCodeFieldSettings,
    [WidgetTypeDtoEnum.PROMOTION_SUMMARY]: PromotionSummarySettings,
    [WidgetTypeDtoEnum.CART_TEXT]: EmptyWidgetSettings,
    [WidgetTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER]:
      FrequentlyBoughtTogetherSettings,
    [WidgetTypeDtoEnum.VOLUME_DISCOUNT]: EmptyWidgetSettings,
    [WidgetTypeDtoEnum.CUSTOM_STRIKETHROUGH_PRICING]:
      CustomStrikeThroughPricingSettings,
    [WidgetTypeDtoEnum.PRODUCT_STRIKETHROUGH_PRICING]:
      CustomStrikeThroughPricingSettings,
    [WidgetTypeDtoEnum.ESSENTIALS]: EssentialsSettings,
    [WidgetTypeDtoEnum.OFFER_RULE_POPUP]: OfferRulePopupSettings,
    [WidgetTypeDtoEnum.DISCOUNT_LABEL]: DiscountLabelSettings,
    [WidgetTypeDtoEnum.CHECKOUT_LOADING_POPUP]: CheckoutLoadingPopupSettings,
    [WidgetTypeDtoEnum.COUNTDOWN_CLOCK]: CountdownClockSettings,
    [WidgetTypeDtoEnum.NINJA_CART_PAYMENT_OPTIONS]:
      NinjaCartPaymentOptionsSettings,
    [WidgetTypeDtoEnum.NINJA_CART_PRODUCT_BADGE]: NinjaCartProductBadgeSettings,
    [WidgetTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY]: PromotionSummarySettings,
    [WidgetTypeDtoEnum.NINJA_CART_CART_TEXT]: EmptyWidgetSettings,
    [WidgetTypeDtoEnum.NINJA_CART_SUBSCRIPTION_UPSELL]: EmptyWidgetSettings,
    [WidgetTypeDtoEnum.NINJA_CART_REWARDS_MULTI_GOAL_PROGRESS_BAR]:
      EmptyWidgetSettings,
    [WidgetTypeDtoEnum.NINJA_CART_TERMS_AND_CONDITIONS]: EmptyWidgetSettings,
    [WidgetTypeDtoEnum.NINJA_CART_REWARDS_SINGLE_GOAL_PROGRESS_BAR]:
      EmptyWidgetSettings,
    [WidgetTypeDtoEnum.NINJA_CART_FREE_GIFT_PICKER]:
      NinjaCartGiftPickerSettings,
    [WidgetTypeDtoEnum.NINJA_CART_DRAWER_CART_ANNOUNCEMENT_BAR]:
      AnnouncementBarSettings,
    [WidgetTypeDtoEnum.NINJA_CART_ORDER_BUMP]: OrderBumpSettings,
    [WidgetTypeDtoEnum.NINJA_CART_PROMOTION_CODE_FIELD]:
      PromotionCodeFieldSettings,
    [WidgetTypeDtoEnum.NINJA_CART_FREQUENT_BOUGHT_TOGETHER]:
      FrequentlyBoughtTogetherSettings,
  } as const;

  const isAllowed = useMemo(() => {
    return (
      currentWidgetType &&
      allowedWidgetTypes[pageType]?.includes(currentWidgetType)
    );
  }, [pageType, currentWidgetType]);

  const isEssentialsWidget = useMemo(
    () =>
      [
        WidgetTypeDtoEnum.DISCOUNT_LABEL,
        WidgetTypeDtoEnum.OFFER_RULE_POPUP,
        WidgetTypeDtoEnum.COUNTDOWN_CLOCK,
        WidgetTypeDtoEnum.CHECKOUT_LOADING_POPUP,
      ].includes(currentWidgetType as WidgetTypeDtoEnum),
    [currentWidgetType]
  );

  const ninjaCartControlCenterData = useNinjaCartWidget(
    undefined,
    isNinjaCart && isAllowed && !isEssentialsWidget
      ? (currentWidgetType as WidgetTypeDtoEnum)
      : undefined
  );

  const widgetControlCenterData = useWidgetsControlCenter(
    WidgetsControlCenterModalsEnum.NON_MODAL,
    true,
    !isNinjaCart && isAllowed && !isEssentialsWidget
      ? (currentWidgetType as WidgetTypeDtoEnum)
      : undefined
  );

  const {
    widgetStatusesData,
    widgetStatusesDataIsFetching,
    refetchWidgetStatusesData,
  } = isNinjaCart ? ninjaCartControlCenterData : widgetControlCenterData;

  const WidgetComponent = useMemo(
    () =>
      isAllowed && currentWidgetType && currentWidgetType in widgetComponents
        ? widgetComponents[currentWidgetType as keyof typeof widgetComponents]
        : null,
    [isAllowed, currentWidgetType, widgetComponents]
  );

  const widgetToRender = useMemo(() => {
    return WidgetComponent && currentWidgetType ? (
      <WidgetComponent
        ref={savebarRef}
        widgetType={currentWidgetType}
        setIsLoading={setIsLoading}
        setIsSaving={setIsSaving}
      />
    ) : null;
  }, [WidgetComponent, currentWidgetType]);

  const hasErrors = useMemo(
    () => settingsFormValidity.some((form) => form.isValid === false),
    [settingsFormValidity]
  );

  const disableSaveBtn = useMemo(
    () => isLoading || isSaving || (hasErrors && showValidation),
    [isLoading, isSaving, hasErrors, showValidation]
  );

  const handleValidationError = useCallback(() => {
    dispatch(setShowValidation(true));
    for (let i = 0; i < settingsFormValidity.length; i++) {
      const validity = settingsFormValidity[i];
      if (!validity.isValid) {
        scroller.scrollTo(validity.sectionName, {
          duration: 1000,
          delay: 100,
          smooth: true,
        });
        break;
      }
    }
  }, [scroller, settingsFormValidity, dispatch]);

  const handleUpdateRequestWidgetData = useCallback(() => {
    hasErrors ? handleValidationError() : savebarRef.current?.saveChanges();
  }, [hasErrors, dispatch]);

  const handleDiscard = useCallback(() => {
    savebarRef.current?.discardChanges();
    dispatch(setShowValidation(false));
  }, [dispatch]);

  const onBackActionClick = useCallback(async () => {
    if (!isDebugOrLocal && isUnsavedChanges) {
      await shopify.saveBar.leaveConfirmation();
    }
    navigate('..', { relative: 'path' });
  }, [isDebugOrLocal, isUnsavedChanges, navigate]);

  useEffect(() => {
    if (currentWidgetType && !isAllowed) {
      navigate('..', { replace: true });
    }
  }, [currentWidgetType, isAllowed, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(setShowValidation(false));
      dispatch(setSettingsFormValidity([]));
    };
  }, []);

  if (!isAllowed) return null;

  return (
    <Box width='100%' paddingBlockEnd='2000'>
      {currentWidgetType && (
        <Page
          title={[
            params?.subType && i18n.translate('Essentials') + ':',
            i18n.translate(currentWidgetType),
          ]
            .filter(Boolean)
            .join(' ')}
          backAction={{ onAction: onBackActionClick }}
        >
          {!isDebugOrLocal && (
            <SaveBar id='widgetWrapperSavebar' open={isUnsavedChanges}>
              <button
                // eslint-disable-next-line react/no-unknown-property
                variant='primary'
                onClick={handleUpdateRequestWidgetData}
                disabled={disableSaveBtn}
              />
              <button
                onClick={handleDiscard}
                disabled={isLoading || isSaving}
              />
            </SaveBar>
          )}
          <div className='widgetWrapper'>
            <InlineGrid columns={['twoThirds', 'oneThird']} gap='400'>
              {isLoading && <WidgetWrapperSkeleton />}
              <BlockStack gap='400'>
                {widgetToRender}
                {![
                  WidgetTypeDtoEnum.ESSENTIALS,
                  WidgetTypeDtoEnum.DISCOUNT_LABEL,
                ].includes(currentWidgetType) && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant='primary'
                      onClick={handleUpdateRequestWidgetData}
                      disabled={disableSaveBtn || !isUnsavedChanges}
                      loading={isLoading || isSaving}
                    >
                      {i18n.translate('Save')}
                    </Button>
                  </div>
                )}
              </BlockStack>
              <BlockStack gap='400'>
                {![
                  WidgetTypeDtoEnum.OFFER_RULE_POPUP,
                  WidgetTypeDtoEnum.DISCOUNT_LABEL,
                  WidgetTypeDtoEnum.COUNTDOWN_CLOCK,
                  WidgetTypeDtoEnum.CHECKOUT_LOADING_POPUP,
                ].includes(currentWidgetType) && (
                  <WidgetStatus
                    data={widgetStatusesData}
                    isWidgetControlCenterPage={
                      pageType === ControlPageTypeEnum.WIDGETS_CONTROL_CENTER
                    }
                    widgetStatusesDataIsFetching={widgetStatusesDataIsFetching}
                    refetchWidgetStatusesData={refetchWidgetStatusesData}
                  />
                )}

                {currentWidgetType !== WidgetTypeDtoEnum.ESSENTIALS && (
                  <MediaCard
                    title={i18n.translate('ExploreWidgetStyles')}
                    primaryAction={{
                      content: i18n.translate('ExploreWidgetBtn'),
                      onAction: () => console.log('Explore styles'),
                    }}
                    portrait={width > 1040}
                    description={i18n.translate('ExploreWidgetContent')}
                    size='small'
                  >
                    <div className='placeholder separate'>
                      <Icon source={ImagesIcon} tone='subdued' />
                    </div>
                  </MediaCard>
                )}
              </BlockStack>
            </InlineGrid>
          </div>
        </Page>
      )}
    </Box>
  );
};
