import React, { useCallback, useState } from 'react';
import {
  AlertDto,
  AlertLevelDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { Banner, BlockStack, Card, Link, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { AlertLevelDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import {
  dismissComponent,
  DismissedEnum,
  getDismissedComponents,
} from 'features/dashboard/utils/utils';
import { useSearchParams } from 'react-router-dom';
import { NavPaths } from 'core/enums/NavPathsEnum';
import {
  SettingsModalPathsEnums,
  SettingsModalSubPathsEnums,
} from 'pages/enums/PagesEnums';

type AlertNotificationsProps = {
  alerts?: AlertDto[];
};

export const AlertNotifications: React.FC<AlertNotificationsProps> = (
  props
) => {
  const { alerts } = props;

  const [i18n] = useI18n();

  const [, setSearchParams] = useSearchParams();

  const [dismissedComponents, setDismissedComponents] = useState<string[]>(
    getDismissedComponents(DismissedEnum.ALERT_PAGE_DISMISSED_COMPONENTS)
  );

  const getTone = useCallback((level?: AlertLevelDto) => {
    switch (level) {
      case AlertLevelDtoEnum.WARNING:
        return 'warning';
      case AlertLevelDtoEnum.CRITICAL:
        return 'critical';
      default:
        return 'info';
    }
  }, []);

  const handleDismissComponent = useCallback((componentId: string) => {
    dismissComponent(
      DismissedEnum.ALERT_PAGE_DISMISSED_COMPONENTS,
      componentId
    );
    setDismissedComponents((prev) => [...prev, componentId]);
  }, []);

  const isComponentDismissed = useCallback(
    (componentId?: string | null) => {
      if (!componentId) return false;
      return dismissedComponents.includes(componentId);
    },
    [dismissedComponents]
  );

  const handleActionClick = useCallback(
    (actionType?: string) => {
      switch (actionType) {
        // case 'SETTINGS_CONFIGURE_STRIKETHROUGH_PRICING':
        //   setSearchParams({
        //     modal: NavPaths.SettingsModal,
        //     path: SettingsModalPathsEnums.StrikethroughPricing,
        //   });
        //   break;
        case 'SETTINGS_PLAN_UPGRADE':
          setSearchParams({
            modal: NavPaths.SettingsModal,
            path: SettingsModalPathsEnums.Plan,
            subPath: SettingsModalSubPathsEnums.PickYourPlan,
          });
          break;
        case 'SETTINGS_CHECKOUT_MODE':
          setSearchParams({
            modal: NavPaths.SettingsModal,
            path: SettingsModalPathsEnums.CheckoutOptions,
          });
          break;
        case 'SETTINGS_PLAN_CHECK_BILLING':
          setSearchParams({
            modal: NavPaths.SettingsModal,
            path: SettingsModalPathsEnums.Plan,
          });
          break;
        case 'APP_RELOAD':
          window.location.reload();
          break;
        default:
          break;
      }
    },
    [setSearchParams]
  );

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('description')}
          </Text>
        </BlockStack>
        <div>
          {alerts?.map(
            (alert, idx) =>
              !isComponentDismissed(alert.dismissKey) && (
                <Banner
                  key={idx}
                  tone={getTone(alert.level)}
                  action={
                    alert.actionType
                      ? {
                          content: i18n.translate(alert.actionType),
                          onAction: () =>
                            handleActionClick(alert.actionType as string),
                        }
                      : undefined
                  }
                  onDismiss={
                    alert.dismissKey
                      ? () => handleDismissComponent(alert.dismissKey as string)
                      : undefined
                  }
                >
                  {alert.text}{' '}
                  {alert.url && (
                    <Link url={alert.url}>{i18n.translate('learnMore')}</Link>
                  )}
                </Banner>
              )
          )}
        </div>
      </BlockStack>
    </Card>
  );
};
