import React, { useCallback } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoNullableInt32 } from 'core/api/adminSettings/adminSettingsApi';
import {
  Badge,
  BlockStack,
  Card,
  InlineStack,
  Link,
  Text,
  RangeSlider,
  TextField,
  Collapsible,
  Banner,
  InlineGrid,
} from '@shopify/polaris';
import { Knob } from 'features/widgets/components/Knob/Knob';

type LoadingEffectSettingProps = {
  loadingEffectDuration?: OptionDtoNullableInt32;
  setLoadingEffectDuration: (value: OptionDtoNullableInt32) => void;
};

export const LoadingEffectSetting: React.FC<LoadingEffectSettingProps> = ({
  loadingEffectDuration = { enabled: false, value: 2 },
  setLoadingEffectDuration,
}) => {
  const [i18n] = useI18n();

  const handleToggleEnabled = useCallback(() => {
    setLoadingEffectDuration({
      ...loadingEffectDuration,
      enabled: !loadingEffectDuration.enabled,
    });
  }, [loadingEffectDuration, setLoadingEffectDuration]);

  const handleValueChange = useCallback(
    (newValue: string) => {
      if (newValue === '') {
        setLoadingEffectDuration({ ...loadingEffectDuration, value: null });
        return;
      }

      const numericValue = Number(newValue);
      if (!isNaN(numericValue)) {
        setLoadingEffectDuration({
          ...loadingEffectDuration,
          value: numericValue,
        });
      }
    },
    [loadingEffectDuration, setLoadingEffectDuration]
  );

  const handleBlur = useCallback(() => {
    let value = loadingEffectDuration.value ?? 2;

    if (value < 2) value = 2;
    if (value > 16) value = 16;

    setLoadingEffectDuration({ ...loadingEffectDuration, value });
  }, [loadingEffectDuration, setLoadingEffectDuration]);

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack
          align='space-between'
          blockAlign='center'
          wrap={false}
          gap='1600'
        >
          <BlockStack gap='100'>
            <InlineStack gap='200' align='start'>
              <Text as='p' fontWeight='bold'>
                {i18n.translate('Title')}
              </Text>
              <Badge
                tone={loadingEffectDuration.enabled ? 'success' : undefined}
              >
                {loadingEffectDuration.enabled ? 'On' : 'Off'}
              </Badge>
            </InlineStack>

            <Text as='span' tone='subdued'>
              {i18n.translate('Description', {
                link: (
                  <Link url='http://www.google.com' target='_blank'>
                    {i18n.translate('FollowingArticle')}
                  </Link>
                ),
              })}
            </Text>
          </BlockStack>
          <Knob
            selected={!!loadingEffectDuration.enabled}
            ariaLabel='Knob'
            onClick={handleToggleEnabled}
          />
        </InlineStack>

        <Collapsible
          open={!!loadingEffectDuration.enabled}
          id='LoadingEffect'
          transition={{ animateIn: true }}
        >
          <BlockStack gap='400'>
            <Banner tone='info'>{i18n.translate('FirstBanner')}</Banner>
            <Banner tone='info'>
              {i18n.translate('SecondBanner', {
                link: (
                  <Link url='http://www.google.com' target='_blank'>
                    {i18n.translate('FollowingArticle')}
                  </Link>
                ),
              })}
            </Banner>
            <InlineGrid columns={['oneThird', 'twoThirds']} gap='600'>
              <TextField
                type='number'
                label={i18n.translate('LoadingEffectDuration')}
                helpText={i18n.translate('LoadingEffectDurationHelpText')}
                value={
                  loadingEffectDuration.value !== null
                    ? String(loadingEffectDuration.value)
                    : ''
                }
                onChange={handleValueChange}
                onBlur={handleBlur}
                min={2}
                max={16}
                autoComplete='off'
              />

              <div style={{ margin: 'auto', width: '100%' }}>
                <RangeSlider
                  label=''
                  labelHidden
                  value={loadingEffectDuration.value ?? 2}
                  onChange={(value) => handleValueChange(String(value))}
                  min={2}
                  max={16}
                />
              </div>
            </InlineGrid>
          </BlockStack>
        </Collapsible>
      </BlockStack>
    </Card>
  );
};
