import React from 'react';
import { Card, BlockStack, Text, Banner, Link } from '@shopify/polaris';
import { css as CSS } from '@codemirror/lang-css';
import { useI18n } from '@shopify/react-i18n';
import { CodeEditor } from 'core/components';

type MandatoryCheckboxes = {
  selector?: string;
  setSelector: (value: string) => void;
};

export const MandatoryCheckboxes: React.FC<MandatoryCheckboxes> = ({
  selector,
  setSelector,
}) => {
  const [i18n] = useI18n();

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('MandatoryCheckboxesTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('MandatoryCheckboxesDescription', {
              followingArticle: (
                <Link>{i18n.translate('followingArticle')}</Link>
              ),
            })}
          </Text>
        </BlockStack>
        <BlockStack>
          <Text as='p' variant='bodyMd'>
            {i18n.translate('CSSSelector')}
          </Text>
          <CodeEditor
            value={selector || ''}
            height='120px'
            extensions={[CSS()]}
            onChange={setSelector}
            editable={true}
          />
        </BlockStack>
      </BlockStack>
    </Card>
  );
};
