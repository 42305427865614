import React, { useState } from 'react';
import {
  Banner,
  BlockStack,
  Checkbox,
  MediaCard,
  Modal,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

type EnableDrawerCartModalProps = {
  isOpen: boolean;
  onEnable: () => void;
  onClose: () => void;
};

export const EnableDrawerCartModal: React.FC<EnableDrawerCartModalProps> = ({
  isOpen,
  onClose,
  onEnable,
}) => {
  const [i18n] = useI18n();
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <Modal
      primaryAction={{
        content: i18n.translate('Enable'),
        onAction: onEnable,
        disabled: !checked,
      }}
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        },
      ]}
      open={isOpen}
      onClose={onClose}
      title={i18n.translate('ModalTitle')}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <Text as='p'>
            {i18n.translate('EnableNinjaCart', {
              on: (
                <Text fontWeight='semibold' as='span'>
                  {i18n.translate('DrawerCart')}
                </Text>
              ),
            })}
          </Text>
          <MediaCard
            title={i18n.translate('MediaCardTitle')}
            primaryAction={{
              content: i18n.translate('LearnMore'),
            }}
            description={i18n.translate('MediaCardDescription')}
            size='small'
          >
            <img
              alt=''
              width='100%'
              height='100%'
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              src='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
            />
          </MediaCard>
          <Banner tone='warning'>{i18n.translate('BannerInfo')}</Banner>
          <Checkbox
            label={i18n.translate('CheckboxLabel')}
            checked={checked}
            onChange={setChecked}
          />
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
