import React, { useCallback, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { Icon, MediaCard, VideoThumbnail } from '@shopify/polaris';
import { LearnMoreModal } from 'features/promotions/components/SelectedOfferTypeSettings/components/LearnMoreModal/LearnMoreModal';
import { XIcon } from '@shopify/polaris-icons';
import { getVideoThumbnail } from 'core/utils/getThumbnail';

type DiscountNinjaTutorialProps = {
  tutorialVideoUrl?: string;
  noPopoverActions?: boolean;
  isDismissable?: boolean;
  onDismiss?: () => void;
  content?: {
    title: string;
    button: string;
    description: string;
  };
};

export const DiscountNinjaTutorial: React.FC<DiscountNinjaTutorialProps> = (
  props
) => {
  const {
    tutorialVideoUrl,
    isDismissable,
    noPopoverActions,
    content,
    onDismiss,
  } = props;

  const [i18n] = useI18n();

  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const thumbnailUrl = getVideoThumbnail(
    tutorialVideoUrl ||
      'https://www.loom.com/share/cd98f3811dcf418aa0d2104b3c567421'
  );

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState) => !prevState);
  }, [setShowHowModal]);

  return (
    <>
      <MediaCard
        title={content?.title || i18n.translate('title')}
        primaryAction={{
          content: content?.button || i18n.translate('button'),
          onAction: toggleShowHowModal,
        }}
        description={content?.description || i18n.translate('description')}
        popoverActions={
          !noPopoverActions
            ? [
                {
                  content: i18n.translate('dismiss'),
                  onAction: onDismiss,
                  disabled: !isDismissable,
                  prefix: (
                    <div
                      style={{
                        height: '1rem',
                        width: '1rem',
                        paddingTop: '.05rem',
                      }}
                    >
                      <Icon tone='subdued' source={XIcon} />
                    </div>
                  ),
                },
              ]
            : undefined
        }
        size='small'
      >
        <VideoThumbnail
          videoLength={duration}
          videoProgress={currentTime}
          showVideoProgress={!!duration}
          thumbnailUrl={thumbnailUrl}
          onClick={toggleShowHowModal}
        />
      </MediaCard>
      {showHowModal && (
        <LearnMoreModal
          onModalClose={toggleShowHowModal}
          url={tutorialVideoUrl || ''}
          title={i18n.translate('tutorial')}
          setDuration={setDuration}
          setCurrentTime={setCurrentTime}
        />
      )}
    </>
  );
};
