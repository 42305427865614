import React, { useCallback, useRef, useState } from 'react';
import { BlockStack, Box, ButtonGroup, Page } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import '@shopify/polaris-viz/build/esm/styles.css';
import {
  AnalyticsBarChart,
  AnalyticsConversionChart,
  AnalyticsLineChart,
} from './components/Charts';
import {
  AnalyticsQueryComparisonPeriodDto,
  useGetAnalyticsV6OverviewQuery,
} from 'core/api/adminAnalytics/adminAnalyticsApi';
import {
  AnalyticsReportList,
  AnalyticsReportListRef,
} from './components/Charts/AnalyticsReportList/AnalyticsReportList';
import { ChartuUnitOfMeasurementEnum, ReportListTypeEnum } from 'pages';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { PeriodSelector } from './components/PeriodSelector/PeriodSelector';
import { AnalyticsPageSubPathsEnums } from 'pages/enums/PagesEnums';
import './Analytics.scss';
import { setAnalyticsActivitySort } from 'core/store/settingsSlice';
import { PromotionActivityAnalyticsSortFieldDtoEnum } from 'core/api/adminAnalytics/adminAnalyticsEnums';
const APP_NAME = process.env.REACT_APP_APP_NAME || '';

export const Analytics = () => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const [i18n] = useI18n();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const orderListRef = useRef<AnalyticsReportListRef>(null);
  const codesListRef = useRef<AnalyticsReportListRef>(null);
  const activityListRef = useRef<AnalyticsReportListRef>(null);
  const { analyticsPeriod } = useAppSelector((store) => store.settings);
  //Develop in next iteration
  const [comparisonPeriod, setComparisonPeriod] = useState<
    AnalyticsQueryComparisonPeriodDto | undefined
  >(undefined);

  const {
    data: analyticsOverviewData,
    isFetching: analyticsOverviewIsFetching,
    refetch: refetchAnalyticsOverview,
  } = useGetAnalyticsV6OverviewQuery(
    {
      comparisonPeriod: comparisonPeriod,
      period: analyticsPeriod,
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleRefreshAnalytics = useCallback(() => {
    orderListRef.current?.refresh();
    codesListRef.current?.refresh();
    activityListRef.current?.refresh();
    refetchAnalyticsOverview();
  }, [orderListRef.current, codesListRef.current, activityListRef.current]);

  return (
    <Page
      fullWidth
      title={i18n.translate(`Analytics`)}
      secondaryActions={[
        {
          content: i18n.translate(`Refresh`),
          onAction: handleRefreshAnalytics,
        },
      ]}
    >
      <Box paddingBlockEnd='500'>
        <BlockStack gap='400'>
          <ButtonGroup>
            {/*Develop in next iteration */}
            {/* <Button>Compare to: Jun1, 2022-May 31,2023</Button> */}
            <PeriodSelector />
          </ButtonGroup>
          <div className='AnalyticsChatsBox'>
            <AnalyticsLineChart
              analyticsData={analyticsOverviewData?.totalRevenue || {}}
              title={i18n.translate('TotalDNRevenue')}
              tooltip={i18n.translate('TotalRevenueTooltip')}
              onNavigate={() =>
                navigate(AnalyticsPageSubPathsEnums.TotalDnRevenue)
              }
              isLoading={analyticsOverviewIsFetching}
              unit={ChartuUnitOfMeasurementEnum.CURRENCY}
            />
            <AnalyticsBarChart
              title={i18n.translate('RevenueByPromotion')}
              analyticsData={analyticsOverviewData?.revenueByPromotion || {}}
              unit={ChartuUnitOfMeasurementEnum.CURRENCY}
              tooltip={i18n.translate('RevenueByPromotionTooltip')}
              onNavigate={() => {
                navigate(AnalyticsPageSubPathsEnums.PromotionActivityReport);
                dispatch(
                  setAnalyticsActivitySort(
                    PromotionActivityAnalyticsSortFieldDtoEnum.REVENUE
                  )
                );
              }}
              isLoading={analyticsOverviewIsFetching}
            />
            <AnalyticsLineChart
              analyticsData={analyticsOverviewData?.totalOrders || {}}
              title={i18n.translate('TotalDNOrders')}
              tooltip={i18n.translate('TotalDNOrdersTooltip')}
              onNavigate={() =>
                navigate(AnalyticsPageSubPathsEnums.TotalDnOrders)
              }
              isLoading={analyticsOverviewIsFetching}
              unit={ChartuUnitOfMeasurementEnum.NUMBER}
            />
            <AnalyticsConversionChart
              title={i18n.translate('DNConversionRate')}
              isLoading={analyticsOverviewIsFetching}
              analyticsData={analyticsOverviewData?.conversionRate || {}}
              tooltip={i18n.translate('ConversionRateTooltip')}
              onNavigate={() =>
                navigate(AnalyticsPageSubPathsEnums.DnConversionRate)
              }
            />
            <AnalyticsBarChart
              analyticsData={analyticsOverviewData?.ordersByPromotion || {}}
              title={i18n.translate('OrdersByPromotion')}
              tooltip={i18n.translate('OrdersByPromotionTooltip')}
              onNavigate={() => {
                navigate(AnalyticsPageSubPathsEnums.PromotionActivityReport);
                dispatch(
                  setAnalyticsActivitySort(
                    PromotionActivityAnalyticsSortFieldDtoEnum.ORDERS
                  )
                );
              }}
              unit={ChartuUnitOfMeasurementEnum.NUMBER}
              isLoading={analyticsOverviewIsFetching}
            />
            <AnalyticsLineChart
              analyticsData={analyticsOverviewData?.sessions || {}}
              title={i18n.translate('DNSessions')}
              tooltip={i18n.translate('DNSessionsTooltip')}
              onNavigate={() => navigate(AnalyticsPageSubPathsEnums.DnSessions)}
              isLoading={analyticsOverviewIsFetching}
              unit={ChartuUnitOfMeasurementEnum.NUMBER}
            />
          </div>
          <AnalyticsReportList
            listType={ReportListTypeEnum.PROMOTION_ACTIVITY}
            ref={activityListRef}
            simplified
          />
          <AnalyticsReportList
            listType={ReportListTypeEnum.DETAILED_ORDER}
            simplified
            ref={orderListRef}
            tooltip={i18n.translate('DetailedOrderTooltip')}
          />
          <AnalyticsReportList
            listType={ReportListTypeEnum.DETAILED_CODE}
            simplified
            ref={codesListRef}
          />
        </BlockStack>
      </Box>
    </Page>
  );
};
