import React from 'react';
import {
  Card,
  BlockStack,
  Checkbox,
  Text,
  Banner,
  Link,
} from '@shopify/polaris';
import { StructuredDataIntegrationsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';

type StructuredDataProps = {
  structuredData?: StructuredDataIntegrationsSettingsDto;
  setStructuredData: (value: StructuredDataIntegrationsSettingsDto) => void;
};

export const StructuredData: React.FC<StructuredDataProps> = ({
  structuredData,
  setStructuredData,
}) => {
  const [i18n] = useI18n();
  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('StructuredData')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('StructuredDataSubtitle')}
          </Text>
        </BlockStack>
        <Banner>
          {i18n.translate('StructuredDataBanner', {
            following: <Link>{i18n.translate('FollowingArticle')}</Link>,
          })}
        </Banner>
        <Checkbox
          label={i18n.translate('CheckboxLabel')}
          helpText={i18n.translate('CheckboxHelp')}
          checked={structuredData?.updateDynamically}
          onChange={(value) => setStructuredData({ updateDynamically: value })}
        />
      </BlockStack>
    </Card>
  );
};
