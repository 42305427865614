import {
  AnnouncementBarShopSettingsDto,
  NotificationShopSettingsDto,
  ProductBannerShopSettingsDto,
  PromotionalBadgeShopSettingsDto,
  PopupShopSettingsDto,
  useGetSettingsV6ThemesWidgetSettingsAnnouncementBarQuery,
  useGetSettingsV6ThemesWidgetSettingsOfferRulePopupQuery,
  useGetSettingsV6ThemesWidgetSettingsNotificationQuery,
  useGetSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupQuery,
  useGetSettingsV6ThemesWidgetSettingsCountdownClockQuery,
  useGetSettingsV6ThemesWidgetSettingsStrikethroughPricingCustomQuery,
  useGetSettingsV6ThemesWidgetSettingsStrikethroughPricingProductQuery,
  useGetSettingsV6ThemesWidgetSettingsEssentialsQuery,
  usePutSettingsV6ThemesWidgetSettingsStrikethroughPricingCustomMutation,
  usePutSettingsV6ThemesWidgetSettingsOfferRulePopupMutation,
  usePutSettingsV6ThemesWidgetSettingsStrikethroughPricingProductMutation,
  useGetSettingsV6ThemesWidgetSettingsPopupQuery,
  usePutSettingsV6ThemesWidgetSettingsPopupMutation,
  useGetSettingsV6ThemesWidgetSettingsProductBannerQuery,
  useGetSettingsV6ThemesWidgetSettingsPromotionalBadgeQuery,
  useGetSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherQuery,
  usePutSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupMutation,
  usePutSettingsV6ThemesWidgetSettingsAnnouncementBarMutation,
  usePutSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherMutation,
  usePutSettingsV6ThemesWidgetSettingsNotificationMutation,
  usePutSettingsV6ThemesWidgetSettingsProductBannerMutation,
  usePutSettingsV6ThemesWidgetSettingsCountdownClockMutation,
  usePutSettingsV6ThemesWidgetSettingsPromotionalBadgeMutation,
  useGetSettingsV6ThemesWidgetSettingsPromotionCodeFieldQuery,
  usePutSettingsV6ThemesWidgetSettingsPromotionCodeFieldMutation,
  PromotionCodeFieldShopSettingsDto,
  useGetSettingsV6ThemesWidgetSettingsPromotionSummaryQuery,
  usePutSettingsV6ThemesWidgetSettingsPromotionSummaryMutation,
  PromotionSummaryShopSettingsDto,
  useGetSettingsV6ThemesWidgetSettingsCartTextQuery,
  FrequentBoughtTogetherShopSettingsDto,
  useGetSettingsV6ThemesWidgetSettingsVolumeDiscountQuery,
  CustomStrikethroughPricingShopSettingsDto,
  ProductStrikethroughPricingShopSettingsDto,
  OfferRulePopupShopSettingsDto,
  CheckoutLoadingPopupShopSettingsDto,
  CountdownClockShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import resolveEnvVar from 'env-var-resolver';
import { useCallback, useMemo } from 'react';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

export const useWidget = (widgetType?: WidgetTypeDtoEnum) => {
  const emptyWidgetSettingsQuery = useMemo(() => {
    switch (widgetType) {
      case WidgetTypeDtoEnum.CART_TEXT:
        return useGetSettingsV6ThemesWidgetSettingsCartTextQuery;
      case WidgetTypeDtoEnum.VOLUME_DISCOUNT:
        return useGetSettingsV6ThemesWidgetSettingsVolumeDiscountQuery;
      default:
        return useGetSettingsV6ThemesWidgetSettingsCartTextQuery;
    }
  }, [widgetType]);

  const shouldFetchEmptyWidgetData = useMemo(
    () =>
      widgetType &&
      [WidgetTypeDtoEnum.CART_TEXT, WidgetTypeDtoEnum.VOLUME_DISCOUNT].includes(
        widgetType
      ),
    [widgetType]
  );

  const {
    data: emptyWidgetSettingsData,
    isFetching: emptyWidgetSettingsDataIsFetching,
    refetch: refetchEmptyWidgetSettingsData,
  } = emptyWidgetSettingsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !shouldFetchEmptyWidgetData,
    }
  );

  const {
    data: checkoutLoadingPopupData,
    isFetching: checkoutLoadingPopupDataIsFetching,
    refetch: refetchCheckoutLoadingPopupData,
  } = useGetSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.CHECKOUT_LOADING_POPUP,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: countdownClockData,
    isFetching: countdownClockDataIsFetching,
    refetch: refetchCountdownClockData,
  } = useGetSettingsV6ThemesWidgetSettingsCountdownClockQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.COUNTDOWN_CLOCK,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: announcementBarData,
    isFetching: announcementBarDataIsFetching,
    refetch: refetchAnnouncementBarData,
  } = useGetSettingsV6ThemesWidgetSettingsAnnouncementBarQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.ANNOUNCEMENT_BAR,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: offerRulePopupData,
    isFetching: offerRulePopupDataIsFetching,
    refetch: refetchOfferRulePopupData,
  } = useGetSettingsV6ThemesWidgetSettingsOfferRulePopupQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.OFFER_RULE_POPUP,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: essentialsData,
    isFetching: essentialsDataIsFetching,
    refetch: refetchEssentialsData,
  } = useGetSettingsV6ThemesWidgetSettingsEssentialsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.ESSENTIALS,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: customStrikeThroughPricingData,
    isFetching: customStrikeThroughPricingDataIsFetching,
    refetch: refetchCustomStrikeThroughPricingData,
  } = useGetSettingsV6ThemesWidgetSettingsStrikethroughPricingCustomQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.CUSTOM_STRIKETHROUGH_PRICING,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: productStrikeThroughPricingData,
    isFetching: productStrikeThroughPricingDataIsFetching,
    refetch: refetchProductStrikeThroughPricingData,
  } = useGetSettingsV6ThemesWidgetSettingsStrikethroughPricingProductQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.PRODUCT_STRIKETHROUGH_PRICING,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: promotionalBadgeData,
    isFetching: promotionalBadgeDataIsFetching,
    refetch: refetchPromotionalBadgeData,
  } = useGetSettingsV6ThemesWidgetSettingsPromotionalBadgeQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.PROMOTIONAL_BADGE,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: promotionSummaryData,
    isFetching: promotionSummaryDataIsFetching,
    refetch: refetchPromotionSummaryData,
  } = useGetSettingsV6ThemesWidgetSettingsPromotionSummaryQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.PROMOTION_SUMMARY,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: frequentlyBoughtTogetherData,
    isFetching: frequentlyBoughtTogetherDataIsFetching,
    refetch: refetchFrequentlyBoughtTogetherData,
  } = useGetSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: promotionCodeFieldData,
    isFetching: promotionCodeFieldDataIsFetching,
    refetch: refetchPromotionCodeFieldData,
  } = useGetSettingsV6ThemesWidgetSettingsPromotionCodeFieldQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.PROMOTION_CODE_FIELD,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: popupData,
    isFetching: popupDataIsFetching,
    refetch: refetchPopupData,
  } = useGetSettingsV6ThemesWidgetSettingsPopupQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.POP_UP,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: productBannerData,
    isFetching: productBannerDataIsFetching,
    refetch: refetchProductBannerData,
  } = useGetSettingsV6ThemesWidgetSettingsProductBannerQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.PRODUCT_BANNER,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: notificationData,
    isFetching: notificationDataIsFetching,
    refetch: refetchNotificationData,
  } = useGetSettingsV6ThemesWidgetSettingsNotificationQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: widgetType !== WidgetTypeDtoEnum.NOTIFICATIONS,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    saveOfferRulePopupMutation,
    { isLoading: saveOfferRulePopupIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsOfferRulePopupMutation();

  const saveOfferRulePopup = useCallback(
    async (
      data: OfferRulePopupShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveOfferRulePopupMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveOfferRulePopupMutation]
  );

  const [
    saveProductStrikeThroughPricingMutation,
    { isLoading: saveProductStrikeThroughPricingIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsStrikethroughPricingProductMutation();

  const saveProductStrikeThroughPricing = useCallback(
    async (
      data: ProductStrikethroughPricingShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveProductStrikeThroughPricingMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveProductStrikeThroughPricingMutation]
  );

  const [
    saveCountdownClockMutation,
    { isLoading: saveCountdownClockIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsCountdownClockMutation();

  const saveCountdownClock = useCallback(
    async (
      data: CountdownClockShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveCountdownClockMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveCountdownClockMutation]
  );

  const [
    saveCustomStrikeThroughPricingMutation,
    { isLoading: saveCustomStrikeThroughPricingIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsStrikethroughPricingCustomMutation();

  const saveCustomStrikeThroughPricing = useCallback(
    async (
      data: CustomStrikethroughPricingShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveCustomStrikeThroughPricingMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveCustomStrikeThroughPricingMutation]
  );

  const [
    saveFrequentlyBoughtTogetherMutation,
    { isLoading: saveFrequentlyBoughtTogetherIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsFrequentBoughtTogetherMutation();

  const saveFrequentlyBoughtTogether = useCallback(
    async (
      data: FrequentBoughtTogetherShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveFrequentlyBoughtTogetherMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveFrequentlyBoughtTogetherMutation]
  );

  const [
    savePromotionCodeFieldMutation,
    { isLoading: savePromotionCodeFieldIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsPromotionCodeFieldMutation();

  const savePromotionCodeField = useCallback(
    async (
      data: PromotionCodeFieldShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await savePromotionCodeFieldMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [savePromotionCodeFieldMutation]
  );

  const [
    saveCheckoutLoadingPopupMutation,
    { isLoading: saveCheckoutLoadingPopupIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsCheckoutLoadingPopupMutation();

  const saveCheckoutLoadingPopup = useCallback(
    async (
      data: CheckoutLoadingPopupShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveCheckoutLoadingPopupMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveCheckoutLoadingPopupMutation]
  );

  const [savePopupMutation, { isLoading: savePopupIsLoading }] =
    usePutSettingsV6ThemesWidgetSettingsPopupMutation();

  const savePopup = useCallback(
    async (data: PopupShopSettingsDto | null, onSuccess: () => void) => {
      data &&
        (await savePopupMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [savePopupMutation]
  );

  const [
    savePromotionalBadgeMutation,
    { isLoading: savePromotionalBadgeIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsPromotionalBadgeMutation();

  const savePromotionalBadge = useCallback(
    async (
      data: PromotionalBadgeShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await savePromotionalBadgeMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [savePromotionalBadgeMutation]
  );

  const [saveProductBannerMutation, { isLoading: saveProductBannerIsLoading }] =
    usePutSettingsV6ThemesWidgetSettingsProductBannerMutation();

  const saveProductBanner = useCallback(
    async (
      data: ProductBannerShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveProductBannerMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveProductBannerMutation]
  );

  const [saveNotificationMutation, { isLoading: saveNotificationIsLoading }] =
    usePutSettingsV6ThemesWidgetSettingsNotificationMutation();

  const saveNotification = useCallback(
    async (data: NotificationShopSettingsDto | null, onSuccess: () => void) => {
      data &&
        (await saveNotificationMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveNotificationMutation]
  );

  const [
    saveAnnouncementBarMutation,
    { isLoading: saveAnnouncementBarIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsAnnouncementBarMutation();

  const saveAnnouncementBar = useCallback(
    async (
      data: AnnouncementBarShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await saveAnnouncementBarMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [saveAnnouncementBarMutation]
  );

  const [
    savePromotionSummaryMutation,
    { isLoading: savePromotionSummaryIsLoading },
  ] = usePutSettingsV6ThemesWidgetSettingsPromotionSummaryMutation();
  const savePromotionSummary = useCallback(
    async (
      data: PromotionSummaryShopSettingsDto | null,
      onSuccess: () => void
    ) => {
      data &&
        (await savePromotionSummaryMutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          body: data,
        })
          .unwrap()
          .then(onSuccess));
    },
    [savePromotionSummaryMutation]
  );

  return {
    notificationData,
    notificationDataIsFetching,
    announcementBarData,
    announcementBarDataIsFetching,
    saveAnnouncementBarIsLoading,
    saveNotificationIsLoading,
    productBannerData,
    productBannerDataIsFetching,
    saveProductBannerIsLoading,
    promotionalBadgeData,
    promotionalBadgeDataIsFetching,
    savePromotionalBadgeIsLoading,
    popupData,
    popupDataIsFetching,
    savePopupIsLoading,
    promotionCodeFieldData,
    promotionCodeFieldDataIsFetching,
    savePromotionCodeFieldIsLoading,
    savePromotionSummaryIsLoading,
    promotionSummaryData,
    promotionSummaryDataIsFetching,
    emptyWidgetSettingsData,
    emptyWidgetSettingsDataIsFetching,
    frequentlyBoughtTogetherData,
    frequentlyBoughtTogetherDataIsFetching,
    saveFrequentlyBoughtTogetherIsLoading,
    customStrikeThroughPricingData,
    customStrikeThroughPricingDataIsFetching,
    saveCustomStrikeThroughPricingIsLoading,
    productStrikeThroughPricingData,
    productStrikeThroughPricingDataIsFetching,
    saveProductStrikeThroughPricingIsLoading,
    essentialsData,
    essentialsDataIsFetching,
    offerRulePopupData,
    offerRulePopupDataIsFetching,
    saveOfferRulePopupIsLoading,
    checkoutLoadingPopupData,
    checkoutLoadingPopupDataIsFetching,
    saveCheckoutLoadingPopupIsLoading,
    countdownClockData,
    countdownClockDataIsFetching,
    saveCountdownClockIsLoading,
    saveCountdownClock,
    refetchCountdownClockData,
    saveCheckoutLoadingPopup,
    refetchCheckoutLoadingPopupData,
    saveOfferRulePopup,
    refetchOfferRulePopupData,
    refetchEssentialsData,
    saveProductStrikeThroughPricing,
    refetchProductStrikeThroughPricingData,
    saveCustomStrikeThroughPricing,
    refetchCustomStrikeThroughPricingData,
    refetchEmptyWidgetSettingsData,
    saveFrequentlyBoughtTogether,
    refetchFrequentlyBoughtTogetherData,
    refetchPromotionSummaryData,
    savePromotionSummary,
    refetchPromotionCodeFieldData,
    savePromotionCodeField,
    savePopup,
    refetchPopupData,
    savePromotionalBadge,
    refetchPromotionalBadgeData,
    saveProductBanner,
    refetchProductBannerData,
    saveNotification,
    saveAnnouncementBar,
    refetchAnnouncementBarData,
    refetchNotificationData,
  };
};
