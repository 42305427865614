import React, { useCallback, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { TriggerPurchaseHistoryFilterDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  BlockStack,
  Box,
  ChoiceList,
  InlineStack,
  TextField,
  Text,
} from '@shopify/polaris';
import { TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

type PurchaseHistoryFilterProps = {
  purchaseHistory?: TriggerPurchaseHistoryFilterDto;
  updatePurchaseHistory: (data: TriggerPurchaseHistoryFilterDto) => void;
};

export const PurchaseHistoryFilter: React.FC<PurchaseHistoryFilterProps> = ({
  updatePurchaseHistory,
  purchaseHistory,
}) => {
  const [i18n] = useI18n();

  const purchaseType = purchaseHistory?.type;

  const showMinOrdersField =
    purchaseType === TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.BETWEEN ||
    purchaseType === TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.AT_LEAST;

  const showMaxOrdersField =
    purchaseType === TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.BETWEEN ||
    purchaseType === TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.AT_MOST;

  const orderPlacedOptions = useMemo(
    () =>
      Object.values(TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum).map(
        (value) => ({
          label: i18n.translate(value),
          value: value,
        })
      ),
    [i18n]
  );

  const validateOnBlur = useCallback(
    (field: 'to' | 'from') =>
      updatePurchaseHistory({
        ...purchaseHistory,
        [field]:
          (purchaseHistory?.[field] as number) < 1
            ? 1
            : purchaseHistory?.[field],
      }),
    [purchaseHistory, updatePurchaseHistory]
  );

  return (
    <Box
      padding='400'
      borderColor='border'
      borderWidth='025'
      borderRadius='200'
    >
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text fontWeight='semibold' as='p'>
            {i18n.translate('OrdersPlaced')}
          </Text>
          <Text tone='subdued' as='p'>
            {i18n.translate('OrdersPlacedSubtitle')}
          </Text>
        </BlockStack>
        <ChoiceList
          title=''
          selected={[purchaseHistory?.type as string]}
          choices={orderPlacedOptions}
          onChange={(value: TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum[]) =>
            updatePurchaseHistory({ ...purchaseHistory, type: value[0] })
          }
        />

        {purchaseType !== TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.ANY && (
          <Box width='320px'>
            <InlineStack wrap={false} gap='500'>
              {showMinOrdersField && (
                <TextField
                  label={i18n.translate(
                    purchaseType ===
                      TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.BETWEEN
                      ? 'MinimumOrders'
                      : 'OrdersPlaced'
                  )}
                  type='integer'
                  autoComplete=''
                  value={(purchaseHistory?.from || 1).toString()}
                  onChange={(value) =>
                    updatePurchaseHistory({ ...purchaseHistory, from: +value })
                  }
                  onBlur={() => validateOnBlur('from')}
                />
              )}
              {showMaxOrdersField && (
                <TextField
                  label={i18n.translate(
                    purchaseType ===
                      TriggerPurchaseHistoryOrdersPlacedTypeDtoEnum.BETWEEN
                      ? 'MaximumOrders'
                      : 'OrdersPlaced'
                  )}
                  type='integer'
                  autoComplete=''
                  value={(purchaseHistory?.to || 1).toString()}
                  onChange={(value) =>
                    updatePurchaseHistory({ ...purchaseHistory, to: +value })
                  }
                  onBlur={() => validateOnBlur('to')}
                />
              )}
            </InlineStack>
          </Box>
        )}
      </BlockStack>
    </Box>
  );
};
