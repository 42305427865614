import React, { useMemo } from 'react';
import {
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  SkeletonBodyText,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { SearchResourceIcon, InfoIcon } from '@shopify/polaris-icons';
import { ConversionRateDataOverviewDtoRead } from 'core/api/adminAnalytics/adminAnalyticsApi';
import { useI18n } from '@shopify/react-i18n';
import { TrendIndicator } from 'core/components';
import { formatNumber } from 'core/utils';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
import { ChartuUnitOfMeasurementEnum } from 'pages';
type AnalyticsConversionChartProps = {
  analyticsData: ConversionRateDataOverviewDtoRead;
  title: string;
  isLoading?: boolean;
  tooltip?: string;
  onNavigate?: () => void;
};
export const AnalyticsConversionChart: React.FC<
  AnalyticsConversionChartProps
> = ({ title, tooltip, onNavigate, analyticsData, isLoading }) => {
  const [i18n] = useI18n();

  const { formatValue } = useNumericFormatter();

  const dataSeries = useMemo(
    () => [
      { ...analyticsData.addToCart, name: i18n.translate('AddToCart') },
      {
        ...analyticsData.checkoutInitiated,
        name: i18n.translate('CheckoutInitiated'),
      },
      { ...analyticsData.sessions, name: i18n.translate('SessionsConverted') },
    ],
    [
      analyticsData.addToCart,
      analyticsData.checkoutInitiated,
      analyticsData.sessions,
      i18n,
    ]
  );

  const loadingChartState = useMemo(
    () => (
      <Box paddingBlockEnd='1200' paddingBlockStart='300'>
        <BlockStack gap='1000'>
          <Box width={'100px'} minHeight='22px'>
            <SkeletonBodyText lines={1} />
          </Box>
          <SkeletonBodyText lines={2} />
          <SkeletonBodyText lines={2} />
          <SkeletonBodyText lines={2} />
        </BlockStack>
      </Box>
    ),
    []
  );

  return (
    <Card>
      <BlockStack gap='300'>
        <InlineStack align='space-between' blockAlign='center'>
          <InlineStack gap='200'>
            <Text tone='base' as='h1' fontWeight='medium'>
              {title}
            </Text>
            {tooltip && (
              <Tooltip content={tooltip}>
                <Icon tone='base' source={InfoIcon} />
              </Tooltip>
            )}
          </InlineStack>
          {onNavigate && (
            <Button
              onClick={onNavigate}
              icon={SearchResourceIcon}
              variant='tertiary'
            />
          )}
        </InlineStack>
        {!isLoading ? (
          <>
            <InlineStack blockAlign='center' gap='200'>
              <Text
                variant='headingLg'
                tone='base'
                as='h1'
                fontWeight='semibold'
              >
                {formatValue(
                  analyticsData.total,
                  ChartuUnitOfMeasurementEnum.PERCENTAGE
                )}
              </Text>
              {analyticsData.comparisonValueTotal !== null && (
                <TrendIndicator
                  value={analyticsData.comparisonValueTotal as number}
                />
              )}
            </InlineStack>
            <Box paddingBlockStart='100' paddingBlockEnd='400'>
              <Bleed marginInline='400'>
                {dataSeries.map((data, index) => (
                  <Box
                    padding='400'
                    key={data.name}
                    borderColor='border-brand'
                    borderBlockEndWidth={
                      dataSeries.length === index + 1 ? '0' : '025'
                    }
                  >
                    <InlineStack
                      align='space-between'
                      blockAlign='center'
                      gap='500'
                    >
                      <BlockStack gap='100'>
                        <Text as='p'>{data.name}</Text>
                        <Text as='p' tone='subdued'>
                          {i18n.translate('Sessions', {
                            count: formatNumber(i18n, data.sessionsCount),
                          })}
                        </Text>
                      </BlockStack>
                      <InlineStack gap='500'>
                        <Box width='100px'>
                          <Text alignment='end' as='p'>
                            {formatValue(
                              data.conversionRate,
                              ChartuUnitOfMeasurementEnum.PERCENTAGE
                            )}
                          </Text>
                        </Box>
                        {!!data.trendValue && (
                          <Box width='100px'>
                            <InlineStack align='end'>
                              <TrendIndicator
                                value={data.trendValue as number}
                              />
                            </InlineStack>
                          </Box>
                        )}
                      </InlineStack>
                    </InlineStack>
                  </Box>
                ))}
              </Bleed>
            </Box>
          </>
        ) : (
          loadingChartState
        )}
      </BlockStack>
    </Card>
  );
};
