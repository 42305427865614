import resolveEnvVar from 'env-var-resolver';
import {
  InstallationSupportRequestDto,
  InstallWidgetRequestDto,
  SaveThemeSlotsRequestDto,
  ThemeStatusesDto,
  ThemeTypeDto,
  useDeleteSettingsV6WidgetInstallationSupportRequestMutation,
  useGetSettingsV6LookupPageTemplatesByThemeTypeAndWidgetTypeQuery,
  useGetSettingsV6ThemesByThemeTypeNinjaCartWidgetInstallationQuery,
  useGetSettingsV6LookupThemesQuery,
  useGetSettingsV6ThemesByThemeTypeWidgetInstallationQuery,
  useGetSettingsV6ThemesStatusQuery,
  useGetSettingsV6WidgetInstallationSupportRequestQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetInstallationInstallMutation,
  usePutSettingsV6ThemesMutation,
  usePutSettingsV6WidgetInstallationSupportRequestMutation,
  WidgetsInstallationStatusSettingsDto,
  useGetSettingsV6WidgetInstallationByWidgetTypeQuery,
  NinjaCartWidgetsInstallationStatusSettingsDto,
  usePutSettingsV6ThemesByThemeTypeNinjaCartWidgetInstallationInstallMutation,
  usePutSettingsV6ThemesByThemeTypeNinjaCartWidgetInstallationInstallCartPageMutation,
  InstallNinjaCartCartPageRequestDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './useRedux';
import { WidgetsControlCenterModalsEnum } from 'features/widgets/enums/WidgetsControlCenterModalsType';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { setToastMessages } from 'core/store/offersWizardSlice';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

export const useWidgetsControlCenter = (
  modal?: WidgetsControlCenterModalsEnum,
  forceSkip?: boolean,
  widgetType?: WidgetTypeDtoEnum
) => {
  const { widgetThemeType } = useAppSelector((store) => store.settings);

  const dispatch = useAppDispatch();

  const [
    widgetInstallationDataIsFetching,
    setWidgetInstallationDataIsFetching,
  ] = useState<boolean>(false);
  const [widgetInstallationData, setWidgetInstallationData] =
    useState<WidgetsInstallationStatusSettingsDto | null>(null);

  const [
    ninjaCartWidgetInstallationDataIsFetching,
    setNinjaCartWidgetInstallationDataIsFetching,
  ] = useState<boolean>(false);
  const [ninjaCartWidgetInstallationData, setNinjaCartWidgetInstallationData] =
    useState<NinjaCartWidgetsInstallationStatusSettingsDto | null>(null);

  const {
    data: themeSlotsData,
    isFetching: themeSlotsDataIsFetching,
    refetch: refetchThemeSlotsData,
  } = useGetSettingsV6ThemesStatusQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        (modal &&
          ![
            WidgetsControlCenterModalsEnum.WIDGETS_CONTROL_CENTER_MAIN_PAGE,
            WidgetsControlCenterModalsEnum.NINJA_CART_CONTROL_CENTER_MAIN_PAGE,
          ].includes(modal)) ||
        forceSkip,
    }
  );

  const {
    data: themeSlotsBreakCacheData,
    refetch: refetchThemeSlotsBreakCacheData,
  } = useGetSettingsV6ThemesStatusQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      'Cache-Control': 'no-cache',
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        (modal &&
          ![
            WidgetsControlCenterModalsEnum.WIDGETS_CONTROL_CENTER_MAIN_PAGE,
            WidgetsControlCenterModalsEnum.NINJA_CART_CONTROL_CENTER_MAIN_PAGE,
          ].includes(modal)) ||
        forceSkip ||
        !themeSlotsData ||
        (!widgetInstallationData && !ninjaCartWidgetInstallationData),
    }
  );

  const {
    data: widgetStatusesData,
    isFetching: widgetStatusesDataIsFetching,
    refetch: refetchWidgetStatusesData,
  } = useGetSettingsV6WidgetInstallationByWidgetTypeQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      widgetType: widgetType || WidgetTypeDtoEnum.ANNOUNCEMENT_BAR,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: modal !== WidgetsControlCenterModalsEnum.NON_MODAL || !widgetType,
    }
  );

  const {
    data: pageTemplatesData,
    isFetching: pageTemplatesDataIsFetching,
    refetch: refetchPageTemplatesData,
  } = useGetSettingsV6LookupPageTemplatesByThemeTypeAndWidgetTypeQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      widgetType: widgetType || WidgetTypeDtoEnum.ANNOUNCEMENT_BAR,
      themeType: widgetThemeType,
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        modal !== WidgetsControlCenterModalsEnum.ENABLE_APP_BLOCK_MODAL ||
        !widgetType,
    }
  );

  const [saveThemeSlotsMutation, { isLoading: saveThemeSlotsIsLoading }] =
    usePutSettingsV6ThemesMutation();

  const saveThemeSlots = useCallback(
    async (
      data: SaveThemeSlotsRequestDto,
      updateStates: (data: ThemeStatusesDto[]) => void
    ) => {
      await saveThemeSlotsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => {
          updateStates(data.slots as ThemeStatusesDto[]);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    },
    [saveThemeSlotsMutation]
  );

  const fetchNinjaCartWidgetInstallationNoCacheData = useCallback(async () => {
    try {
      setNinjaCartWidgetInstallationDataIsFetching(true);
      const response = await fetch(
        `${resolveEnvVar(
          'REACT_APP_PROMOTIONS_ADMIN_API_URL'
        )}/settings/v6/themes/${widgetThemeType}/ninjaCartWidgetInstallation`,
        {
          method: 'GET',
          headers: {
            'X-LimoniApps-AppName': APP_NAME,
            'X-LimoniApps-AppSecret': APP_PASSWORD,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Cache-Control': 'no-cache',
          },
        }
      );
      const data = await response.json();
      setNinjaCartWidgetInstallationDataIsFetching(false);
      setNinjaCartWidgetInstallationData(data);
    } catch (error) {
      setNinjaCartWidgetInstallationDataIsFetching(false);
      dispatch(
        setToastMessages({
          error: true,
          message: 'FetchWidgetInstallationNoCacheDataError',
        })
      );
    }
  }, [
    widgetThemeType,
    setNinjaCartWidgetInstallationData,
    setNinjaCartWidgetInstallationDataIsFetching,
    dispatch,
  ]);

  const {
    data: ninjaCartWidgetInstallationRTKdata,
    isFetching: ninjaCartWidgetInstallationDataIsLoading,
    refetch: refetchNinjaCartWidgetInstallationDataBase,
  } = useGetSettingsV6ThemesByThemeTypeNinjaCartWidgetInstallationQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        (modal &&
          ![
            WidgetsControlCenterModalsEnum.NINJA_CART_CONTROL_CENTER_MAIN_PAGE,
            WidgetsControlCenterModalsEnum.ENABLE_NINJA_CART_STEP_MODAL,
          ].includes(modal)) ||
        forceSkip,
    }
  );

  useEffect(() => {
    if (ninjaCartWidgetInstallationRTKdata) {
      setNinjaCartWidgetInstallationData(ninjaCartWidgetInstallationRTKdata);
    }
  }, [ninjaCartWidgetInstallationRTKdata]);

  useEffect(() => {
    setNinjaCartWidgetInstallationDataIsFetching(
      ninjaCartWidgetInstallationDataIsLoading
    );
  }, [ninjaCartWidgetInstallationDataIsLoading]);

  const fetchWidgetInstallationNoCacheData = useCallback(async () => {
    try {
      setWidgetInstallationDataIsFetching(true);
      const response = await fetch(
        `${resolveEnvVar(
          'REACT_APP_PROMOTIONS_ADMIN_API_URL'
        )}/settings/v6/themes/${widgetThemeType}/widgetInstallation`,
        {
          method: 'GET',
          headers: {
            'X-LimoniApps-AppName': APP_NAME,
            'X-LimoniApps-AppSecret': APP_PASSWORD,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Cache-Control': 'no-cache',
          },
        }
      );
      const data = await response.json();
      setWidgetInstallationDataIsFetching(false);
      setWidgetInstallationData(data);
    } catch (error) {
      setWidgetInstallationDataIsFetching(false);
      dispatch(
        setToastMessages({
          error: true,
          message: 'FetchWidgetInstallationNoCacheDataError',
        })
      );
    }
  }, [
    widgetThemeType,
    setWidgetInstallationData,
    setWidgetInstallationDataIsFetching,
    dispatch,
  ]);

  const {
    data: widgetInstallationRTKdata,
    isFetching: widgetInstallationDataIsLoading,
    refetch: refetchWidgetInstallationDataBase,
  } = useGetSettingsV6ThemesByThemeTypeWidgetInstallationQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        (modal &&
          ![
            WidgetsControlCenterModalsEnum.WIDGETS_CONTROL_CENTER_MAIN_PAGE,
            WidgetsControlCenterModalsEnum.ENABLE_NINJA_CART_STEP_MODAL,
          ].includes(modal)) ||
        forceSkip,
    }
  );

  useEffect(() => {
    if (widgetInstallationRTKdata) {
      setWidgetInstallationData(widgetInstallationRTKdata);
    }
  }, [widgetInstallationRTKdata]);

  useEffect(() => {
    setWidgetInstallationDataIsFetching(widgetInstallationDataIsLoading);
  }, [widgetInstallationDataIsLoading]);

  const {
    data: themesData,
    isFetching: themesIsFetching,
    error: themesError,
    refetch: refetchThemesData,
  } = useGetSettingsV6LookupThemesQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip:
        modal !== WidgetsControlCenterModalsEnum.MANAGE_THEMES_MODAL ||
        forceSkip,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: requestSupportData,
    isFetching: requestSupportIsFetching,
    error: requestSupportError,
  } = useGetSettingsV6WidgetInstallationSupportRequestQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    },
    {
      skip:
        modal !==
          WidgetsControlCenterModalsEnum.REQUEST_SUPPORT_DETAILS_MODAL ||
        forceSkip,
      refetchOnMountOrArgChange: true,
    }
  );

  const [installCartPageMutation, { isLoading: installCartPageIsLoading }] =
    usePutSettingsV6ThemesByThemeTypeNinjaCartWidgetInstallationInstallCartPageMutation();

  const installCartPage = useCallback(
    async (data: InstallNinjaCartCartPageRequestDto) => {
      const response = await installCartPageMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
        themeType: widgetThemeType,
      }).unwrap();
      return response;
    },
    [installCartPageMutation]
  );

  const [updateSupportRequestMutation, { isLoading: updateSupportIsLoading }] =
    usePutSettingsV6WidgetInstallationSupportRequestMutation();

  const updateSupportRequest = useCallback(
    async (data: InstallationSupportRequestDto) => {
      const response = await updateSupportRequestMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      }).unwrap();
      return response;
    },
    [updateSupportRequestMutation]
  );

  const [deleteSupportRequestMutation, { isLoading: deleteSupportIsLoading }] =
    useDeleteSettingsV6WidgetInstallationSupportRequestMutation();

  const deleteSupportRequest = useCallback(async () => {
    const response = await deleteSupportRequestMutation({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      themeType: widgetThemeType,
    }).unwrap();
    return response;
  }, [deleteSupportRequestMutation]);

  const [turnNinjaCartWidgetOnManuallyMutation] =
    usePutSettingsV6ThemesByThemeTypeNinjaCartWidgetInstallationInstallMutation();

  const [
    turnWidgetOnManuallyMutation,
    {
      isLoading: turnWidgetOnManuallyIsLoading,
      error: turnWidgetOnManuallyError,
    },
  ] = usePutSettingsV6ThemesByThemeTypeWidgetInstallationInstallMutation();

  const turnWidgetOnManually = useCallback(
    async (
      data: InstallWidgetRequestDto,
      themeType?: ThemeTypeDto,
      onSuccess?: () => void,
      onError?: (value: boolean) => void,
      isWCC = true
    ) => {
      const mutation = isWCC
        ? turnWidgetOnManuallyMutation
        : turnNinjaCartWidgetOnManuallyMutation;

      try {
        const response = await mutation({
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          themeType: themeType ?? widgetThemeType,
          body: data,
        }).unwrap();

        onSuccess?.();
        return response;
      } catch {
        onError?.(false);
      }
    },
    [
      turnWidgetOnManuallyMutation,
      turnNinjaCartWidgetOnManuallyMutation,
      widgetThemeType,
    ]
  );

  return {
    requestSupportData,
    requestSupportIsFetching,
    requestSupportError,
    themeSlotsData,
    themeSlotsDataIsFetching,
    saveThemeSlotsIsLoading,
    widgetInstallationData,
    widgetInstallationDataIsFetching,
    themesData,
    themesIsFetching,
    themesError,
    updateSupportIsLoading,
    deleteSupportIsLoading,
    turnWidgetOnManuallyIsLoading,
    turnWidgetOnManuallyError,
    pageTemplatesData,
    pageTemplatesDataIsFetching,
    widgetStatusesData,
    widgetStatusesDataIsFetching,
    ninjaCartWidgetInstallationData,
    ninjaCartWidgetInstallationDataIsFetching,
    installCartPageIsLoading,
    themeSlotsBreakCacheData,
    fetchWidgetInstallationNoCacheData,
    fetchNinjaCartWidgetInstallationNoCacheData,
    refetchThemeSlotsBreakCacheData,
    installCartPage,
    refetchNinjaCartWidgetInstallationDataBase,
    refetchWidgetStatusesData,
    refetchPageTemplatesData,
    refetchThemesData,
    refetchWidgetInstallationDataBase,
    refetchThemeSlotsData,
    saveThemeSlots,
    updateSupportRequest,
    deleteSupportRequest,
    turnWidgetOnManually,
  };
};
