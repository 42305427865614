import React from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  BlockStack,
  Card,
  Text,
  RadioButton,
  InlineStack,
  Badge,
} from '@shopify/polaris';
import { FrequentBoughtTogetherDisplayOrderDto } from 'core/api/adminSettings/adminSettingsApi';
import { FrequentBoughtTogetherDisplayOrderDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type DisplayOrderRadioSettingProps = {
  displayOrder?: FrequentBoughtTogetherDisplayOrderDto;
  setDisplayOrder: (value: FrequentBoughtTogetherDisplayOrderDto) => void;
};

export const DisplayOrderRadioSetting: React.FC<
  DisplayOrderRadioSettingProps
> = ({ displayOrder, setDisplayOrder }) => {
  const [i18n] = useI18n();

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='bold'>
            {i18n.translate('Title')}
          </Text>
          <Text as='span' tone='subdued'>
            {i18n.translate('Description')}
          </Text>
        </BlockStack>
        <BlockStack gap='100'>
          {Object.entries(FrequentBoughtTogetherDisplayOrderDtoEnum).map(
            ([key, value]) => (
              <RadioButton
                key={key}
                label={
                  <InlineStack gap='200'>
                    <Text as='span'>{i18n.translate(key)}</Text>
                    {value ===
                      FrequentBoughtTogetherDisplayOrderDtoEnum.DEFAULT && (
                      <Badge>{i18n.translate('Recommended')}</Badge>
                    )}
                  </InlineStack>
                }
                checked={displayOrder === value}
                onChange={() =>
                  setDisplayOrder(
                    value as FrequentBoughtTogetherDisplayOrderDtoEnum
                  )
                }
              />
            )
          )}
        </BlockStack>
      </BlockStack>
    </Card>
  );
};
