import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Link } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import {
  PositionDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  PopupShopSettingsDto,
  WidgetSettingsDtoOfferRulePopupShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  IconSetting,
  OffsetSetting,
  OverlaySettings,
  PositionSetting,
  StyleSetting,
} from '../../components';

type PopupSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PopupSettings = forwardRef<WidgetSavebarRef, PopupSettingsProps>(
  ({ setIsLoading, setIsSaving }, ref) => {
    const [i18n] = useI18n();
    const dispatch = useDispatch();
    const {
      popupData,
      savePopup,
      popupDataIsFetching,
      savePopupIsLoading,
      // refetchPopupData,
    } = useWidget(WidgetTypeDtoEnum.POP_UP);

    const [savedData, setSavedData] =
      useState<WidgetSettingsDtoOfferRulePopupShopSettingsDto>({});
    const [popupState, setPopupState] =
      useState<WidgetSettingsDtoOfferRulePopupShopSettingsDto>({});

    const updatePopupState = useCallback(
      (
        field: keyof PopupShopSettingsDto,
        data: PopupShopSettingsDto[keyof PopupShopSettingsDto]
      ) =>
        setPopupState((prev) => ({
          ...prev,
          settings: {
            ...prev.settings,
            [field]: data,
          },
        })),
      [setPopupState]
    );

    const handleSave = useCallback(
      () =>
        savePopup(popupState.settings || null, () => setSavedData(popupState)),
      [popupState]
    );

    useEffect(() => {
      if (!isEmpty(popupData)) {
        setPopupState(popupData);
        setSavedData(popupData);
      }
    }, [popupData]);

    useEffect(() => {
      return () => {
        dispatch(setIsUnsavedChanges(false));
      };
    }, []);

    useEffect(() => {
      if (!isEmpty(savedData) && !isEmpty(popupState)) {
        dispatch(setIsUnsavedChanges(!isEqual(popupState, savedData)));
      }
    }, [savedData, popupState]);

    useEffect(() => {
      setIsLoading(popupDataIsFetching);
    }, [popupDataIsFetching]);

    useEffect(() => {
      setIsSaving(savePopupIsLoading);
    }, [savePopupIsLoading]);

    useImperativeHandle(ref, () => ({
      saveChanges: handleSave,
      discardChanges: () => setPopupState(savedData),
    }));

    return !popupDataIsFetching ? (
      <>
        <div
          style={{
            height: 'fit-content',
          }}
        >
          <VideoCard
            isPortrait={false}
            cardData={{
              title: i18n.translate('CardDataTitle'),
              description: i18n.translate('CardDataDescription'),
              btn: i18n.translate('CardDataBtn'),
              link: 'http://www.google.com',
              videoUrl: '',
            }}
          />
        </div>
        <StyleSetting styled={!!popupData?.isShopLevelWidget} />
        <PositionSetting
          position={popupData?.settings?.position as PositionDtoEnum}
          positionSides={[
            PositionDtoEnum.CENTER,
            PositionDtoEnum.LEFT,
            PositionDtoEnum.RIGHT,
          ]}
          title={i18n.translate('Position')}
          setPosition={(value) => updatePopupState('position', value)}
        />
        <OffsetSetting
          offset={popupState.settings?.offset || {}}
          subtitle={i18n.translate('OffsetSubtitle')}
          setOffset={(data) => updatePopupState('offset', data)}
        />
        <OverlaySettings
          overlay={popupState.settings?.overlay || {}}
          setOverlay={(data) => updatePopupState('overlay', data)}
        />
        <IconSetting
          icon={popupState.settings?.closeButtonIcon || {}}
          setIcon={(data) => updatePopupState('closeButtonIcon', data)}
          bannerContent={
            <>
              {i18n.translate('CloseBtnBannerText')}
              <Link>{i18n.translate('PresetEditor')}</Link>.
            </>
          }
          enableText={i18n.translate('EnableCloseBtn')}
          groupKeys={[ResourceGroupKeyDtoEnum.CLOSE_BUTTON]}
          title={i18n.translate('CloseBtn')}
          subtitle={i18n.translate('CloseBtnSubtitle')}
        />
      </>
    ) : null;
  }
);

PopupSettings.displayName = 'PopupSettings';
