import React, { useState } from 'react';
import './CustomCSS.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  BlockStack,
  Box,
  Button,
  Collapsible,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { ChevronUpIcon, ChevronDownIcon } from '@shopify/polaris-icons';
import { css as CSS } from '@codemirror/lang-css';
import { CodeEditor } from 'core/components';

type CustomCSSProps = {
  css: string;
  disabled?: boolean;
  preview?: string;
  note?: string;
  setCSS: (value: string) => void;
};

export const CustomCSS: React.FC<CustomCSSProps> = ({
  css,
  disabled,
  preview,
  note,
  setCSS,
}) => {
  const [i18n] = useI18n();
  const [isCustomOpen, setIsCustomOpen] = useState<boolean>(true);
  // Refactor needed Andriy (Reinstall different library)
  const formattedPreview = preview
    ?.replace('{', ' {\n')
    .replace(':', ':')
    .replace('}', '\n}');
  return (
    <div className='CustomCSS'>
      <InlineStack align='space-between' blockAlign='center'>
        <Text fontWeight='medium' as='p'>
          {i18n.translate('CustomCss')}
        </Text>
        <Button
          onClick={() => setIsCustomOpen(!isCustomOpen)}
          variant='tertiary'
          icon={isCustomOpen ? ChevronUpIcon : ChevronDownIcon}
        />
      </InlineStack>
      <Collapsible
        open={isCustomOpen}
        id='basic-collapsible'
        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
        expandOnPrint
      >
        <Box paddingBlockStart='300'>
          <BlockStack gap='300'>
            <Box>
              <Text tone='subdued' as='span'>
                {i18n.translate('AddCustom')}
              </Text>
              <Text as='span'>
                {i18n.translate('ThisWidget')}
                {'. '}
                <Link>{i18n.translate('LearnMore')}</Link>
              </Text>
              .
            </Box>
            {!!preview && (
              <BlockStack gap='100'>
                <Text as='p'>{i18n.translate('StylePresetCSS')}</Text>
                <CodeEditor disabled value={formattedPreview || ''} readOnly />
              </BlockStack>
            )}
            <CodeEditor
              value={css || ''}
              height='120px'
              extensions={[CSS()]}
              onChange={setCSS}
              disabled={disabled}
            />
            {note && (
              <Text tone='subdued' as='p'>
                {note}
              </Text>
            )}
          </BlockStack>
        </Box>
      </Collapsible>
    </div>
  );
};
