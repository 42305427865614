import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  NinjaCartGiftPickerWidgetSettingsDto,
  WidgetSettingsDtoNinjaCartGiftPickerWidgetSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import { StyleSetting, NinjaCartDisplayOption } from '../../components';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';
import { Card, BlockStack, Text, Checkbox } from '@shopify/polaris';
import { Element } from 'react-scroll';

type NinjaCartGiftPickerSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NinjaCartGiftPickerSettings = forwardRef<
  WidgetSavebarRef,
  NinjaCartGiftPickerSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const {
    ninjaCartGiftPickerData,
    ninjaCartGiftPickerDataIsFetching,
    saveNinjaCartGiftPickerIsLoading,
    saveNinjaCartGiftPicker,
    // refetchNinjaCartGiftPickerData,
  } = useNinjaCartWidget(WidgetTypeDtoEnum.NINJA_CART_FREE_GIFT_PICKER);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoNinjaCartGiftPickerWidgetSettingsDto>({});
  const [giftPickerState, setGiftPickerState] =
    useState<WidgetSettingsDtoNinjaCartGiftPickerWidgetSettingsDto>({});
  const updateGiftPickerState = useCallback(
    (
      field: keyof NinjaCartGiftPickerWidgetSettingsDto,
      data: NinjaCartGiftPickerWidgetSettingsDto[keyof NinjaCartGiftPickerWidgetSettingsDto]
    ) =>
      setGiftPickerState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setGiftPickerState]
  );

  const handleSave = useCallback(
    () =>
      saveNinjaCartGiftPicker(giftPickerState.settings || null, () =>
        setSavedData(giftPickerState)
      ),
    [giftPickerState]
  );

  useEffect(() => {
    if (!isEmpty(ninjaCartGiftPickerData)) {
      setGiftPickerState(ninjaCartGiftPickerData);
      setSavedData(ninjaCartGiftPickerData);
    }
  }, [ninjaCartGiftPickerData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(giftPickerState)) {
      dispatch(setIsUnsavedChanges(!isEqual(giftPickerState, savedData)));
    }
  }, [savedData, giftPickerState]);

  useEffect(() => {
    setIsLoading(ninjaCartGiftPickerDataIsFetching);
  }, [ninjaCartGiftPickerDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveNinjaCartGiftPickerIsLoading);
  }, [saveNinjaCartGiftPickerIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setGiftPickerState(savedData),
  }));

  return !ninjaCartGiftPickerDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!ninjaCartGiftPickerData?.isShopLevelWidget} />
      <Element
        name='ProductVariantsSettings'
        className='ProductVariantsSettings'
      >
        <Card>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='p' fontWeight='bold'>
                {i18n.translate('ProductVariantsSettingsTitle')}
              </Text>
              <Text as='span' tone='subdued'>
                {i18n.translate('ProductVariantsSettingsSubtitle')}
              </Text>
            </BlockStack>
            <Checkbox
              label={i18n.translate('ShowVariantsSeparate')}
              checked={giftPickerState.settings?.showVariantsSeparateCard}
              onChange={(value) =>
                updateGiftPickerState('showVariantsSeparateCard', value)
              }
            ></Checkbox>
          </BlockStack>
        </Card>
      </Element>
      {['productOptions', 'vendor', 'productProperties', 'productValue'].map(
        (value) => {
          const type = value as Exclude<
            keyof NinjaCartGiftPickerWidgetSettingsDto,
            'showVariantsSeparateCard'
          >;
          return (
            <NinjaCartDisplayOption
              key={type}
              setup={giftPickerState.settings?.[type] || {}}
              setSetup={(data) => updateGiftPickerState(type, data)}
              type={type}
            />
          );
        }
      )}
    </>
  ) : null;
});

NinjaCartGiftPickerSettings.displayName = 'NinjaCartGiftPickerSettings';
