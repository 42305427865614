import React, { useCallback, useState } from 'react';
import './PromotionsOfferList.scss';
import { Box, Icon, InlineStack, Popover, Text } from '@shopify/polaris';
import { ChevronDownIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  PagedResponseDtoPromotionOverviewOfferDto,
  useGetPromotionsV6PromotionByIdOffersQuery,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OverviewOfferList } from 'features/promotions/components/PromotionOverview/components';
import resolveEnvVar from 'env-var-resolver';

type PromotionsOfferListProps = {
  offers?: PagedResponseDtoPromotionOverviewOfferDto;
  promotionId?: string;
  offersPopover: string;
  setOffersPopover: (id: string) => void;
};
export const PromotionsOfferList: React.FC<PromotionsOfferListProps> = ({
  offers,
  promotionId,
  offersPopover,
  setOffersPopover,
}) => {
  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME');
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const [i18n] = useI18n();
  const [offerPage, setOfferPage] = useState<number>(1);

  const {
    data: offerListData,
    isFetching: offerListIsFetching,
    refetch: refetchOfferList,
  } = useGetPromotionsV6PromotionByIdOffersQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: promotionId || '',
      itemsPerPage: 5,
      page: offerPage,
    },
    {
      skip: promotionId !== offersPopover,
      refetchOnMountOrArgChange: true,
    }
  );

  const handlePopover = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();
      !!offers?.totalItems &&
        setOffersPopover(offersPopover ? '' : promotionId || '');
    },
    [promotionId, offers?.totalItems, offersPopover]
  );
  return (
    <Popover
      fluidContent
      preferredPosition='below'
      preferredAlignment='left'
      activator={
        <div onClick={handlePopover} className='PromotionsOfferList'>
          <InlineStack wrap={false}>
            <Box width='68px'>
              <Text as='p'>
                {i18n.translate('OffersCount', {
                  count: offers?.totalItems,
                })}
              </Text>
            </Box>
            {!!offers?.totalItems && (
              <div className='IconActivator'>
                <Icon source={ChevronDownIcon} />
              </div>
            )}
          </InlineStack>
        </div>
      }
      active={offersPopover === promotionId}
      onClose={() => setOffersPopover('')}
    >
      <div style={{ width: 600 }}>
        <OverviewOfferList
          promotionId={promotionId || ''}
          offerList={offerListData || {}}
          offerListIsFetching={offerListIsFetching}
          offerPage={offerPage}
          refetchOfferList={refetchOfferList}
          setOfferPage={setOfferPage}
        />
      </div>
    </Popover>
  );
};
