import React from 'react';
import { useI18n } from '@shopify/react-i18n';
import { WidgetTypeDto } from 'core/api/adminSettings/adminSettingsApi';
import { BlockStack, Card, Icon, InlineStack, Text } from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
import { transformToKebabOrSnakeCase } from 'core/utils/transformToKebabOrSnakeCase';

type SubTypesNavigationProps = {
  subTypes?: WidgetTypeDto[];
};

export const SubTypesNavigation: React.FC<SubTypesNavigationProps> = ({
  subTypes,
}) => {
  const [i18n] = useI18n();

  const navigate = useNavigate();

  return subTypes?.length ? (
    <Card>
      <BlockStack gap='400'>
        <Text as='p' fontWeight='bold'>
          {i18n.translate('Title')}
        </Text>
        <BlockStack gap='200'>
          {subTypes?.map(
            (type) =>
              type && (
                <div
                  key={type}
                  onClick={() =>
                    navigate(transformToKebabOrSnakeCase(type, 'kebab-case'))
                  }
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Card>
                    <InlineStack blockAlign='center' align='space-between'>
                      <Text as='p'>{i18n.translate(type)}</Text>
                      <div>
                        <Icon source={ChevronRightIcon} />
                      </div>
                    </InlineStack>
                  </Card>
                </div>
              )
          )}
        </BlockStack>
      </BlockStack>
    </Card>
  ) : null;
};
