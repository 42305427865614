import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  BlockStack,
  Button,
  Card,
  Checkbox,
  ChoiceList,
  InlineStack,
  Page,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { NotificationsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  useConfigureSettings,
  SettingsFetchTypeEnum,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { NotificationLocationSettingDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { ColorSelector } from '../Style/components/ColorSelector/ColorSelector';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';

export const Notifications = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const pageBackAction = useSettingsPageBackAction();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    notificationsData,
    notificationsDataIsFetching,
    notificationsDataChange,
  } = useConfigureSettings(SettingsFetchTypeEnum.NOTIFICATIONS);

  const [notificationsState, setNotificationsState] =
    useState<NotificationsSettingsDto>();
  const [savedData, setSavedData] = useState<NotificationsSettingsDto>({});

  const positionOptions = useMemo(
    () =>
      Object.values(NotificationLocationSettingDtoEnum).map((value) => ({
        label: i18n.translate(`${value}`),
        value: value,
      })),
    [i18n]
  );

  const navigateToTranslations = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    searchParams.set('path', SettingsModalPathsEnums.Translations);
    setSearchParams(searchParams);
  }, [searchParams]);

  const handleUpdateNotifications = useCallback(
    (
      field: keyof NotificationsSettingsDto,
      data: NotificationsSettingsDto[keyof NotificationsSettingsDto]
    ) => setNotificationsState((prev) => ({ ...prev, [field]: data })),
    [setNotificationsState]
  );

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () =>
      setSavedData(notificationsState as NotificationsSettingsDto);
    return notificationsDataChange(
      notificationsState as NotificationsSettingsDto,
      onSuccess
    );
  }, [notificationsState]);

  useEffect(() => {
    const { onAppRefresh, onGiftAdded, position, style } =
      notificationsState || {};
    if (onAppRefresh || onGiftAdded) {
      !position &&
        handleUpdateNotifications(
          'position',
          NotificationLocationSettingDtoEnum.BOTTOM
        );
      !style &&
        handleUpdateNotifications('style', {
          backgroundColor: '#00000',
          textColor: '#00000',
        });
    }
  }, [notificationsState?.onAppRefresh, notificationsState?.onGiftAdded]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => setNotificationsState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, notificationsState)));
  }, [notificationsState, savedData]);

  useEffect(() => {
    if (!isEmpty(notificationsData)) {
      setNotificationsState(notificationsData);
      setSavedData(notificationsData);
    }
  }, [notificationsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <>
      {!notificationsDataIsFetching && notificationsState ? (
        <Page
          backAction={pageBackAction}
          title={i18n.translate('Notifications')}
        >
          <Card>
            <BlockStack gap='400'>
              <InlineStack align='space-between'>
                <Text as='p' fontWeight='semibold'>
                  {i18n.translate('Notifications')}
                </Text>
                <Button onClick={navigateToTranslations} variant='plain'>
                  {i18n.translate('ConfigureTranslations')}
                </Button>
              </InlineStack>
              <BlockStack>
                <Checkbox
                  label={i18n.translate('Gifts')}
                  helpText={i18n.translate('GiftsHelp')}
                  checked={notificationsState.onGiftAdded}
                  onChange={(value) =>
                    handleUpdateNotifications('onGiftAdded', value)
                  }
                />
                <Checkbox
                  label={i18n.translate('PageReload')}
                  helpText={i18n.translate('PageReloadHelp')}
                  checked={notificationsState.onAppRefresh}
                  onChange={(value) =>
                    handleUpdateNotifications('onAppRefresh', value)
                  }
                />
              </BlockStack>
              {(notificationsState.onAppRefresh ||
                notificationsState.onGiftAdded) && (
                <>
                  <ChoiceList
                    choices={positionOptions}
                    title={i18n.translate('Position')}
                    selected={[notificationsState.position as string]}
                    onChange={(value) =>
                      handleUpdateNotifications(
                        'position',
                        value[0] as NotificationLocationSettingDtoEnum
                      )
                    }
                  />
                  <BlockStack gap='200'>
                    <Text as='p'>{i18n.translate('Style')}</Text>
                    <InlineStack gap='600'>
                      <ColorSelector
                        color={notificationsState.style?.backgroundColor}
                        label={i18n.translate('BackgroundColor')}
                        onColorChange={(value) =>
                          handleUpdateNotifications('style', {
                            ...notificationsState.style,
                            backgroundColor: value,
                          })
                        }
                      />
                      <ColorSelector
                        color={notificationsState.style?.textColor}
                        label={i18n.translate('TextColor')}
                        onColorChange={(value) =>
                          handleUpdateNotifications('style', {
                            ...notificationsState.style,
                            textColor: value,
                          })
                        }
                      />
                    </InlineStack>
                  </BlockStack>
                </>
              )}
            </BlockStack>
          </Card>
        </Page>
      ) : (
        <SettingsSkeleton cardsCount={1} />
      )}
    </>
  );
});
Notifications.displayName = 'Notifications';
