import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { DeviceDisplayDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  ProductBannerShopSettingsDto,
  WidgetSettingsDtoProductBannerShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import {
  DeviceDisplaySetting,
  StackingSetting,
  StyleSetting,
} from '../../components';

type ProductBannerSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProductBannerSettings = forwardRef<
  WidgetSavebarRef,
  ProductBannerSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const {
    productBannerData,
    saveProductBanner,
    productBannerDataIsFetching,
    saveProductBannerIsLoading,
    // refetchProductBannerData,
  } = useWidget(WidgetTypeDtoEnum.PRODUCT_BANNER);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoProductBannerShopSettingsDto>({});
  const [productBannerState, setProductBannerState] =
    useState<WidgetSettingsDtoProductBannerShopSettingsDto>({});

  const updateProductBannerState = useCallback(
    (
      field: keyof ProductBannerShopSettingsDto,
      data: ProductBannerShopSettingsDto[keyof ProductBannerShopSettingsDto]
    ) =>
      setProductBannerState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setProductBannerState]
  );

  const handleSave = useCallback(
    () =>
      saveProductBanner(productBannerState.settings || null, () =>
        setSavedData(productBannerState)
      ),
    [productBannerState]
  );

  useEffect(() => {
    if (!isEmpty(productBannerData)) {
      setProductBannerState(productBannerData);
      setSavedData(productBannerData);
    }
  }, [productBannerData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(productBannerState)) {
      dispatch(setIsUnsavedChanges(!isEqual(productBannerState, savedData)));
    }
  }, [savedData, productBannerState]);

  useEffect(() => {
    setIsLoading(productBannerDataIsFetching);
  }, [productBannerDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveProductBannerIsLoading);
  }, [saveProductBannerIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setProductBannerState(savedData),
  }));

  return !productBannerDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!productBannerData?.isShopLevelWidget} />
      <DeviceDisplaySetting
        deviceDisplay={
          productBannerState.settings?.deviceDisplay as DeviceDisplayDtoEnum
        }
        setDeviceDisplay={(value) =>
          updateProductBannerState('deviceDisplay', value)
        }
      />
      <StackingSetting
        stacking={productBannerState.settings?.stacking || {}}
        setStacking={(data) => updateProductBannerState('stacking', data)}
        maxCustomSpacing={20}
        subtitle={i18n.translate('AllowMoreOffers')}
      />
    </>
  ) : null;
});

ProductBannerSettings.displayName = 'ProductBannerSettings';
