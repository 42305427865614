import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Link } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useDispatch } from 'react-redux';
import {
  DeviceDisplayDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  AnnouncementBarShopSettingsDto,
  WidgetSettingsDtoAnnouncementBarShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  AnnouncementBarPagePositionDtoEnum,
  SizeTypeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  AnimationsSetting,
  BehaviorSetting,
  CycleOffersSetting,
  DeviceDisplaySetting,
  IconSetting,
  IndicatorsSetting,
  PageDisplaySetting,
  PagePositionSetting,
  SizeTypeSetting,
  SizeWidthSetting,
  StyleSetting,
} from '../../components';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';
import { useSettingsFormValidity } from 'features/promotions/hooks/useSettingsFormValidity';
import { setShowValidation } from 'core/store/promotionsSlice';

type AnnouncementBarSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  widgetType?: WidgetTypeDtoEnum;
};

export const AnnouncementBarSettings = forwardRef<
  WidgetSavebarRef,
  AnnouncementBarSettingsProps
>(({ setIsLoading, setIsSaving, widgetType }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const { validityChangeHandler } = useSettingsFormValidity();

  const isNinjaCart =
    widgetType === WidgetTypeDtoEnum.NINJA_CART_DRAWER_CART_ANNOUNCEMENT_BAR;

  const ninjaCartData = useNinjaCartWidget(
    isNinjaCart
      ? WidgetTypeDtoEnum.NINJA_CART_DRAWER_CART_ANNOUNCEMENT_BAR
      : undefined
  );
  const widgetData = useWidget(
    !isNinjaCart ? WidgetTypeDtoEnum.ANNOUNCEMENT_BAR : undefined
  );

  const {
    announcementBarData,
    saveAnnouncementBar,
    announcementBarDataIsFetching,
    saveAnnouncementBarIsLoading,
    // refetchAnnouncementBarData,
  } = isNinjaCart ? ninjaCartData : widgetData;

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoAnnouncementBarShopSettingsDto>({});
  const [announcementBarState, setAnnouncementBarState] =
    useState<WidgetSettingsDtoAnnouncementBarShopSettingsDto>({});

  const updateAnnouncementBarState = useCallback(
    (
      field: keyof AnnouncementBarShopSettingsDto,
      data: AnnouncementBarShopSettingsDto[keyof AnnouncementBarShopSettingsDto]
    ) =>
      setAnnouncementBarState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setAnnouncementBarState]
  );

  const handleSave = useCallback(
    () =>
      saveAnnouncementBar(announcementBarState.settings || null, () => {
        setSavedData(announcementBarState);
        dispatch(setShowValidation(false));
      }),
    [announcementBarState]
  );

  useEffect(() => {
    if (!isEmpty(announcementBarData)) {
      setAnnouncementBarState(announcementBarData);
      setSavedData(announcementBarData);
    }
  }, [announcementBarData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(announcementBarState)) {
      dispatch(setIsUnsavedChanges(!isEqual(announcementBarState, savedData)));
    }
  }, [savedData, announcementBarState]);

  useEffect(() => {
    setIsLoading(announcementBarDataIsFetching);
  }, [announcementBarDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveAnnouncementBarIsLoading);
  }, [saveAnnouncementBarIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setAnnouncementBarState(savedData),
  }));

  return !announcementBarDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate(`${widgetType}_CardDataTitle`),
            description: i18n.translate(`${widgetType}_CardDataDescription`),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!announcementBarData?.isShopLevelWidget} />
      {!isNinjaCart && (
        <>
          <DeviceDisplaySetting
            deviceDisplay={
              announcementBarState.settings
                ?.deviceDisplay as DeviceDisplayDtoEnum
            }
            setDeviceDisplay={(value) =>
              updateAnnouncementBarState('deviceDisplay', value)
            }
          />
          <PageDisplaySetting
            pageDisplay={announcementBarState.settings?.pageDisplay || {}}
            setPageDisplay={(data) =>
              updateAnnouncementBarState('pageDisplay', data)
            }
          />
          <PagePositionSetting
            pagePosition={
              announcementBarState.settings
                ?.pagePosition as AnnouncementBarPagePositionDtoEnum
            }
            setPagePosition={(value) =>
              updateAnnouncementBarState('pagePosition', value)
            }
          />
          <SizeTypeSetting
            sizeType={
              announcementBarState.settings?.size?.size as SizeTypeDtoEnum
            }
            setSizeType={(value) =>
              updateAnnouncementBarState('size', {
                ...announcementBarState.settings?.size,
                size: value,
              })
            }
            subtitle={i18n.translate('SetTheHeight')}
            simplified
          />
          <SizeWidthSetting
            size={announcementBarState.settings?.size || {}}
            setSize={(data) => updateAnnouncementBarState('size', data)}
          />
        </>
      )}
      <BehaviorSetting
        behavior={announcementBarState.settings?.stickyScroll || {}}
        onFormValidityChange={validityChangeHandler}
        setBehavior={(data) => updateAnnouncementBarState('stickyScroll', data)}
      />
      <CycleOffersSetting
        cycling={announcementBarState.settings?.cycling || {}}
        setCycling={(data) => updateAnnouncementBarState('cycling', data)}
      />
      <AnimationsSetting
        animations={announcementBarState.settings?.animations || {}}
        setAnimations={(data) => updateAnnouncementBarState('animations', data)}
      />
      {!isNinjaCart && (
        <>
          <IconSetting
            icon={announcementBarState.settings?.chevronsIcon || {}}
            setIcon={(data) => updateAnnouncementBarState('chevronsIcon', data)}
            bannerContent={
              <>
                {i18n.translate('ChevronsBannerText')}
                <Link>{i18n.translate('PresetEditor')}</Link>.
              </>
            }
            enableText={i18n.translate('EnableChevrons')}
            groupKeys={[ResourceGroupKeyDtoEnum.CHEVRONS]}
            title={i18n.translate('Chevrons')}
            subtitle={i18n.translate('ChevronsSubtitle')}
            onFormValidityChange={validityChangeHandler}
          />

          <IconSetting
            icon={announcementBarState.settings?.closeButtonIcon || {}}
            setIcon={(data) =>
              updateAnnouncementBarState('closeButtonIcon', data)
            }
            bannerContent={
              <>
                {i18n.translate('CloseBtnBannerText')}
                <Link>{i18n.translate('PresetEditor')}</Link>.
              </>
            }
            enableText={i18n.translate('EnableCloseBtn')}
            groupKeys={[ResourceGroupKeyDtoEnum.CLOSE_BUTTON]}
            title={i18n.translate('CloseBtn')}
            subtitle={i18n.translate('CloseBtnSubtitle')}
            onFormValidityChange={validityChangeHandler}
          />
        </>
      )}
      <IndicatorsSetting
        indicators={announcementBarState.settings?.indicators || {}}
        setIndicators={(data) => updateAnnouncementBarState('indicators', data)}
      />
    </>
  ) : null;
});

AnnouncementBarSettings.displayName = 'AnnouncementBarSettings';
