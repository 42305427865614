import React from 'react';
import CodeMirror, {
  ReactCodeMirrorProps,
  ReactCodeMirrorRef,
} from '@uiw/react-codemirror';
import classNames from 'classnames';
import './CodeEditor.scss';
import { BlockStack, Box, Icon, InlineStack, Text } from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';

export type CodeEditorProps = ReactCodeMirrorProps &
  React.RefAttributes<ReactCodeMirrorRef> & {
    disabled?: boolean;
    error?: string | boolean;
  };

export const CodeEditor: React.FC<CodeEditorProps> = (props) => {
  const { disabled, error, editable, ...rest } = props;
  return (
    <div
      className={classNames('DN-CodeMirror', {
        Disabled: disabled,
        Error: !!error,
      })}
    >
      <BlockStack gap='100'>
        <CodeMirror {...rest} editable={disabled ? false : editable} />
        {error && typeof error === 'string' && (
          <InlineStack gap='200'>
            <Box>
              <Icon tone='textCritical' source={InfoIcon} />
            </Box>
            <Text as='p' tone='critical'>
              {error}
            </Text>
          </InlineStack>
        )}
      </BlockStack>
    </div>
  );
};
