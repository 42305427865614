import React, { useMemo } from 'react';
import {
  Badge,
  Box,
  Icon,
  IndexTable,
  InlineStack,
  SkeletonDisplayText,
  SkeletonThumbnail,
  Text,
  Thumbnail,
} from '@shopify/polaris';
import { AlertTriangleIcon } from '@shopify/polaris-icons';
import { ExpandedShopifyDetailedObjectDto } from '../../SelectedProductList';
import {
  ShopifyObjectTypeDtoEnum,
  ShopifyProductStatusDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useI18n } from '@shopify/react-i18n';
import { OfferConflictBrokenItemDto } from 'core/api/adminPromotions/adminPromotionsApi';

type SelectedProductListRowProps = {
  index: number;
  data: ExpandedShopifyDetailedObjectDto;
  isLoading: boolean;
  currentResourceType: string;
  listWidth: number;
  conflict?: OfferConflictBrokenItemDto;
};

export const SelectedProductListRow: React.FC<SelectedProductListRowProps> = ({
  index,
  data,
  isLoading,
  currentResourceType,
  listWidth,
  conflict,
}) => {
  const [i18n] = useI18n();
  const resourceStatusTone = useMemo(() => {
    if (conflict) return 'warning';
    switch (data.status) {
      case ShopifyProductStatusDtoEnum.DRAFT:
        return 'info';
      case ShopifyProductStatusDtoEnum.ACTIVE:
        return 'success';
      default:
        return 'new';
    }
  }, [data.status, conflict]);

  return (
    <IndexTable.Row
      id={data.id as string}
      position={index}
      tone={conflict ? 'warning' : undefined}
    >
      <IndexTable.Cell>
        <Box width={`${(listWidth / 100) * 40}px`}>
          {!isLoading ? (
            <InlineStack blockAlign='center' gap='400' wrap={false}>
              {data.imageUrl || data.featuredImageUrl ? (
                <Thumbnail
                  source={(data.imageUrl || data.featuredImageUrl) as string}
                  alt={data.title || ''}
                  size='small'
                />
              ) : (
                <SkeletonThumbnail size='small' />
              )}
              <InlineStack gap='100'>
                <Text truncate as='p'>
                  {data.title}
                </Text>
                {conflict && (
                  <Box>
                    <Icon tone='textWarning' source={AlertTriangleIcon} />
                  </Box>
                )}
              </InlineStack>
            </InlineStack>
          ) : (
            <SkeletonThumbnail size='small' />
          )}
        </Box>
      </IndexTable.Cell>
      {currentResourceType === ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT && (
        <IndexTable.Cell className='VariantColumn'>
          {!isLoading ? (
            <Text alignment='start' as='p'>
              {data.variant}
            </Text>
          ) : (
            <SkeletonDisplayText />
          )}
        </IndexTable.Cell>
      )}
      <IndexTable.Cell className='StatusColumn'>
        {!isLoading ? (
          <Badge tone={resourceStatusTone}>
            {i18n.translate(`${conflict ? conflict.reason : data.status}`)}
          </Badge>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='SalesChannelsColumn'>
        {!isLoading ? (
          <Text as='p' tone='subdued'>
            {data?.salesChannels?.length
              ? data.salesChannels?.map((channel: string) => channel).join(', ')
              : '-'}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};
