import React, { useCallback, useMemo } from 'react';
import { Button, Popover, ActionList, IconSource } from '@shopify/polaris';
import { MenuHorizontalIcon } from '@shopify/polaris-icons';

export type ActionItem = {
  content: string;
  active?: boolean;
  icon?: IconSource;
  destructive?: boolean;
  disabled?: boolean;
  onAction: () => void;
};

type ActionListInPopoverProps = {
  id: string;
  btnContent: string;
  actionList: ActionItem[];
  selectType?: boolean;
  disable?: boolean;
  width?: string;
  fullWidth?: boolean;
  activePopoverId: string;
  removeDisclosure?: boolean;
  forceSecondary?: boolean;
  setActivePopoverId: (id: string) => void;
};

export const ActionListInPopover: React.FC<ActionListInPopoverProps> = (
  props
) => {
  const {
    id,
    activePopoverId,
    btnContent,
    actionList,
    selectType = false,
    disable = false,
    width,
    fullWidth = true,
    removeDisclosure = false,
    forceSecondary = false,
    setActivePopoverId,
  } = props;

  const isActive = useMemo(() => activePopoverId === id, [activePopoverId, id]);

  const toggleActive = useCallback(
    (e?: React.MouseEvent<HTMLDivElement>) => {
      e?.stopPropagation();
      if (!disable) {
        setActivePopoverId(isActive ? '' : id);
      }
    },
    [disable, isActive, id, setActivePopoverId]
  );

  const activator = useMemo(
    () => (
      <div
        style={{ width: width || 'auto', display: 'flex' }}
        onClick={toggleActive}
      >
        <Button
          disclosure={
            btnContent.length && !removeDisclosure
              ? selectType
                ? 'select'
                : isActive
                ? 'up'
                : 'down'
              : false
          }
          variant={
            btnContent.length || forceSecondary ? 'secondary' : 'tertiary'
          }
          icon={!btnContent.length ? MenuHorizontalIcon : undefined}
          fullWidth={fullWidth}
          textAlign='left'
          size={btnContent.length || forceSecondary ? 'medium' : 'micro'}
          disabled={disable}
        >
          {btnContent}
        </Button>
      </div>
    ),
    [btnContent, isActive, selectType, disable, width, forceSecondary]
  );

  if (disable) {
    return activator;
  }

  return (
    <Popover
      active={isActive}
      activator={activator}
      onClose={() => setActivePopoverId('')}
      fullWidth={!!btnContent.length && fullWidth}
      captureOverscroll
    >
      <div onClick={(e) => e.stopPropagation()}>
        <ActionList
          actionRole='menuitem'
          items={actionList}
          onActionAnyItem={() => setActivePopoverId('')}
        />
      </div>
    </Popover>
  );
};
