import React from 'react';
import { I18n } from '@shopify/react-i18n';
import {
  OfferCartRuleGroupConnectorDtoEnum,
  OfferCrossSellRequirementRuleTypeDtoEnum,
  OfferRuleTypeDtoEnum,
  OfferRuleValueTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { formatPercentage } from 'core/utils';
import {
  OfferCartRuleGroupConnectorDto,
  PrerequisiteEntitledExclusionsDto,
  Query,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { headerTitle } from 'core/components/SearchFieldWithGrayBoxOfferWizard/utils/utils';

export enum SectionTypeEnum {
  PRODUCTS = 'PRODUCTS',
  CART_RULES = 'CART_RULES',
  GIFT_SECTION = 'GIFT_SECTION',
  DISCOUNT = 'DISCOUNT',
}

export const boldMessageParts = (message: string): JSX.Element => {
  const boldedMessage = message.replace(
    /<bold>(.*?)<\/bold>/g,
    (_, p1) => `<strong>${p1}</strong>`
  );
  return <span dangerouslySetInnerHTML={{ __html: boldedMessage }} />;
};

export const generateMessage = (
  section: SectionTypeEnum,
  rule: any,
  index: number,
  currentSelections: string,
  i18n: I18n,
  connector?: OfferCartRuleGroupConnectorDto,
  singleRule?: boolean,
  isPrerequisite?: boolean
) => {
  const {
    type,
    value,
    appliesTo,
    applyAfterDiscounts,
    fixedAmount,
    percentage,
    count,
    limit,
  } = rule;

  const appliesToTypeTranslation = appliesTo?.type
    ? i18n.translate(`OfferRule.${appliesTo.type}`)
    : '';

  const beforeAfter =
    type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT &&
    section === SectionTypeEnum.CART_RULES
      ? applyAfterDiscounts
        ? i18n.translate('OfferRule.After')
        : i18n.translate('OfferRule.Before')
      : '';

  let message = '';

  if (section === SectionTypeEnum.PRODUCTS) {
    const ruleTypeSuffix = isPrerequisite
      ? '_PREREQUISITE'
      : singleRule
      ? '_SINGLE'
      : '';
    const ruleTypeKey = `Products.${appliesTo?.type}_RULE_TYPE${ruleTypeSuffix}`;

    const amount = singleRule ? `${limit?.perOrder?.value || 1}` : '';

    const ruleMessage = i18n.translate(ruleTypeKey, { amount });

    message = ruleMessage;
  }

  if (section === SectionTypeEnum.GIFT_SECTION) {
    message = `${i18n.translate('Offer', {
      amount: rule?.rule?.amount || rule?.limit?.value,
      type: i18n.translate(`${rule.rule?.type}_type`),
    })}`;
  }

  if (section === SectionTypeEnum.DISCOUNT) {
    message = `${i18n.translate('DiscountRule', {
      discountType:
        type === OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT
          ? i18n.formatCurrency(fixedAmount)
          : formatPercentage(i18n, percentage),
      yValue: count,
      type: appliesToTypeTranslation,
      condition:
        value?.type === OfferRuleValueTypeDtoEnum.BETWEEN
          ? i18n.translate('MinimumItemsBetween', {
              from: value.from,
              to: value.to,
            })
          : value?.type === OfferRuleValueTypeDtoEnum.AT_LEAST
          ? i18n.translate('MinimumItemsAtLeast', {
              count: value.from,
            })
          : value?.type === OfferRuleValueTypeDtoEnum.AT_MOST
          ? i18n.translate('MinimumItemsAtMost', {
              count: value.to,
            })
          : i18n.translate('MinimumItemsExactly', {
              count: value.exactly,
            }),
    })}`;
  }

  if (
    type === OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY &&
    section === SectionTypeEnum.CART_RULES
  ) {
    switch (value?.type) {
      case OfferRuleValueTypeDtoEnum.BETWEEN:
        message = `${i18n.translate(
          `OfferRule.MinimumItemsBetween${singleRule ? 'SingleRule' : ''}`,
          {
            from: value.from,
            to: value.to,
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.AT_LEAST:
        message = `${i18n.translate(
          `OfferRule.MinimumItemsAtLeast${singleRule ? 'SingleRule' : ''}`,
          {
            count: value.from || 1,
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.AT_MOST:
        message = `${i18n.translate(
          `OfferRule.MinimumItemsAtMost${singleRule ? 'SingleRule' : ''}`,
          {
            count: value.to,
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.EXACTLY:
        message = `${i18n.translate(
          `OfferRule.MinimumItemsExactly${singleRule ? 'SingleRule' : ''}`,
          {
            count: value.exactly,
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
    }
  }

  if (
    type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT &&
    section === SectionTypeEnum.CART_RULES
  ) {
    switch (value?.type) {
      case OfferRuleValueTypeDtoEnum.BETWEEN:
        message = `${i18n.translate(
          `OfferRule.MinimumPurchaseAmountBetween${
            singleRule ? 'SingleRule' : ''
          }`,
          {
            from: i18n.formatCurrency(value.from),
            to: i18n.formatCurrency(value.to),
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.AT_LEAST:
        message = `${i18n.translate(
          `OfferRule.MinimumPurchaseAmountAtLeast${
            singleRule ? 'SingleRule' : ''
          }`,
          {
            amount: i18n.formatCurrency(value.from || 1),
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.AT_MOST:
        message = `${i18n.translate(
          `OfferRule.MinimumPurchaseAmountAtMost${
            singleRule ? 'SingleRule' : ''
          }`,
          {
            amount: i18n.formatCurrency(value.to),
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.EXACTLY:
        message = `${i18n.translate(
          `OfferRule.MinimumPurchaseAmountExactly${
            singleRule ? 'SingleRule' : ''
          }`,
          {
            amount: i18n.formatCurrency(value.exactly),
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
    }
  }

  if (index === 0 && !connector) {
    message = `${i18n.translate(
      `OfferRule.Apply${singleRule ? '' : 'If'}`
    )} ${message}`;
  }

  if (connector === OfferCartRuleGroupConnectorDtoEnum.BUT_NOT && index === 0) {
    message = `${i18n.translate('OfferRule.Unless')} ${message}`;
  }

  if (index > 0) {
    message = `${i18n.translate('OfferRule.Or')} ${message
      .charAt(0)
      .toLowerCase()}${message.slice(1)}`;
  }

  return `${message} ${currentSelections} ${beforeAfter}`;
};

const addExclusionRule = async (
  key: string,
  type: ShopifyObjectTypeDtoEnum | undefined,
  value: any,
  i18n: I18n,
  getLookupShopifyDetailedObjectsDetails: (
    query: Query,
    setIsLoading?: (value: boolean) => void,
    signal?: AbortSignal
  ) => Promise<any>,
  rules: JSX.Element[]
) => {
  if (!value) return;

  const res = type
    ? await getLookupShopifyDetailedObjectsDetails({ type, objects: value })
    : value;

  if (res) {
    const headerTitleResult = headerTitle(
      res,
      type as ShopifyObjectTypeDtoEnum,
      i18n
    );
    rules.push(
      <span key={key}>
        {i18n.translate(`OfferRule.${key}`)}{' '}
        {boldMessageParts(headerTitleResult)}
      </span>
    );
  }
};

export const generateRules = async (
  cartRule: any,
  i18n: I18n,
  getLookupShopifyDetailedObjectsDetails: (
    query: Query,
    setIsLoading?: (value: boolean) => void,
    signal?: AbortSignal
  ) => Promise<any>,
  shopLevelExclusionsData?: PrerequisiteEntitledExclusionsDto
): Promise<JSX.Element[]> => {
  const rules: JSX.Element[] = [];

  const { appliesTo, exclusions } = cartRule || {};

  // Rule for product match type
  if (appliesTo?.productMatchType?.enabled) {
    rules.push(
      <span key='productMatchType'>
        {i18n.translate(`OfferRule.${appliesTo.productMatchType.value}`)}
      </span>
    );
  }

  // Helper function to add rules from exclusions data
  const handleExclusions = async (
    source: string,
    exclusionsData?: PrerequisiteEntitledExclusionsDto
  ) => {
    if (!exclusionsData) return;

    // Map of exclusion types to their corresponding lookup types
    const exclusionMap: Record<
      keyof Pick<
        PrerequisiteEntitledExclusionsDto,
        'collections' | 'products' | 'productVariants'
      >,
      ShopifyObjectTypeDtoEnum
    > = {
      collections: ShopifyObjectTypeDtoEnum.COLLECTION,
      products: ShopifyObjectTypeDtoEnum.PRODUCT,
      productVariants: ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT,
    };

    // Handle exclusions requiring lookup
    await Promise.all(
      (Object.keys(exclusionMap) as Array<keyof typeof exclusionMap>).map(
        async (key) => {
          if (exclusionsData[key]?.enabled) {
            await addExclusionRule(
              `${key}`,
              exclusionMap[key],
              exclusionsData[key]?.value,
              i18n,
              getLookupShopifyDetailedObjectsDetails,
              rules
            );
          }
        }
      )
    );

    // Direct rules without lookup
    const directExclusions: Array<
      keyof Pick<
        PrerequisiteEntitledExclusionsDto,
        | 'tags'
        | 'vendors'
        | 'excludeProductNameFilter'
        | 'includeProductNameFilter'
        | 'excludeProductVariantNameFilter'
        | 'includeProductVariantNameFilter'
      >
    > = [
      'tags',
      'vendors',
      'excludeProductNameFilter',
      'includeProductNameFilter',
      'excludeProductVariantNameFilter',
      'includeProductVariantNameFilter',
    ];

    directExclusions.forEach((key) => {
      if (exclusionsData[key]?.enabled && exclusionsData[key]?.value) {
        const headerTitleResult = headerTitle(
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          exclusionsData[key]?.value!,
          appliesTo?.type,
          i18n
        );
        rules.push(
          <span key={`${source}_${key}`}>
            {i18n.translate(`OfferRule.${key}`)}{' '}
            {boldMessageParts(headerTitleResult)}
          </span>
        );
      }
    });

    // Boolean rules (no lookup needed)
    const booleanExclusions: Array<
      keyof Pick<
        PrerequisiteEntitledExclusionsDto,
        | 'excludeVariantsWithCompareAtPrice'
        | 'excludeVariantsWithoutCompareAtPrice'
        | 'excludeRecurringPurchase'
        | 'excludeOneTimePurchase'
      >
    > = [
      'excludeVariantsWithCompareAtPrice',
      'excludeVariantsWithoutCompareAtPrice',
      'excludeRecurringPurchase',
      'excludeOneTimePurchase',
    ];

    booleanExclusions.forEach((key) => {
      if (exclusionsData[key]) {
        rules.push(
          <span key={`${source}_${key}`}>
            {i18n.translate(`OfferRule.${key}`)}
          </span>
        );
      }
    });
  };

  // Add rules from cartRule.exclusions
  await handleExclusions('cart', exclusions);

  // Add rules from shopLevelExclusionsData
  await handleExclusions('shop', shopLevelExclusionsData);

  return rules;
};
