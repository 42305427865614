import React, { useCallback } from 'react';
import { Box, Button, InlineStack } from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';
import {
  OptionDtoListShopifyObjectDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTargetTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
} from 'core/components/SearchFieldWithGrayBox';
import { formateList } from 'core/utils';
import { SearchFieldWithGrayBox } from 'core/components/SearchFieldWithGrayBoxOfferWizard';

type ExclusionsResourcePickerProps = {
  formatType: OfferTargetTypeDtoEnum;
  resource: OptionDtoListShopifyObjectDto;
  setResource: (data: OptionDtoListShopifyObjectDto) => void;
};
export const ExclusionsResourcePicker: React.FC<
  ExclusionsResourcePickerProps
> = ({ formatType, resource, setResource }) => {
  const onResourcePickerClose = useCallback(() => {
    if (resource.enabled && !resource.value?.length) {
      setResource({ ...resource, enabled: false });
    }
  }, [resource]);

  return (
    <InlineStack blockAlign='start' gap='200' wrap={false}>
      <Box width='100%'>
        <SearchFieldWithGrayBox
          resourceType={
            formatType === OfferTargetTypeDtoEnum.COLLECTIONS
              ? GrayBoxResourceTypeEnum.Collection
              : GrayBoxResourceTypeEnum.Product
          }
          selectList={(resource.value as ResourceSelectionProps[]) || []}
          showVariants={formatType === OfferTargetTypeDtoEnum.VARIANTS}
          onSelectedChange={(value) => {
            const data = formateList(value, formatType);
            setResource({ ...resource, value: data as ShopifyObjectDto[] });
          }}
          onResourcePickerClose={onResourcePickerClose}
          immediatelyOpenPicker={!resource.value?.length}
          asExclusions
        />
      </Box>
      <Box paddingBlockStart='400'>
        <Button
          variant='tertiary'
          icon={DeleteIcon}
          onClick={() => setResource({ ...resource, enabled: false })}
        />
      </Box>
    </InlineStack>
  );
};
