import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferSuggestedRuleConditionGroupDto,
  OfferSuggestedRuleDto,
  OfferSuggestedRuleProposedItemDto,
  OfferSuggestedRuleSuggestedItemDto,
  ShopifyObjectDto,
  ShopifyProductAppBridgeResourceDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useI18n } from '@shopify/react-i18n';
import './RuleComponent.scss';
import classNames from 'classnames';
import {
  Collapsible,
  Icon,
  Button,
  Banner,
  RadioButton,
  Checkbox,
  IconSource,
  Tooltip,
} from '@shopify/polaris';
import {
  ChevronDownIcon,
  DuplicateIcon,
  CaretDownIcon,
} from '@shopify/polaris-icons';
import { Switch } from 'core/components/Switch/Switch';
import { Element } from 'react-scroll';
import MagicWand from './assets/wandMagicSparkles.svg';
import { usePrevious } from 'core/hooks/usePrevious';
import { isEmpty, isEqual } from 'lodash';
import { GroupComponent } from '../GroupComponent/GroupComponent';
import {
  OfferCartRuleGroupConnectorDtoEnum,
  OfferProposedItemActionTypeDtoEnum,
  OfferTargetTypeDtoEnum,
  OfferTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
  SearchFieldWithGrayBox,
} from 'core/components/SearchFieldWithGrayBox';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import { v4 as uuidv4 } from 'uuid';
// import { ComboBoxOptionsProps } from '../../../FreeGiftSection/FreeGiftSection';
import { useResourcePickerResources } from 'core/components/ResourcePickerAdapter/hooks/useResourcePickerResources';
import { Select } from 'core/components';

type RuleComponentProps = {
  rule?: OfferSuggestedRuleDto | null;
  aiSuggestionsEnabled?: boolean;
  aiSuggestionsRuleUpdate?: (value: boolean) => void;
  deleteRuleAction?: (id: string) => void;
  duplicateRuleAction?: (id: string) => void;
  updateRuleAction?: (id: string, body: OfferSuggestedRuleDto) => void;
  idx?: number;
  aiSuggestionsId?: string;
  onFormValidityChange(formIsValid: boolean): void;
  onlyOneRule?: boolean;
  offerType?: OfferTypeDtoEnum;
  limitExceeded?: boolean;
};

export type MergedSelectedGrayBoxElementsProps = {
  id?: string;
  title?: string;
  subtitle?: string;
  featuredImageUrl?: string;
};

export const RuleComponent: React.FC<RuleComponentProps> = (props) => {
  const {
    rule,
    aiSuggestionsEnabled,
    aiSuggestionsRuleUpdate,
    idx,
    deleteRuleAction,
    duplicateRuleAction,
    updateRuleAction,
    aiSuggestionsId,
    onFormValidityChange,
    onlyOneRule,
    offerType,
    limitExceeded,
  } = props;

  const [i18n] = useI18n();
  // const trashIconDef = findIconDefinition(trashAltIconLookup);
  // const icon = useIcon(plusIconLookup);
  const { productsListData } = useResourcePickerResources();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [currentRule, setCurrentRule] = useState<OfferSuggestedRuleDto>(
    rule || {}
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(
    !currentRule.proposedItem?.productVariant?.id
  );

  const [productItems, setProductItems] = useState(
    currentRule.suggestedItems
      ?.filter((item) => item.itemType === ShopifyObjectTypeDtoEnum.PRODUCT)
      .map((item) => item.item)
  );
  const [variantItems, setVariantItems] = useState(
    currentRule.suggestedItems
      ?.filter(
        (item) => item.itemType === ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
      )
      .map((item) => item.item)
  );

  const [products, setProducts] = useState<any>([]);
  const [variants, setVariants] = useState<any>([]);

  const getOrder = useCallback(
    (id: string, array: any[]) => {
      const findOrder = currentRule.suggestedItems?.find(
        (item) => item.item?.id === id
      )?.order;
      if (findOrder) {
        return findOrder;
      } else {
        const mergedOrders = array.map((item) => item.order);
        const availableOrders = Array.from({ length: 10 }, (_, i) => i + 1);
        const unusedOrders = availableOrders.filter(
          (order) => !mergedOrders.includes(order)
        );

        if (unusedOrders.length > 0) {
          return unusedOrders[0];
        } else {
          // Handle the case when all orders are already used
          // Here, you can either return a default value or handle the error
          return -1; // For example, returning -1 when all orders are used
        }
      }
    },
    [currentRule.suggestedItems]
  );

  const mergedSelectedGrayBoxElements = useMemo(
    () =>
      [...products, ...variants]
        .flatMap((item: ShopifyProductAppBridgeResourceDto) => {
          if (item.variants && item.variants.length) {
            return item.variants.map((variant) => ({
              id: variant.id,
              title: item.title,
              subtitle: variant.title,
              featuredImageUrl: item.featuredImageUrl,
            }));
          } else {
            return {
              id: item.id,
              title: item.title,
              subtitle: i18n.translate('anyVariant'),
              featuredImageUrl: item.featuredImageUrl,
            };
          }
        })
        .map((item, index, arr) => ({
          ...item,
          order: getOrder(item.id as string, arr),
        }))
        .sort((a, b) => a.order - b.order),
    [products, variants, i18n, currentRule.suggestedItems, getOrder]
  );

  const radioBtns = useMemo(
    () => [
      {
        label: i18n.translate('Propose'),
        tooltip: i18n.translate('ProposeTooltip'),
        value: OfferProposedItemActionTypeDtoEnum.PROPOSE_IN_BUILDING_BLOCKS,
      },
      {
        label: i18n.translate('AutoAdd'),
        tooltip: i18n.translate('AutoAddTooltip'),
        value: OfferProposedItemActionTypeDtoEnum.AUTO_ADD,
      },
    ],
    [i18n]
  );

  const selectList: ShopifyObjectDto[] = useMemo(() => {
    if (currentRule.proposedItem?.productVariant?.id) {
      return [currentRule.proposedItem?.productVariant];
    } else {
      return currentRule.proposedItem?.product?.id
        ? [currentRule.proposedItem?.product]
        : [];
    }
  }, [currentRule.proposedItem]);

  const selectedOptions: string[] = useMemo(
    () =>
      currentRule.proposedItem?.productVariant?.id
        ? [currentRule.proposedItem?.productVariant?.id]
        : [],
    [currentRule.proposedItem?.productVariant?.id]
  );

  const comboBoxOptions: any[] = useMemo(
    // ComboBoxOptionsProps[]
    () =>
      productsListData
        ?.find(
          (product) => product.id === currentRule.proposedItem?.product?.id
        )
        ?.variants?.map((variant) => ({
          value: variant.id || '',
          label: variant.title || '',
        })) || [],
    [productsListData, currentRule.proposedItem]
  );

  const prevRule = usePrevious(currentRule);
  const prevProductItems = usePrevious(productItems);
  const prevVariantItems = usePrevious(variantItems);

  const updateCurrentRule = useCallback(
    (
      field: keyof OfferSuggestedRuleDto,
      data: OfferSuggestedRuleDto[keyof OfferSuggestedRuleDto]
    ) => {
      setCurrentRule((prevState: OfferSuggestedRuleDto) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [setCurrentRule]
  );

  const deleteGroup = useCallback(
    (index: number) => {
      const newGroups = currentRule.groups ? [...currentRule.groups] : [];
      newGroups.splice(index, 1);
      updateCurrentRule('groups', newGroups);
    },
    [updateCurrentRule, currentRule.groups]
  );

  const addGroup = useCallback(() => {
    const currentGroups: OfferSuggestedRuleConditionGroupDto[] =
      currentRule.groups ? [...currentRule.groups] : [];
    const newGroups = [
      ...currentGroups,
      {
        connector: OfferCartRuleGroupConnectorDtoEnum.AND,
        conditions: [],
      },
    ];
    updateCurrentRule('groups', newGroups);
  }, [updateCurrentRule, currentRule.groups]);

  const updateGroup = useCallback(
    (group: OfferSuggestedRuleConditionGroupDto, idx: number) => {
      if (currentRule.groups && idx < currentRule.groups?.length) {
        const updatedGroups = [...currentRule.groups];
        updatedGroups[idx] = group;
        updateCurrentRule('groups', updatedGroups);
      }
    },
    [currentRule.groups, updateCurrentRule]
  );

  const handleIsExpanded = useCallback(
    () => setIsExpanded((active) => !active),
    [setIsExpanded]
  );

  const handleBlur = useCallback(
    (e: any) => {
      setIsFocused(false);
      updateCurrentRule('name', e.currentTarget.textContent);
      if (prevRule && prevRule?.name !== currentRule?.name && currentRule.id) {
        updateRuleAction?.(currentRule.id, currentRule);
      }
    },
    [setIsFocused, prevRule, currentRule, updateRuleAction]
  );

  const handleSelectChangeProduct = useCallback(
    (list: ResourceSelectionProps[]) => {
      const listWithoutVariants = list.map((item) => ({
        ...item,
        variants: null,
      }));
      const formatedList = formateList(list, OfferTargetTypeDtoEnum.PRODUCTS);
      setProducts(listWithoutVariants);
      setProductItems(formatedList as ShopifyObjectDto[]);
    },
    [formateList, setProductItems, setProducts]
  );

  const handleSelectChangeProductVariants = useCallback(
    (list: ResourceSelectionProps[]) => {
      const formatedList = formateList(list, OfferTargetTypeDtoEnum.VARIANTS);
      setVariants(list);
      setVariantItems(formatedList as ShopifyObjectDto[]);
    },
    [formateList, setVariantItems, setVariants]
  );

  const changeSuggestedItemOrder = useCallback(
    (startIndex: number, endIndex: number) => {
      const result = currentRule.suggestedItems
        ? [...currentRule.suggestedItems]
        : [];
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      updateCurrentRule(
        'suggestedItems',
        result.map((item, index) => ({
          ...item,
          order: index + 1,
        }))
      );
    },
    [updateCurrentRule, currentRule.suggestedItems]
  );

  const handleSelectorChange = useCallback(
    (selectedOptions: string[]) => {
      selectedOptions[0] &&
        updateCurrentRule('proposedItem', {
          ...currentRule.proposedItem,
          productVariant: {
            id: selectedOptions[0],
            productId: currentRule.proposedItem?.product?.id,
          },
        });
    },
    [currentRule.proposedItem?.product?.id, updateCurrentRule]
  );

  const handleSelectChange = useCallback(
    (list: ResourceSelectionProps[]) => {
      if (list.length) {
        const data: OfferSuggestedRuleProposedItemDto = {
          ...currentRule.proposedItem,
          product: formateList(list, 'VARIANTS', true)[0] as ShopifyObjectDto,
        };
        setIsDefault(true);
        updateCurrentRule('proposedItem', data);
      }
    },
    [updateCurrentRule, formateList, setIsDefault, currentRule.proposedItem]
  );

  const handleDefaultVariantCheckbox = useCallback(
    (val: boolean) => {
      setIsDefault(val);
      val &&
        updateCurrentRule('proposedItem', {
          ...currentRule.proposedItem,
          productVariant: undefined,
        });
    },
    [setIsDefault, updateCurrentRule, currentRule.proposedItem]
  );

  useEffect(() => {
    if (
      (productItems &&
        prevProductItems &&
        !isEqual(prevProductItems, productItems)) ||
      (variantItems &&
        prevVariantItems &&
        !isEqual(prevVariantItems, variantItems))
    ) {
      const newProductItems: OfferSuggestedRuleSuggestedItemDto[] =
        productItems?.map((item) => ({
          item,
          itemType: ShopifyObjectTypeDtoEnum.PRODUCT,
        })) || [];
      const newVariantItems: OfferSuggestedRuleSuggestedItemDto[] =
        variantItems?.map((item) => ({
          item,
          itemType: ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT,
        })) || [];
      const mergedItems = [...newProductItems, ...newVariantItems]
        .map((item, index, arr) => ({
          ...item,
          order: getOrder(item.item?.id as string, arr),
        }))
        .sort((a, b) => a.order - b.order);
      updateCurrentRule('suggestedItems', mergedItems);
    }
  }, [
    productItems,
    variantItems,
    prevProductItems,
    prevVariantItems,
    updateCurrentRule,
    getOrder,
  ]);

  useEffect(() => {
    if (
      !isFocused &&
      prevRule &&
      currentRule.id &&
      !isEqual(prevRule, currentRule)
    ) {
      updateRuleAction?.(currentRule.id, currentRule);
    }
  }, [isFocused, prevRule, currentRule, updateRuleAction]);

  const ruleToRender = useMemo(() => {
    if (!isEmpty(currentRule) && typeof idx === 'number') {
      return (
        <>
          <div
            className={classNames({
              RuleWrapper: true,
            })}
          >
            <div
              className={classNames({
                RuleTitle: true,
                RuleOpen: isExpanded,
              })}
              onClick={handleIsExpanded}
            >
              <div className='ruleName'>
                <div className='number'>{`${i18n.translate('Rule')} ${
                  idx + 1
                }`}</div>
                <span className='inputWrapper'>
                  <span
                    className='input'
                    role='textbox'
                    contentEditable
                    suppressContentEditableWarning
                    onClick={(e) => e.stopPropagation()}
                    onFocus={() => setIsFocused(true)}
                    onBlur={handleBlur}
                  >
                    {currentRule?.name || ''}
                  </span>
                  <span className='placeholder'>
                    {i18n.translate('Placeholder')}
                  </span>
                </span>
              </div>
              <Switch
                id={`ruleEnableSwitch${idx}`}
                labels={{ on: 'ON', off: 'OFF' }}
                checked={!!currentRule.enabled}
                onChange={() =>
                  updateCurrentRule('enabled', !currentRule.enabled)
                }
              />
              <Icon source={ChevronDownIcon as IconSource} tone='base' />
            </div>
            <Collapsible id='rule-collapsible' open={isExpanded}>
              <div className='groups'>
                {currentRule.groups?.map((group, index) => (
                  <GroupComponent
                    group={group}
                    idx={index}
                    updateGroup={updateGroup}
                    deleteGroup={deleteGroup}
                    key={uuidv4()}
                    offerType={offerType}
                  />
                ))}
                {currentRule.groups && currentRule.groups?.length < 5 ? (
                  <Button
                    // FIX FONTAWESOME
                    // icon={icon}
                    onClick={addGroup}
                  >
                    {i18n.translate('AddCondition')}
                  </Button>
                ) : (
                  <Banner tone='info'>
                    <p className='header'>
                      {i18n.translate('MaxConditionGroups')}
                    </p>
                    <p className='body'>
                      {i18n.translate('MaxConditionGroupsSuggestion')}
                    </p>
                  </Banner>
                )}
              </div>
              {offerType === OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER ? (
                <div className='suggestedItemsBlock'>
                  <h6 className='title'>
                    {i18n.translate('SuggestedItemsTitle')}
                  </h6>
                  <span
                    className='description'
                    style={{ marginBottom: '4px !important' }}
                  >
                    {i18n.translate('SuggestedItemsDescription')}
                  </span>
                  <SearchFieldWithGrayBox
                    selectList={productItems as ResourceSelectionProps[]}
                    resourceType='Product'
                    showLabel
                    isFBTtype
                    getOnMount={setProducts}
                    onSelectedChange={handleSelectChangeProduct}
                    onFormValidityChange={(value) =>
                      onFormValidityChange(!value)
                    }
                    isLimitReached={
                      !!(
                        currentRule.suggestedItems &&
                        currentRule.suggestedItems?.length > 10
                      )
                    }
                  />
                  <SearchFieldWithGrayBox
                    selectList={variantItems as ResourceSelectionProps[]}
                    resourceType='Product'
                    showVariants
                    showLabel
                    isFBTtype
                    mergedSelectedGrayBoxElements={
                      mergedSelectedGrayBoxElements as MergedSelectedGrayBoxElementsProps[]
                    }
                    getOnMount={setVariants}
                    onSelectedChange={handleSelectChangeProductVariants}
                    onFormValidityChange={(value) =>
                      onFormValidityChange(!value)
                    }
                    changeSuggestedItemOrder={changeSuggestedItemOrder}
                    isLimitReached={
                      !!(
                        currentRule.suggestedItems &&
                        currentRule.suggestedItems?.length > 10
                      )
                    }
                  />
                </div>
              ) : (
                <div className='actionTypeBlock'>
                  <div className='title'>{i18n.translate('ActionType')}</div>
                  <div className='radioBtns'>
                    {radioBtns.map((btn) => (
                      <RadioButton
                        key={btn.value}
                        label={
                          <div className='label'>
                            <span className='text'>{btn.label}</span>
                            <Tooltip
                              preferredPosition='mostSpace'
                              content={btn.tooltip}
                            >
                              {/*FIX FONTAWESOME */}
                              {/* <span className='tooltip'>
                                <FontAwesomeIcon
                                  icon={findIconDefinition(
                                    questionMarkIconLookup
                                  )}
                                  color='#5C5F62'
                                />
                              </span> */}
                            </Tooltip>
                          </div>
                        }
                        checked={currentRule.actionType === btn.value}
                        id={btn.label}
                        name={btn.label}
                        onChange={() =>
                          updateCurrentRule('actionType', btn.value)
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
              {offerType === OfferTypeDtoEnum.ORDER_BUMP && (
                <div className='proposedItemsBlock'>
                  <div className='title'>
                    {i18n.translate('ProposeSection')}
                  </div>
                  <SearchFieldWithGrayBox
                    selectList={selectList as ResourceSelectionProps[]}
                    resourceType={GrayBoxResourceTypeEnum.Product}
                    showVariants
                    productVariantId={
                      currentRule.proposedItem?.productVariant?.id
                    }
                    onSelectedChange={handleSelectChange}
                    onFormValidityChange={(value) =>
                      onFormValidityChange(!value)
                    }
                    isOrderBumpType
                    showLabel
                  />
                  {currentRule.proposedItem?.product?.id && (
                    <>
                      <Checkbox
                        checked={isDefault}
                        label={i18n.translate('DefaultVariant')}
                        onChange={handleDefaultVariantCheckbox}
                      />
                      {!isDefault && comboBoxOptions?.length && (
                        <div className='SelectContainer'>
                          <Select
                            options={comboBoxOptions}
                            selectedOptions={selectedOptions}
                            label=''
                            placeholder={i18n.translate('SelectVariant')}
                            hideLabel={true}
                            suffix={
                              <Icon
                                source={CaretDownIcon as IconSource}
                                tone='base'
                              />
                            }
                            onChange={handleSelectorChange}
                          />
                          <div
                            className='SelectInputInfoText'
                            style={{ marginBottom: 0 }}
                          >
                            {i18n.translate('SelectVariantNote')}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              <div className='mainOperationBtns'>
                <div
                  className={classNames({
                    TrashBlock: true,
                    Disable: onlyOneRule,
                  })}
                  onClick={() =>
                    currentRule.id &&
                    !onlyOneRule &&
                    deleteRuleAction?.(currentRule.id)
                  }
                >
                  {/*FIX FONTAWESOME */}
                  {/* <FontAwesomeIcon icon={trashIconDef} /> */}
                  <span>{i18n.translate('Delete')}</span>
                </div>
                <Button
                  disabled={limitExceeded}
                  icon={<DuplicateIcon />}
                  onClick={() =>
                    currentRule.id && duplicateRuleAction?.(currentRule.id)
                  }
                >
                  {i18n.translate('Duplicate')}
                </Button>
              </div>
            </Collapsible>
          </div>
        </>
      );
    } else {
      return (
        <Element name={aiSuggestionsId as string} className='RuleComponent'>
          <div
            className={classNames({
              RuleWrapper: true,
              AiSuggestionsRule: true,
            })}
          >
            <div
              className={classNames({
                RuleTitle: true,
                RuleOpen: isExpanded,
              })}
              onClick={handleIsExpanded}
            >
              <div className='ruleName'>
                <img src={MagicWand} alt='Magic wand' />
                <div className='number'>{i18n.translate('AISuggestions')}</div>
              </div>
              <Switch
                id='aiSuggestionsSwitch'
                labels={{ on: 'ON', off: 'OFF' }}
                checked={!!aiSuggestionsEnabled}
                onChange={() =>
                  aiSuggestionsRuleUpdate?.(!aiSuggestionsEnabled)
                }
              />
              <Icon source={ChevronDownIcon as IconSource} tone='base' />
            </div>
            <Collapsible id='ai-suggestions-rule-collapsible' open={isExpanded}>
              <div className='content'>
                {i18n.translate('AISuggestionsDescription')}
              </div>
            </Collapsible>
          </div>
        </Element>
      );
    }
  }, [
    currentRule,
    radioBtns,
    isExpanded,
    aiSuggestionsEnabled,
    idx,
    updateRuleAction,
    aiSuggestionsRuleUpdate,
    deleteRuleAction,
    duplicateRuleAction,
    addGroup,
    i18n,
    aiSuggestionsId,
    mergedSelectedGrayBoxElements,
    isDefault,
    comboBoxOptions,
    selectedOptions,
    handleDefaultVariantCheckbox,
    handleSelectorChange,
  ]);

  return ruleToRender;
};
