import React from 'react';
import {
  Card,
  BlockStack,
  Checkbox,
  Text,
  Banner,
  Link,
} from '@shopify/polaris';
import { JsonMetafieldsIntegrationsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';

type JsonMetafieldsProps = {
  jsonMetafields?: JsonMetafieldsIntegrationsSettingsDto;
  setJsonMetafields: (value: JsonMetafieldsIntegrationsSettingsDto) => void;
};

export const JsonMetafields: React.FC<JsonMetafieldsProps> = ({
  jsonMetafields,
  setJsonMetafields,
}) => {
  const [i18n] = useI18n();
  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('JsonMetafields')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('JsonMetafieldsSubtitle')}
          </Text>
        </BlockStack>
        <Banner>
          {i18n.translate('JsonMetafieldsBanner', {
            following: <Link>{i18n.translate('FollowingArticle')}</Link>,
          })}
        </Banner>
        <Checkbox
          label={i18n.translate('CheckboxLabel')}
          checked={
            jsonMetafields?.storeDiscountApplicationDataInOrderMetafields
          }
          onChange={(value) =>
            setJsonMetafields({
              storeDiscountApplicationDataInOrderMetafields: value,
            })
          }
        />
      </BlockStack>
    </Card>
  );
};
