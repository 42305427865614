import React from 'react';
import { Card, BlockStack, Checkbox, Text, Box } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TagsMetadataSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';

type ProductTagsProps = {
  autoTaggingState: TagsMetadataSettingsDto;
  handleAutoTaggingState: (
    field: keyof TagsMetadataSettingsDto,
    value: TagsMetadataSettingsDto[keyof TagsMetadataSettingsDto]
  ) => void;
};

export const ProductTags: React.FC<ProductTagsProps> = ({
  autoTaggingState,
  handleAutoTaggingState,
}) => {
  const {
    tagDiscountedProductsWithOfferToken,
    tagDiscountedProductsWithFixedTags,
  } = autoTaggingState;
  const [i18n] = useI18n();

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='semibold'>
            {i18n.translate('ProductTags')}
          </Text>

          <Text as='p' tone='subdued'>
            {i18n.translate('ProductTagsSubtitle')}
          </Text>
        </BlockStack>
        <Checkbox
          label={i18n.translate('OfferTokenTitle')}
          checked={tagDiscountedProductsWithOfferToken}
          onChange={(value) =>
            handleAutoTaggingState('tagDiscountedProductsWithOfferToken', value)
          }
        />
        <Checkbox
          label={i18n.translate('FixedTagsTitle')}
          checked={tagDiscountedProductsWithFixedTags?.enabled}
          helpText={
            tagDiscountedProductsWithFixedTags?.enabled && (
              <Box paddingBlockStart='200'>
                <MultiselectTagCombobox
                  label={i18n.translate('ProductTags')}
                  id='ProductTagsMultiselector'
                  labelHidden={false}
                  placeholder={i18n.translate('ProductTagsPlaceholder')}
                  selectedTags={tagDiscountedProductsWithFixedTags.value || []}
                  requiredIndicator={false}
                  setSelectedTags={(value) =>
                    handleAutoTaggingState(
                      'tagDiscountedProductsWithFixedTags',
                      {
                        ...tagDiscountedProductsWithFixedTags,
                        value: value,
                      }
                    )
                  }
                />
              </Box>
            )
          }
          onChange={(value) =>
            handleAutoTaggingState('tagDiscountedProductsWithFixedTags', {
              ...tagDiscountedProductsWithFixedTags,
              enabled: value,
            })
          }
        />
      </BlockStack>
    </Card>
  );
};
