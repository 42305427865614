import {
  Badge,
  BlockStack,
  Card,
  Checkbox,
  ChoiceList,
  InlineError,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionSummaryTotalDiscountCalculationModeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import React, { useEffect, useMemo } from 'react';
import { PromotionSummaryTotalDiscountCalculationModeDto } from 'core/api/adminSettings/adminSettingsApi';
import { Element } from 'react-scroll';
import { useAppSelector } from 'core/hooks';
type TotalDiscountSettingProps = {
  total: PromotionSummaryTotalDiscountCalculationModeDto;
  showTotal: boolean;
  showDiscountBreakdown: boolean;
  setShowTotal: (value: boolean) => void;
  setShowDiscountBreakdown: (value: boolean) => void;
  setTotal: (
    value: PromotionSummaryTotalDiscountCalculationModeDtoEnum
  ) => void;
  onFormValidityChange: (sectionName: string, isValid: boolean) => void;
};
export const TotalDiscountSetting: React.FC<TotalDiscountSettingProps> = ({
  total,
  showTotal,
  showDiscountBreakdown,
  setTotal,
  setShowTotal,
  setShowDiscountBreakdown,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();
  const { showValidation } = useAppSelector((store) => store.promotions);

  const totalDiscountOptions = useMemo(
    () =>
      Object.values(PromotionSummaryTotalDiscountCalculationModeDtoEnum).map(
        (value) => ({
          label: (
            <InlineStack gap='200'>
              <Text as='p'>{i18n.translate(value)}</Text>
              {value ===
                PromotionSummaryTotalDiscountCalculationModeDtoEnum.ORDER_DISCOUNTS_ONLY && (
                <Badge>{i18n.translate('Recommended')}</Badge>
              )}
            </InlineStack>
          ),
          value: value,
          helpText: i18n.translate(`${value}_HELP`),
        })
      ),
    [i18n]
  );

  const isTotalDiscountInvalid = useMemo(
    () =>
      total ===
        PromotionSummaryTotalDiscountCalculationModeDtoEnum.SUM_OF_ALL &&
      !showTotal &&
      !showDiscountBreakdown,
    [total, showTotal, showDiscountBreakdown]
  );

  useEffect(() => {
    onFormValidityChange?.('TotalDiscount', !isTotalDiscountInvalid);
  }, [isTotalDiscountInvalid]);

  return (
    <Element name='TotalDiscount' className='TotalDiscount'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('TotalDiscountCalculation')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          <ChoiceList
            title=''
            choices={totalDiscountOptions}
            selected={[
              total ||
                PromotionSummaryTotalDiscountCalculationModeDtoEnum.ORDER_DISCOUNTS_ONLY,
            ]}
            onChange={(value) =>
              setTotal(
                value[0] as PromotionSummaryTotalDiscountCalculationModeDtoEnum
              )
            }
          />
          {total ===
            PromotionSummaryTotalDiscountCalculationModeDtoEnum.SUM_OF_ALL && (
            <BlockStack gap='200'>
              <Text as='p' fontWeight='medium'>
                {i18n.translate('DiscountSettings')}
              </Text>
              <Checkbox
                label={i18n.translate('ShowTotal')}
                checked={showTotal}
                onChange={(value) => setShowTotal(value)}
                error={isTotalDiscountInvalid && showValidation}
              />
              <Checkbox
                label={i18n.translate('ShowDiscountBreakdown')}
                checked={showDiscountBreakdown}
                onChange={(value) => setShowDiscountBreakdown(value)}
                error={isTotalDiscountInvalid && showValidation}
              />
              {isTotalDiscountInvalid && showValidation && (
                <InlineError
                  message={i18n.translate('ValidationError')}
                  fieldID='PromotionSummaryDiscountSettings'
                />
              )}
            </BlockStack>
          )}
        </BlockStack>
      </Card>
    </Element>
  );
};
