import React, { useCallback, useMemo } from 'react';
import { Card } from '@shopify/polaris';
import './PromotionStatus.scss';
import {
  PromotionOverviewDto,
  PromotionStatusDto,
  PromotionScheduleDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  PromotionStatusDtoEnum,
  PromotionTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import ProgressStatus from './components/ProgressStatus/ProgressStatus';
import DefaultStatus from './components/DefaultStatus/DefaultStatus';

type PromotionStatusProps = {
  promotionId: string;
  promotionOverviewData: PromotionOverviewDto;
  scheduleIsLoading: boolean;
  isPromotionEditable: boolean;
  hasOffers: boolean;
  toggleScheduleModal: () => void;
  onSchedulePromotion: (data: PromotionScheduleDto) => void;
};

export const PromotionStatus: React.FC<PromotionStatusProps> = ({
  promotionOverviewData,
  promotionId,
  scheduleIsLoading,
  hasOffers,
  isPromotionEditable,
  toggleScheduleModal,
  onSchedulePromotion,
}) => {
  const SDCScheduleForbidden = useMemo(() => {
    const {
      shopifyDiscountApp,
      shopifyDiscountCode,
      shopifyDiscountGroup,
      promotionType,
    } = promotionOverviewData.settings || {};

    return (
      promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
      (shopifyDiscountGroup ||
        shopifyDiscountCode ||
        (!shopifyDiscountGroup && !shopifyDiscountCode && !shopifyDiscountApp))
    );
  }, [promotionOverviewData.settings]);

  const isPromotionInProgress = useMemo(
    () =>
      [
        PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING,
        PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING,
        PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK,
      ].includes(promotionOverviewData.status as PromotionStatusDtoEnum),
    [promotionOverviewData.status]
  );

  const onRemoveSchedule = useCallback(() => {
    onSchedulePromotion({ startsAt: null, endsAt: null });
  }, []);

  return (
    <Card>
      {isPromotionInProgress ? (
        <ProgressStatus
          operationProgress={promotionOverviewData.operationProgress || {}}
          promotionId={promotionId}
        />
      ) : (
        <DefaultStatus
          promotionStatus={promotionOverviewData.status as PromotionStatusDto}
          schedule={promotionOverviewData.schedule || {}}
          scheduleIsLoading={scheduleIsLoading}
          isPromotionEditable={
            isPromotionEditable && !SDCScheduleForbidden && hasOffers
          }
          toggleScheduleModal={toggleScheduleModal}
          onRemoveSchedule={onRemoveSchedule}
        />
      )}
    </Card>
  );
};
