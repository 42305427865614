import React, { useCallback, useMemo } from 'react';
import {
  Card,
  BlockStack,
  Text,
  Checkbox,
  ChoiceList,
  InlineStack,
  Button,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { GiftPriceGiftsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { GiftPriceHideOnPagesDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { useIsDebugOrLocal } from 'core/hooks';
import { useSearchParams } from 'react-router-dom';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';

type GiftPriceProps = {
  giftPrice?: GiftPriceGiftsSettingsDto;
  setGiftPrice: (data: GiftPriceGiftsSettingsDto) => void;
};

export const GiftPrice: React.FC<GiftPriceProps> = ({
  giftPrice,
  setGiftPrice,
}) => {
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [searchParams, setSearchParams] = useSearchParams();

  const giftPriceOptions = useMemo(
    () =>
      Object.values(GiftPriceHideOnPagesDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    []
  );

  const toggleHidePrice = useCallback(
    (value: boolean) => {
      setGiftPrice({
        hideGiftOriginalPrice: value,
        hideOnPages: !value
          ? null
          : giftPrice?.hideOnPages || GiftPriceHideOnPagesDtoEnum.ALL_PAGES,
      });
    },
    [giftPrice]
  );

  const navigateToTranslations = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    searchParams.set('path', SettingsModalPathsEnums.Translations);
    setSearchParams(searchParams);
  }, [searchParams]);

  return (
    <Card padding='400'>
      <BlockStack gap='400'>
        <InlineStack align='space-between'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('GiftPrice')}
          </Text>
          <Button onClick={navigateToTranslations} variant='plain'>
            {i18n.translate('ConfigureTranslations')}
          </Button>
        </InlineStack>

        <Checkbox
          label={i18n.translate('HideOriginalPrice')}
          checked={giftPrice?.hideGiftOriginalPrice}
          helpText={i18n.translate('HideOriginalPriceHelp')}
          onChange={toggleHidePrice}
        />
        {giftPrice?.hideGiftOriginalPrice && (
          <ChoiceList
            title={i18n.translate('HideOnPages')}
            choices={giftPriceOptions}
            selected={[giftPrice.hideOnPages as string]}
            onChange={(value) =>
              setGiftPrice({
                ...giftPrice,
                hideOnPages: value[0] as GiftPriceHideOnPagesDtoEnum,
              })
            }
          />
        )}
      </BlockStack>
    </Card>
  );
};
