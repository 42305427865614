import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { TagsMetadataSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { useAppDispatch } from 'core/hooks';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';
import { BasedAutoTaggingComponent } from './components/BasedAutoTaggingComponent/BasedAutoTaggingComponent';
import { CouponFraudTags } from './components/CouponFraudTags/CouponFraudTags';
import { CustomerTags } from './components/CustomerTags/CustomerTags';
import { ProductTags } from './components/ProductTags/ProductTags';

export const AutoTagging = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const pageBackAction = useSettingsPageBackAction();

  const { saveAutoTagging, autoTaggingData, autoTaggingDataIsFetching } =
    useConfigureSettings(SettingsFetchTypeEnum.AUTO_TAGGING);

  const [autoTaggingState, setAutoTaggingState] =
    useState<TagsMetadataSettingsDto>({});
  const [savedData, setSavedData] = useState<TagsMetadataSettingsDto>({});

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(autoTaggingState);
    return saveAutoTagging(autoTaggingState, onSuccess);
  }, [autoTaggingState]);

  const handleAutoTaggingState = useCallback(
    (
      field: keyof TagsMetadataSettingsDto,
      value: TagsMetadataSettingsDto[keyof TagsMetadataSettingsDto]
    ) => setAutoTaggingState({ ...autoTaggingState, [field]: value }),
    [autoTaggingState]
  );

  useImperativeHandle(ref, () => ({
    discardChanges: () => setAutoTaggingState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, autoTaggingState)));
  }, [autoTaggingState, savedData]);

  useEffect(() => {
    if (!isEmpty(autoTaggingData)) {
      setAutoTaggingState(autoTaggingData);
      setSavedData(autoTaggingData);
    }
  }, [autoTaggingData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <>
      {!autoTaggingDataIsFetching && autoTaggingState ? (
        <Page
          title={i18n.translate('Title')}
          subtitle={i18n.translate('Subtitle')}
          backAction={pageBackAction}
        >
          <BlockStack gap='600'>
            <BasedAutoTaggingComponent
              title={i18n.translate('DiscountNinjaTag')}
              subtitle={i18n.translate('DiscountNinjaTagSubtitle')}
              autoTaggingState={autoTaggingState}
              keys={['discountNinjaOriginatedTag']}
              handleAutoTaggingState={handleAutoTaggingState}
            />
            <BasedAutoTaggingComponent
              title={i18n.translate('PromotionTags')}
              subtitle={i18n.translate('PromotionTagsSubtitle')}
              autoTaggingState={autoTaggingState}
              keys={[
                'prefixForAppliedDiscountCode',
                'prefixForEachAppliedOffer',
                'prefixForEachAppliedPromotion',
                'prefixForEachAppliedPromotionCode',
              ]}
              handleAutoTaggingState={handleAutoTaggingState}
            />
            <CouponFraudTags
              addTagFlagCouponFraud={!!autoTaggingState.addTagFlagCouponFraud}
              setAddTagFlagCouponFraud={(value) =>
                handleAutoTaggingState('addTagFlagCouponFraud', value)
              }
            />
            <CustomerTags
              autoTaggingState={autoTaggingState}
              handleAutoTaggingState={handleAutoTaggingState}
            />
            <ProductTags
              autoTaggingState={autoTaggingState}
              handleAutoTaggingState={handleAutoTaggingState}
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
AutoTagging.displayName = 'AutoTagging';
