import { useCallback, useRef, useState, useEffect } from 'react';
import {
  NinjaCartWidgetInstallationDto,
  WidgetInstallationDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  NinjaCartWidgetGroupDtoEnum,
  ThemeTypeDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { useWidgetsControlCenter } from 'core/hooks/useWidgetsControlCenter';

type UseToggleWidgetProps = {
  widgetThemeType: ThemeTypeDtoEnum;
  isWidgetControlCenterPage: boolean;
  setActionWidget: React.Dispatch<
    React.SetStateAction<
      WidgetInstallationDto | NinjaCartWidgetInstallationDto | null
    >
  >;
  refetchWidgetInstallationData: () => void;
};

const ENABLED_STATUSES = [
  WidgetStatusDtoEnum.ENABLED_VIA_CODE,
  WidgetStatusDtoEnum.ENABLED_VIA_CSS,
  WidgetStatusDtoEnum.ENABLED,
  WidgetStatusDtoEnum.ENABLED_BUILT_IN,
];

export const useToggleWidget = ({
  widgetThemeType,
  isWidgetControlCenterPage,
  setActionWidget,
  refetchWidgetInstallationData,
}: UseToggleWidgetProps) => {
  const { turnWidgetOnManually } = useWidgetsControlCenter(undefined, true);
  const [loading, setLoading] = useState(false);
  const widgetThemeTypeRef = useRef(widgetThemeType);

  const updateWidgetStatus = useCallback(
    async (
      widget: WidgetInstallationDto | NinjaCartWidgetInstallationDto,
      status: WidgetStatusDtoEnum
    ) => {
      setLoading(true);
      try {
        const customProperties =
          'customProperties' in widget ? widget.customProperties : {};

        await turnWidgetOnManually(
          {
            type: widget.type,
            status,
            customProperties: { ...customProperties },
          },
          widgetThemeTypeRef.current,
          refetchWidgetInstallationData,
          undefined,
          isWidgetControlCenterPage
        );
      } finally {
        setLoading(false);
      }
    },
    [
      turnWidgetOnManually,
      refetchWidgetInstallationData,
      isWidgetControlCenterPage,
    ]
  );

  const toggleWidget = useCallback(
    async (
      widget: (WidgetInstallationDto | NinjaCartWidgetInstallationDto) & {
        isAppBlockRequestSupport?: boolean;
      }
    ) => {
      const isWidgetEnabled = ENABLED_STATUSES.includes(
        widget.status as WidgetStatusDtoEnum
      );
      const newStatus = isWidgetEnabled
        ? WidgetStatusDtoEnum.DISABLED
        : WidgetStatusDtoEnum.ENABLED;

      const { installationMode } = widget;
      const isCustomOrAppBlockActive =
        (installationMode === WidgetInstallationModeDtoEnum.CUSTOM &&
          !isWidgetEnabled) ||
        installationMode === WidgetInstallationModeDtoEnum.APP_BLOCKS;

      if (isCustomOrAppBlockActive) {
        setActionWidget(widget);
      } else if (
        [
          WidgetInstallationModeDtoEnum.APP_EMBED,
          NinjaCartWidgetGroupDtoEnum.ADD_ON,
          NinjaCartWidgetGroupDtoEnum.NINJA_CART_PRO,
          NinjaCartWidgetGroupDtoEnum.NINJA_X_UPSELL,
        ].includes(
          installationMode as
            | NinjaCartWidgetGroupDtoEnum
            | WidgetInstallationModeDtoEnum
        ) ||
        (isWidgetEnabled && installationMode)
      ) {
        await updateWidgetStatus(widget, newStatus);
      }
    },
    [setActionWidget, updateWidgetStatus]
  );

  useEffect(() => {
    widgetThemeTypeRef.current = widgetThemeType;
  }, [widgetThemeType]);

  return { toggleWidget, loading };
};
