import React, { useCallback, useMemo } from 'react';
import { Listbox, Combobox, Icon, Box } from '@shopify/polaris';
import { SelectIcon } from '@shopify/polaris-icons';
import { ComboboxOption } from '../ControlLibrary';

type MultiSelectorProps = {
  label: string;
  options: ComboboxOption[];
  selectedOptions?: string[];
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  error?: string | boolean;
  onChange: (value: string[]) => void;
};

export const MultiSelector: React.FC<MultiSelectorProps> = ({
  label,
  options,
  disabled,
  placeholder,
  selectedOptions,
  id,
  error,
  onChange,
}) => {
  const updateSelection = useCallback(
    (selected: string) => {
      if (selectedOptions?.includes(selected)) {
        onChange(selectedOptions.filter((option) => option !== selected));
      } else {
        onChange([...(selectedOptions || []), selected]);
      }
    },
    [selectedOptions, onChange]
  );

  const inputPreview = useMemo(() => {
    const selected = options
      ?.filter((option) => selectedOptions?.includes(option.value))
      .map((option) => option.label);
    return selected.join(', ');
  }, [selectedOptions, options]);

  const optionsMarkup =
    options.length > 0
      ? options.map((option) => {
          const { label, value } = option;

          return (
            <Listbox.Option
              key={`${value}`}
              value={value}
              selected={selectedOptions?.includes(value)}
              accessibilityLabel={label}
            >
              {label}
            </Listbox.Option>
          );
        })
      : null;

  return (
    <Box id={id}>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            suffix={<Icon source={SelectIcon} />}
            label={label}
            value={inputPreview}
            placeholder={placeholder}
            autoComplete='off'
            error={error}
            disabled={disabled}
          />
        }
      >
        {optionsMarkup ? (
          <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
        ) : null}
      </Combobox>
    </Box>
  );
};
