import { SortButtonChoice } from '@shopify/polaris';
import { I18n } from '@shopify/react-i18n';
import {
  OrderAnalyticsSortFieldDtoEnum,
  PromotionActivityAnalyticsSortFieldDtoEnum,
  PromotionCodeAnalyticsSortFieldDtoEnum,
} from 'core/api/adminAnalytics/adminAnalyticsEnums';
import { ReportListTypeEnum } from 'pages';

export const sortPopoverOptions = (
  i18n: I18n,
  listType: ReportListTypeEnum
): SortButtonChoice[] => {
  switch (listType) {
    case ReportListTypeEnum.PROMOTION_ACTIVITY:
      return [
        {
          label: i18n.translate('PromotionName'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.PROMOTION_NAME} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('PromotionName'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.PROMOTION_NAME} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('Sessions'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.SESSIONS} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('Sessions'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.SESSIONS} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('AddedToCart'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.ADD_TO_CART} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('AddedToCart'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.ADD_TO_CART} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('ReachedCheckout'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.CHECKOUT_INITIATED} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('ReachedCheckout'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.CHECKOUT_INITIATED} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('Orders'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.ORDERS} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('Orders'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.ORDERS} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('Revenue'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.REVENUE} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('Revenue'),
          value:
            `${PromotionActivityAnalyticsSortFieldDtoEnum.REVENUE} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
      ];
    case ReportListTypeEnum.DETAILED_CODE:
      return [
        {
          label: i18n.translate('PromotionCode'),
          value:
            `${PromotionCodeAnalyticsSortFieldDtoEnum.PROMOTION_CODE} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('PromotionCode'),
          value:
            `${PromotionCodeAnalyticsSortFieldDtoEnum.PROMOTION_CODE} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('PromotionsApplied'),
          value:
            `${PromotionCodeAnalyticsSortFieldDtoEnum.PROMOTIONS_APPLIED} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('PromotionsApplied'),
          value:
            `${PromotionCodeAnalyticsSortFieldDtoEnum.PROMOTIONS_APPLIED} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('Status'),
          value:
            `${PromotionCodeAnalyticsSortFieldDtoEnum.STATUS} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('Status'),
          value:
            `${PromotionCodeAnalyticsSortFieldDtoEnum.STATUS} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('Usage'),
          value:
            `${PromotionCodeAnalyticsSortFieldDtoEnum.USAGE_COUNT} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('Usage'),
          value:
            `${PromotionCodeAnalyticsSortFieldDtoEnum.USAGE_COUNT} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('Total'),
          value: `${PromotionCodeAnalyticsSortFieldDtoEnum.LIMIT} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('Total'),
          value:
            `${PromotionCodeAnalyticsSortFieldDtoEnum.LIMIT} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
      ];
    case ReportListTypeEnum.DETAILED_ORDER:
      return [
        {
          label: i18n.translate('Order'),
          value: `${OrderAnalyticsSortFieldDtoEnum.ORDER} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('Order'),
          value: `${OrderAnalyticsSortFieldDtoEnum.ORDER} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },

        {
          label: i18n.translate('Date'),
          value: `${OrderAnalyticsSortFieldDtoEnum.DATE} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('Date'),
          value: `${OrderAnalyticsSortFieldDtoEnum.DATE} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('Customer'),
          value: `${OrderAnalyticsSortFieldDtoEnum.CUSTOMER} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('Customer'),
          value: `${OrderAnalyticsSortFieldDtoEnum.CUSTOMER} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('OrderValue'),
          value: `${OrderAnalyticsSortFieldDtoEnum.ORDER_VALUE} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('OrderValue'),
          value: `${OrderAnalyticsSortFieldDtoEnum.ORDER_VALUE} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('PromotionCodes'),
          value:
            `${OrderAnalyticsSortFieldDtoEnum.PROMOTION_CODES} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('PromotionCodes'),
          value:
            `${OrderAnalyticsSortFieldDtoEnum.PROMOTION_CODES} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
        {
          label: i18n.translate('PromotionsApplied'),
          value:
            `${OrderAnalyticsSortFieldDtoEnum.PROMOTIONS_APPLIED} asc` as const,
          directionLabel: i18n.translate('Ascending'),
        },
        {
          label: i18n.translate('PromotionsApplied'),
          value:
            `${OrderAnalyticsSortFieldDtoEnum.PROMOTIONS_APPLIED} desc` as const,
          directionLabel: i18n.translate('Descending'),
        },
      ];
  }
};

export const sortColumnOptions = (
  index: number,
  direction: 'ascending' | 'descending',
  listType: ReportListTypeEnum
) => {
  if (listType === ReportListTypeEnum.PROMOTION_ACTIVITY) {
    switch (true) {
      case index === 0 && direction === 'ascending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.PROMOTION_NAME} asc`;
      case index === 0 && direction === 'descending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.PROMOTION_NAME} desc`;
      case index === 1 && direction === 'ascending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.SESSIONS} asc`;
      case index === 1 && direction === 'descending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.SESSIONS} desc`;
      case index === 2 && direction === 'ascending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.ADD_TO_CART} asc`;
      case index === 2 && direction === 'descending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.ADD_TO_CART} desc`;
      case index === 3 && direction === 'ascending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.CHECKOUT_INITIATED} asc`;
      case index === 3 && direction === 'descending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.CHECKOUT_INITIATED} desc`;
      case index === 4 && direction === 'ascending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.ORDERS} asc`;
      case index === 4 && direction === 'descending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.ORDERS} desc`;
      case index === 5 && direction === 'ascending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.REVENUE} asc`;
      case index === 5 && direction === 'descending':
        return `${PromotionActivityAnalyticsSortFieldDtoEnum.REVENUE} desc`;
    }
  }

  if (listType === ReportListTypeEnum.DETAILED_CODE) {
    switch (true) {
      case index === 0 && direction === 'ascending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.PROMOTION_CODE} asc`;
      case index === 0 && direction === 'descending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.PROMOTION_CODE} desc`;
      case index === 1 && direction === 'ascending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.PROMOTIONS_APPLIED} asc`;
      case index === 1 && direction === 'descending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.PROMOTIONS_APPLIED} desc`;
      case index === 2 && direction === 'ascending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.STATUS} asc`;
      case index === 2 && direction === 'descending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.STATUS} desc`;
      case index === 3 && direction === 'ascending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.USAGE_COUNT} asc`;
      case index === 3 && direction === 'descending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.USAGE_COUNT} desc`;
      case index === 4 && direction === 'ascending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.LIMIT} asc`;
      case index === 4 && direction === 'descending':
        return `${PromotionCodeAnalyticsSortFieldDtoEnum.LIMIT} desc`;
    }
  }
  if (listType === ReportListTypeEnum.DETAILED_ORDER) {
    switch (true) {
      case index === 0 && direction === 'ascending':
        return `${OrderAnalyticsSortFieldDtoEnum.ORDER} asc`;
      case index === 0 && direction === 'descending':
        return `${OrderAnalyticsSortFieldDtoEnum.ORDER} desc`;
      case index === 1 && direction === 'ascending':
        return `${OrderAnalyticsSortFieldDtoEnum.DATE} asc`;
      case index === 1 && direction === 'descending':
        return `${OrderAnalyticsSortFieldDtoEnum.DATE} desc`;
      case index === 2 && direction === 'ascending':
        return `${OrderAnalyticsSortFieldDtoEnum.CUSTOMER} asc`;
      case index === 2 && direction === 'descending':
        return `${OrderAnalyticsSortFieldDtoEnum.CUSTOMER} desc`;
      case index === 3 && direction === 'ascending':
        return `${OrderAnalyticsSortFieldDtoEnum.ORDER_VALUE} asc`;
      case index === 3 && direction === 'descending':
        return `${OrderAnalyticsSortFieldDtoEnum.ORDER_VALUE} desc`;
      case index === 4 && direction === 'ascending':
        return `${OrderAnalyticsSortFieldDtoEnum.PROMOTION_CODES} asc`;
      case index === 4 && direction === 'descending':
        return `${OrderAnalyticsSortFieldDtoEnum.PROMOTION_CODES} desc`;
      case index === 5 && direction === 'ascending':
        return `${OrderAnalyticsSortFieldDtoEnum.PROMOTIONS_APPLIED} asc`;
      case index === 5 && direction === 'descending':
        return `${OrderAnalyticsSortFieldDtoEnum.PROMOTIONS_APPLIED} desc`;
    }
  }
};
