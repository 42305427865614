import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './ExploreAllWidgets.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  InstallationSupportRequestSummaryDto,
  NinjaCartWidgetInstallationDto,
  WidgetInstallationDto,
  WidgetStatusDto,
  WidgetTypeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  Banner,
  BlockStack,
  Card,
  ChoiceList,
  Collapsible,
  Filters,
  FiltersProps,
  Icon,
  InlineGrid,
  InlineStack,
  Page,
  ResourceList,
  Text,
} from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';
import { useAppSelector } from 'core/hooks';
import {
  InstallationSupportRequestGeneralStatusDtoEnum,
  NinjaCartWidgetGroupDtoEnum,
  PageTypeDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { groupBy, isEmpty } from 'lodash';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import { useToggleWidget } from 'features/widgets/hooks/useToggleWidget';

type ExploreAllWidgetsProps = {
  isEnabled: boolean;
  isLoading: boolean;
  widgets: NinjaCartWidgetInstallationDto[] | WidgetInstallationDto[];
  themeName?: string;
  supportRequest?: InstallationSupportRequestSummaryDto;
  isWidgetControlCenterPage: boolean;
  setActionWidget: React.Dispatch<
    React.SetStateAction<
      WidgetInstallationDto | NinjaCartWidgetInstallationDto | null
    >
  >;
  handleExploreAllWidgets: () => void;
  handleEnableAppModal: () => void;
  refetchWidgetInstallationData: () => void;
  setConfigureStyleModal: React.Dispatch<
    React.SetStateAction<WidgetTypeDto | 'GENERAL'>
  >;
  setIsWidgetsListUpdating: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ExploreAllWidgets: React.FC<ExploreAllWidgetsProps> = (props) => {
  const {
    widgets,
    isEnabled,
    isLoading,
    themeName,
    supportRequest,
    isWidgetControlCenterPage,
    setActionWidget,
    handleExploreAllWidgets,
    handleEnableAppModal,
    refetchWidgetInstallationData,
    setConfigureStyleModal,
    setIsWidgetsListUpdating,
  } = props;
  const [i18n] = useI18n();

  const { widgetThemeType } = useAppSelector((store) => store.settings);

  const { toggleWidget, loading } = useToggleWidget({
    widgetThemeType,
    isWidgetControlCenterPage,
    setActionWidget,
    refetchWidgetInstallationData,
  });

  const [hideBanner, setHideBanner] = useState<boolean>(false);
  const [delayPassed, setDelayPassed] = useState<boolean>(false);
  const [collapsedModes, setCollapsedModes] = useState<Record<string, boolean>>(
    {}
  );
  const [queryValue, setQueryValue] = useState('');
  const [selectedType, setSelectedType] = useState<
    (WidgetInstallationModeDtoEnum | NinjaCartWidgetGroupDtoEnum)[]
  >([]);

  const [selectedPages, setSelectedPages] = useState<PageTypeDtoEnum[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<WidgetStatusDtoEnum[]>(
    []
  );

  const allPages = useMemo(() => {
    return Array.from(
      new Set(
        widgets
          .flatMap(
            (widget: WidgetInstallationDto | NinjaCartWidgetInstallationDto) =>
              'pages' in widget ? widget.pages ?? [] : []
          )
          .filter(Boolean)
      )
    );
  }, [widgets]);

  const handleQueryChange = useCallback(
    (value: string) => setQueryValue(value),
    []
  );
  const handleQueryClear = useCallback(() => setQueryValue(''), []);

  const handleTypeChange = useCallback(
    (value: WidgetInstallationModeDtoEnum[] | NinjaCartWidgetGroupDtoEnum[]) =>
      setSelectedType(value),
    []
  );
  const handlePageChange = useCallback(
    (value: PageTypeDtoEnum[]) => setSelectedPages(value),
    []
  );
  const handleStatusChange = useCallback(
    (value: WidgetStatusDtoEnum[]) => setSelectedStatus(value),
    []
  );

  const handleToggleCollapse = (
    installationMode:
      | WidgetInstallationModeDtoEnum
      | NinjaCartWidgetGroupDtoEnum
  ) => {
    setCollapsedModes((prev) => ({
      ...prev,
      [installationMode]: !prev[installationMode],
    }));
  };

  const handleClearAll = useCallback(() => {
    setQueryValue('');
    setSelectedType([]);
    setSelectedPages([]);
    setSelectedStatus([]);
  }, []);

  const handleFilterRemove = useCallback((key: string) => {
    if (key === 'type') {
      setSelectedType([]);
    } else if (key === 'pages') {
      setSelectedPages([]);
    } else if (key === 'status') {
      setSelectedStatus([]);
    }
  }, []);

  const filters = useMemo(
    () => [
      {
        key: 'type',
        label: i18n.translate('Type'),
        value: selectedType,
        filter: (
          <ChoiceList
            title=''
            titleHidden
            choices={Object.values(
              isWidgetControlCenterPage
                ? WidgetInstallationModeDtoEnum
                : NinjaCartWidgetGroupDtoEnum
            ).map((value) => ({
              label: i18n.translate(`${value}_MODE`),
              value,
            }))}
            selected={selectedType}
            onChange={handleTypeChange}
            allowMultiple
          />
        ),
        shortcut: true,
        pinned: true,
      },
      ...(isWidgetControlCenterPage
        ? [
            {
              key: 'pages',
              label: i18n.translate('Page'),
              value: selectedPages,
              filter: (
                <ChoiceList
                  title=''
                  titleHidden
                  choices={(allPages as string[]).map((value) => ({
                    label: i18n.translate(value),
                    value,
                  }))}
                  selected={selectedPages}
                  onChange={handlePageChange}
                  allowMultiple
                />
              ),
              shortcut: true,
              pinned: true,
            },
          ]
        : []),
      {
        key: 'status',
        label: i18n.translate('Status'),
        value: selectedStatus,
        filter: (
          <ChoiceList
            title=''
            titleHidden
            choices={Object.values(WidgetStatusDtoEnum)
              .filter(
                (value) =>
                  ![
                    WidgetStatusDtoEnum.ENABLED_VIA_CODE,
                    WidgetStatusDtoEnum.ENABLED_VIA_CSS,
                    WidgetStatusDtoEnum.DISABLED_BY_USER,
                    WidgetStatusDtoEnum.NOT_READY,
                  ].includes(value)
              )
              .map((value) => ({
                label: i18n.translate(value),
                value,
              }))}
            selected={selectedStatus}
            onChange={handleStatusChange}
            allowMultiple
          />
        ),
        shortcut: true,
        pinned: true,
      },
    ],
    [
      allPages,
      selectedType,
      selectedPages,
      selectedStatus,
      isWidgetControlCenterPage,
      i18n,
      handleTypeChange,
      handlePageChange,
      handleStatusChange,
    ]
  );

  const appliedFilters: FiltersProps['appliedFilters'] = useMemo(() => {
    return filters
      .filter(({ value }) => !isEmpty(value))
      .map(({ key, label, value }) => ({
        key,
        label: `${label}: ${(value as string[])
          .map((item: string) =>
            key === 'type'
              ? i18n.translate(`${item}_MODE`)
              : i18n.translate(item)
          )
          .join(', ')}`,
        onRemove: () => handleFilterRemove(key),
      }));
  }, [filters]);

  const filteredWidgets = useMemo(() => {
    const widgetList: (
      | WidgetInstallationDto
      | NinjaCartWidgetInstallationDto
    )[] = widgets as (WidgetInstallationDto | NinjaCartWidgetInstallationDto)[];

    return widgetList.filter((widget) => {
      const matchesType =
        selectedType.length === 0 ||
        (widget.installationMode &&
          selectedType.includes(
            widget.installationMode as
              | WidgetInstallationModeDtoEnum
              | NinjaCartWidgetGroupDtoEnum
          ));

      const matchesPages =
        selectedPages.length === 0 ||
        ('pages' in widget &&
          (widget.pages || []).some(
            (page) => page && selectedPages.includes(page as PageTypeDtoEnum)
          ));

      const widgetStatus =
        widget.status === WidgetStatusDtoEnum.DISABLED_BY_USER
          ? WidgetStatusDtoEnum.DISABLED
          : widget.status;

      const enabledStatuses = new Set<WidgetStatusDtoEnum>([
        WidgetStatusDtoEnum.ENABLED,
        WidgetStatusDtoEnum.ENABLED_VIA_CODE,
        WidgetStatusDtoEnum.ENABLED_VIA_CSS,
      ]);

      const matchesStatus =
        selectedStatus.length === 0 ||
        selectedStatus.some((status) =>
          status === WidgetStatusDtoEnum.ENABLED
            ? enabledStatuses.has(widgetStatus as WidgetStatusDtoEnum)
            : widgetStatus === status
        );

      const matchesQuery =
        queryValue.trim() === '' ||
        (widget.type?.toLowerCase() || '').includes(
          queryValue.trim().toLowerCase()
        );

      return matchesType && matchesPages && matchesStatus && matchesQuery;
    });
  }, [widgets, selectedType, selectedPages, selectedStatus, queryValue]);

  const groupedWidgets = useMemo(() => {
    return groupBy(filteredWidgets, 'installationMode');
  }, [filteredWidgets]);

  useEffect(() => {
    setIsWidgetsListUpdating(loading);
  }, [loading]);

  useEffect(() => {
    const timer = setTimeout(() => setDelayPassed(true), 1000); // 1-second delay
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='ExploreAllWidgets'>
      <Page
        title={i18n.translate('PageTitle')}
        subtitle={`${i18n.translate(`${widgetThemeType}_THEME`)}: ${themeName}`}
        backAction={{
          onAction: handleExploreAllWidgets,
        }}
      >
        <BlockStack gap='400'>
          {!isEnabled && !hideBanner && !isLoading && delayPassed && (
            <Banner
              title={i18n.translate(`WarningBannerTitle_${widgetThemeType}`)}
              tone='warning'
              action={{
                content: i18n.translate('WarningBannerAction'),
                onAction: handleEnableAppModal,
              }}
              onDismiss={() => setHideBanner((prev) => !prev)}
            >
              <p>{i18n.translate(`WarningBannerContent_${widgetThemeType}`)}</p>
            </Banner>
          )}
          <Card roundedAbove='sm' padding='0'>
            <ResourceList
              resourceName={{
                singular: i18n.translate('Singular'),
                plural: i18n.translate('Plural'),
              }}
              loading={isLoading}
              filterControl={
                <Filters
                  queryPlaceholder={i18n.translate('SearchWidget')}
                  queryValue={queryValue}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={handleQueryChange}
                  onQueryClear={handleQueryClear}
                  onClearAll={handleClearAll}
                  loading={isLoading}
                />
              }
              items={Object.entries(groupedWidgets)}
              renderItem={([installationMode, widgets]) => {
                const enabledStatuses = new Set<WidgetStatusDto>([
                  WidgetStatusDtoEnum.ENABLED,
                  WidgetStatusDtoEnum.ENABLED_VIA_CODE,
                  WidgetStatusDtoEnum.ENABLED_VIA_CSS,
                  WidgetStatusDtoEnum.ENABLED_BUILT_IN,
                ]);
                const enabledWidgetsQuantity = widgets.filter((widget) => {
                  const widgetStatus =
                    widget.status === WidgetStatusDtoEnum.DISABLED_BY_USER
                      ? WidgetStatusDtoEnum.DISABLED
                      : widget.status;

                  return enabledStatuses.has(
                    widgetStatus as WidgetStatusDtoEnum
                  );
                }).length;

                return (
                  <div key={installationMode} className='installationMode'>
                    <BlockStack gap='400'>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleToggleCollapse(
                            installationMode as
                              | WidgetInstallationModeDtoEnum
                              | NinjaCartWidgetGroupDtoEnum
                          )
                        }
                      >
                        <InlineStack align='space-between'>
                          <BlockStack gap='100'>
                            <Text as='h2' variant='headingSm'>
                              {i18n.translate(
                                `${installationMode}_MODE` || 'UNKNOWN_MODE'
                              )}
                            </Text>
                            <Text as='p' tone='subdued'>
                              {i18n.translate('WidgetsEnabled', {
                                enabled: enabledWidgetsQuantity,
                                all: widgets.length,
                              })}
                            </Text>
                          </BlockStack>
                          <div>
                            <Icon
                              source={
                                collapsedModes[installationMode]
                                  ? ChevronDownIcon
                                  : ChevronUpIcon
                              }
                              tone='base'
                            />
                          </div>
                        </InlineStack>
                      </div>
                      <Collapsible
                        id={installationMode}
                        open={!collapsedModes[installationMode]}
                        transition={{
                          duration: '300ms',
                          timingFunction: 'ease',
                        }}
                      >
                        <InlineGrid
                          gap='400'
                          columns={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                        >
                          {widgets.map((widget) => (
                            <WidgetCard
                              key={widget.type}
                              isEnabled={isEnabled}
                              widget={widget}
                              isSupportRequested={
                                supportRequest?.status ===
                                  InstallationSupportRequestGeneralStatusDtoEnum.SUBMITTED &&
                                Array.isArray(supportRequest.selectedWidgets) &&
                                supportRequest.selectedWidgets.includes(
                                  widget.type!
                                )
                              }
                              toggleWidget={toggleWidget}
                              setConfigureStyleModal={setConfigureStyleModal}
                            />
                          ))}
                        </InlineGrid>
                      </Collapsible>
                    </BlockStack>
                  </div>
                );
              }}
            />
          </Card>
        </BlockStack>
      </Page>
    </div>
  );
};
