import React from 'react';
import {
  BlockStack,
  Box,
  Card,
  Checkbox,
  Text,
  TextField,
} from '@shopify/polaris';
import { TagsMetadataSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';

type BasedBasedAutoTaggingKeys = Pick<
  TagsMetadataSettingsDto,
  | 'discountNinjaOriginatedTag'
  | 'prefixForEachAppliedPromotion'
  | 'prefixForEachAppliedOffer'
  | 'prefixForEachAppliedPromotionCode'
  | 'prefixForAppliedDiscountCode'
>;

type BasedAutoTaggingComponentProps = {
  title: string;
  subtitle: string;
  keys: (keyof BasedBasedAutoTaggingKeys)[];
  autoTaggingState: TagsMetadataSettingsDto;
  handleAutoTaggingState: (
    field: keyof TagsMetadataSettingsDto,
    value: TagsMetadataSettingsDto[keyof TagsMetadataSettingsDto]
  ) => void;
};

const inputPlaceholders = {
  discountNinjaOriginatedTag: 'e.g. Discount Ninja',
  prefixForEachAppliedPromotion: 'e.g. DN-TT-',
  prefixForEachAppliedOffer: 'e.g. DN-OT-',
  prefixForEachAppliedPromotionCode: 'e.g. DN-PC-',
  prefixForAppliedDiscountCode: 'e.g. DN-DC-',
};

export const BasedAutoTaggingComponent: React.FC<
  BasedAutoTaggingComponentProps
> = ({ title, subtitle, keys, autoTaggingState, handleAutoTaggingState }) => {
  const [i18n] = useI18n();

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='semibold'>
            {title}
          </Text>
          <Text as='p' tone='subdued'>
            {subtitle}
          </Text>
        </BlockStack>
        <BlockStack gap='300'>
          {keys.map((key) => (
            <BlockStack gap='200' key={key}>
              <Checkbox
                label={i18n.translate(`CheckboxLabels.${key}`)}
                checked={autoTaggingState?.[key]?.enabled}
                helpText={
                  autoTaggingState?.[key]?.enabled && (
                    <Box paddingBlockStart='200'>
                      <TextField
                        autoComplete=''
                        label={
                          <Text as='p' tone='base'>
                            {i18n.translate(`InputLabels.${key}`, {
                              optional: (
                                <Text as='span' tone='subdued'>
                                  {i18n.translate('Optional')}
                                </Text>
                              ),
                            })}
                          </Text>
                        }
                        value={autoTaggingState?.[key]?.value || ''}
                        placeholder={inputPlaceholders[key]}
                        onChange={(value) =>
                          handleAutoTaggingState(key, {
                            ...autoTaggingState?.[key],
                            value: value,
                          })
                        }
                      />
                    </Box>
                  )
                }
                onChange={(value) =>
                  handleAutoTaggingState(key, {
                    ...autoTaggingState?.[key],
                    enabled: value,
                  })
                }
              />
            </BlockStack>
          ))}
        </BlockStack>
      </BlockStack>
    </Card>
  );
};
