import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  CheckoutLoadingPopupShopSettingsDto,
  WidgetSettingsDtoCheckoutLoadingPopupShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { StyleSetting, OverlaySettings } from '../../components';
import {
  BlockStack,
  Card,
  Text,
  Button,
  Link,
  InlineGrid,
} from '@shopify/polaris';
import { useIsDebugOrLocal } from 'core/hooks';
import { useSearchParams } from 'react-router-dom';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';

type CheckoutLoadingPopupSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CheckoutLoadingPopupSettings = forwardRef<
  WidgetSavebarRef,
  CheckoutLoadingPopupSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const isDebugOrLocal = useIsDebugOrLocal();
  const [, setSearchParams] = useSearchParams();
  const {
    checkoutLoadingPopupData,
    saveCheckoutLoadingPopup,
    checkoutLoadingPopupDataIsFetching,
    saveCheckoutLoadingPopupIsLoading,
    // refetchCheckoutLoadingPopupData,
  } = useWidget(WidgetTypeDtoEnum.CHECKOUT_LOADING_POPUP);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoCheckoutLoadingPopupShopSettingsDto>({});
  const [checkoutLoadingPopupState, setCheckoutLoadingPopupState] =
    useState<WidgetSettingsDtoCheckoutLoadingPopupShopSettingsDto>({});

  const updateCheckoutLoadingPopupState = useCallback(
    (
      field: keyof CheckoutLoadingPopupShopSettingsDto,
      data: CheckoutLoadingPopupShopSettingsDto[keyof CheckoutLoadingPopupShopSettingsDto]
    ) =>
      setCheckoutLoadingPopupState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setCheckoutLoadingPopupState]
  );

  const handleSave = useCallback(
    () =>
      saveCheckoutLoadingPopup(checkoutLoadingPopupState.settings || null, () =>
        setSavedData(checkoutLoadingPopupState)
      ),
    [checkoutLoadingPopupState]
  );

  const navigateToTranslations = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    setSearchParams((params) => {
      params.set('modal', NavPaths.SettingsModal);
      params.set('path', SettingsModalPathsEnums.Translations);
      return params;
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(checkoutLoadingPopupData)) {
      setCheckoutLoadingPopupState(checkoutLoadingPopupData);
      setSavedData(checkoutLoadingPopupData);
    }
  }, [checkoutLoadingPopupData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(checkoutLoadingPopupState)) {
      dispatch(
        setIsUnsavedChanges(!isEqual(checkoutLoadingPopupState, savedData))
      );
    }
  }, [savedData, checkoutLoadingPopupState]);

  useEffect(() => {
    setIsLoading(checkoutLoadingPopupDataIsFetching);
  }, [checkoutLoadingPopupDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveCheckoutLoadingPopupIsLoading);
  }, [saveCheckoutLoadingPopupIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setCheckoutLoadingPopupState(savedData),
  }));

  return !checkoutLoadingPopupDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!checkoutLoadingPopupData?.isShopLevelWidget} />
      <Card>
        <BlockStack gap='400'>
          <InlineGrid alignItems='start' columns={['twoThirds', 'oneThird']}>
            <BlockStack gap='100'>
              <Text as='p' fontWeight='bold'>
                {i18n.translate('MessageTitle')}
              </Text>
              <Text as='span' tone='subdued'>
                {i18n.translate('MessageDescription', {
                  link: (
                    <Link url='http://www.google.com' target='_blank'>
                      {i18n.translate('LearnMore')}
                    </Link>
                  ),
                })}
              </Text>
            </BlockStack>

            <Button
              onClick={navigateToTranslations}
              variant='plain'
              textAlign='end'
            >
              {i18n.translate('ConfigureTranslations')}
            </Button>
          </InlineGrid>
        </BlockStack>
      </Card>
      <OverlaySettings
        overlay={checkoutLoadingPopupState.settings?.overlay || {}}
        setOverlay={(data) => updateCheckoutLoadingPopupState('overlay', data)}
      />
    </>
  ) : null;
});

CheckoutLoadingPopupSettings.displayName = 'CheckoutLoadingPopupSettings';
