export const updateFirstLetter = (
  value: string,
  toUpperCase = true
): string => {
  if (!value) return value; // Handle empty strings
  const firstLetter = toUpperCase
    ? value.charAt(0).toUpperCase()
    : value.charAt(0).toLowerCase();
  return firstLetter + value.slice(1);
};
