import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { PositionDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  PromotionalBadgeShopSettingsDto,
  WidgetSettingsDtoPromotionalBadgeShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  OffsetSetting,
  PositionSetting,
  StackingSetting,
  StyleSetting,
} from '../../components';

type PromotionalBadgeSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PromotionalBadgeSettings = forwardRef<
  WidgetSavebarRef,
  PromotionalBadgeSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const {
    promotionalBadgeData,
    savePromotionalBadge,
    promotionalBadgeDataIsFetching,
    savePromotionalBadgeIsLoading,
    // refetchPromotionalBadgeData,
  } = useWidget(WidgetTypeDtoEnum.PROMOTIONAL_BADGE);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoPromotionalBadgeShopSettingsDto>({});
  const [promotionalBadgeState, setPromotionalBadgeState] =
    useState<WidgetSettingsDtoPromotionalBadgeShopSettingsDto>({});

  const updatePromotionalBadgeState = useCallback(
    (
      field: keyof PromotionalBadgeShopSettingsDto,
      data: PromotionalBadgeShopSettingsDto[keyof PromotionalBadgeShopSettingsDto]
    ) =>
      setPromotionalBadgeState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setPromotionalBadgeState]
  );

  const handleSave = useCallback(
    () =>
      savePromotionalBadge(promotionalBadgeState.settings || null, () =>
        setSavedData(promotionalBadgeState)
      ),
    [promotionalBadgeState]
  );

  useEffect(() => {
    if (!isEmpty(promotionalBadgeData)) {
      setPromotionalBadgeState(promotionalBadgeData);
      setSavedData(promotionalBadgeData);
    }
  }, [promotionalBadgeData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(promotionalBadgeState)) {
      dispatch(setIsUnsavedChanges(!isEqual(promotionalBadgeState, savedData)));
    }
  }, [savedData, promotionalBadgeState]);

  useEffect(() => {
    setIsLoading(promotionalBadgeDataIsFetching);
  }, [promotionalBadgeDataIsFetching]);

  useEffect(() => {
    setIsSaving(savePromotionalBadgeIsLoading);
  }, [savePromotionalBadgeIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setPromotionalBadgeState(savedData),
  }));

  return !promotionalBadgeDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!promotionalBadgeData?.isShopLevelWidget} />

      <PositionSetting
        position={promotionalBadgeState.settings?.position as PositionDtoEnum}
        positionSides={[
          PositionDtoEnum.BOTTOM_LEFT,
          PositionDtoEnum.BOTTOM_RIGHT,
          PositionDtoEnum.TOP_LEFT,
          PositionDtoEnum.TOP_RIGHT,
        ]}
        title={i18n.translate('Position')}
        subtitle={i18n.translate('AdjustPosition')}
        setPosition={(value) => updatePromotionalBadgeState('position', value)}
      />
      <OffsetSetting
        offset={promotionalBadgeState.settings?.offset || {}}
        setOffset={(data) => updatePromotionalBadgeState('offset', data)}
      />
      <StackingSetting
        stacking={promotionalBadgeState.settings?.stacking || {}}
        setStacking={(data) => updatePromotionalBadgeState('stacking', data)}
        subtitle={i18n.translate('AllowMoreOffers')}
        checkboxSubtitle={i18n.translate('SupportedForRectangle')}
        maxCustomSpacing={20}
      />
    </>
  ) : null;
});

PromotionalBadgeSettings.displayName = 'PromotionalBadgeSettings';
