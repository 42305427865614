import {
  Banner,
  BlockStack,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  InlineStack,
  Link,
  List,
  Text,
} from '@shopify/polaris';
import './DiscountSelectSection.scss';
import { I18n } from '@shopify/react-i18n';
import { DiscountType } from 'features/promotions/components/SelectedOfferTypeSettings/enums/DiscountType';
import { DiscountValueTypeEnum } from 'features/promotions/components/SelectedOfferTypeSettings/enums/DiscountValueType';
import React, { useMemo, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { floor } from 'lodash';
import { Loader } from 'core/components';
import {
  OfferLevelDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
import { ChartuUnitOfMeasurementEnum } from 'pages';
import { getCurrencySymbol } from 'core/utils';

type DiscountLevelTilesProps = {
  activeDiscountValueType: DiscountValueTypeEnum;
  i18n: I18n;
  selectedDiscountType: DiscountType;
  onActiveDiscountTypeSelect: (data: string) => void;
  textFieldValue: NumberFormatValues;
  offerType: OfferTypeDtoEnum;
  isLoading?: boolean;
};

export const DiscountSelectSection: React.FC<DiscountLevelTilesProps> = (
  props
) => {
  const {
    activeDiscountValueType,
    i18n,
    selectedDiscountType,
    onActiveDiscountTypeSelect,
    textFieldValue,
    offerType,
    isLoading,
  } = props;

  const { formatValue } = useNumericFormatter();

  const currentCurrencySymbol = getCurrencySymbol();

  const [expanded, setExpanded] = useState(false);

  const isPercentage: boolean = useMemo(
    () => activeDiscountValueType === DiscountValueTypeEnum.Percentage,
    [activeDiscountValueType]
  );

  const discountResult = useMemo(() => {
    let product1Price, product2Price, productTotal, orderDiscount, orderTotal;
    let value: number = textFieldValue?.floatValue || 0;

    switch (activeDiscountValueType) {
      case DiscountValueTypeEnum.Percentage:
        value = value * 0.01;
        product1Price = 100 - 100 * value;
        product2Price = 50 - 50 * value;
        productTotal = 150 - (100 * value + 50 * value);
        orderDiscount = 150 * value;
        orderTotal = 150 - orderDiscount;

        orderDiscount = floor(orderDiscount, 2);
        orderTotal = floor(orderTotal, 2);
        product1Price = floor(product1Price, 2);
        product2Price = floor(product2Price, 2);
        productTotal = floor(productTotal, 2);

        return {
          product1Price,
          product2Price,
          productTotal,
          orderDiscount,
          orderTotal,
        };

      case DiscountValueTypeEnum.FixedAmount:
        product1Price = 100 - value;
        product2Price = 50 - value;
        productTotal = product1Price + product2Price;
        orderDiscount = value;
        orderTotal = 150 - orderDiscount;

        orderDiscount = floor(orderDiscount, 2);
        orderTotal = floor(orderTotal, 2);
        product1Price = floor(product1Price, 2);
        product2Price = floor(product2Price, 2);
        productTotal = floor(productTotal, 2);

        return {
          product1Price,
          product2Price,
          productTotal,
          orderDiscount,
          orderTotal,
        };
      case DiscountValueTypeEnum.FixedPricePerUnit:
        break;
    }
  }, [activeDiscountValueType, textFieldValue]);

  const discountCalculationTiles = useMemo(
    () =>
      activeDiscountValueType !== DiscountValueTypeEnum.FixedPricePerUnit ? (
        <div className='DiscountSelectSection' style={{ marginTop: '12px' }}>
          <BlockStack gap='400' inlineAlign='start'>
            <InlineStack gap='100' blockAlign='center'>
              <Text as='p'>{i18n.translate('Discount.Using')}</Text>
              <span className='ApplyButtons'>
                <ButtonGroup variant='segmented'>
                  <Button
                    id={`${OfferLevelDtoEnum.LINE}_DiscountButton`}
                    pressed={
                      selectedDiscountType === DiscountType.ProductDiscount
                    }
                    onClick={() =>
                      onActiveDiscountTypeSelect(DiscountType.ProductDiscount)
                    }
                  >
                    {i18n.translate('Discount.productDiscount').toLowerCase()}
                  </Button>
                  <Button
                    id={`${OfferLevelDtoEnum.ORDER}_DiscountButton`}
                    pressed={
                      selectedDiscountType === DiscountType.OrderDiscount
                    }
                    onClick={() =>
                      onActiveDiscountTypeSelect(DiscountType.OrderDiscount)
                    }
                  >
                    {i18n.translate('Discount.orderDiscount').toLowerCase()}
                  </Button>
                </ButtonGroup>
              </span>
            </InlineStack>

            <Button
              variant='plain'
              disclosure={expanded ? 'up' : 'down'}
              id='OfferDiscountExpandDifferencesButton'
              onClick={() => {
                setExpanded((prev) => !prev);
              }}
            >
              {i18n.translate(`Discount.${expanded ? 'Hide' : 'Show'}`)}{' '}
              {i18n.translate('Discount.DiscountTypeExamples')}
            </Button>
            {expanded && (
              <BlockStack gap='400' id='OfferDiscountExpandDifferencesBox'>
                <Grid>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    <Card roundedAbove='sm' padding='400'>
                      <div className='content'>
                        <BlockStack gap='100'>
                          <Text as='p'>
                            {i18n.translate('Discount.productDiscount')}
                          </Text>
                          <List type='bullet'>
                            {[0, 1, 2].map((el: number) => (
                              <List.Item key={el}>
                                <Text as='p' tone='subdued'>
                                  {i18n.translate(
                                    `Discount.ProductDiscountList.${el}`,
                                    {
                                      returnObjects: true,
                                    }
                                  )}
                                </Text>
                              </List.Item>
                            ))}
                          </List>
                        </BlockStack>
                        <Card
                          padding='400'
                          roundedAbove='sm'
                          background='bg-surface-secondary'
                        >
                          <BlockStack gap='200'>
                            <BlockStack gap='100'>
                              <InlineStack align='space-between'>
                                <Text as='p'>
                                  {i18n.translate('Discount.product1')}
                                </Text>{' '}
                                <InlineStack gap='100'>
                                  <Text
                                    as='p'
                                    tone='subdued'
                                    textDecorationLine='line-through'
                                  >
                                    {i18n.translate('Discount.currencySign') ||
                                      currentCurrencySymbol}
                                    100
                                  </Text>
                                  <Text as='p' fontWeight='semibold'>
                                    {i18n.translate('Discount.currencySign') ||
                                      currentCurrencySymbol}
                                    {discountResult?.product1Price &&
                                    discountResult?.product1Price > 0
                                      ? discountResult?.product1Price.toFixed(2)
                                      : (0).toFixed(2)}
                                  </Text>
                                  <Text as='p'>
                                    (-
                                    {(discountResult?.product1Price as number) <
                                    0
                                      ? `${
                                          isPercentage
                                            ? '100'
                                            : (i18n.translate(
                                                'Discount.currencySign'
                                              ) || currentCurrencySymbol) +
                                              '100'
                                        }`
                                      : activeDiscountValueType !==
                                        DiscountValueTypeEnum.Percentage
                                      ? `$${
                                          textFieldValue.value.replaceAll(
                                            ' ',
                                            ''
                                          ) || 0
                                        }`
                                      : formatValue(
                                          textFieldValue.floatValue,
                                          ChartuUnitOfMeasurementEnum.PERCENTAGE
                                        )}
                                    )
                                  </Text>
                                </InlineStack>
                              </InlineStack>
                              <InlineStack align='space-between'>
                                <Text as='p'>
                                  {i18n.translate('Discount.product2')}
                                </Text>{' '}
                                <InlineStack gap='100'>
                                  <Text
                                    as='p'
                                    tone='subdued'
                                    textDecorationLine='line-through'
                                  >
                                    {i18n.translate('Discount.currencySign') ||
                                      currentCurrencySymbol}
                                    50
                                  </Text>
                                  <Text as='p' fontWeight='semibold'>
                                    {i18n.translate('Discount.currencySign') ||
                                      currentCurrencySymbol}
                                    {discountResult?.product2Price &&
                                    discountResult?.product2Price > 0
                                      ? discountResult?.product2Price.toFixed(2)
                                      : (0).toFixed(2)}
                                  </Text>
                                  <Text as='p'>
                                    (-
                                    {(discountResult?.product2Price as number) <
                                    0
                                      ? `${
                                          isPercentage
                                            ? '100'
                                            : (i18n.translate(
                                                'Discount.currencySign'
                                              ) || currentCurrencySymbol) +
                                              '100'
                                        }`
                                      : activeDiscountValueType !==
                                        DiscountValueTypeEnum.Percentage
                                      ? `$${
                                          textFieldValue.value.replaceAll(
                                            ' ',
                                            ''
                                          ) || 0
                                        }`
                                      : formatValue(
                                          textFieldValue.floatValue,
                                          ChartuUnitOfMeasurementEnum.PERCENTAGE
                                        )}
                                    )
                                  </Text>
                                </InlineStack>
                              </InlineStack>
                            </BlockStack>
                            <Divider />
                            <BlockStack gap='100'>
                              <InlineStack align='space-between'>
                                <Text as='p' tone='subdued'>
                                  {i18n.translate('Discount.subtotal') ||
                                    'Subtotal'}
                                </Text>
                                <Text
                                  id='ProductDiscountSubtotalText'
                                  as='p'
                                  tone='subdued'
                                >
                                  {i18n.translate('Discount.currencySign') ||
                                    currentCurrencySymbol}
                                  {discountResult?.productTotal &&
                                  discountResult?.productTotal > 0
                                    ? discountResult?.productTotal.toFixed(2)
                                    : (0).toFixed(2)}
                                </Text>
                              </InlineStack>
                              <InlineStack align='space-between'>
                                <Text as='p' fontWeight='semibold'>
                                  {i18n.translate('Discount.discount') ||
                                    'Discount'}
                                </Text>
                                <Text as='p'>
                                  -
                                  {i18n.translate('Discount.currencySign') ||
                                    currentCurrencySymbol}
                                  0.00
                                </Text>
                              </InlineStack>
                              <InlineStack align='space-between'>
                                <Text as='p' tone='subdued'>
                                  {i18n.translate('Discount.total') || 'Total'}
                                </Text>
                                <Text
                                  id='ProductDiscountTotalText'
                                  as='p'
                                  tone='subdued'
                                >
                                  {i18n.translate('Discount.currencySign') ||
                                    currentCurrencySymbol}
                                  {discountResult?.productTotal &&
                                  discountResult?.productTotal > 0
                                    ? discountResult?.productTotal.toFixed(2)
                                    : (0).toFixed(2)}
                                </Text>
                              </InlineStack>
                            </BlockStack>
                          </BlockStack>
                        </Card>
                      </div>
                    </Card>
                  </Grid.Cell>
                  <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                    <Card roundedAbove='sm' padding='400'>
                      <div className='content'>
                        <BlockStack gap='100'>
                          <Text as='p'>
                            {i18n.translate('Discount.orderDiscount')}
                          </Text>
                          <List type='bullet'>
                            {[0, 1, 2].map((el: number) => (
                              <List.Item key={el}>
                                <Text as='p' tone='subdued'>
                                  {i18n.translate(
                                    `Discount.OrderDiscountList.${el}`,
                                    {
                                      returnObjects: true,
                                    }
                                  )}
                                </Text>
                              </List.Item>
                            ))}
                          </List>
                        </BlockStack>
                        <Card
                          padding='400'
                          roundedAbove='sm'
                          background='bg-surface-secondary'
                        >
                          <BlockStack gap='200'>
                            <BlockStack gap='100'>
                              <InlineStack align='space-between'>
                                <Text as='p'>
                                  {i18n.translate('Discount.product1') ||
                                    'Product 1'}
                                </Text>
                                <Text as='p' fontWeight='semibold'>
                                  {i18n.translate('Discount.currencySign') ||
                                    currentCurrencySymbol}
                                  100.00
                                </Text>
                              </InlineStack>
                              <InlineStack align='space-between'>
                                <Text as='p'>
                                  {i18n.translate('Discount.product2') ||
                                    'Product 2'}
                                </Text>
                                <Text as='p' fontWeight='semibold'>
                                  {i18n.translate('Discount.currencySign') ||
                                    currentCurrencySymbol}
                                  50.00
                                </Text>
                              </InlineStack>
                            </BlockStack>
                            <Divider />
                            <BlockStack gap='100'>
                              <InlineStack align='space-between'>
                                <Text as='p' tone='subdued'>
                                  {i18n.translate('Discount.subtotal') ||
                                    'Subtotal'}
                                </Text>
                                <Text
                                  id='OrderDiscountSubtotalText'
                                  as='p'
                                  tone='subdued'
                                >
                                  {i18n.translate('Discount.currencySign') ||
                                    currentCurrencySymbol}
                                  150.00
                                </Text>
                              </InlineStack>
                              <InlineStack align='space-between'>
                                <Text as='p' fontWeight='semibold'>
                                  {i18n.translate('Discount.discount') ||
                                    'Discount'}
                                </Text>
                                <Text as='p' fontWeight='semibold'>
                                  -
                                  {i18n.translate('Discount.currencySign') ||
                                    currentCurrencySymbol}
                                  {(discountResult?.orderDiscount as number) >
                                  150
                                    ? (150).toFixed(2)
                                    : discountResult?.orderDiscount.toFixed(2)}
                                </Text>
                              </InlineStack>
                              <InlineStack align='space-between'>
                                <Text as='p' tone='subdued'>
                                  {i18n.translate('Discount.total') || 'Total'}
                                </Text>
                                <Text
                                  id='OrderDiscountTotalText'
                                  as='p'
                                  tone='subdued'
                                >
                                  {i18n.translate('Discount.currencySign') ||
                                    currentCurrencySymbol}
                                  {discountResult?.productTotal &&
                                  discountResult?.productTotal > 0
                                    ? discountResult?.orderTotal.toFixed(2)
                                    : 0.0}
                                </Text>
                              </InlineStack>
                            </BlockStack>
                          </BlockStack>
                        </Card>
                      </div>
                    </Card>
                  </Grid.Cell>
                </Grid>
                <Banner>
                  {i18n.translate('Discount.BannerInfo', {
                    link: (
                      <Link url='https://discountninja.io/' target='_blank'>
                        {i18n.translate('Discount.FollowingArticle')}{' '}
                      </Link>
                    ),
                  })}
                </Banner>
              </BlockStack>
            )}
          </BlockStack>
        </div>
      ) : null,
    [
      activeDiscountValueType,
      discountResult?.orderDiscount,
      discountResult?.orderTotal,
      discountResult?.product1Price,
      discountResult?.product2Price,
      discountResult?.productTotal,
      i18n,
      onActiveDiscountTypeSelect,
      selectedDiscountType,
      textFieldValue,
      isPercentage,
      offerType,
      expanded,
    ]
  );

  const html = useMemo(
    () =>
      isLoading ? <Loader size='large' fullWidth /> : discountCalculationTiles,
    [isLoading, discountCalculationTiles]
  );

  return <>{html}</>;
};
