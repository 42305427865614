import React, { useCallback, useMemo } from 'react';
import { Checkbox, RadioButton, BlockStack, Box } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCartRuleDto,
  OfferPrerequisiteEntitledAppliesToDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferCartContentRequirementProductMatchTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import './GrayBoxBogoOptions.scss';

export type GrayBoxBogoOptionsProps = {
  data: OfferCartRuleDto;
  updateValue?: (data: OfferCartRuleDto) => void;
  productsUpdateValue?: (data: OfferPrerequisiteEntitledAppliesToDto) => void;
};

export const GrayBoxBogoOptions: React.FC<GrayBoxBogoOptionsProps> = (
  props
) => {
  const { data, updateValue, productsUpdateValue } = props;

  const [i18n] = useI18n();

  const cartRule = useMemo(
    () =>
      data.appliesTo?.productMatchType?.value ||
      OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT,
    [data.appliesTo?.productMatchType?.value]
  );

  const handleCartRuleChange = useCallback(
    (
      _checked: boolean,
      newValue: OfferCartContentRequirementProductMatchTypeDtoEnum
    ) => {
      productsUpdateValue?.({
        ...data.appliesTo,
        productMatchType: {
          ...data.appliesTo?.productMatchType,
          value: newValue,
        },
      });
      updateValue?.({
        ...data,
        appliesTo: {
          ...data.appliesTo,
          productMatchType: {
            ...data.appliesTo?.productMatchType,
            value: newValue,
          },
        },
      });
    },
    [data, updateValue, productsUpdateValue]
  );

  const handleRequireProductsMatchChange = useCallback(
    (val: boolean) => {
      updateValue?.({
        ...data,
        appliesTo: {
          ...data.appliesTo,
          productMatchType: {
            ...data.appliesTo?.productMatchType,
            enabled: val,
          },
        },
      });
      productsUpdateValue?.({
        ...data.appliesTo,
        productMatchType: {
          ...data.appliesTo?.productMatchType,
          enabled: val,
        },
      });
    },
    [data, updateValue, productsUpdateValue]
  );

  return (
    <BlockStack>
      <Checkbox
        label={i18n.translate('GrayBoxBogoOptions.RequireProducts')}
        checked={data.appliesTo?.productMatchType?.enabled}
        onChange={handleRequireProductsMatchChange}
      />
      {data.appliesTo?.productMatchType?.enabled && (
        <Box paddingInlineStart='600' paddingBlockStart='0'>
          <BlockStack gap='025'>
            <RadioButton
              label={i18n.translate('GrayBoxBogoOptions.SameProduct')}
              checked={
                cartRule ===
                OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT
              }
              id={
                OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT
              }
              onChange={handleCartRuleChange}
            />
            <RadioButton
              label={i18n.translate('GrayBoxBogoOptions.SameProductVariant')}
              id={
                OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_VARIANT
              }
              checked={
                cartRule ===
                OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_VARIANT
              }
              onChange={handleCartRuleChange}
            />
          </BlockStack>
        </Box>
      )}
    </BlockStack>
  );
};
