import React from 'react';
import {
  Card,
  BlockStack,
  Text,
  Banner,
  Link,
  Checkbox,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PixelEventsIntegrationsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';

type PixelEventIntegrations = {
  pixelEvents?: PixelEventsIntegrationsSettingsDto;
  setPixelEvents: (data: PixelEventsIntegrationsSettingsDto) => void;
};

export const PixelEventIntegrations: React.FC<PixelEventIntegrations> = ({
  pixelEvents,
  setPixelEvents,
}) => {
  const [i18n] = useI18n();

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('PixelEventIntegrationsTitle')}
          </Text>
        </BlockStack>

        <Banner tone='info'>
          {i18n.translate('WarningBanner', {
            link: (
              <Link url='http://www.google.com' target='_blank'>
                {i18n.translate('FollowingArticle')}
              </Link>
            ),
          })}
        </Banner>
        <Checkbox
          label={i18n.translate('Facebook')}
          helpText={i18n.translate('FacebookHelpText')}
          checked={pixelEvents?.trackCustomEventFacebookPixel}
          onChange={(value) =>
            setPixelEvents({
              ...pixelEvents,
              trackCustomEventFacebookPixel: value,
            })
          }
        />
        <Checkbox
          label={i18n.translate('GoogleAnalytics')}
          helpText={i18n.translate('GoogleAnalyticsHelpText')}
          checked={pixelEvents?.trackCustomEventGoogleAnalyticsPixel}
          onChange={(value) =>
            setPixelEvents({
              ...pixelEvents,
              trackCustomEventGoogleAnalyticsPixel: value,
            })
          }
        />
        <Checkbox
          label={i18n.translate('GoogleTagManager')}
          helpText={i18n.translate('GoogleTagManagerHelpText')}
          checked={pixelEvents?.trackPromoViewEventInGoogleAnalytics}
          onChange={(value) =>
            setPixelEvents({
              ...pixelEvents,
              trackPromoViewEventInGoogleAnalytics: value,
            })
          }
        />
      </BlockStack>
    </Card>
  );
};
