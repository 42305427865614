import React, { useCallback, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  BlockStack,
  Button,
  Card,
  InlineStack,
  Text,
  ChoiceList,
} from '@shopify/polaris';
import {
  PageTypeDto,
  StrikethroughPricingLabelsSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useIsDebugOrLocal } from 'core/hooks';
import { useSearchParams } from 'react-router-dom';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { PageTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type LabelsSettingProps = {
  labels?: StrikethroughPricingLabelsSettingsDto;
  setLabels: (value: StrikethroughPricingLabelsSettingsDto) => void;
};

export const LabelsSetting: React.FC<LabelsSettingProps> = ({
  labels,
  setLabels,
}) => {
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [, setSearchParams] = useSearchParams();

  const pageOptions = useMemo(
    () =>
      Object.values(PageTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value,
      })),
    [i18n]
  );

  const navigateToTranslations = useCallback(async () => {
    !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
    setSearchParams((params) => {
      params.set('modal', NavPaths.SettingsModal);
      params.set('path', SettingsModalPathsEnums.Translations);
      return params;
    });
  }, []);

  const handleSoldOutChange = useCallback(
    (selected: string[]) => {
      setLabels({ ...labels, soldOutPages: selected as PageTypeDto[] });
    },
    [labels, setLabels]
  );

  const handleFromChange = useCallback(
    (selected: string[]) => {
      setLabels({ ...labels, fromPages: selected as PageTypeDto[] });
    },
    [labels, setLabels]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='center' wrap={false}>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Title')}
            </Text>
            <Text as='span' tone='subdued'>
              {i18n.translate('Description')}
            </Text>
          </BlockStack>
          <Button onClick={navigateToTranslations} variant='plain'>
            {i18n.translate('ConfigureTranslations')}
          </Button>
        </InlineStack>

        {labels?.soldOutPages && (
          <ChoiceList
            allowMultiple
            title={i18n.translate('SoldOutTitle')}
            choices={pageOptions}
            selected={(labels.soldOutPages ?? []).filter(
              (page): page is Exclude<PageTypeDto, null> => page !== null
            )}
            onChange={handleSoldOutChange}
          />
        )}

        {labels?.fromPages && (
          <ChoiceList
            allowMultiple
            title={i18n.translate('FromTitle')}
            choices={pageOptions}
            selected={(labels.fromPages ?? []).filter(
              (page): page is Exclude<PageTypeDto, null> => page !== null
            )}
            onChange={handleFromChange}
          />
        )}
      </BlockStack>
    </Card>
  );
};
