import React from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  Banner,
  BlockStack,
  Button,
  Card,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';

type TestStrikethroughPricingSettingProps = {
  testUrl?: string | null;
};

export const TestStrikethroughPricingSetting: React.FC<
  TestStrikethroughPricingSettingProps
> = ({ testUrl }) => {
  const [i18n] = useI18n();

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='center' wrap={false}>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate('Title')}
            </Text>
            <Text as='span' tone='subdued'>
              {i18n.translate('Description')}
            </Text>
          </BlockStack>
          <div>
            <Button onClick={() => testUrl && window.open(testUrl, '_blank')}>
              {i18n.translate('Test')}
            </Button>
          </div>
        </InlineStack>
        <Banner tone='info'>
          {i18n.translate('BannerContent', {
            link: (
              <Link url='http://www.google.com' target='_blank'>
                {i18n.translate('FollowingArticle')}
              </Link>
            ),
          })}
        </Banner>
      </BlockStack>
    </Card>
  );
};
