import React, { forwardRef, useCallback, useEffect } from 'react';
import { BlockStack, MediaCard, Page, VideoThumbnail } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { BrandColors } from './components/BrandColors/BrandColors';
import { PromotionLevelStylePreset } from './components/PromotionLevelStylePreset/PromotionLevelStylePreset';
import { useSearchParams } from 'react-router-dom';
import { PresetBoundariesTypeDtoEnum } from '../../../../../core/api/adminWidgets/adminWidgetsEnums';
import useFetchPresetList from '../../../hooks/useFetchPresetList/useFetchPresetList';
import { useAppSelector } from '../../../../../core/hooks';
import { ShopLevel } from './components/ShopLevel/ShopLevel';
import resolveEnvVar from 'env-var-resolver';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';

export const presetsPerPage = 10;
export const Style = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const pageBackAction = useSettingsPageBackAction();

  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const { fetchList, getCurrentShopLevelDefaultStylePreview } =
    useFetchPresetList();

  const {
    defaultShopLevelStylePreset,
    shopLevelStylePresets,
    promotionLevelStylePresets,
  } = useAppSelector((state) => state.widgets);

  const onLevelClick = useCallback(
    (subPath: string) => {
      setSearchParams((params) => {
        params.set('subPath', subPath);
        return params;
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    !promotionLevelStylePresets &&
      fetchList({
        boundaries: PresetBoundariesTypeDtoEnum.PROMOTION_LEVEL,
        page: 1,
        search: '',
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        itemsPerPage: presetsPerPage,
      });
    !shopLevelStylePresets &&
      fetchList({
        boundaries: PresetBoundariesTypeDtoEnum.SHOP_LEVEL,
        page: 1,
        search: '',
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        itemsPerPage: presetsPerPage,
      });
    !defaultShopLevelStylePreset &&
      getCurrentShopLevelDefaultStylePreview({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      });
  }, []);

  return (
    <>
      {shopLevelStylePresets &&
      promotionLevelStylePresets &&
      defaultShopLevelStylePreset ? (
        <Page backAction={pageBackAction} title={i18n.translate('Style')}>
          <BlockStack gap='600'>
            <MediaCard
              title={i18n.translate('LearnMoreAbout')}
              primaryAction={{
                content: i18n.translate('LearnMore'),
                onAction: () => null,
              }}
              description={i18n.translate(`LearnMoreAboutDescription`)}
              size='small'
            >
              <VideoThumbnail
                videoLength={80}
                thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
                onClick={() =>
                  window.open(
                    'https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley',
                    '_blank'
                  )
                }
              />
            </MediaCard>
            <PromotionLevelStylePreset
              onLevelClick={onLevelClick}
              promotionLevelStylePresets={promotionLevelStylePresets}
            />
            <ShopLevel
              onLevelClick={onLevelClick}
              defaultShopLevelStylePreset={defaultShopLevelStylePreset}
              shopLevelStylePresets={shopLevelStylePresets}
            />
            <BrandColors ref={ref} />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
Style.displayName = 'Style';
