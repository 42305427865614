import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusIcon, QuestionCircleIcon } from '@shopify/polaris-icons';
import {
  ActionList,
  Badge,
  Banner,
  BlockStack,
  Button,
  Icon,
  InlineStack,
  Popover,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OptionDtoListShopifyObjectDto,
  OptionDtoListString,
  PrerequisiteEntitledExclusionsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTargetTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { ExclusionsResourcePicker } from './components/ExclusionsResourcePicker/ExclusionsResourcePicker';
import { ExclusionsPhrasePicker } from './components/ExclusionsPhrasePicker/ExclusionsPhrasePicker';
import { Tooltip } from '../ControlLibrary';
import { SimpleExclusion } from './components/SimpleExclusion/SimpleExclusion';

export type PickerExclusionsType = Omit<
  PrerequisiteEntitledExclusionsDto,
  | 'excludeVariantsWithCompareAtPrice'
  | 'excludeVariantsWithoutCompareAtPrice'
  | 'excludeRecurringPurchase'
  | 'excludeOneTimePurchase'
>;

type SimpleExclusionsType = Pick<
  PrerequisiteEntitledExclusionsDto,
  | 'excludeVariantsWithCompareAtPrice'
  | 'excludeVariantsWithoutCompareAtPrice'
  | 'excludeRecurringPurchase'
  | 'excludeOneTimePurchase'
>;

type ExclusionsBoxProps = {
  title: string;
  exclusions: PrerequisiteEntitledExclusionsDto;
  subtitle?: string;
  selectedCompareAtPrice?: keyof SimpleExclusionsType;
  selectedPurchaseType?: keyof SimpleExclusionsType;
  tooltip?: string;
  setExclusions: (data: PrerequisiteEntitledExclusionsDto) => void;
  onFormValidityChange?: (isValid: boolean) => void;
};

export const ExclusionsBox: React.FC<ExclusionsBoxProps> = ({
  title,
  exclusions,
  subtitle,
  selectedCompareAtPrice,
  selectedPurchaseType,
  tooltip,
  setExclusions,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();
  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [phraseModal, setPhraseModal] = useState<
    keyof PrerequisiteEntitledExclusionsDto | null
  >(null);

  const exclusionKeys = {
    resource: [
      'collections',
      'products',
      'productVariants',
    ] as (keyof PickerExclusionsType)[],
    phrase: [
      'tags',
      'vendors',
      'excludeProductNameFilter',
      'includeProductNameFilter',
      'excludeProductVariantNameFilter',
      'includeProductVariantNameFilter',
    ] as (keyof PickerExclusionsType)[],
    simple: [
      'excludeVariantsWithCompareAtPrice',
      'excludeVariantsWithoutCompareAtPrice',
      'excludeRecurringPurchase',
      'excludeOneTimePurchase',
    ] as (keyof SimpleExclusionsType)[],
    items: [
      'collections',
      'products',
      'productVariants',
      'tags',
      'vendors',
    ] as (keyof PickerExclusionsType)[],
    productTitle: [
      'excludeProductNameFilter',
      'includeProductNameFilter',
    ] as (keyof PickerExclusionsType)[],
    variantTitle: [
      'excludeProductVariantNameFilter',
      'includeProductVariantNameFilter',
    ] as (keyof PickerExclusionsType)[],
    compareAtPrice: [
      'excludeVariantsWithCompareAtPrice',
      'excludeVariantsWithoutCompareAtPrice',
    ] as (keyof SimpleExclusionsType)[],
    purchaseType: [
      'excludeRecurringPurchase',
      'excludeOneTimePurchase',
    ] as (keyof SimpleExclusionsType)[],
  };

  const isCompareAtPriceConflict = useMemo(
    () =>
      selectedCompareAtPrice &&
      (exclusions.excludeVariantsWithCompareAtPrice ||
        exclusions.excludeVariantsWithoutCompareAtPrice),
    [
      selectedCompareAtPrice,
      exclusions.excludeVariantsWithCompareAtPrice,
      exclusions.excludeVariantsWithoutCompareAtPrice,
    ]
  );

  const isPurchaseTypeConflict = useMemo(
    () =>
      selectedPurchaseType &&
      (exclusions.excludeRecurringPurchase ||
        exclusions.excludeOneTimePurchase),
    [
      selectedCompareAtPrice,
      exclusions.excludeVariantsWithCompareAtPrice,
      exclusions.excludeVariantsWithoutCompareAtPrice,
    ]
  );

  const isValidationError = useMemo(() => {
    return (
      [
        exclusions.collections,
        exclusions.productVariants,
        exclusions.products,
        exclusions.excludeProductNameFilter,
        exclusions.includeProductNameFilter,
        exclusions.excludeProductVariantNameFilter,
        exclusions.includeProductVariantNameFilter,
        exclusions.tags,
        exclusions.vendors,
      ].some(
        (item) =>
          item?.enabled === true && (!item.value || item.value.length === 0)
      ) ||
      isCompareAtPriceConflict ||
      isPurchaseTypeConflict
    );
  }, [exclusions, isCompareAtPriceConflict, isPurchaseTypeConflict]);

  const exclusionsStatus = useMemo(
    () => [
      exclusions.collections?.enabled,
      exclusions.productVariants?.enabled,
      exclusions.products?.enabled,
      exclusions.excludeProductNameFilter?.enabled,
      exclusions.includeProductNameFilter?.enabled,
      exclusions.excludeProductVariantNameFilter?.enabled,
      exclusions.includeProductVariantNameFilter?.enabled,
      exclusions.tags?.enabled,
      exclusions.vendors?.enabled,
      exclusions.excludeVariantsWithCompareAtPrice,
      exclusions.excludeVariantsWithoutCompareAtPrice,
      exclusions.excludeOneTimePurchase,
      exclusions.excludeRecurringPurchase,
    ],
    [exclusions]
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive((active) => !active),
    []
  );

  const togglePickerExclusions = useCallback(
    (key: keyof PickerExclusionsType) => {
      setPopoverActive(false);
      if (exclusionKeys.phrase.includes(key)) {
        setPhraseModal(key);
        return;
      }
      setExclusions({
        ...exclusions,
        [key]: {
          ...exclusions?.[key],
          enabled: !exclusions?.[key]?.enabled,
        },
      });
    },
    [exclusions, setExclusions]
  );

  const toggleSimpleExclusions = useCallback(
    (key: keyof SimpleExclusionsType) => {
      setPopoverActive(false);
      setExclusions({
        ...exclusions,
        [key]: !exclusions?.[key],
      });
    },
    [exclusions, setExclusions]
  );

  const handleExclusionsState = useCallback(
    (
      field: keyof PrerequisiteEntitledExclusionsDto,
      value: PrerequisiteEntitledExclusionsDto[keyof PrerequisiteEntitledExclusionsDto]
    ) => setExclusions({ ...exclusions, [field]: value }),
    [exclusions, setExclusions]
  );

  const popoverActions = useMemo(() => {
    const createPickerItems = (keys: (keyof PickerExclusionsType)[]) =>
      keys.map((key) => ({
        content: i18n.translate(`Actions.${key}`),
        disabled: exclusions?.[key]?.enabled,
        onAction: () => togglePickerExclusions(key),
      }));
    const createSimpleItems = (keys: (keyof SimpleExclusionsType)[]) => {
      return keys.map((key) => {
        const isDisabled =
          keys.some((targetKey) => exclusions?.[targetKey]) ||
          (selectedCompareAtPrice && keys.includes(selectedCompareAtPrice)) ||
          (selectedPurchaseType && keys.includes(selectedPurchaseType));

        return {
          content: i18n.translate(`Actions.${key}`),
          disabled: isDisabled,
          suffix: isDisabled &&
            !exclusions?.[key] &&
            key !== selectedCompareAtPrice &&
            key !== selectedPurchaseType && (
              <Tooltip content={i18n.translate('ConflictedRule')}>
                <Icon source={QuestionCircleIcon} />
              </Tooltip>
            ),
          onAction: () => toggleSimpleExclusions(key),
        };
      });
    };

    return [
      {
        title: i18n.translate('Item'),
        items: createPickerItems(exclusionKeys.items),
      },
      {
        title: i18n.translate('ProductTitle'),
        items: createPickerItems(exclusionKeys.productTitle),
      },
      {
        title: i18n.translate('VariantTitle'),
        items: createPickerItems(exclusionKeys.variantTitle),
      },
      {
        title: i18n.translate('CompareAtPrice'),
        items: createSimpleItems(exclusionKeys.compareAtPrice),
      },
      {
        title: i18n.translate('PurchaseType'),
        items: createSimpleItems(exclusionKeys.purchaseType),
      },
    ];
  }, [
    i18n,
    exclusions,
    togglePickerExclusions,
    selectedCompareAtPrice,
    selectedPurchaseType,
  ]);

  useEffect(() => {
    onFormValidityChange?.(!isValidationError);
  }, [isValidationError]);

  return (
    <div className='ExclusionsBox'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <InlineStack gap='200'>
            <Tooltip hasUnderline content={tooltip}>
              <Text as='p' fontWeight='semibold'>
                {title}
              </Text>
            </Tooltip>
            <Badge>{`${
              exclusionsStatus.filter((value) => Boolean(value)).length
            }`}</Badge>
          </InlineStack>
          {subtitle && (
            <Text as='p' tone='subdued'>
              {subtitle}
            </Text>
          )}
        </BlockStack>
        <BlockStack gap='200'>
          {(isCompareAtPriceConflict || isPurchaseTypeConflict) && (
            <Banner tone='critical'>{i18n.translate('ConflictsBanner')}</Banner>
          )}

          {/* Resource pickers */}
          {exclusionKeys.resource.map((key) => {
            const resourceData = exclusions?.[
              key
            ] as OptionDtoListShopifyObjectDto;
            const formatType =
              key === 'collections'
                ? OfferTargetTypeDtoEnum.COLLECTIONS
                : key === 'products'
                ? OfferTargetTypeDtoEnum.PRODUCTS
                : OfferTargetTypeDtoEnum.VARIANTS;

            return (
              resourceData?.enabled && (
                <ExclusionsResourcePicker
                  key={key}
                  formatType={formatType}
                  resource={resourceData as OptionDtoListShopifyObjectDto}
                  setResource={(data) => handleExclusionsState(key, data)}
                />
              )
            );
          })}

          {/* Phrase pickers */}
          {exclusionKeys.phrase.map((key) => {
            const phrasesData = exclusions?.[key];
            return (
              (key === phraseModal || phrasesData?.enabled) && (
                <ExclusionsPhrasePicker
                  key={key}
                  type={key}
                  label={i18n.translate(`Actions.${key}`)}
                  phrases={phrasesData as OptionDtoListString}
                  isModalOpen={key === phraseModal}
                  toggleModal={() => setPhraseModal(phraseModal ? null : key)}
                  setPhrases={(data) => handleExclusionsState(key, data)}
                />
              )
            );
          })}

          {/* Boolean exclusions (simple) */}
          {exclusionKeys.simple.map((key) => {
            const isEnabled = !!exclusions?.[key];
            const isCompateAtPrice = exclusionKeys.compareAtPrice.includes(key);
            return (
              isEnabled && (
                <SimpleExclusion
                  label={i18n.translate(`Actions.${key}`)}
                  conflict={
                    isCompateAtPrice
                      ? isCompareAtPriceConflict
                      : isPurchaseTypeConflict
                  }
                  onRemove={() => toggleSimpleExclusions(key)}
                />
              )
            );
          })}

          {!exclusionsStatus.every((value) => Boolean(value)) && (
            <Popover
              active={popoverActive}
              activator={
                <Button
                  onClick={togglePopoverActive}
                  textAlign='start'
                  icon={PlusIcon}
                  variant='plain'
                >
                  {i18n.translate('AddExclusion')}
                </Button>
              }
              autofocusTarget='first-node'
              onClose={togglePopoverActive}
            >
              <ActionList actionRole='menuitem' sections={popoverActions} />
            </Popover>
          )}
        </BlockStack>
      </BlockStack>
    </div>
  );
};
