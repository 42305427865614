import React, { useCallback, useEffect, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { NinjaCartEngineInstallationDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useAppSelector } from 'core/hooks';
import { Loader } from 'core/components';
import { CheckCircle } from 'core/components/SetupStepBox/assets/icons';
import { ActionListInPopover } from 'features/promotions/components/ActionListInPopover/ActionListInPopover';
import { useWidgetsControlCenter } from 'core/hooks/useWidgetsControlCenter';
import { WidgetStatusDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { EnableDrawerCartModal } from './components/EnableDrawerCartModal/EnableDrawerCartModal';

type NinjaCartSectionProps = {
  isAppEnabled: boolean;
  isLoading: boolean;
  themeName: string;
  ninjaCartDrawerCart?: NinjaCartEngineInstallationDto;
  ninjaCartCartPage?: boolean;
  refetchWidgetInstallationData: () => void;
};

export const NinjaCartSection: React.FC<NinjaCartSectionProps> = ({
  isAppEnabled,
  isLoading,
  ninjaCartDrawerCart,
  ninjaCartCartPage,
  themeName,
  refetchWidgetInstallationData,
}) => {
  const [i18n] = useI18n();

  const { widgetThemeType } = useAppSelector((store) => store.settings);

  const { installCartPageIsLoading, installCartPage } = useWidgetsControlCenter(
    undefined,
    true
  );

  const [activePopoverId, setActivePopoverId] = useState<string>('');
  const [checkConfiguration, setCheckConfiguration] = useState<boolean>(false);
  const [enableCartModal, setDrawerCartModal] = useState<boolean>(false);

  const toggleDrawerCartModal = useCallback(
    () => setDrawerCartModal((prev) => !prev),
    [setDrawerCartModal]
  );

  const handleDrawerCartOpen = useCallback(() => {
    if (ninjaCartDrawerCart?.installationDeepLink) {
      window.open(ninjaCartDrawerCart.installationDeepLink, '_blank');
      setCheckConfiguration(true);
      toggleDrawerCartModal();
    }
  }, [ninjaCartDrawerCart?.installationDeepLink]);

  const handleCartOpen = useCallback(async () => {
    const newStatus = ninjaCartCartPage
      ? WidgetStatusDtoEnum.DISABLED
      : WidgetStatusDtoEnum.ENABLED;

    if (!installCartPageIsLoading) {
      await installCartPage({ status: newStatus });
      refetchWidgetInstallationData();
    }
  }, [
    installCartPage,
    installCartPageIsLoading,
    ninjaCartCartPage,
    refetchWidgetInstallationData,
  ]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && checkConfiguration) {
        refetchWidgetInstallationData();
        setCheckConfiguration(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [checkConfiguration]);

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='200'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('Title')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('Description', {
              theme: i18n.translate(widgetThemeType),
              name: themeName,
            })}
          </Text>
        </BlockStack>
        <Box
          borderWidth='025'
          borderColor='border'
          borderRadius='200'
          paddingInline='300'
          paddingBlock='300'
        >
          <BlockStack gap='300'>
            <InlineStack blockAlign='center' align='space-between'>
              <InlineStack align='center' gap='200'>
                <div style={{ maxHeight: 20 }}>
                  {isLoading ? (
                    <Loader fullWidth size='small' />
                  ) : (
                    <CheckCircle checked={!!ninjaCartDrawerCart?.enabled} />
                  )}
                </div>
                <Text as='p'>{i18n.translate('DrawerCart')}</Text>
                <Badge
                  tone={ninjaCartDrawerCart?.enabled ? 'success' : undefined}
                >
                  {i18n.translate(
                    ninjaCartDrawerCart?.enabled ? 'Enabled' : 'Disabled'
                  )}
                </Badge>
              </InlineStack>
              {ninjaCartDrawerCart?.enabled ? (
                <ActionListInPopover
                  id='DrawerCart'
                  btnContent=''
                  activePopoverId={activePopoverId}
                  setActivePopoverId={setActivePopoverId}
                  disable={
                    !isAppEnabled || installCartPageIsLoading || isLoading
                  }
                  actionList={[
                    {
                      content: i18n.translate('Disable'),
                      onAction: toggleDrawerCartModal,
                      destructive: true,
                    },
                  ]}
                />
              ) : (
                <Button variant='primary' onClick={toggleDrawerCartModal}>
                  {i18n.translate('Enable')}
                </Button>
              )}
            </InlineStack>
            <Bleed marginInline='300'>
              <Divider borderColor='border' />
            </Bleed>
            <InlineStack blockAlign='center' align='space-between'>
              <InlineStack align='center' gap='200'>
                <div style={{ maxHeight: 20 }}>
                  {isLoading || installCartPageIsLoading ? (
                    <Loader fullWidth size='small' />
                  ) : (
                    <CheckCircle checked={!!ninjaCartCartPage} />
                  )}
                </div>
                <Text as='p'>{i18n.translate('Cart')}</Text>
                <Badge tone={ninjaCartCartPage ? 'success' : undefined}>
                  {i18n.translate(ninjaCartCartPage ? 'Enabled' : 'Disabled')}
                </Badge>
              </InlineStack>
              {ninjaCartCartPage ? (
                <ActionListInPopover
                  id='CartPage'
                  btnContent=''
                  activePopoverId={activePopoverId}
                  setActivePopoverId={setActivePopoverId}
                  disable={
                    !isAppEnabled || installCartPageIsLoading || isLoading
                  }
                  actionList={[
                    {
                      content: i18n.translate('Disable'),
                      onAction: handleCartOpen,
                      destructive: true,
                    },
                  ]}
                />
              ) : (
                <Button variant='primary' onClick={handleCartOpen}>
                  {i18n.translate('Enable')}
                </Button>
              )}
            </InlineStack>
          </BlockStack>
        </Box>
      </BlockStack>
      <EnableDrawerCartModal
        isOpen={enableCartModal}
        onClose={toggleDrawerCartModal}
        onEnable={handleDrawerCartOpen}
      />
    </Card>
  );
};
