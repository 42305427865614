import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  CustomStrikethroughPricingShopSettingsDto,
  WidgetSettingsDtoCustomStrikethroughPricingShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  StrikethroughPricingFreeGiftsQuantitySpinnerModeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  BlockWithGeneralCheckboxSetting,
  InstallmentsSetting,
  LabelsSetting,
  StyleSetting,
  TestStrikethroughPricingSetting,
} from '../../components';
import { LoadingEffectSetting } from '../../components/LoadingEffectSetting/LoadingEffectSetting';

type StrikeThroughPricingSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  widgetType: WidgetTypeDtoEnum;
};

export const CustomStrikeThroughPricingSettings = forwardRef<
  WidgetSavebarRef,
  StrikeThroughPricingSettingsProps
>(({ setIsLoading, setIsSaving, widgetType }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const isProductStrikeThroughPricing =
    widgetType === WidgetTypeDtoEnum.PRODUCT_STRIKETHROUGH_PRICING;

  const {
    customStrikeThroughPricingData,
    saveCustomStrikeThroughPricing,
    customStrikeThroughPricingDataIsFetching,
    saveCustomStrikeThroughPricingIsLoading,
    productStrikeThroughPricingData,
    saveProductStrikeThroughPricing,
    productStrikeThroughPricingDataIsFetching,
    saveProductStrikeThroughPricingIsLoading,
    // refetchProductStrikeThroughPricingData,
    // refetchCustomStrikeThroughPricingData,
  } = useWidget(
    isProductStrikeThroughPricing
      ? WidgetTypeDtoEnum.PRODUCT_STRIKETHROUGH_PRICING
      : WidgetTypeDtoEnum.CUSTOM_STRIKETHROUGH_PRICING
  );

  const currentStrikeThroughPricing = useMemo(() => {
    return isProductStrikeThroughPricing
      ? {
          data: productStrikeThroughPricingData,
          isFetching: productStrikeThroughPricingDataIsFetching,
          isSaving: saveProductStrikeThroughPricingIsLoading,
          save: saveProductStrikeThroughPricing,
        }
      : {
          data: customStrikeThroughPricingData,
          isFetching: customStrikeThroughPricingDataIsFetching,
          isSaving: saveCustomStrikeThroughPricingIsLoading,
          save: saveCustomStrikeThroughPricing,
        };
  }, [
    isProductStrikeThroughPricing,
    productStrikeThroughPricingData,
    productStrikeThroughPricingDataIsFetching,
    saveProductStrikeThroughPricingIsLoading,
    saveProductStrikeThroughPricing,
    customStrikeThroughPricingData,
    customStrikeThroughPricingDataIsFetching,
    saveCustomStrikeThroughPricingIsLoading,
    saveCustomStrikeThroughPricing,
  ]);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoCustomStrikethroughPricingShopSettingsDto>({});
  const [strikeThroughPricingState, setStrikeThroughPricingState] =
    useState<WidgetSettingsDtoCustomStrikethroughPricingShopSettingsDto>({});

  const updateStrikeThroughPricingState = useCallback(
    (
      field: keyof CustomStrikethroughPricingShopSettingsDto,
      data: CustomStrikethroughPricingShopSettingsDto[keyof CustomStrikethroughPricingShopSettingsDto]
    ) => {
      setStrikeThroughPricingState((prev) => {
        const newState = {
          ...prev,
          settings: {
            ...prev.settings,
            [field]: data,
          },
        };
        return newState;
      });
    },
    [setStrikeThroughPricingState]
  );

  const handleSave = useCallback(
    () =>
      currentStrikeThroughPricing.save(
        strikeThroughPricingState.settings || null,
        () => setSavedData(strikeThroughPricingState)
      ),
    [strikeThroughPricingState]
  );

  useEffect(() => {
    if (!isEmpty(currentStrikeThroughPricing.data)) {
      setStrikeThroughPricingState(currentStrikeThroughPricing.data);
      setSavedData(currentStrikeThroughPricing.data);
    }
  }, [currentStrikeThroughPricing.data]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(strikeThroughPricingState)) {
      dispatch(
        setIsUnsavedChanges(!isEqual(strikeThroughPricingState, savedData))
      );
    }
  }, [savedData, strikeThroughPricingState]);

  useEffect(() => {
    setIsLoading(currentStrikeThroughPricing.isFetching);
  }, [currentStrikeThroughPricing.isFetching]);

  useEffect(() => {
    setIsSaving(currentStrikeThroughPricing.isSaving);
  }, [currentStrikeThroughPricing.isSaving]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setStrikeThroughPricingState(savedData),
  }));

  return !currentStrikeThroughPricing.isFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting
        styled={!!currentStrikeThroughPricing.data?.isShopLevelWidget}
      />
      <TestStrikethroughPricingSetting
        testUrl={currentStrikeThroughPricing.data?.settings?.testUrl}
      />
      <LabelsSetting
        labels={strikeThroughPricingState?.settings?.labels}
        setLabels={(data) => updateStrikeThroughPricingState('labels', data)}
      />
      {strikeThroughPricingState?.settings?.quantitySpinner && (
        <BlockWithGeneralCheckboxSetting
          settings={strikeThroughPricingState?.settings?.quantitySpinner}
          setSettingState={(data) =>
            updateStrikeThroughPricingState('quantitySpinner', data)
          }
          valuesEnum={StrikethroughPricingFreeGiftsQuantitySpinnerModeDtoEnum}
          checkboxLabel='Checkbox'
          title='QuantitySpinner'
          mainDescription='QuantitySpinnerDescription'
          fields={Object.keys(
            strikeThroughPricingState?.settings?.quantitySpinner || {}
          )}
          defaultValue={
            StrikethroughPricingFreeGiftsQuantitySpinnerModeDtoEnum.VISIBLE_AND_ENABLED
          }
          badge='ThemeDefault'
        />
      )}

      <InstallmentsSetting
        installments={strikeThroughPricingState.settings?.installments || {}}
        setInstallments={(data) =>
          updateStrikeThroughPricingState('installments', data)
        }
      />
      <LoadingEffectSetting
        loadingEffectDuration={
          strikeThroughPricingState.settings?.loadingEffectDuration
        }
        setLoadingEffectDuration={(data) =>
          updateStrikeThroughPricingState('loadingEffectDuration', data)
        }
      />
    </>
  ) : null;
});

CustomStrikeThroughPricingSettings.displayName =
  'CustomStrikeThroughPricingSettings';
