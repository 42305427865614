import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  NinjaCartPaymentOptionsWidgetSettingsDto,
  WidgetSettingsDtoNinjaCartPaymentOptionsWidgetSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import { PaymentOptionsSetting, StyleSetting } from '../../components';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';

type NinjaCartPaymentOptionsSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NinjaCartPaymentOptionsSettings = forwardRef<
  WidgetSavebarRef,
  NinjaCartPaymentOptionsSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const {
    ninjaCartPaymentOptionsData,
    ninjaCartPaymentOptionsDataIsFetching,
    saveNinjaCartPaymentOptionsIsLoading,
    saveNinjaCartPaymentOptions,
  } = useNinjaCartWidget(WidgetTypeDtoEnum.NINJA_CART_PAYMENT_OPTIONS);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoNinjaCartPaymentOptionsWidgetSettingsDto>({});
  const [paymentOptionsState, setPaymentOptionsState] =
    useState<WidgetSettingsDtoNinjaCartPaymentOptionsWidgetSettingsDto>({});

  const updatePaymentOptionsState = useCallback(
    (
      field: keyof NinjaCartPaymentOptionsWidgetSettingsDto,
      data: NinjaCartPaymentOptionsWidgetSettingsDto[keyof NinjaCartPaymentOptionsWidgetSettingsDto]
    ) =>
      setPaymentOptionsState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setPaymentOptionsState]
  );

  const handleSave = useCallback(
    () =>
      saveNinjaCartPaymentOptions(paymentOptionsState.settings || null, () =>
        setSavedData(paymentOptionsState)
      ),
    [paymentOptionsState]
  );

  useEffect(() => {
    if (!isEmpty(ninjaCartPaymentOptionsData)) {
      setPaymentOptionsState(ninjaCartPaymentOptionsData);
      setSavedData(ninjaCartPaymentOptionsData);
    }
  }, [ninjaCartPaymentOptionsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(paymentOptionsState)) {
      dispatch(setIsUnsavedChanges(!isEqual(paymentOptionsState, savedData)));
    }
  }, [savedData, paymentOptionsState]);

  useEffect(() => {
    setIsLoading(ninjaCartPaymentOptionsDataIsFetching);
  }, [ninjaCartPaymentOptionsDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveNinjaCartPaymentOptionsIsLoading);
  }, [saveNinjaCartPaymentOptionsIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setPaymentOptionsState(savedData),
  }));

  return !ninjaCartPaymentOptionsDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!ninjaCartPaymentOptionsData?.isShopLevelWidget} />
      <PaymentOptionsSetting
        paymentOptions={paymentOptionsState.settings?.paymentOptionsType || []}
        setPaymentOptions={(value) =>
          updatePaymentOptionsState('paymentOptionsType', value)
        }
      />
    </>
  ) : null;
});

NinjaCartPaymentOptionsSettings.displayName = 'NinjaCartPaymentOptionsSettings';
