import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  NinjaCartPromotionSummaryWidgetSettingsDto,
  PromotionSummaryTotalDiscountCalculationModeDto,
  WidgetSettingsDtoNinjaCartPromotionSummaryWidgetSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  ShippingAmountSetting,
  StyleSetting,
  TotalDiscountSetting,
} from '../../components';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';
import { useWidget } from 'features/widgets/hooks/useWidget';
import { Card, BlockStack, Checkbox, Text } from '@shopify/polaris';
import { setShowValidation } from 'core/store/promotionsSlice';
import { useSettingsFormValidity } from 'features/promotions/hooks/useSettingsFormValidity';

type PromotionSummarySettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  widgetType: WidgetTypeDtoEnum;
};

export const PromotionSummarySettings = forwardRef<
  WidgetSavebarRef,
  PromotionSummarySettingsProps
>(({ setIsLoading, setIsSaving, widgetType }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const { validityChangeHandler } = useSettingsFormValidity();

  const isNinjaCart =
    widgetType === WidgetTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY;

  const ninjaCartData = useNinjaCartWidget(
    isNinjaCart ? WidgetTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY : undefined
  );
  const widgetData = useWidget(
    !isNinjaCart ? WidgetTypeDtoEnum.PROMOTION_SUMMARY : undefined
  );

  const {
    promotionSummaryData,
    promotionSummaryDataIsFetching,
    savePromotionSummary,
    savePromotionSummaryIsLoading,
  } = isNinjaCart ? ninjaCartData : widgetData;

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoNinjaCartPromotionSummaryWidgetSettingsDto>({});
  const [promotionSummaryState, setPromotionSummaryState] =
    useState<WidgetSettingsDtoNinjaCartPromotionSummaryWidgetSettingsDto>({});

  const updatePromotionSummaryState = useCallback(
    (
      field: keyof NinjaCartPromotionSummaryWidgetSettingsDto,
      data: NinjaCartPromotionSummaryWidgetSettingsDto[keyof NinjaCartPromotionSummaryWidgetSettingsDto]
    ) =>
      setPromotionSummaryState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setPromotionSummaryState]
  );

  const handleSave = useCallback(
    () =>
      savePromotionSummary(promotionSummaryState.settings || null, () => {
        setSavedData(promotionSummaryState);
        dispatch(setShowValidation(false));
      }),
    [promotionSummaryState, dispatch]
  );

  useEffect(() => {
    if (!isEmpty(promotionSummaryData)) {
      setPromotionSummaryState(promotionSummaryData);
      setSavedData(promotionSummaryData);
    }
  }, [promotionSummaryData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(promotionSummaryState)) {
      dispatch(setIsUnsavedChanges(!isEqual(promotionSummaryState, savedData)));
    }
  }, [savedData, promotionSummaryState]);

  useEffect(() => {
    setIsLoading(promotionSummaryDataIsFetching);
  }, [promotionSummaryDataIsFetching]);

  useEffect(() => {
    setIsSaving(savePromotionSummaryIsLoading);
  }, [savePromotionSummaryIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setPromotionSummaryState(savedData),
  }));

  return !promotionSummaryDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!promotionSummaryData?.isShopLevelWidget} />
      <TotalDiscountSetting
        total={
          promotionSummaryState.settings
            ?.totalDiscountCalculationMode as PromotionSummaryTotalDiscountCalculationModeDto
        }
        showTotal={!!promotionSummaryState.settings?.showTotal}
        showDiscountBreakdown={
          !!promotionSummaryState.settings?.showDiscountBreakdown
        }
        setTotal={(value) =>
          updatePromotionSummaryState('totalDiscountCalculationMode', value)
        }
        setShowTotal={(value) =>
          updatePromotionSummaryState('showTotal', value)
        }
        setShowDiscountBreakdown={(value) =>
          updatePromotionSummaryState('showDiscountBreakdown', value)
        }
        onFormValidityChange={validityChangeHandler}
      />
      <ShippingAmountSetting
        shippingAmount={promotionSummaryState.settings?.shippingAmount || {}}
        setShippingAmount={(data) =>
          updatePromotionSummaryState('shippingAmount', data)
        }
      />
      <Card>
        <BlockStack gap='400'>
          <Text as='p' fontWeight='bold'>
            {i18n.translate('FreeGift')}
          </Text>
          <Checkbox
            label={i18n.translate('ConsiderFreeGift')}
            checked={
              !!promotionSummaryState.settings?.considerFreeGiftADiscount
            }
            onChange={(value) =>
              updatePromotionSummaryState('considerFreeGiftADiscount', value)
            }
          />
        </BlockStack>
      </Card>
    </>
  ) : null;
});

PromotionSummarySettings.displayName = 'PromotionSummarySettings';
