import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferFreeGiftRuleDto,
  OfferGiftSpecificationDto,
  OfferGiftTypeDto,
  OfferTargetTypeDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Button,
  BlockStack,
  InlineStack,
  Page,
  Text,
  Box,
  Card,
  Bleed,
  Icon,
  ChoiceList,
  List,
  Banner,
  Link,
} from '@shopify/polaris';
import { GiftCardIcon, AlertTriangleIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import './FreeGiftSection.scss';
import { FreeGiftFirstLineContent } from './components/FreeGiftFirstLineContent/FreeGiftFirstLineContent';
import {
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
  SearchFieldWithGrayBox,
} from 'core/components/SearchFieldWithGrayBoxOfferWizard';

import {
  OfferDefaultGiftRuleDtoEnum,
  OfferGiftTypeDtoEnum,
  OfferTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import { setCurrentConfigPage } from 'core/store/offersWizardSlice';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import {
  boldMessageParts,
  generateMessage,
  SectionTypeEnum,
} from '../CartRules/utils/utils';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { headerTitle } from 'core/components/SearchFieldWithGrayBoxOfferWizard/utils/utils';
import { isEqual } from 'lodash';
import { ExclusionsPhrasePicker } from 'core/components/ExclusionsBox/components/ExclusionsPhrasePicker/ExclusionsPhrasePicker';
import { PickerExclusionsType } from 'core/components';

export type FreeGiftSectionProps = {
  specification?: OfferGiftSpecificationDto;
  configureComponent: boolean;
  isProPlan?: boolean;
  isNinjaCartProEnabled?: boolean;
  preLimit?: number;
  type?: OfferTypeDtoEnum;
  onFormValidityChange(formIsValid: boolean): void;
  onSpecificationUpdate: (specification: OfferGiftSpecificationDto) => void;
  setConfigureComponent: (data: boolean) => void;
};

export type FreeGiftSectionValidityBlocksProps = {
  isTypeSwitcherBlockValid: boolean;
  isSearchFieldBlockValid: boolean;
};

export type ComboBoxOptionsProps = {
  value: string;
  label: string;
};

export const initialManualByCustomerGiftSpecificationValues: OfferGiftSpecificationDto =
  {
    advancedOptions: {
      addGiftAutomaticallyWhenCriteriaMet: false,
      removeGiftAutomaticallyWhenCriteriaNotMet: true,
    },
    limit: {
      enabled: true,
      value: 1,
    },
    rule: {
      amount: 1,
      collections: [],
      defaultGiftRule: OfferDefaultGiftRuleDtoEnum.MANUAL_BY_CUSTOMER,
      productVariants: [],
      products: [],
      type: OfferGiftTypeDtoEnum.COLLECTIONS,
    },
  };

export const initialAutomaticGiftSpecificationValues: OfferGiftSpecificationDto =
  {
    rule: {
      amount: 1,
      type: OfferGiftTypeDtoEnum.PRODUCT_VARIANT,
      products: [],
      productVariants: [],
      collections: [],
      defaultGiftRule: OfferDefaultGiftRuleDtoEnum.AUTOMATIC,
    },
    limit: {
      enabled: true,
      value: 1,
    },
    advancedOptions: {
      addGiftAutomaticallyWhenCriteriaMet: true,
      removeGiftAutomaticallyWhenCriteriaNotMet: true,
    },
  };

export const FreeGiftSection: React.FC<FreeGiftSectionProps> = (props) => {
  const {
    specification,
    isProPlan,
    isNinjaCartProEnabled,
    preLimit,
    type,
    configureComponent,
    onFormValidityChange,
    onSpecificationUpdate,
    setConfigureComponent,
  } = props;

  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const isDebugOrLocal = useIsDebugOrLocal();

  const { getLookupShopifyDetailedObjectsDetails } = useConfigureOffers();

  const initialSpecificationValues = useMemo(
    () =>
      type === OfferTypeDtoEnum.GIFT_AUTOMATIC
        ? initialAutomaticGiftSpecificationValues
        : initialManualByCustomerGiftSpecificationValues,
    [type]
  );

  const [currentSpecification, setCurrentSpecification] =
    useState<OfferGiftSpecificationDto>(
      specification || initialSpecificationValues
    );
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [validityBlocks, setValidityBlocks] =
    useState<FreeGiftSectionValidityBlocksProps>({
      isTypeSwitcherBlockValid: false,
      isSearchFieldBlockValid: true,
    });
  const [currentSelections, setCurrentSelections] = useState<string>('');
  const [shouldShowWarningBanner, setShouldShowWarningBanner] = useState(false);
  const [phraseModal, setPhraseModal] = useState<boolean>(false);

  useEffect(() => {
    if (configureComponent && !currentSpecification.rule) {
      updateCurrentSpecification('amount', 1);
      updateCurrentSpecification('type', OfferGiftTypeDtoEnum.PRODUCT_VARIANT);
    }
  }, [configureComponent, currentSpecification.rule]);

  const manualByCustomerBannerType = useMemo(() => {
    if (
      currentSpecification.rule?.defaultGiftRule !==
      OfferDefaultGiftRuleDtoEnum.MANUAL_BY_CUSTOMER
    ) {
      return null;
    }

    return !isProPlan ? 'Unlock' : !isNinjaCartProEnabled ? 'Enable' : null;
  }, [
    currentSpecification.rule?.defaultGiftRule,
    isNinjaCartProEnabled,
    isProPlan,
  ]);

  const isEmpty = useMemo(() => {
    if (
      !currentSpecification ||
      Object.keys(currentSpecification).length === 0
    ) {
      return true; // Handle null, undefined, or empty objects
    }

    return isEqual(currentSpecification, initialSpecificationValues);
  }, [currentSpecification]);

  const selectList: ShopifyObjectDto[] | string[] = useMemo(() => {
    const { rule } = currentSpecification || {};
    if (!rule || !rule.type) return [];

    const map: Partial<
      Record<OfferGiftTypeDtoEnum, ShopifyObjectDto[] | string[]>
    > = {
      [OfferGiftTypeDtoEnum.PRODUCT_VARIANT]: rule.productVariants ?? [],
      [OfferGiftTypeDtoEnum.COLLECTIONS]: rule.collections ?? [],
      [OfferGiftTypeDtoEnum.TAGS]: rule.tags ?? [],
      [OfferGiftTypeDtoEnum.VENDORS]: rule.vendors ?? [],
    };

    return map[rule.type] ?? rule.products ?? [];
  }, [currentSpecification?.rule]);

  const availableChoices = useMemo(
    () => [
      {
        label: i18n.translate(
          `FreeGift.DefaultGiftSection.${OfferDefaultGiftRuleDtoEnum.AUTOMATIC}`,
          {
            quantity: currentSpecification.rule?.amount || 1,
          }
        ),
        helpText: i18n.translate(
          `FreeGift.DefaultGiftSection.${OfferDefaultGiftRuleDtoEnum.AUTOMATIC}_HelperText`
        ),
        value: OfferDefaultGiftRuleDtoEnum.AUTOMATIC,
      },
      {
        label: i18n.translate(
          `FreeGift.DefaultGiftSection.${OfferDefaultGiftRuleDtoEnum.MANUAL_BY_CUSTOMER}`
        ),
        value: OfferDefaultGiftRuleDtoEnum.MANUAL_BY_CUSTOMER,
        helpText: i18n.translate(
          `FreeGift.DefaultGiftSection.${
            OfferDefaultGiftRuleDtoEnum.MANUAL_BY_CUSTOMER
          }_HelperText${isProPlan ? '_Special' : ''}`
        ),
      },
    ],
    [isProPlan, currentSpecification.rule]
  );

  const resourceType = useMemo(() => {
    switch (currentSpecification.rule?.type) {
      case OfferGiftTypeDtoEnum.COLLECTIONS:
        return ShopifyObjectTypeDtoEnum.COLLECTION;
      case OfferGiftTypeDtoEnum.PRODUCT:
        return ShopifyObjectTypeDtoEnum.PRODUCT;
      case OfferGiftTypeDtoEnum.PRODUCT_VARIANT:
        return ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT;
      default:
        return null;
    }
  }, [currentSpecification.rule?.type]);

  const currentRuleType = useMemo(() => {
    switch (currentSpecification.rule?.type) {
      case OfferGiftTypeDtoEnum.COLLECTIONS:
        return 'collections';
      case OfferGiftTypeDtoEnum.PRODUCT:
        return 'products';
      case OfferGiftTypeDtoEnum.PRODUCT_VARIANT:
        return 'productVariants';
      case OfferGiftTypeDtoEnum.TAGS:
        return 'tags';
      case OfferGiftTypeDtoEnum.VENDORS:
        return 'vendors';
      default:
        return null;
    }
  }, [currentSpecification.rule?.type]);

  const productCurrentList = useMemo(
    () =>
      currentRuleType
        ? ((currentSpecification.rule?.[
            currentRuleType as keyof typeof currentSpecification.rule
          ] || []) as ShopifyObjectDto[])
        : null,
    [currentSpecification.rule, currentRuleType]
  );

  const isValid = useMemo(
    () => Object.values(validityBlocks).every((validity: boolean) => validity),
    [validityBlocks]
  );

  const updateValidityBlock = useCallback(
    (field: keyof FreeGiftSectionValidityBlocksProps, value: boolean) => {
      setValidityBlocks((prevState: FreeGiftSectionValidityBlocksProps) => ({
        ...prevState,
        [field]: value,
      }));
    },
    [setValidityBlocks]
  );

  const updateCurrentSpecification = useCallback(
    (
      field: keyof OfferFreeGiftRuleDto | keyof OfferGiftSpecificationDto,
      data:
        | OfferGiftSpecificationDto[keyof OfferGiftSpecificationDto]
        | number
        | ShopifyObjectDto[]
        | OfferGiftTypeDto
        | null
        | OfferDefaultGiftRuleDtoEnum
        | string[]
    ) => {
      const amount =
        field === 'amount' ? data : currentSpecification.rule?.amount;
      const limitValue = preLimit || 1;

      if (((amount as number) || 1) > limitValue) {
        setShouldShowWarningBanner(true);
      } else {
        setShouldShowWarningBanner(false);
      }

      setCurrentSpecification((prevState: OfferGiftSpecificationDto) => ({
        ...prevState,
        limit: {
          ...prevState.limit,
          value: Math.max((amount as number) || 1, limitValue),
        },
        rule: {
          ...prevState.rule,
          [field]: data,
        },
      }));

      setIsChanged(true);
    },
    [
      currentSpecification.rule?.amount,
      preLimit,
      setCurrentSpecification,
      setIsChanged,
      setShouldShowWarningBanner,
    ]
  );

  const handleSelectChange = useCallback(
    (list: ResourceSelectionProps[] | string[]) => {
      if (!currentSpecification?.rule?.type) return;

      const fieldMap: Record<
        OfferGiftTypeDtoEnum,
        keyof OfferFreeGiftRuleDto | keyof OfferGiftSpecificationDto
      > = {
        [OfferGiftTypeDtoEnum.PRODUCT_VARIANT]: 'productVariants',
        [OfferGiftTypeDtoEnum.COLLECTIONS]: 'collections',
        [OfferGiftTypeDtoEnum.TAGS]: 'tags',
        [OfferGiftTypeDtoEnum.VENDORS]: 'vendors',
        [OfferGiftTypeDtoEnum.PRODUCT]: 'products',
      };

      const field = fieldMap[currentSpecification.rule.type];

      if (list.length) {
        const data = formateList(
          list,
          currentSpecification.rule.type ===
            OfferGiftTypeDtoEnum.PRODUCT_VARIANT
            ? 'VARIANTS'
            : (currentSpecification.rule.type as OfferTargetTypeDto)
        );

        updateCurrentSpecification(field, data);
      }
    },
    [currentSpecification, updateCurrentSpecification, formateList]
  );

  const onSearchFieldValidityChange = useCallback((formIsInvalid: boolean) => {
    updateValidityBlock('isSearchFieldBlockValid', !formIsInvalid);
  }, []);

  const togglePhraseModal = useCallback(
    () => setPhraseModal((prev) => !prev),
    []
  );

  useEffect(() => {
    if (isChanged) {
      onSpecificationUpdate(currentSpecification);
      setIsChanged(false);
    }
  }, [isChanged]);

  useEffect(() => {
    onFormValidityChange(isValid);
  }, [isValid]);

  useEffect(() => {
    if (configureComponent) {
      window.scrollTo(0, 0);
      dispatch(setCurrentConfigPage('specification'));
    }
    return () => {
      dispatch(setCurrentConfigPage(null));
    };
  }, [configureComponent]);

  useEffect(() => {
    if (!configureComponent && resourceType && productCurrentList?.length) {
      getLookupShopifyDetailedObjectsDetails({
        type: resourceType,
        objects: productCurrentList,
      }).then((res) => {
        setCurrentSelections(
          headerTitle(res as ResourceSelectionProps[], resourceType, i18n)
        );
      });
    }
  }, [resourceType, productCurrentList]);

  // for tags and vendors
  useEffect(() => {
    if (
      currentSpecification.rule?.type &&
      productCurrentList &&
      [OfferGiftTypeDtoEnum.TAGS, OfferGiftTypeDtoEnum.VENDORS].includes(
        currentSpecification.rule?.type as OfferGiftTypeDtoEnum
      )
    ) {
      setCurrentSelections(
        headerTitle(
          productCurrentList as string[],
          currentSpecification.rule?.type,
          i18n
        )
      );
    }
  }, [currentSpecification.rule?.type, productCurrentList]);

  return (
    <>
      {!configureComponent ? (
        <Card roundedAbove='sm' padding='400'>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('FreeGift.Title')}
              </Text>
              <Text as='span' tone='subdued'>
                {i18n.translate('FreeGift.Subtitle')}
              </Text>
            </BlockStack>
            <Card roundedAbove='sm' padding='400'>
              <InlineStack
                align='space-between'
                wrap={false}
                blockAlign='center'
              >
                <InlineStack wrap={false} gap='200' blockAlign='center'>
                  {!isEmpty ? (
                    <>
                      <div style={{ width: 20, height: 20 }}>
                        <Icon source={GiftCardIcon} tone='base' />
                      </div>
                      {boldMessageParts(
                        generateMessage(
                          SectionTypeEnum.GIFT_SECTION,
                          currentSpecification,
                          0,
                          currentSelections,
                          i18n
                        )
                      )}
                    </>
                  ) : (
                    <InlineStack gap='200'>
                      <Icon source={AlertTriangleIcon} tone='textCaution' />
                      <Text as='p' tone='caution'>
                        {i18n.translate('FreeGift.NoGiftConfigured')}
                      </Text>
                    </InlineStack>
                  )}
                </InlineStack>
                <BlockStack align='center'>
                  <Button
                    variant='plain'
                    onClick={async () => {
                      !isDebugOrLocal &&
                        (await shopify.saveBar.leaveConfirmation());
                      setConfigureComponent(true);
                    }}
                  >
                    {currentSpecification.rule?.type
                      ? i18n.translate('FreeGift.Configure')
                      : i18n.translate('FreeGift.Select')}
                  </Button>
                </BlockStack>
              </InlineStack>
              {!isEmpty && currentSpecification.rule?.defaultGiftRule && (
                <div className='bullet'>
                  <List>
                    <List.Item>
                      {i18n.translate(
                        `FreeGift.DefaultGiftSection.${currentSpecification.rule?.defaultGiftRule}`,
                        {
                          quantity: currentSpecification.rule?.amount || 1,
                        }
                      )}
                    </List.Item>
                  </List>
                </div>
              )}
            </Card>
          </BlockStack>
        </Card>
      ) : (
        <Page
          backAction={{
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              setConfigureComponent(false);
            },
          }}
          title={i18n.translate('FreeGift.Modal.Title')}
          subtitle={i18n.translate('FreeGift.Modal.Subtitle')}
        >
          <BlockStack gap='600'>
            <Card>
              <BlockStack gap='400'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate('FreeGift.Modal.FreeGift')}
                </Text>
                <FreeGiftFirstLineContent
                  updateCurrentSpecification={updateCurrentSpecification}
                  updateValidityBlock={updateValidityBlock}
                  type={
                    currentSpecification?.rule?.type ||
                    OfferGiftTypeDtoEnum.PRODUCT_VARIANT
                  }
                  amount={currentSpecification?.rule?.amount}
                />
                {shouldShowWarningBanner && (
                  <Banner tone='warning'>
                    {i18n.translate(
                      'FreeGift.DefaultGiftSection.BannerWarning',
                      {
                        limit: preLimit || 1,
                        amount: currentSpecification.rule?.amount || 1,
                      }
                    )}
                  </Banner>
                )}
                {[
                  OfferGiftTypeDtoEnum.TAGS,
                  OfferGiftTypeDtoEnum.VENDORS,
                ].includes(
                  currentSpecification?.rule?.type as OfferGiftTypeDtoEnum
                ) ? (
                  <ExclusionsPhrasePicker
                    type={
                      currentSpecification?.rule?.type?.toLowerCase() as keyof PickerExclusionsType
                    }
                    label={i18n.translate(
                      currentSpecification?.rule?.type?.toLowerCase() || 'tags'
                    )}
                    phrases={{ enabled: true, value: selectList as string[] }}
                    isModalOpen={phraseModal}
                    toggleModal={togglePhraseModal}
                    setPhrases={(data) => handleSelectChange(data.value || [])}
                    onSearchFieldValidityChange={onSearchFieldValidityChange}
                  />
                ) : (
                  <SearchFieldWithGrayBox
                    selectList={(selectList as ResourceSelectionProps[]) || []}
                    resourceType={
                      currentSpecification?.rule?.type ===
                      OfferGiftTypeDtoEnum.COLLECTIONS
                        ? GrayBoxResourceTypeEnum.Collection
                        : GrayBoxResourceTypeEnum.Product
                    }
                    productVariantId={
                      currentSpecification.rule?.productVariants?.length
                        ? (currentSpecification.rule?.productVariants[0]
                            .productId as string)
                        : ''
                    }
                    showVariants={
                      currentSpecification?.rule?.type ===
                      OfferGiftTypeDtoEnum.PRODUCT_VARIANT
                    }
                    onSelectedChange={handleSelectChange}
                    onFormValidityChange={onSearchFieldValidityChange}
                    // preventFromOpening={
                    //   currentSpecification?.rule?.type ===
                    //     OfferGiftTypeDtoEnum.PRODUCT_VARIANT && preventFromOpening
                    // }
                    isFreeGiftSearchField
                  />
                )}

                <Bleed marginInline='400' marginBlockEnd='400'>
                  <Box padding='400' background='bg-surface-secondary'>
                    <Text as='p' tone='subdued'>
                      {i18n.translate('FreeGift.Modal.Footer')}
                    </Text>
                  </Box>
                </Bleed>
              </BlockStack>
            </Card>
            {currentSpecification.rule?.type ===
              OfferGiftTypeDtoEnum.PRODUCT_VARIANT &&
            currentSpecification.rule.productVariants?.length === 1 ? null : (
              <Card>
                <BlockStack gap='400'>
                  <BlockStack gap='100'>
                    <Text as='h2' variant='headingSm'>
                      {i18n.translate('FreeGift.DefaultGiftSection.Title')}
                    </Text>
                    <Text as='p' tone='subdued'>
                      {i18n.translate(
                        'FreeGift.DefaultGiftSection.Description'
                      )}
                    </Text>
                  </BlockStack>
                  <ChoiceList
                    title=''
                    titleHidden
                    choices={availableChoices}
                    selected={
                      currentSpecification.rule?.defaultGiftRule
                        ? [currentSpecification.rule?.defaultGiftRule]
                        : []
                    }
                    onChange={(selected) =>
                      updateCurrentSpecification(
                        'defaultGiftRule',
                        selected[0] as OfferDefaultGiftRuleDtoEnum
                      )
                    }
                  />
                  {currentSpecification.rule?.defaultGiftRule ===
                  OfferDefaultGiftRuleDtoEnum.AUTOMATIC ? (
                    <Banner tone='info'>
                      {i18n.translate(
                        'FreeGift.DefaultGiftSection.BannerInfo',
                        {
                          here: (
                            <Link url='http://www.google.com' target='_blank'>
                              {i18n.translate(
                                'FreeGift.DefaultGiftSection.here'
                              )}
                            </Link>
                          ),
                        }
                      )}
                    </Banner>
                  ) : manualByCustomerBannerType ? (
                    <Banner
                      title={i18n.translate(
                        `FreeGift.DefaultGiftSection.${manualByCustomerBannerType}BannerTitle`
                      )}
                      action={{
                        content: i18n.translate(
                          `FreeGift.DefaultGiftSection.${manualByCustomerBannerType}BannerBtn`
                        ),
                        onAction: () =>
                          window.open('http://www.google.com', '_blank'),
                      }}
                    >
                      {i18n.translate(
                        `FreeGift.DefaultGiftSection.${manualByCustomerBannerType}BannerContent`
                      )}
                    </Banner>
                  ) : null}
                </BlockStack>
              </Card>
            )}
          </BlockStack>
        </Page>
      )}
    </>
  );
};
