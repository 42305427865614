import React from 'react';
import { I18n } from '@shopify/react-i18n';
import { Text } from '@shopify/polaris';
import { RecurringScheduleTypeEnum } from '../RecurringSchedule';
import {
  PromotionScheduleDto,
  RecurringScheduleDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { WeekDayDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

export const getInitialDate = (startsAt?: number) => {
  const currentDate = startsAt ? new Date(startsAt || 0) : new Date();
  if (startsAt) {
    return currentDate.setHours(currentDate.getHours() + 24);
  }
  return currentDate.setHours(currentDate.getHours() + 24);
};

export function getRecurringIntervalSummary(
  i18n: I18n,
  selectedRepeat: keyof RecurringScheduleDto | undefined,
  scheduleSetup: PromotionScheduleDto,
  bold = true
) {
  const weekDays =
    scheduleSetup.recurringSchedule?.weeklySchedule?.weekDays || [];
  const monthDays =
    scheduleSetup.recurringSchedule?.monthlySchedule?.days || [];

  if (selectedRepeat === RecurringScheduleTypeEnum.DAILY_SCHEDULE) {
    return (
      <Text as='p' fontWeight={bold ? 'semibold' : 'regular'}>
        {i18n.translate('Everyday')}
      </Text>
    );
  }

  if (selectedRepeat === RecurringScheduleTypeEnum.WEEKLY_SCHEDULE) {
    if (!weekDays.length) return '';

    return weekDays.includes(WeekDayDtoEnum.EVERY) ? (
      <Text as='span' fontWeight={bold ? 'semibold' : 'regular'}>
        {i18n.translate(`${WeekDayDtoEnum.EVERY}_DAY_OPTION`)},
      </Text>
    ) : (
      i18n.translate('EverySelectedDay', {
        days: (
          <Text as='span' fontWeight={bold ? 'semibold' : 'regular'}>
            {weekDays
              .map((day) => i18n.translate(`${day}_DAY_OPTION`))
              .join(', ')}
            ,
          </Text>
        ),
      })
    );
  }

  if (selectedRepeat === RecurringScheduleTypeEnum.MONTHLY_SCHEDULE) {
    if (!monthDays || !monthDays?.length) return '';
    return (
      <Text as='p'>
        {i18n.translate('On')}{' '}
        <Text as='span' fontWeight={bold ? 'semibold' : 'regular'}>
          {i18n.translate('EveryMonth', {
            days: [...monthDays]
              .sort((a, b) => a - b)
              .map((value) => (value === 1 ? `${value}st` : `${value}th`))
              .join(', '),
          })}
        </Text>
        ,
      </Text>
    );
  }
  return null;
}
