import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import {
  GetPresetListResponseDto,
  ImageResourceDto,
  PresetPreviewDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  DeviceTypeDtoEnum,
  NinjaCartStatesPresetEntryTypeDtoEnum,
  NotificationStatesPromotionEntryTypeDtoEnum,
  PageTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { PriceWidgetTypesDtoEnum } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/EditPresetRightBar/EditPresetRightBar';
import { PromotionPriceWidgetTypesDtoEnum } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/EditPromotionRightBar/EditPromotionRightBar';
import { PreviewEntry } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import {
  WidgetListOptionsDtoEnum,
  WidgetListTypeDtoEnum,
} from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { Params } from 'react-router-dom';

type OfferEntryPreview = {
  desktop: string;
  mobile: string;
  offerId: string;
};

export type BodyRowEntriesStateDto = {
  price?: PreviewEntry;
  badge?: PreviewEntry;
};
export type PricingEntriesStateDto = {
  bodyRow1?: BodyRowEntriesStateDto;
  bodyRow2?: BodyRowEntriesStateDto;
  bodyRow3?: BodyRowEntriesStateDto;
  footerText: PreviewEntry;
  headerText: PreviewEntry;
};

export type ToggleSettingsModalConfigDto = {
  widgetType?: WidgetTypeDtoEnum;
  subType?: WidgetTypeDtoEnum;
  element?: string;
  action: 'open' | 'save';
};

type PricingEntriesStatesDto = {
  discountedPrice: PricingEntriesStateDto;
  notDiscountedPrice: PricingEntriesStateDto;
};
type NotificationHeaderStates = {
  collapsed?: PreviewEntry;
  expanded?: PreviewEntry;
};

export type WidgetsSliceType = {
  activeDeviceType: DeviceTypeDtoEnum;
  selectedPage: PageTypeDtoEnum;
  isSmartTagConfigOpen: boolean;
  showPresetChangesModalAgain: boolean;
  isPromotionEditorDataLoading: boolean;
  defaultShopLevelStylePreset: PresetPreviewDto | null;
  defaultPromotionLevelStylePreset: PresetPreviewDto | null;
  shopLevelStylePresets: GetPresetListResponseDto | null;
  promotionLevelStylePresets: GetPresetListResponseDto | null;
  promotionTransactionId?: string;
  isChangePresetLoading: boolean;
  defaultImageLibary: ImageResourceDto[];
  customImageLibary: ImageResourceDto[];
  defaultIconLibary: ImageResourceDto[];
  customIconLibary: ImageResourceDto[];
  toggleSettingsConfig: ToggleSettingsModalConfigDto | null;
  currentParams: Params<string> | null;
  productBannerMessages: {
    message: OfferEntryPreview[];
    entitled: OfferEntryPreview[];
    prerequisite: OfferEntryPreview[];
  };

  promotionalBadgeMessages: OfferEntryPreview[];

  announcementBarPreLaunchMessages: OfferEntryPreview[];
  announcementBarTextMessages: OfferEntryPreview[];

  notificationFooterMessages?: PreviewEntry;
  notificationHeaderMessages?: NotificationHeaderStates;
  notificationOfferMessages: OfferEntryPreview[];
  drawerCartTextMessages: OfferEntryPreview[];
  cartTextMessages: OfferEntryPreview[];

  promotionSummaryPageEntries: {
    cartPromotionSummary?: {
      disclaimerText: PreviewEntry;
    };
    drawerCartPromotionSummary?: {
      disclaimerText: PreviewEntry;
    };
    promotionSummary?: {
      disclaimerText: PreviewEntry;
    };
  };

  pricePageEntries: {
    productPagePrice?: PricingEntriesStatesDto;
    collectionPagePrice?: PricingEntriesStatesDto;
    cartBreakdownPrice?: PricingEntriesStatesDto;
    cartLinePrice?: PricingEntriesStatesDto;
    cartProductPrice?: PricingEntriesStatesDto;
    cartTotalPrice?: PricingEntriesStatesDto;
    drawerCartBreakdownPrice?: PricingEntriesStatesDto;
    drawerCartLinePrice?: PricingEntriesStatesDto;
    drawerCartProductPrice?: PricingEntriesStatesDto;
    drawerCartTotalPrice?: PricingEntriesStatesDto;
  };
};

const initialState: WidgetsSliceType = {
  activeDeviceType: DeviceTypeDtoEnum.DESKTOP,
  selectedPage: PageTypeDtoEnum.HOME,
  isSmartTagConfigOpen: false,
  toggleSettingsConfig: null,
  showPresetChangesModalAgain: true,
  isPromotionEditorDataLoading: false,
  shopLevelStylePresets: null,
  promotionLevelStylePresets: null,
  defaultShopLevelStylePreset: null,
  defaultPromotionLevelStylePreset: null,
  promotionTransactionId: undefined,
  isChangePresetLoading: false,
  defaultImageLibary: [],
  customImageLibary: [],
  defaultIconLibary: [],
  customIconLibary: [],
  productBannerMessages: {
    message: [],
    entitled: [],
    prerequisite: [],
  },
  promotionalBadgeMessages: [],
  announcementBarPreLaunchMessages: [],
  announcementBarTextMessages: [],
  notificationFooterMessages: undefined,
  notificationHeaderMessages: {},
  drawerCartTextMessages: [],
  cartTextMessages: [],
  notificationOfferMessages: [],
  promotionSummaryPageEntries: {},
  pricePageEntries: {},
  currentParams: null,
};

const updateMessages = (
  payload: OfferEntryPreview,
  messages: OfferEntryPreview[]
) => {
  const existingIndex = messages.findIndex(
    (item) => item.offerId === payload.offerId
  );
  if (existingIndex !== -1) {
    messages[existingIndex] = payload;
  } else {
    messages.push(payload);
  }
};

const widgetsSlice = createSlice({
  name: 'promotions',
  initialState: initialState,
  reducers: {
    setActiveDeviceType: (
      state,
      action: PayloadAction<WidgetsSliceType['activeDeviceType']>
    ) => {
      state.activeDeviceType = action.payload;
    },
    setSelectedPage: (
      state,
      action: PayloadAction<WidgetsSliceType['selectedPage']>
    ) => {
      state.selectedPage = action.payload;
    },
    setCurrentParams: (
      state,
      action: PayloadAction<WidgetsSliceType['currentParams']>
    ) => {
      state.currentParams = action.payload;
    },
    setToggleSettingsConfig: (
      state,
      action: PayloadAction<WidgetsSliceType['toggleSettingsConfig']>
    ) => {
      state.toggleSettingsConfig = action.payload;
    },
    setShowPresetChangesModalAgain: (
      state,
      action: PayloadAction<WidgetsSliceType['showPresetChangesModalAgain']>
    ) => {
      state.showPresetChangesModalAgain = action.payload;
    },
    setIsPromotionEditorDataLoading: (
      state,
      action: PayloadAction<WidgetsSliceType['isPromotionEditorDataLoading']>
    ) => {
      state.isPromotionEditorDataLoading = action.payload;
    },
    setDefaultShopLevelStylePreset: (
      state,
      action: PayloadAction<WidgetsSliceType['defaultShopLevelStylePreset']>
    ) => {
      state.defaultShopLevelStylePreset = action.payload;
    },
    setDefaultPromotionLevelStylePreset: (
      state,
      action: PayloadAction<
        WidgetsSliceType['defaultPromotionLevelStylePreset']
      >
    ) => {
      state.defaultPromotionLevelStylePreset = action.payload;
    },
    setPromotionLevelStylePresets: (
      state,
      action: PayloadAction<WidgetsSliceType['promotionLevelStylePresets']>
    ) => {
      state.promotionLevelStylePresets = action.payload;
    },
    setShopLevelStylePresets: (
      state,
      action: PayloadAction<WidgetsSliceType['shopLevelStylePresets']>
    ) => {
      state.shopLevelStylePresets = action.payload;
    },
    setPromotionTransactionId: (
      state,
      action: PayloadAction<WidgetsSliceType['promotionTransactionId']>
    ) => {
      state.promotionTransactionId = action.payload;
    },
    setIsChangePresetLoading: (
      state,
      action: PayloadAction<WidgetsSliceType['isChangePresetLoading']>
    ) => {
      state.isChangePresetLoading = action.payload;
    },
    setDefaultImageLibary: (
      state,
      action: PayloadAction<WidgetsSliceType['defaultImageLibary']>
    ) => {
      state.defaultImageLibary = action.payload;
    },
    setCustomImageLibary: (
      state,
      action: PayloadAction<WidgetsSliceType['customImageLibary']>
    ) => {
      state.customImageLibary = action.payload;
    },
    setDefaultIconLibary: (
      state,
      action: PayloadAction<WidgetsSliceType['defaultIconLibary']>
    ) => {
      state.defaultIconLibary = action.payload;
    },
    setCustomIconLibary: (
      state,
      action: PayloadAction<WidgetsSliceType['customIconLibary']>
    ) => {
      state.customIconLibary = action.payload;
    },
    setProductBannerMessages: (
      state,
      action: PayloadAction<{
        data: OfferEntryPreview;
        field: 'entitled' | 'prerequisite' | 'message';
      }>
    ) => {
      const existingIndex = state.productBannerMessages[
        action.payload.field
      ].findIndex((item) => item.offerId === action.payload.data.offerId);
      if (existingIndex !== -1) {
        state.productBannerMessages[action.payload.field][existingIndex] =
          action.payload.data;
      } else {
        state.productBannerMessages[action.payload.field].push(
          action.payload.data
        );
      }
    },
    setPricePageEntries: (
      state,
      action: PayloadAction<{
        priceType: PriceWidgetTypesDtoEnum | PromotionPriceWidgetTypesDtoEnum;
        stateType:
          | WidgetListOptionsDtoEnum.DISCOUNTED_PRICE
          | WidgetListOptionsDtoEnum.NOT_DISCOUNTED_PRICE;
        field: keyof PricingEntriesStateDto;
        data: PricingEntriesStateDto[keyof PricingEntriesStateDto];
      }>
    ) => {
      const { data, field, priceType, stateType } = action.payload;
      state.pricePageEntries = {
        ...state.pricePageEntries,
        [priceType]: {
          ...state.pricePageEntries?.[priceType],
          [stateType]: {
            ...state.pricePageEntries?.[priceType]?.[stateType],
            [field]: data,
          },
        },
      };
    },
    setPromotionSummaryPageEntries: (
      state,
      action: PayloadAction<{
        pageType:
          | WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_SUMMARY
          | WidgetListTypeDtoEnum.NINJA_CART_PROMOTION_SUMMARY
          | WidgetListTypeDtoEnum.CART_PROMOTION_SUMMARY;

        data: PreviewEntry;
      }>
    ) => {
      const { data, pageType } = action.payload;
      state.promotionSummaryPageEntries = {
        ...state.promotionSummaryPageEntries,
        [pageType]: {
          disclaimerText: data,
        },
      };
    },
    setCartTextMessages: (
      state,
      action: PayloadAction<{
        pageType:
          | WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT
          | WidgetListTypeDtoEnum.CART_PAGE_CART_TEXT;
        data: OfferEntryPreview;
      }>
    ) => {
      updateMessages(
        action.payload.data,
        action.payload.pageType ===
          WidgetListTypeDtoEnum.DRAWER_CART_PAGE_CART_TEXT
          ? state.drawerCartTextMessages
          : state.cartTextMessages
      );
    },

    setPromotionalBadgeMessages: (
      state,
      action: PayloadAction<OfferEntryPreview>
    ) => {
      updateMessages(action.payload, state.promotionalBadgeMessages);
    },
    setAnnouncementBarPreLaunchMessages: (
      state,
      action: PayloadAction<OfferEntryPreview>
    ) => {
      updateMessages(action.payload, state.announcementBarPreLaunchMessages);
    },
    setAnnouncementBarTextMessages: (
      state,
      action: PayloadAction<OfferEntryPreview>
    ) => {
      updateMessages(action.payload, state.announcementBarTextMessages);
    },
    setNotificationFooterMessages: (
      state,
      action: PayloadAction<WidgetsSliceType['notificationFooterMessages']>
    ) => {
      state.notificationFooterMessages = action.payload;
    },
    setNotificationHeaderMessages: (
      state,
      action: PayloadAction<{
        pageType: NotificationStatesPromotionEntryTypeDtoEnum;
        data: PreviewEntry;
      }>
    ) => {
      const { data, pageType } = action.payload;
      const stateProperty =
        pageType === NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE
          ? 'collapsed'
          : 'expanded';
      state.notificationHeaderMessages = {
        ...state.notificationHeaderMessages,
        [stateProperty]: data,
      };
    },
    setNotificationOfferMessages: (
      state,
      action: PayloadAction<OfferEntryPreview>
    ) => {
      updateMessages(action.payload, state.notificationOfferMessages);
    },
    setIsSmartTagConfigOpen: (
      state,
      action: PayloadAction<WidgetsSliceType['isSmartTagConfigOpen']>
    ) => {
      state.isSmartTagConfigOpen = action.payload;
    },
  },
});

export const {
  setShopLevelStylePresets,
  setToggleSettingsConfig,
  setShowPresetChangesModalAgain,
  setPromotionLevelStylePresets,
  setIsPromotionEditorDataLoading,
  setDefaultShopLevelStylePreset,
  setDefaultPromotionLevelStylePreset,
  setPromotionTransactionId,
  setIsChangePresetLoading,
  setDefaultImageLibary,
  setCustomImageLibary,
  setDefaultIconLibary,
  setCustomIconLibary,
  setProductBannerMessages,
  setPromotionalBadgeMessages,
  setActiveDeviceType,
  setAnnouncementBarPreLaunchMessages,
  setAnnouncementBarTextMessages,
  setNotificationFooterMessages,
  setNotificationHeaderMessages,
  setNotificationOfferMessages,
  setPricePageEntries,
  setPromotionSummaryPageEntries,
  setIsSmartTagConfigOpen,
  setCartTextMessages,
  setCurrentParams,
  setSelectedPage,
} = widgetsSlice.actions;

export default widgetsSlice.reducer;
