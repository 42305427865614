import React, { useCallback, useMemo, useState } from 'react';
import './WidgetCard.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  NinjaCartWidgetInstallationDto,
  PlanLevelDto,
  WidgetInstallationDto,
  WidgetTypeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  NinjaCartWidgetGroupDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  ActionListItemDescriptor,
  Badge,
  Box,
  Button,
  ComplexAction,
  Icon,
  MediaCard,
  SkeletonBodyText,
} from '@shopify/polaris';
import { ImagesIcon, EmailIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
import { transformToKebabOrSnakeCase } from 'core/utils/transformToKebabOrSnakeCase';
import {
  ChangePlanModal,
  ChangePlanModalTypeEnum,
} from 'features/settings/components/GeneralSettings/Plan/components/ChangePlanModal/ChangePlanModal';

type WidgetCardProps = {
  isEnabled: boolean;
  isSupportRequested: boolean;
  widget: WidgetInstallationDto | NinjaCartWidgetInstallationDto;
  toggleWidget: (
    widget: (WidgetInstallationDto | NinjaCartWidgetInstallationDto) & {
      isAppBlockRequestSupport?: boolean;
    }
  ) => void;
  setConfigureStyleModal: React.Dispatch<
    React.SetStateAction<WidgetTypeDto | 'GENERAL'>
  >;
};

export const WidgetCard: React.FC<WidgetCardProps> = ({
  isEnabled,
  isSupportRequested,
  widget,
  toggleWidget,
  setConfigureStyleModal,
}) => {
  const { status, type, planSupportingType, installationMode } = widget;
  const [i18n] = useI18n();
  const navigate = useNavigate();

  const [changePlanOpen, setChangePlanOpen] = useState<boolean>(false);

  const isEmpty = useMemo(
    () => widget.type?.toLowerCase().includes('dummy'),
    [widget.type]
  );

  const isDisabled = useMemo(
    () =>
      [
        WidgetStatusDtoEnum.DISABLED,
        WidgetStatusDtoEnum.DISABLED_BY_USER,
      ].includes(status as WidgetStatusDtoEnum),
    [status]
  );

  const isEnabledStatus = useMemo(
    () =>
      [
        WidgetStatusDtoEnum.ENABLED,
        WidgetStatusDtoEnum.ENABLED_BUILT_IN,
        WidgetStatusDtoEnum.ENABLED_VIA_CODE,
        WidgetStatusDtoEnum.ENABLED_VIA_CSS,
      ].includes(status as WidgetStatusDtoEnum),
    [status]
  );

  const handlePrimaryAction = useCallback(() => {
    if (isDisabled) {
      toggleWidget(widget);
    }
    if (status === WidgetStatusDtoEnum.LOCKED) {
      setChangePlanOpen(true);
    }
  }, [isDisabled, widget, status, toggleWidget]);

  const widgetSpecificPageRedirection = useCallback(() => {
    if (type) {
      navigate(transformToKebabOrSnakeCase(type, 'kebab-case'));
    }
  }, [type, navigate]);

  const handleSecondaryAction = useCallback(() => {
    if (!isDisabled && status !== WidgetStatusDtoEnum.LOCKED) {
      widgetSpecificPageRedirection();
    }
  }, [isDisabled, status, widgetSpecificPageRedirection]);

  const statusBar = useMemo(() => {
    const statusClass =
      isDisabled && isSupportRequested
        ? 'info'
        : isDisabled || status === WidgetStatusDtoEnum.LOCKED
        ? 'default'
        : 'success';

    return (
      <div className={`statusBar ${statusClass}`}>
        {`${i18n.translate(status as string)} ${
          isSupportRequested ? `• ${i18n.translate('SupportRequested')}` : ''
        }`}
      </div>
    );
  }, [i18n, isDisabled, status, isSupportRequested, type]);

  const primaryContent: ComplexAction | undefined = useMemo(() => {
    if (
      (isDisabled || status === WidgetStatusDtoEnum.LOCKED) &&
      installationMode !== NinjaCartWidgetGroupDtoEnum.CORE
    ) {
      return {
        content: isDisabled
          ? i18n.translate('Enable')
          : i18n.translate('GetTheFeature'),
        disabled: !isEnabled,
        onAction: handlePrimaryAction,
        id: 'widgetCardPrimaryBtn',
      };
    }
    return undefined;
  }, [
    i18n,
    isDisabled,
    status,
    isEnabled,
    installationMode,
    handlePrimaryAction,
  ]);

  const secondaryContent: ComplexAction = useMemo(
    () => ({
      content:
        isDisabled || status === WidgetStatusDtoEnum.LOCKED
          ? i18n.translate('LearnMore')
          : i18n.translate('ConfigureSettings'),
      onAction: handleSecondaryAction,
    }),
    [i18n, isDisabled, status, handleSecondaryAction]
  );

  const popoverContent: ActionListItemDescriptor[] | undefined = useMemo(() => {
    if (isDisabled) {
      const actions: ActionListItemDescriptor[] = [
        {
          content: i18n.translate('ConfigureSettings'),
          onAction: widgetSpecificPageRedirection,
        },
        {
          content: i18n.translate('ConfigureStyle'),
          onAction: () => widget.type && setConfigureStyleModal(widget.type),
        },
      ];

      if (installationMode === WidgetInstallationModeDtoEnum.APP_BLOCKS) {
        actions.unshift({
          content: i18n.translate('RequestSetupSupport'),
          onAction: () =>
            toggleWidget({ ...widget, isAppBlockRequestSupport: true }),
          prefix: <Icon source={EmailIcon} tone='subdued' />,
        });
      }
      return actions;
    }

    if (isEnabledStatus) {
      const actions: ActionListItemDescriptor[] = [
        {
          content: i18n.translate('ConfigureStyle'),
          onAction: () => widget.type && setConfigureStyleModal(widget.type),
        },
        {
          content: i18n.translate('LearnMore'),
          onAction: () => console.log('Learn More'),
        },
      ];

      if (installationMode === WidgetInstallationModeDtoEnum.APP_BLOCKS) {
        actions.splice(1, 0, {
          content: i18n.translate('ManageStatus'),
          onAction: () => toggleWidget(widget),
        });
      } else if (installationMode !== NinjaCartWidgetGroupDtoEnum.CORE) {
        actions.push({
          content: i18n.translate('Disable'),
          onAction: () => toggleWidget(widget),
          destructive: true,
        });
      }

      return actions;
    }

    return undefined;
  }, [
    i18n,
    isDisabled,
    isEnabledStatus,
    widget,
    installationMode,
    widgetSpecificPageRedirection,
    setConfigureStyleModal,
    toggleWidget,
  ]);

  return (
    <>
      <div className={`WidgetCard ${isEmpty ? 'isEmpty' : ''}`}>
        <MediaCard
          portrait
          title={
            isEmpty ? (
              <div className='customBlockStack'>
                <Box width='140px'>
                  <SkeletonBodyText lines={1} />
                </Box>
                <SkeletonBodyText lines={2} />
                <Box width='90px'>
                  <Button loading fullWidth />
                </Box>
              </div>
            ) : (
              i18n.translate(type as string)
            )
          }
          description={!isEmpty ? i18n.translate('WidgetDescription') : ''}
          primaryAction={primaryContent}
          secondaryAction={!isEmpty ? secondaryContent : undefined}
          popoverActions={popoverContent}
        >
          <div className='placeholder'>
            <Icon source={ImagesIcon} tone='subdued' />
            {!isEmpty && statusBar}
            {planSupportingType?.level && (
              <div className='badge'>
                <Badge tone='info'>
                  {i18n.translate(planSupportingType?.level)}
                </Badge>
              </div>
            )}
          </div>
        </MediaCard>
      </div>
      {changePlanOpen && (
        <ChangePlanModal
          modalType={ChangePlanModalTypeEnum.GET_FEATURE}
          targetPlanLevel={planSupportingType?.level as PlanLevelDto}
          isOpen={changePlanOpen}
          onClose={() => setChangePlanOpen(false)}
          isMaxModal={false}
        />
      )}
    </>
  );
};
