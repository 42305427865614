import React, { useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { BlockStack, Checkbox, TextField } from '@shopify/polaris';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import { NinjaCartDefaultStateFooterContinueShoppingActionDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { NinjaCartContinueShoppingActionButtonTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';

type ContinueShoppingActionProps = {
  action: NinjaCartDefaultStateFooterContinueShoppingActionDto;
  setAction: (
    data: NinjaCartDefaultStateFooterContinueShoppingActionDto
  ) => void;
  onSystemReset?: () => void;
};

export const ContinueShoppingAction: React.FC<ContinueShoppingActionProps> = ({
  action,
  setAction,
  onSystemReset,
}) => {
  const [i18n] = useI18n();

  const typeOptions = useMemo(
    () =>
      Object.values(NinjaCartContinueShoppingActionButtonTypeDtoEnum).map(
        (value) => {
          return {
            value: value,
            label: i18n.translate(`${value}`),
          };
        }
      ),
    [i18n]
  );

  return (
    <StyleComponentHeader
      buttons={[
        {
          external: true,
          content: i18n.translate('ResetToSystemDefault'),
          onAction: () => onSystemReset?.(),
        },
      ]}
      label={i18n.translate('Action')}
    >
      <SelectOptions
        options={typeOptions}
        onOptionSelect={(value) =>
          setAction({
            ...action,
            actionType:
              value as NinjaCartContinueShoppingActionButtonTypeDtoEnum,
          })
        }
        selectedOption={
          action.actionType as NinjaCartContinueShoppingActionButtonTypeDtoEnum
        }
        label=''
      />
      {action.actionType ===
        NinjaCartContinueShoppingActionButtonTypeDtoEnum.OPEN_SELECTED_PAGE && (
        <BlockStack gap='300'>
          <TextField
            autoComplete=''
            label={i18n.translate('Page')}
            helpText={i18n.translate('InputHelpText')}
            value={action.pageLink || ''}
            placeholder={i18n.translate('InputPlaceholder')}
            onChange={(value) =>
              setAction({
                ...action,
                pageLink: value,
              })
            }
          />
          <Checkbox
            checked={!!action.disablePageBeforeTermsAndConditions}
            label={i18n.translate('DisableBeforeTermsConditions')}
            onChange={(value) =>
              setAction({
                ...action,
                disablePageBeforeTermsAndConditions: value,
              })
            }
          />
        </BlockStack>
      )}
    </StyleComponentHeader>
  );
};
