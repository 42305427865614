import React, { useCallback, useMemo } from 'react';
import {
  BlockStack,
  Card,
  Checkbox,
  Text,
  RadioButton,
  InlineStack,
  Badge,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { Element } from 'react-scroll';

export type SettingDto<ValueType extends string | number | symbol> = {
  [key: string]: ValueType | null;
};

export type BlockWithGeneralCheckboxSettingProps<
  ValueType extends string | number | symbol
> = {
  settings: SettingDto<ValueType>;
  setSettingState: (value: SettingDto<ValueType>) => void;
  valuesEnum: { [key in ValueType]: string };
  descriptionEnum?: { [key in ValueType]: string };
  checkboxLabel: string;
  title: string;
  mainDescription?: string;
  badge?: string;
  fields: string[];
  defaultValue?: ValueType;
};

export const BlockWithGeneralCheckboxSetting = <
  ValueType extends string | number | symbol
>({
  settings,
  valuesEnum,
  descriptionEnum,
  checkboxLabel,
  title,
  mainDescription,
  badge,
  fields,
  defaultValue,
  setSettingState,
}: BlockWithGeneralCheckboxSettingProps<ValueType>) => {
  const [i18n] = useI18n();

  const isGeneral = useMemo(
    () => settings[fields[0]] !== null,
    [settings, fields]
  );

  const radios = useMemo(
    () =>
      Object.values(valuesEnum).map((mode, idx) => ({
        value: mode,
        label: (
          <InlineStack gap='200'>
            {i18n.translate(mode as string)}
            {badge && !idx && <Badge>{i18n.translate(badge)}</Badge>}
          </InlineStack>
        ),
        helpText: descriptionEnum
          ? i18n.translate(
              descriptionEnum[mode as keyof typeof descriptionEnum]
            )
          : undefined,
      })),
    [i18n, valuesEnum, descriptionEnum, badge]
  );

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      setSettingState({
        ...settings,
        [fields[0] as keyof SettingDto<ValueType>]: checked
          ? ((defaultValue || Object.values(valuesEnum)[0]) as ValueType)
          : null,
      });
    },
    [settings, setSettingState, valuesEnum, fields, defaultValue]
  );

  const handleRadioChange = useCallback(
    (field: string, value: ValueType) => {
      setSettingState({ ...settings, [field]: value });
    },
    [settings, setSettingState]
  );

  const RenderRadioGroup = useCallback(
    ({ field, title }: { field: string; title: string }) => (
      <BlockStack gap='100'>
        {title.length ? <Text as='p'>{i18n.translate(title)}</Text> : null}
        {radios.map(({ value, label, helpText }) => {
          const typedValue = value as ValueType;

          return (
            <RadioButton
              key={typedValue as string}
              label={label}
              checked={settings[field] === typedValue}
              onChange={() => handleRadioChange(field, typedValue)}
              helpText={helpText}
            />
          );
        })}
      </BlockStack>
    ),
    [radios, handleRadioChange, i18n, settings]
  );

  return (
    <Element name={title} className={title}>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate(title)}
            </Text>
            {mainDescription && (
              <Text as='span' tone='subdued'>
                {i18n.translate(mainDescription)}
              </Text>
            )}
          </BlockStack>

          <Checkbox
            label={i18n.translate(checkboxLabel)}
            checked={isGeneral}
            onChange={handleCheckboxChange}
          />
          {isGeneral ? (
            <RenderRadioGroup field={fields[0]} title='' />
          ) : (
            fields
              .slice(1)
              .map((field) => (
                <RenderRadioGroup key={field} field={field} title={field} />
              ))
          )}
        </BlockStack>
      </Card>
    </Element>
  );
};
