import { ShopifyObjectTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { ResourceSelectionProps } from '../SearchFieldWithGrayBoxOfferWizard';
import { I18n } from '@shopify/react-i18n';

interface Variant {
  title: string;
}

const hasVariants = (
  item: ResourceSelectionProps
): item is ResourceSelectionProps & { variants: Variant[] } =>
  'variants' in item && Array.isArray(item.variants);

export const headerTitle = (
  selectedGrayBoxElements: (ResourceSelectionProps | string)[],
  currentResourceType: string,
  i18n: I18n
): string => {
  let result = '';
  let counter = 0;

  selectedGrayBoxElements.forEach((item) => {
    if (typeof item === 'string') {
      // Handle case where item is a string
      counter++;
      if (counter <= 3) {
        result += `${item}, `;
      }
    } else if (
      currentResourceType === ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT &&
      hasVariants(item)
    ) {
      // Handle case where item has variants
      item.variants.forEach((variant) => {
        counter++;
        if (counter <= 3) {
          result += `${item.title}/${variant.title}, `;
        }
      });
    } else {
      // Handle case where item is a ResourceSelectionProps without variants
      counter++;
      if (counter <= 3) {
        result += `${item.title}, `;
      }
    }
  });

  return counter <= 3
    ? `<b>${result.slice(0, -2)}</b>`
    : i18n.translate('ConfiguredData', {
        data: result.slice(0, -2),
        amount: counter - 3,
      });
};
