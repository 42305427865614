import React, { useEffect, useMemo } from 'react';
import {
  Card,
  BlockStack,
  Banner,
  Checkbox,
  Text,
  Link,
  Box,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TagsMetadataSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { RichTextEditor } from 'core/components';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import {
  SmartTagContextEnum,
  SmartTagTypeEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';

type CustomerTagsProps = {
  autoTaggingState: TagsMetadataSettingsDto;
  handleAutoTaggingState: (
    field: keyof TagsMetadataSettingsDto,
    value: TagsMetadataSettingsDto[keyof TagsMetadataSettingsDto]
  ) => void;
};

export const CustomerTags: React.FC<CustomerTagsProps> = ({
  autoTaggingState,
  handleAutoTaggingState,
}) => {
  const { fetchSmartTags } = useConfigureOffers();
  const [i18n] = useI18n();

  useEffect(() => {
    fetchSmartTags(SmartTagContextEnum.REGULAR);
  }, []);

  const tagOptions = useMemo(
    () =>
      [
        {
          key: 'tagCustomerPromotionUse',
          label: i18n.translate('PromotionUseTitle'),
          subtitle: i18n.translate('PromotionUseSubtitle'),
          smartTag: SmartTagTypeEnum.TRIGGER_TOKEN,
        },
        {
          key: 'tagCustomerOfferUse',
          label: i18n.translate('OfferUseTitle'),
          subtitle: i18n.translate('OfferUseSubtitle'),
          smartTag: SmartTagTypeEnum.OFFER_TOKEN,
        },
      ] as const,
    [i18n]
  );

  const hasEnabledTags = useMemo(
    () => tagOptions.some(({ key }) => autoTaggingState[key]?.enabled),
    [tagOptions, autoTaggingState]
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='p' fontWeight='semibold'>
            {i18n.translate('CustomerTags')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('CustomerTagsSubtitle')}
          </Text>
        </BlockStack>

        {hasEnabledTags && (
          <Banner
            title={i18n.translate('BannerTitle')}
            tone='warning'
            action={{ content: i18n.translate('AddPermissions') }}
          >
            {i18n.translate('BannerDescription', {
              learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
            })}
          </Banner>
        )}

        {tagOptions.map(({ key, label, subtitle, smartTag }) => {
          const tagData = autoTaggingState[key];

          return (
            <Checkbox
              key={key}
              label={label}
              helpText={
                <BlockStack>
                  <Text as='p'>{subtitle}</Text>
                  {tagData?.enabled && (
                    <Box paddingBlockStart='300'>
                      <Text as='p' tone='base'>
                        {i18n.translate('Template')}
                      </Text>
                      <RichTextEditor
                        value={tagData.value}
                        features={['smartTag']}
                        regularSmartTags={[smartTag]}
                        onChange={(value) =>
                          handleAutoTaggingState(key, { ...tagData, value })
                        }
                      />
                    </Box>
                  )}
                </BlockStack>
              }
              checked={tagData?.enabled}
              onChange={(value) =>
                handleAutoTaggingState(key, { ...tagData, enabled: value })
              }
            />
          );
        })}
      </BlockStack>
    </Card>
  );
};
