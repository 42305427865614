import React, { useState } from 'react';
import { NavTabs } from 'core/enums/NavTabsEnum';
import { usePageName } from 'core/hooks';
import { Route, Routes } from 'react-router-dom';
import { WidgetWrapper } from 'features/widgets/components/WidgetWrapper/WidgetWrapper';
import { ControlPageTypeEnum } from './enums/PagesEnums';
import { WidgetsControlCenter } from 'features/widgets/WidgetsControlCenter';
import { WidgetsControlCenterModalsEnum } from 'features/widgets/enums/WidgetsControlCenterModalsType';

export const NinjaCartControlCenterPage = () => {
  usePageName(NavTabs.NinjaCartControlCenter);
  const [exploreAllWidgets, setExploreAllWidgets] = useState<boolean>(false);
  return (
    <Routes>
      <Route
        index
        element={
          <WidgetsControlCenter
            exploreAllWidgets={exploreAllWidgets}
            setExploreAllWidgets={setExploreAllWidgets}
            pageType={
              WidgetsControlCenterModalsEnum.NINJA_CART_CONTROL_CENTER_MAIN_PAGE
            }
          />
        }
      />
      <Route
        path='/:widgetType'
        key='widget-overview'
        element={
          <WidgetWrapper
            pageType={ControlPageTypeEnum.NINJA_CART_CONTROL_CENTER}
          />
        }
      />
    </Routes>
  );
};
