import React, { useCallback } from 'react';
import { Card, BlockStack, Checkbox, Text, Banner } from '@shopify/polaris';
import { Element } from 'react-scroll';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { NinjaCartDisplayOptionDto } from 'core/api/adminSettings/adminSettingsApi';
import { NinjaCartDisplayOptionDisplayTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { SimplifiedTextEntries } from 'features/settings/components/EditPresetSkeleton/components/RightSideBarWrapper/RightBarTypes/components';
import './NinjaCartDisplayOption.scss';

export type NinjaCartDisplayOptionType =
  | 'productOptions'
  | 'vendor'
  | 'productProperties'
  | 'productValue';

type NinjaCartDisplayOptionProps = {
  setup: NinjaCartDisplayOptionDto;
  type: NinjaCartDisplayOptionType;
  setSetup: (data: NinjaCartDisplayOptionDto) => void;
};

export const NinjaCartDisplayOption: React.FC<NinjaCartDisplayOptionProps> = ({
  setup,
  type,
  setSetup,
}) => {
  const [i18n] = useI18n();

  const options = Object.values(NinjaCartDisplayOptionDisplayTypeDtoEnum).map(
    (value) => ({
      label: i18n.translate(`${value}`),
      value: value as string,
    })
  );

  const handleUpdateSetup = useCallback(
    (
      field: keyof NinjaCartDisplayOptionDto,
      data: NinjaCartDisplayOptionDto[keyof NinjaCartDisplayOptionDto]
    ) => setSetup({ ...setup, [field]: data }),
    [setup]
  );

  return (
    <Element name={type} className='NinjaCartDisplayOption'>
      <Card>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <Text as='p' fontWeight='bold'>
              {i18n.translate(`${type}_Title`)}
            </Text>
            <Text as='span' tone='subdued'>
              {i18n.translate(`${type}_Subtitle`)}
            </Text>
          </BlockStack>
          <SelectOptions
            label={i18n.translate(`${type}_SelectorLabel`)}
            onOptionSelect={(value) =>
              handleUpdateSetup(
                'displayType',
                value as NinjaCartDisplayOptionDisplayTypeDtoEnum
              )
            }
            options={options}
            selectedOption={setup.displayType as string}
          />
          {!setup.prefix && (
            <Checkbox
              label={i18n.translate(`${type}_CheckboxLabel`)}
              checked={setup.enabled}
              onChange={(value) => handleUpdateSetup('enabled', value)}
              helpText={
                type === 'productOptions'
                  ? i18n.translate(`${type}_CheckboxLabelHelp`)
                  : ''
              }
            />
          )}
          {setup.prefix && (
            <Checkbox
              label={i18n.translate(`${type}_CheckboxLabel`)}
              checked={setup.prefix.enabled}
              onChange={(value) =>
                handleUpdateSetup('prefix', { ...setup.prefix, enabled: value })
              }
              helpText={i18n.translate(`${type}_CheckboxLabelHelp`)}
            />
          )}

          {setup.prefix && setup.prefix.enabled && (
            <BlockStack>
              <Text as='p' fontWeight='bold'>
                {i18n.translate('Prefix')}
              </Text>
              <SimplifiedTextEntries
                message={{
                  entries: [{ text: setup.prefix.value || [] }],
                }}
                setMessage={(data) =>
                  handleUpdateSetup('prefix', {
                    ...setup.prefix,
                    value: data.entries?.[0].text,
                  })
                }
                defaultLanguage={setup.defaultLanguage || ''}
                title={''}
                hideHeader
                onSystemReset={() => null}
              />
            </BlockStack>
          )}
          {type !== 'productValue' && (
            <Banner>{i18n.translate('BannerInfo')}</Banner>
          )}
        </BlockStack>
      </Card>
    </Element>
  );
};
