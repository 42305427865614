import React, { useState } from 'react';
import { NavTabs } from 'core/enums/NavTabsEnum';
import { usePageName } from 'core/hooks';
import { Route, Routes } from 'react-router-dom';
import { WidgetsControlCenter } from 'features/widgets/WidgetsControlCenter';
import { WidgetWrapper } from 'features/widgets/components/WidgetWrapper/WidgetWrapper';
import { ControlPageTypeEnum } from './enums/PagesEnums';
import { WidgetsControlCenterModalsEnum } from 'features/widgets/enums/WidgetsControlCenterModalsType';

export const WidgetsControlCenterPage = () => {
  usePageName(NavTabs.WidgetsControlCenter);
  const [exploreAllWidgets, setExploreAllWidgets] = useState<boolean>(false);

  return (
    <Routes>
      <Route
        index
        element={
          <WidgetsControlCenter
            exploreAllWidgets={exploreAllWidgets}
            setExploreAllWidgets={setExploreAllWidgets}
            pageType={
              WidgetsControlCenterModalsEnum.WIDGETS_CONTROL_CENTER_MAIN_PAGE
            }
          />
        }
      />
      <Route path='/:widgetType'>
        <Route
          index
          element={
            <WidgetWrapper
              pageType={ControlPageTypeEnum.WIDGETS_CONTROL_CENTER}
            />
          }
        />
        <Route
          path=':subType'
          element={
            <WidgetWrapper
              pageType={ControlPageTypeEnum.WIDGETS_CONTROL_CENTER}
            />
          }
        />
      </Route>
    </Routes>
  );
};
