import React, { useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import './Icon.scss';
import StyleComponentHeader from '../StyleComponentHeader/StyleComponentHeader';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';
import {
  IconSource,
  Popover,
  TextField,
  Text,
  Icon as IconPolaris,
  Checkbox,
} from '@shopify/polaris';
import { SelectIcon } from '@shopify/polaris-icons';
import {
  IconComponentDto,
  IconResourceDto,
  ResourceGroupKeyDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import ImageIconLibrary from 'core/components/WidgetEditor/ImageIconLibrary/ImageIconLibrary';
import { useAppSelector } from 'core/hooks';
import { useConfigureWidgets } from 'features/settings/hooks/useConfigureWidgets';

type IconProps = {
  icon: IconComponentDto;
  title?: string;
  groupKeys: ResourceGroupKeyDto[];
  enabled?: boolean;
  enabledText?: string;
  onlyColor?: boolean;
  showIcon?: boolean;
  offersNote?: boolean;
  inherited?: boolean;
  setEnabled?: (value: boolean) => void;
  onSystemReset: () => void;
  setIcon: (value: IconComponentDto) => void;
};
export const Icon: React.FC<IconProps> = ({
  icon,
  title,
  groupKeys,
  enabled,
  enabledText,
  onlyColor,
  showIcon,
  offersNote,
  inherited,
  setEnabled,
  onSystemReset,
  setIcon,
}) => {
  const [i18n] = useI18n();
  const [iconLibraryOpen, setIconLibraryOpen] = useState<boolean>(false);
  const { defaultIconLibary, customIconLibary } = useAppSelector(
    (store) => store.widgets
  );
  const { refetchCustomIconLibary, isCustomIconLibaryFetching } =
    useConfigureWidgets();

  const filteredDefaultIcons = defaultIconLibary.filter((icon) =>
    icon.groupKeys?.some((key) => groupKeys.includes(key))
  );

  const selectedIcon: IconResourceDto =
    defaultIconLibary
      ?.concat(customIconLibary || [])
      .find((file) => file.id === icon.iconId) || {};

  return (
    <div className='IconStyle'>
      <StyleComponentHeader
        label={title ? title : i18n.translate(`Icon`)}
        inherited={inherited}
        buttons={[
          {
            external: true,
            content: i18n.translate(`ResetToSystemDefault`),
            onAction: () => {
              onSystemReset();
            },
          },
        ]}
      >
        {offersNote && (
          <Text tone='subdued' as='p'>
            {i18n.translate('SetIconColor')}
          </Text>
        )}
        {enabled !== undefined && (
          <Checkbox
            label={enabledText}
            checked={enabled}
            onChange={(value) => setEnabled?.(value)}
            disabled={onlyColor}
          />
        )}
        {(enabled === undefined || enabled) && (
          <>
            {showIcon && (
              <div className='IconRow'>
                {i18n.translate(`Icon`)}
                <div className='RightContent'>
                  <Popover
                    active={iconLibraryOpen}
                    fullHeight
                    activator={
                      <div
                        onClick={() =>
                          onlyColor ? null : setIconLibraryOpen(true)
                        }
                      >
                        <TextField
                          disabled={onlyColor}
                          suffix={
                            <IconPolaris source={SelectIcon as IconSource} />
                          }
                          prefix={
                            selectedIcon.url ? (
                              <img
                                className='PrefixImage'
                                src={selectedIcon.url}
                              />
                            ) : null
                          }
                          label=''
                          autoComplete=''
                          placeholder={i18n.translate('IconPlaceholder')}
                          value={selectedIcon?.name || ''}
                        />
                      </div>
                    }
                    autofocusTarget='first-node'
                    onClose={() => setIconLibraryOpen(false)}
                  >
                    <ImageIconLibrary
                      selectedFile={icon.iconId || ''}
                      defaultFilesLibrary={filteredDefaultIcons || []}
                      customFilesLibrary={customIconLibary || []}
                      isCustomFileLibraryFetching={isCustomIconLibaryFetching}
                      fileFormat={'icon'}
                      groupKeys={groupKeys}
                      setSelectedFile={(value) =>
                        setIcon({ ...icon, iconId: value })
                      }
                      refetchCustomLibrary={refetchCustomIconLibary}
                      onClosePopover={() => setIconLibraryOpen(false)}
                    />
                  </Popover>
                </div>
              </div>
            )}

            <div className='IconRow'>
              {i18n.translate(`Color`)}
              <div className='RightContent'>
                <ColorSelectorBox
                  color={icon.color || ''}
                  setColor={(value) => setIcon({ ...icon, color: value })}
                />
              </div>
            </div>
          </>
        )}
      </StyleComponentHeader>
    </div>
  );
};
