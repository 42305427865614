import React from 'react';
import {
  Banner,
  BlockStack,
  Card,
  Checkbox,
  Icon,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { Tooltip } from 'core/components';
import { InfoIcon } from '@shopify/polaris-icons';

type CouponFraudTagsProps = {
  addTagFlagCouponFraud: boolean;
  setAddTagFlagCouponFraud: (value: boolean) => void;
};

export const CouponFraudTags: React.FC<CouponFraudTagsProps> = ({
  addTagFlagCouponFraud,
  setAddTagFlagCouponFraud,
}) => {
  const [i18n] = useI18n();

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <InlineStack gap='200'>
            <Text as='p' fontWeight='semibold'>
              {i18n.translate('CouponFraudTags')}
            </Text>
            <Tooltip content={i18n.translate('CouponFraudTagsTooltip')}>
              <Icon tone='base' source={InfoIcon} />
            </Tooltip>
          </InlineStack>

          <Text as='p' tone='subdued'>
            {i18n.translate('CouponFraudTagsSubtitle')}
          </Text>
        </BlockStack>
        {addTagFlagCouponFraud && (
          <Banner tone='warning'>
            {i18n.translate('CouponFraudTagsBanner', {
              following: <Link>{i18n.translate('FollowingArticle')}</Link>,
            })}
          </Banner>
        )}
        <Checkbox
          label={i18n.translate('CheckboxLabel')}
          checked={addTagFlagCouponFraud}
          onChange={setAddTagFlagCouponFraud}
        />
      </BlockStack>
    </Card>
  );
};
