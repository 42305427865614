import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Node,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
  Editor,
  NodeViewProps,
} from '@tiptap/react';
import { Tag, Popover, Text, Button, ButtonGroup } from '@shopify/polaris';
import { XIcon } from '@shopify/polaris-icons';
import '../RichTextEditor.scss';
import { ToolbarButton } from '../ToolbarButton';
import { smartTagIconToolbar } from '../assets/iconsToolbar';
import { useI18n } from '@shopify/react-i18n';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import { SmartTag as CommonSmartTag } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  SmartTagGroupEnum,
  SmartTagTypeEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';

interface ResultItem {
  category: string;
  smartTags: string[];
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    smartTag: {
      addSmartTag: (tag: CommonSmartTag) => ReturnType;
    };
  }
}

const SmartTag: React.FC<NodeViewProps> = (props) => {
  const { node } = props;

  if (node && node.attrs) {
    const { tag } = node.attrs;
    return (
      <NodeViewWrapper className='smart-tag' as='span'>
        <span data-drag-handle className='draggableTag'>
          <Tag>
            <span className='smartTagContent' title={tag}>
              {tag}
            </span>
          </Tag>
        </span>
      </NodeViewWrapper>
    );
  }
  return null;
};

// done only for 'PRICE' + 'TIME' tags
const getSmartTagAttributes = (tag: CommonSmartTag) => {
  const { id, category } = tag;

  switch (category) {
    case SmartTagGroupEnum.DISCOUNT:
      return { tag: id, category, level: 'shop', label: `{{${id}}}` };
    case SmartTagGroupEnum.DISCOUNT_CODES:
      return { tag: id, category, level: 'shop', label: `{{${id}}}` };
    case SmartTagGroupEnum.GOALS:
      return { tag: id, category, level: 'shop', label: `{{${id}}}` };
    case SmartTagGroupEnum.INSTALLMENTS:
      return { tag: id, category, level: 'shop', label: `{{${id}}}` };
    case SmartTagGroupEnum.LIMITS:
      return { tag: id, category, level: 'shop', label: `{{${id}}}` };
    case SmartTagGroupEnum.LINK:
      return { tag: id, category, level: 'shop', label: `{{${id}}}` };
    case SmartTagGroupEnum.TIME:
      return {
        tag: id,
        category,
        level: 'shop',
        'time-format': 'x days, y hours, z minutes and v seconds',
        'display-labels': true,
        'display-labels-format': 'days, hours, minutes, seconds',
        'enable-separators': true,
        'separators-style': 'Slash',
        'date-value': `{{${id}}}`,
        timer: true,
      };
    // need to refactor this probably
    case SmartTagGroupEnum.PRICE:
      return {
        tag: id,
        level: 'shop',
        'price-strikethrough': 'horizontal',
        'price-text-size': '13',
        'currency-code': 'regular',
        'currency-code-side': 'right',
        'currency-code-size': '13',
        'currency-symbol': 'regular',
        'currency-symbol-size': '13',
        'fractional-part-precision': 'none',
        'fractional-part-format': 'regular',
        'fractional-part-size': '13',
        money: true,
        currency: `##${id}##`,
        amount: `{{${id}}}`,
        category,
      };
    case SmartTagGroupEnum.OTHER:
      return { tag: id, category, level: 'shop', label: `{{${id}}}` };
  }
};

const allAvailableAttrs = [
  'tag',
  'level',
  'price-strikethrough',
  'price-text-size',
  'currency-code',
  'currency-code-side',
  'currency-code-size',
  'currency-symbol',
  'currency-symbol-size',
  'fractional-part-precision',
  'fractional-part-format',
  'fractional-part-size',
  'money',
  'currency',
  'amount',
  'category',
  'time-format',
  'display-labels',
  'display-labels-format',
  'enable-separators',
  'separators-style',
  'label',
  'date-value',
  'timer',
];

export default Node.create({
  name: 'la-dn-smart-tag',
  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,
  code: true,
  defining: true,
  isolating: true,
  draggable: true,

  addOptions() {
    return {
      tags: [],
    };
  },

  addCommands() {
    return {
      addSmartTag:
        (tag) =>
        ({ chain }) => {
          const attrs = getSmartTagAttributes(tag);
          // Convert the attrs object into a string of HTML attributes
          const attrsString = attrs
            ? Object.entries(attrs)
                .map(([key, value]) => `${key}="${value}"`)
                .join(' ')
            : '';

          return chain()
            .insertContent(`<la-dn-smart-tag ${attrsString}></la-dn-smart-tag>`)
            .run();
        },
    };
  },

  addAttributes() {
    const dynamicAttributes: {
      [key: string]: {
        default: undefined;
        renderHTML: (arg: any) => { [key: string]: any };
      };
    } = {};

    allAvailableAttrs.forEach((attr) => {
      dynamicAttributes[attr] = {
        default: undefined,
        renderHTML({ [attr]: value }) {
          return {
            [attr]: value,
          };
        },
      };
    });

    return dynamicAttributes;
  },

  parseHTML() {
    return [
      {
        tag: 'la-dn-smart-tag',
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    if (!node) {
      return '';
    }

    const { tag } = node.attrs;
    const output = `{{${tag}}}`;

    return [
      tag ? 'la-dn-smart-tag' : 'span',
      mergeAttributes(HTMLAttributes),
      output,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SmartTag);
  },
});

type ToolbarElement = {
  disabled: boolean;
  editor: Editor;
  currentList?: CommonSmartTag[];
  offerId?: string;
  priceOnly?: boolean;
  regularSmartTags?: SmartTagTypeEnum[];
};

export const SmartTagToolbarElement: React.FC<ToolbarElement> = (props) => {
  const { disabled, editor, currentList, priceOnly, regularSmartTags } = props;
  const [i18n] = useI18n();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = useState<string>('');
  const [currentSmartTag, setCurrentSmartTag] = useState<string>('');

  const transformData = useCallback(
    (smartTags?: CommonSmartTag[]): ResultItem[] => {
      const result: ResultItem[] = [];

      if (!smartTags) return result;

      // Create "ALL_CATEGORIES" category with all possible smart tags
      const allCategories: ResultItem = {
        category: 'ALL_CATEGORIES',
        smartTags: smartTags.map((tag) => tag.id || ''),
      };
      result.push(allCategories);

      for (const smartTag of smartTags) {
        let categoryExists = false;
        for (const item of result) {
          if (item.category === smartTag.category) {
            item.smartTags.push(smartTag.id || '');
            categoryExists = true;
            break;
          }
        }
        if (!categoryExists) {
          result.push({
            category: smartTag.category || '',
            smartTags: [smartTag.id || ''],
          });
        }
      }

      return result;
    },
    []
  );

  const currentFormatedList = useMemo(
    () => transformData(currentList),
    [currentList]
  );

  const categoryOptions = useMemo(
    () =>
      currentFormatedList.map((value) => ({
        value: value.category,
        label: i18n.translate(value.category),
      })),
    [currentFormatedList, i18n]
  );

  const priceOnlyTags = [
    SmartTagTypeEnum.DISCOUNT,
    SmartTagTypeEnum.DISCOUNT_CODE,
    SmartTagTypeEnum.DISCOUNT_PERCENTAGE,
    SmartTagTypeEnum.DISCOUNT_AMOUNT,
  ];

  const smartTagsOptions = useMemo(
    () =>
      currentFormatedList
        ?.find((item) => item.category === currentCategory)
        ?.smartTags?.filter((item) => {
          if (priceOnly) {
            return priceOnlyTags.includes(item as SmartTagTypeEnum);
          }
          if (regularSmartTags) {
            return regularSmartTags.includes(item as SmartTagTypeEnum);
          }
          return true;
        })
        .map((value) => ({
          value: value,
          label: value,
        })) || [],
    [currentFormatedList, currentCategory]
  );

  const isSmartTagPresent = useMemo(
    () => smartTagsOptions.some((option) => option.value === currentSmartTag),
    [smartTagsOptions, currentSmartTag]
  );

  const smartTagData = useMemo(
    () => currentList?.find((tag) => tag.id === currentSmartTag),
    [currentSmartTag, currentList]
  );

  const toggleDropdown = useCallback(
    () => setIsDropdownOpen((active) => !active),
    []
  );

  const handleDropdownItemClick = useCallback(() => {
    smartTagData && editor.chain().focus().addSmartTag(smartTagData).run();
    toggleDropdown();
    editor.commands.focus();
  }, [editor, toggleDropdown, smartTagData]);

  useEffect(() => {
    !currentCategory.length && setCurrentCategory('ALL_CATEGORIES');
  }, [currentCategory, categoryOptions]);

  useEffect(() => {
    if (!isSmartTagPresent) {
      setCurrentSmartTag('');
    }
  }, [isSmartTagPresent]);

  const activator = useMemo(
    () => (
      <ToolbarButton
        name='smart-tag'
        icon={smartTagIconToolbar(isDropdownOpen)}
        active={isDropdownOpen}
        onClick={toggleDropdown}
        disabled={disabled}
        tooltipContent={`${i18n?.translate('AddSmartTag')}`}
      />
    ),
    [toggleDropdown, disabled, isDropdownOpen]
  );

  return (
    <Popover
      active={isDropdownOpen}
      activator={activator}
      onClose={toggleDropdown}
    >
      <Popover.Pane>
        <div className='insertSmartTagWrapper'>
          <div className='insertSmartTagHeader'>
            <Text as='h6' fontWeight='semibold'>
              {i18n.translate('InsertSmartTag')}
            </Text>
            <Button onClick={toggleDropdown} icon={XIcon} variant='plain' />
          </div>
          <div className='insertSmartTagBody'>
            {!priceOnly && !regularSmartTags && (
              <SelectOptions
                options={categoryOptions}
                onOptionSelect={(value) => setCurrentCategory(value)}
                allowManual={false}
                selectedOption={currentCategory}
                label={i18n.translate('Category')}
              />
            )}
            <SelectOptions
              options={smartTagsOptions}
              onOptionSelect={(value) => setCurrentSmartTag(value)}
              allowManual={false}
              placeholder={i18n.translate('SelectTag')}
              selectedOption={currentSmartTag}
              label={i18n.translate('SmartTag')}
            />
            <ButtonGroup>
              <Button onClick={toggleDropdown}>
                {i18n.translate('Cancel')}
              </Button>
              <Button
                variant='primary'
                disabled={!currentSmartTag}
                onClick={handleDropdownItemClick}
              >
                {i18n.translate('Insert')}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Popover.Pane>
    </Popover>
  );
};
