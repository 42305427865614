import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { GiftsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  useConfigureSettings,
  SettingsFetchTypeEnum,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { RemovingFreeGifts } from './components/RemovingFreeGifts/RemovingFreeGifts';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { GiftPrice } from './components/GiftPrice/GiftPrice';
import { useAppDispatch } from 'core/hooks';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';

export const Gifts = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const pageBackAction = useSettingsPageBackAction();

  const { giftsData, giftsDataIsFetching, giftsDataChange } =
    useConfigureSettings(SettingsFetchTypeEnum.GIFTS);

  const [giftsState, setGiftsState] = useState<GiftsSettingsDto>({});
  const [savedData, setSavedData] = useState<GiftsSettingsDto>({});

  const handleUpdateGifts = useCallback(
    (
      field: keyof GiftsSettingsDto,
      data: GiftsSettingsDto[keyof GiftsSettingsDto]
    ) => setGiftsState((prev) => ({ ...prev, [field]: data })),
    [setGiftsState]
  );

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(giftsState);
    return giftsDataChange(giftsState, onSuccess);
  }, [giftsState]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => setGiftsState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, giftsState)));
  }, [giftsState, savedData]);

  useEffect(() => {
    if (!isEmpty(giftsData)) {
      setGiftsState(giftsData);
      setSavedData(giftsData);
    }
  }, [giftsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <>
      {!giftsDataIsFetching && !isEmpty(giftsState) ? (
        <Page backAction={pageBackAction} title={i18n.translate('Gifts')}>
          <BlockStack gap='400'>
            <RemovingFreeGifts
              giftsState={giftsState}
              handleUpdateGifts={handleUpdateGifts}
            />
            <GiftPrice
              giftPrice={giftsState.giftPrice}
              setGiftPrice={(value) => handleUpdateGifts('giftPrice', value)}
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
Gifts.displayName = 'Gifts';
