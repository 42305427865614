import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { BlockStack, Card, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { useAppDispatch } from 'core/hooks';
import { ExclusionsShopSettingsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { SettingsSavebarRef } from 'features/settings/Settings';
import {
  setIsUnsavedChanges,
  setIsValidationError,
  setSettingsFormValidity,
} from 'core/store/settingsSlice';
import { isEmpty, isEqual } from 'lodash';
import { useSettingsFormValidity } from 'features/promotions/hooks/useSettingsFormValidity';
import { ExclusionsBox } from 'core/components';
import { setShowValidation } from 'core/store/promotionsSlice';

export const Exclusions = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const { validityChangeHandler, formValidityControlArray } =
    useSettingsFormValidity();
  const {
    exclusionsData,
    exclusionsIsFetching,
    refetchExclusionsData,
    saveExclusions,
  } = useConfigurePromotions(PromotionFetchTypeEnum.EXCLUSIONS);

  const [exclusionsState, setExclusionsState] =
    useState<ExclusionsShopSettingsDto>({});
  const [savedData, setSavedData] = useState<ExclusionsShopSettingsDto>({});

  const hasValidityErrors = useMemo(
    () => formValidityControlArray.some((form) => form.isValid === false),
    [formValidityControlArray]
  );

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(exclusionsState);
    return saveExclusions(exclusionsState, onSuccess);
  }, [exclusionsState]);

  const handleExclusionsState = useCallback(
    (
      field: keyof ExclusionsShopSettingsDto,
      value: ExclusionsShopSettingsDto[keyof ExclusionsShopSettingsDto]
    ) => setExclusionsState((prev) => ({ ...prev, [field]: value })),
    [setExclusionsState]
  );

  useImperativeHandle(ref, () => ({
    discardChanges: () => setExclusionsState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, exclusionsState)));
  }, [exclusionsState, savedData]);

  useEffect(() => {
    if (!isEmpty(exclusionsData)) {
      setExclusionsState(exclusionsData);
      setSavedData(exclusionsData);
    }
  }, [exclusionsData]);

  useEffect(() => {
    dispatch(setIsValidationError(hasValidityErrors));
  }, [hasValidityErrors]);

  useEffect(() => {
    refetchExclusionsData();
    return () => {
      dispatch(setSettingsFormValidity([]));
      dispatch(setIsUnsavedChanges(false));
      dispatch(setShowValidation(false));
    };
  }, []);

  return (
    <>
      {!exclusionsIsFetching && exclusionsData ? (
        <Page
          title={i18n.translate('StorewideExlusions')}
          subtitle={i18n.translate('PageSubtitle')}
        >
          <BlockStack gap='600'>
            <Card>
              <ExclusionsBox
                title={i18n.translate('Products')}
                subtitle={i18n.translate('ProductsSubtitle')}
                exclusions={exclusionsState.products || {}}
                setExclusions={(data) =>
                  handleExclusionsState('products', data)
                }
                onFormValidityChange={(isValid) =>
                  validityChangeHandler('Products', isValid)
                }
              />
            </Card>
            <Card>
              <ExclusionsBox
                title={i18n.translate('CartRules')}
                subtitle={i18n.translate('CartRulesSubtitle')}
                exclusions={exclusionsState.cartRules || {}}
                setExclusions={(data) =>
                  handleExclusionsState('cartRules', data)
                }
                onFormValidityChange={(isValid) =>
                  validityChangeHandler('CartRules', isValid)
                }
              />
            </Card>
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
Exclusions.displayName = 'Exclusions';
