import React from 'react';
import { Tooltip as PolarisTooltip } from '@shopify/polaris';
type TooltipProps = {
  content?: React.ReactNode;
  children: React.ReactNode;
  hasUnderline?: boolean;
};
export const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  hasUnderline,
}) => {
  return (
    <>
      {content ? (
        <PolarisTooltip hasUnderline={hasUnderline} content={content}>
          {children}
        </PolarisTooltip>
      ) : (
        children
      )}
    </>
  );
};
