import React from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ConfigureLanguages } from './components/ConfigureLanguages/ConfigureLanguages';
import { TranslationsComponent } from './components/TranslationsComponent/TranslationsComponent';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';

type TranslationsProps = {
  isMaxModal: boolean;
};
export const Translations: React.FC<TranslationsProps> = ({ isMaxModal }) => {
  const [i18n] = useI18n();
  const pageBackAction = useSettingsPageBackAction();
  const { translationMainData, translationMainIsFetching } =
    useConfigureSettings(SettingsFetchTypeEnum.TRANSLATION_MAIN);

  return (
    <>
      {!translationMainIsFetching && translationMainData ? (
        <Page
          backAction={pageBackAction}
          title={i18n.translate(`Translations`)}
        >
          <BlockStack gap='600'>
            <ConfigureLanguages
              isMaxModal={isMaxModal}
              translationMainData={translationMainData}
            />
            <TranslationsComponent
              isMaxModal={isMaxModal}
              translationMainData={translationMainData}
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
};
