import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { MandatoryCheckboxes } from './components/MandatoryCheckboxes/MandatoryCheckboxes';
import { RefreshBehavior } from './components/RefreshBehavior/RefreshBehavior';
import { CartBehaviorSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import {
  setIsUnsavedChanges,
  setIsValidationError,
} from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { useAppDispatch } from 'core/hooks';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';
import { CartBehaviourRefreshBehaviourTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { setShowValidation } from 'core/store/promotionsSlice';

export const CartBehavior = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const pageBackAction = useSettingsPageBackAction();
  const dispatch = useAppDispatch();

  const {
    cartBehaviorDataChange,
    cartBehaviorDataIsFetching,
    cartBehaviorData,
  } = useConfigureSettings(SettingsFetchTypeEnum.CART_BEHAVIOR);
  const [cartBehaviorState, setCartBehaviorState] =
    useState<CartBehaviorSettingsDto>({});
  const [savedData, setSavedData] = useState<CartBehaviorSettingsDto>({});

  const hasValidationError = useMemo(() => {
    const { cart, drawerCart } = cartBehaviorState || {};

    return [cart, drawerCart].some(
      (item) =>
        !item?.ninjaCartEnabled &&
        item?.mode === CartBehaviourRefreshBehaviourTypeDtoEnum.REFRESH_JS &&
        !item?.code
    );
  }, [cartBehaviorState]);

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(cartBehaviorState);
    return cartBehaviorDataChange(cartBehaviorState, onSuccess);
  }, [cartBehaviorState]);

  const handleUpdateCartBehavior = useCallback(
    (
      field: keyof CartBehaviorSettingsDto,
      data: CartBehaviorSettingsDto[keyof CartBehaviorSettingsDto]
    ) => setCartBehaviorState((prev) => ({ ...prev, [field]: data })),
    [setCartBehaviorState]
  );

  useImperativeHandle(ref, () => ({
    discardChanges: () => setCartBehaviorState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsValidationError(hasValidationError));
  }, [hasValidationError]);

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, cartBehaviorState)));
  }, [cartBehaviorState, savedData]);

  useEffect(() => {
    if (!isEmpty(cartBehaviorData)) {
      setCartBehaviorState(cartBehaviorData);
      setSavedData({ ...cartBehaviorData });
    }
  }, [cartBehaviorData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
      dispatch(setShowValidation(false));
    };
  }, []);

  return (
    <>
      {!cartBehaviorDataIsFetching && cartBehaviorData ? (
        <Page
          backAction={pageBackAction}
          title={i18n.translate('CartBehavior')}
        >
          <BlockStack gap='600'>
            <RefreshBehavior
              cartBehaviorState={cartBehaviorState}
              handleUpdateCartBehavior={handleUpdateCartBehavior}
            />
            <MandatoryCheckboxes
              selector={cartBehaviorState.requiredCheckboxesCssSelector}
              setSelector={(value) =>
                handleUpdateCartBehavior('requiredCheckboxesCssSelector', value)
              }
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
CartBehavior.displayName = 'CartBehavior';
