import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { SubscriptionProductsCheckout } from './components/SubscriptionProductsCheckout/SubscriptionProductsCheckout';

import { IntegrationsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { DeveloperHub } from './components/DeveloperHub/DeveloperHub';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { useAppDispatch } from 'core/hooks';
import { useSettingsPageBackAction } from 'features/widgets/hooks/useSettingsPageBackAction';
import { PixelEventIntegrations } from './components/PixelEventIntegrations/PixelEventIntegrations';
import { StructuredData } from './components/StructuredData/StructuredData';
import { JsonMetafields } from './components/JsonMetafields/JsonMetafields';

export const Integrations = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const pageBackAction = useSettingsPageBackAction();

  const {
    saveIntegrationsSettings,
    integrationsDataIsFetching,
    integrationsData,
  } = useConfigureSettings(SettingsFetchTypeEnum.INTEGRATIONS);

  const [integrationState, setIntegrationsState] =
    useState<IntegrationsSettingsDto>({});

  const [savedData, setSavedData] = useState<IntegrationsSettingsDto>({});

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(integrationState);
    return saveIntegrationsSettings(integrationState, onSuccess);
  }, [integrationState]);

  const handleIntegrationsState = useCallback(
    (
      field: keyof IntegrationsSettingsDto,
      data: IntegrationsSettingsDto[keyof IntegrationsSettingsDto]
    ) => setIntegrationsState((prev) => ({ ...prev, [field]: data })),
    [setIntegrationsState]
  );

  useImperativeHandle(ref, () => ({
    discardChanges: () => setIntegrationsState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, integrationState)));
  }, [integrationState, savedData]);

  useEffect(() => {
    if (!isEmpty(integrationsData)) {
      setIntegrationsState(integrationsData);
      setSavedData(integrationsData);
    }
  }, [integrationsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <>
      {!integrationsDataIsFetching && integrationState ? (
        <Page
          backAction={pageBackAction}
          title={i18n.translate('Integrations')}
        >
          <BlockStack gap='600'>
            <DeveloperHub />
            <SubscriptionProductsCheckout
              productsCheckout={
                integrationState.subscriptionProductsCheckoutSettings
              }
              setProductsCheckout={(data) =>
                handleIntegrationsState(
                  'subscriptionProductsCheckoutSettings',
                  data
                )
              }
            />
            <PixelEventIntegrations
              pixelEvents={integrationState.pixelEventsSettings}
              setPixelEvents={(data) =>
                handleIntegrationsState('pixelEventsSettings', data)
              }
            />
            <StructuredData
              structuredData={integrationState.structuredDataSettings}
              setStructuredData={(value) =>
                handleIntegrationsState('structuredDataSettings', value)
              }
            />
            <JsonMetafields
              jsonMetafields={integrationState.jsonMetafieldsSettings}
              setJsonMetafields={(value) =>
                handleIntegrationsState('jsonMetafieldsSettings', value)
              }
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
Integrations.displayName = 'Integrations';
