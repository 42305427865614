import {
  ThemeTypeDto,
  useGetPromotionsV6PromotionByIdAndThemeTypeTestQuery,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useParams } from 'react-router-dom';

const APP_NAME = process.env.REACT_APP_APP_NAME || '';

export const useGetTestLink = (themeType?: ThemeTypeDto) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const params = useParams();

  const {
    data: promotionTestLinkData,
    isFetching: promotionTestLinkIsFetching,
    error: promotionTestLinkError,
    refetch: refetchpromotionTestLink,
  } = useGetPromotionsV6PromotionByIdAndThemeTypeTestQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: params.promotionId || '',
      themeType: themeType as ThemeTypeDto,
    },
    {
      skip: !themeType || !params.promotionId,
      refetchOnMountOrArgChange: true,
    }
  );

  return {
    promotionTestLinkData,
    promotionTestLinkIsFetching,
    promotionTestLinkError,
    refetchpromotionTestLink,
  };
};
