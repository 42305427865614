import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  NinjaCartOrderBumpWidgetSettingsDto,
  WidgetSettingsDtoNinjaCartOrderBumpWidgetSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { WidgetSavebarRef } from '../../WidgetWrapper';
import { WidgetTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import { StyleSetting } from '../../components';
import { useNinjaCartWidget } from 'features/widgets/hooks/useNinjaCartWidget';
import { Card, BlockStack, Checkbox, Text, Banner } from '@shopify/polaris';
import { Element } from 'react-scroll';

type OrderBumpSettingsProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OrderBumpSettings = forwardRef<
  WidgetSavebarRef,
  OrderBumpSettingsProps
>(({ setIsLoading, setIsSaving }, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();
  const {
    orderBumpData,
    orderBumpDataIsFetching,
    saveOrderBumpIsLoading,
    saveOrderBump,
    // refetchOrderBumpData,
  } = useNinjaCartWidget(WidgetTypeDtoEnum.NINJA_CART_ORDER_BUMP);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoNinjaCartOrderBumpWidgetSettingsDto>({});
  const [orderBumpState, setOrderBumpState] =
    useState<WidgetSettingsDtoNinjaCartOrderBumpWidgetSettingsDto>({});

  const updateOrderBumpState = useCallback(
    (
      field: keyof NinjaCartOrderBumpWidgetSettingsDto,
      data: NinjaCartOrderBumpWidgetSettingsDto[keyof NinjaCartOrderBumpWidgetSettingsDto]
    ) =>
      setOrderBumpState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setOrderBumpState]
  );

  const handleSave = useCallback(
    () =>
      saveOrderBump(orderBumpState.settings || null, () =>
        setSavedData(orderBumpState)
      ),
    [orderBumpState]
  );

  useEffect(() => {
    if (!isEmpty(orderBumpData)) {
      setOrderBumpState(orderBumpData);
      setSavedData(orderBumpData);
    }
  }, [orderBumpData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(savedData) && !isEmpty(orderBumpState)) {
      dispatch(setIsUnsavedChanges(!isEqual(orderBumpState, savedData)));
    }
  }, [savedData, orderBumpState]);

  useEffect(() => {
    setIsLoading(orderBumpDataIsFetching);
  }, [orderBumpDataIsFetching]);

  useEffect(() => {
    setIsSaving(saveOrderBumpIsLoading);
  }, [saveOrderBumpIsLoading]);

  useImperativeHandle(ref, () => ({
    saveChanges: handleSave,
    discardChanges: () => setOrderBumpState(savedData),
  }));

  return !orderBumpDataIsFetching ? (
    <>
      <div
        style={{
          height: 'fit-content',
        }}
      >
        <VideoCard
          isPortrait={false}
          cardData={{
            title: i18n.translate('CardDataTitle'),
            description: i18n.translate('CardDataDescription'),
            btn: i18n.translate('CardDataBtn'),
            link: 'http://www.google.com',
            videoUrl: '',
          }}
        />
      </div>
      <StyleSetting styled={!!orderBumpData?.isShopLevelWidget} />
      <Element
        name='OrderBumpProductSettings'
        className='OrderBumpProductSettings'
      >
        <Card>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='p' fontWeight='bold'>
                {i18n.translate('ProductSettingsTitle')}
              </Text>
              <Text as='span' tone='subdued'>
                {i18n.translate('ProductSettingsSubtitle')}
              </Text>
            </BlockStack>
            <BlockStack gap='200'>
              <Checkbox
                label={i18n.translate('HideDrawerCartProduct')}
                helpText={i18n.translate('HideDrawerCartProductHelp')}
                checked={orderBumpState.settings?.hideDrawerCartProduct}
                onChange={(value) =>
                  updateOrderBumpState('hideDrawerCartProduct', value)
                }
              />
              <Checkbox
                label={i18n.translate('IgnoreDrawerCartProduct')}
                checked={orderBumpState.settings?.ignoreDrawerCartProduct}
                onChange={(value) =>
                  updateOrderBumpState('ignoreDrawerCartProduct', value)
                }
              />
              <Banner>{i18n.translate('BannerText')}</Banner>
            </BlockStack>
          </BlockStack>
        </Card>
      </Element>
    </>
  ) : null;
});

OrderBumpSettings.displayName = 'OrderBumpSetting';
