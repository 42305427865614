import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './WidgetsControlCenter.scss';
import {
  BlockStack,
  Box,
  Page,
  Popover,
  Text,
  Button,
  OptionList,
  Badge,
  Link,
  Icon,
  Banner,
  MediaCard,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  SkeletonThumbnail,
  Card,
  Modal,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PlayIcon, StopCircleIcon, ImagesIcon } from '@shopify/polaris-icons';
import { useWidgetsControlCenter } from 'core/hooks/useWidgetsControlCenter';
import ManageThemesModal from 'features/settings/components/GeneralSettings/ManageWidgets/components/ManageThemesModal/ManageThemesModal';
import {
  CreateSupportRequestResponseDto,
  InstallationSupportRequestPriorityDto,
  NinjaCartWidgetInstallationDto,
  NinjaCartWidgetsInstallationStatusSettingsDto,
  ThemeStatusesDto,
  WidgetInstallationDto,
  WidgetTypeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { OptionDescriptor } from '@shopify/polaris/build/ts/src/types';
import {
  InstallationSupportRequestGeneralStatusDtoEnum,
  InstallationSupportRequestPriorityDtoEnum,
  ThemeTypeDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { setWidgetThemeType } from 'core/store/settingsSlice';
import { VideoCard } from 'features/dashboard/components/Explore/components/VideoCard/VideoCard';
import {
  dismissComponent,
  DismissedEnum,
  getDismissedComponents,
} from 'features/dashboard/utils/utils';
import { MyWidgets } from './components/MyWidgets/MyWidgets';
import { ExploreAllWidgets } from './components/ExploreAllWidgets/ExploreAllWidgets';
import { RecommendedWidgets } from './components/RecommendedWidgets/RecommendedWidgets';
import { SettingsSupportDetails } from './components/SettingsSupportDetails/SettingsSupportDetails';
import ToggleWidgetModal from 'features/settings/components/GeneralSettings/ManageWidgets/components/WidgetSettings/components/WidgetSettingsWrapper/components/ToggleWidgetModal/ToggleWidgetModal';
import SupportDetailsModal from './components/SupportDetailsModal/SupportDetailsModal';
import { DiscountNinjaAppModal } from './components/DiscountNinjaAppModal/DiscountNinjaAppModal';
import { ConfigureWidgetsStyleModal } from './components/ConfigureWidgetsStyleModal/ConfigureWidgetsStyleModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';
import { WidgetsControlCenterModalsEnum } from './enums/WidgetsControlCenterModalsType';
import { updateFirstLetter } from 'core/utils/updateFirstLetter';
import { NinjaCartSection } from './components/NinjaCartSection/NinjaCartSection';
import { isEqual } from 'lodash';

export type SuccessSupportRequestModalState =
  | (CreateSupportRequestResponseDto & {
      priority?: InstallationSupportRequestPriorityDto | null;
      highPriorityPrice?: number;
    })
  | null;

type WidgetsControlCenterProps = {
  exploreAllWidgets: boolean;
  pageType: WidgetsControlCenterModalsEnum;
  setExploreAllWidgets: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WidgetsControlCenter: React.FC<WidgetsControlCenterProps> = ({
  exploreAllWidgets,
  pageType,
  setExploreAllWidgets,
}) => {
  const [i18n] = useI18n();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    themeSlotsData,
    themeSlotsDataIsFetching,
    themeSlotsBreakCacheData,
    saveThemeSlotsIsLoading,
    widgetInstallationData,
    widgetInstallationDataIsFetching,
    ninjaCartWidgetInstallationData,
    ninjaCartWidgetInstallationDataIsFetching,
    refetchThemeSlotsBreakCacheData,
    fetchWidgetInstallationNoCacheData,
    fetchNinjaCartWidgetInstallationNoCacheData,
    refetchNinjaCartWidgetInstallationDataBase,
    refetchWidgetInstallationDataBase,
    refetchThemeSlotsData,
    saveThemeSlots,
  } = useWidgetsControlCenter(pageType);

  // Maybe we should transfer widgetThemeType to localhost
  const { widgetThemeType } = useAppSelector((store) => store.settings);

  const [, setSearchParams] = useSearchParams();

  const [themeSlots, setThemeSlots] = useState<ThemeStatusesDto[]>([]);
  const [actionWidget, setActionWidget] = useState<
    | ((WidgetInstallationDto | NinjaCartWidgetInstallationDto) & {
        isAppBlockRequestSupport?: boolean;
      })
    | null
  >(null);

  const [forceLoading, setForceLoading] = useState<boolean>(false);
  const [forceEnableAppOnCurrentTheme, setForceEnableAppOnCurrentTheme] =
    useState<boolean>(false);
  const [isThemesModalOpen, setIsThemesModalOpen] = useState<boolean>(false);
  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [successModal, setSuccessModal] =
    useState<SuccessSupportRequestModalState>(null);
  const [isSupportDetailsOpen, setIsSupportDetailsOpen] =
    useState<boolean>(false);
  const [enableAppModal, setEnableAppModal] = useState<boolean>(false);
  const [configureStyleModal, setConfigureStyleModal] = useState<
    WidgetTypeDto | 'GENERAL'
  >(null);
  const [isWidgetsListUpdating, setIsWidgetsListUpdating] =
    useState<boolean>(false);
  const [templatesUpdated, setTemplatesUpdated] = useState<boolean>(false);

  const [dismissedComponents, setDismissedComponents] = useState<string[]>(
    getDismissedComponents(DismissedEnum.WIDGETS_PAGE_DISMISSED_COMPONENTS)
  );

  const isWidgetControlCenterPage = useMemo(
    () =>
      pageType ===
      WidgetsControlCenterModalsEnum.WIDGETS_CONTROL_CENTER_MAIN_PAGE,
    [pageType]
  );

  const installationState = useMemo(() => {
    return {
      currentInstallationData: !refreshing
        ? isWidgetControlCenterPage
          ? widgetInstallationData
          : ninjaCartWidgetInstallationData
        : null,
      isFetching: isWidgetControlCenterPage
        ? widgetInstallationDataIsFetching
        : ninjaCartWidgetInstallationDataIsFetching,
      refetch: isWidgetControlCenterPage
        ? (noCache?: boolean) =>
            noCache
              ? fetchWidgetInstallationNoCacheData()
              : refetchWidgetInstallationDataBase()
        : (noCache?: boolean) =>
            noCache
              ? fetchNinjaCartWidgetInstallationNoCacheData()
              : refetchNinjaCartWidgetInstallationDataBase(),
    };
  }, [
    refreshing,
    isWidgetControlCenterPage,
    widgetInstallationData,
    widgetInstallationDataIsFetching,
    ninjaCartWidgetInstallationData,
    ninjaCartWidgetInstallationDataIsFetching,
    fetchWidgetInstallationNoCacheData,
    fetchNinjaCartWidgetInstallationNoCacheData,
    refetchWidgetInstallationDataBase,
    refetchNinjaCartWidgetInstallationDataBase,
  ]);

  const { currentInstallationData, isFetching, refetch } = installationState;

  const isAppEnabledOnCurrentTheme = useMemo(
    () =>
      themeSlots?.find((slot) => slot.type === ThemeTypeDtoEnum.CURRENT)
        ?.isAppEngineEnabled,
    [themeSlots]
  );

  const shouldSelectCurrentTheme = useMemo(() => {
    if (widgetThemeType !== ThemeTypeDtoEnum.TEST) {
      return false;
    }

    const testThemeData = themeSlots?.find(
      ({ type }) => type === ThemeTypeDtoEnum.TEST
    );

    const isInvalidTestTheme =
      !testThemeData?.themeName?.trim() ||
      testThemeData.themeId == null ||
      testThemeData.isDeleted;

    return isInvalidTestTheme;
  }, [widgetThemeType, themeSlots]);

  const isAppEnabledOnWidgetThemeType = useMemo(
    () =>
      themeSlots?.find((slot) => slot.type === widgetThemeType)
        ?.isAppEngineEnabled,
    [themeSlots, widgetThemeType]
  );

  const currentThemeName = useMemo(
    () =>
      themeSlots?.find((slot) => slot.type === widgetThemeType)?.themeName ||
      '',
    [themeSlots, widgetThemeType]
  );

  const slotsOptions = useMemo(
    () =>
      themeSlots?.map((slot) => ({
        value: slot.type,
        label: (
          <BlockStack>
            <Text variant='headingSm' fontWeight='regular' as='p'>
              {i18n.translate(`${slot.type}_THEME`)}
            </Text>
            <Text
              variant='headingXs'
              fontWeight='regular'
              tone='subdued'
              as='p'
              truncate
            >
              {slot.themeName?.trim() && slot.themeId != null && !slot.isDeleted
                ? slot.themeName?.slice(0, 24)
                : i18n.translate('NotSet')}
            </Text>
          </BlockStack>
        ),
        disabled:
          !slot.themeName?.trim() || slot.themeId == null || slot.isDeleted,
      })) as OptionDescriptor[],
    [themeSlots, i18n]
  );

  const recommendedWidgets = useMemo(() => {
    const widgets =
      (currentInstallationData?.widgets as WidgetInstallationDto[]) ?? [];
    const recommended = currentInstallationData?.recommendedWidgets ?? [];

    return widgets.filter(
      (widget: WidgetInstallationDto) =>
        widget.type && recommended.includes(widget.type)
    );
  }, [
    currentInstallationData?.widgets,
    currentInstallationData?.recommendedWidgets,
  ]);

  const itemsArray = useCallback(
    (number: number) => Array.from({ length: number }, (_, index) => index + 1),
    []
  );

  const PageContentSkeleton = useMemo(
    () => (
      <>
        {itemsArray(2).map((item) => (
          <Card key={item}>
            <BlockStack gap='400'>
              <SkeletonDisplayText size='small' />
              <SkeletonBodyText lines={7} />
            </BlockStack>
          </Card>
        ))}
        <div
          style={{
            height: 'fit-content',
          }}
        >
          <Card>
            <BlockStack gap='400'>
              <SkeletonThumbnail size='large' />
              <SkeletonBodyText lines={6} />
            </BlockStack>
          </Card>
        </div>
      </>
    ),
    []
  );

  const isLoading = useMemo(
    () =>
      isFetching ||
      themeSlotsDataIsFetching ||
      isWidgetsListUpdating ||
      forceLoading,
    [isFetching, themeSlotsDataIsFetching, isWidgetsListUpdating, forceLoading]
  );

  const handleExploreAllWidgets = useCallback(() => {
    setExploreAllWidgets((prev) => !prev);
    setForceLoading(true);
  }, []);

  const handleEnableAppModal = useCallback((enableCurrent?: boolean) => {
    setForceEnableAppOnCurrentTheme(!!enableCurrent);
    setEnableAppModal((prev) => !prev);
  }, []);

  const isComponentDismissed = useCallback(
    (componentId?: string | null) => {
      if (!componentId) return false;
      return dismissedComponents.includes(componentId);
    },
    [dismissedComponents]
  );

  const handleRefresh = useCallback(
    async (noCache?: boolean) => {
      setRefreshing(true);
      try {
        await refetchThemeSlotsData();
        await refetch(noCache);
      } finally {
        refetchThemeSlotsBreakCacheData();
        setRefreshing(false);
      }
    },
    [refetchThemeSlotsData, refetch, refetchThemeSlotsBreakCacheData]
  );

  const handleDismissComponent = useCallback((componentId: string) => {
    dismissComponent(
      DismissedEnum.WIDGETS_PAGE_DISMISSED_COMPONENTS,
      componentId
    );
    setDismissedComponents((prev) => [...prev, componentId]);
  }, []);

  const updateStates = useCallback((data: ThemeStatusesDto[]) => {
    setThemeSlots(data);
    setIsThemesModalOpen(false);
  }, []);

  const handleSaveThemeSlots = useCallback(
    (data: ThemeStatusesDto[]) => {
      saveThemeSlots({ slots: data }, updateStates);
    },
    [saveThemeSlots]
  );

  const togglePopoverActive = useCallback(
    () => setPopoverActive((prev) => !prev),
    []
  );

  const toggleThemesModal = useCallback(() => {
    if (!refreshing) {
      setIsThemesModalOpen((prev) => !prev);
      setPopoverActive(false);
    }
  }, [refreshing]);

  const toggleSupportModal = useCallback(
    () => setIsSupportDetailsOpen((prev) => !prev),
    [setIsSupportDetailsOpen]
  );

  const handleActionWidgetClose = useCallback(() => {
    setActionWidget(null);
  }, []);

  const handleSuccessPrimaryAction = useCallback(() => {
    if (
      successModal?.priority ===
        InstallationSupportRequestPriorityDtoEnum.NORMAL ||
      !successModal?.paymentUrl
    ) {
      setSuccessModal(null);
    } else {
      navigate('/shopifyRedirect?redirectUri=' + successModal.paymentUrl);
    }
  }, [successModal]);

  const handleSuccessSecondaryAction = useCallback(() => {
    if (
      successModal?.priority === InstallationSupportRequestPriorityDtoEnum.HIGH
    ) {
      setSuccessModal(null);
    } else {
      setIsSupportDetailsOpen(true);
      setSuccessModal(null);
    }
  }, [successModal]);

  const redirectManageStylePresets = useCallback(() => {
    setSearchParams((params) => {
      params.set('modal', NavPaths.SettingsModal);
      params.set('path', SettingsModalPathsEnums.Style);
      return params;
    });
  }, []);

  useEffect(() => {
    if (themeSlotsData) {
      setThemeSlots(themeSlotsData);
    }
  }, [themeSlotsData]);

  useEffect(() => {
    if (
      themeSlotsBreakCacheData &&
      !isEqual(themeSlotsBreakCacheData, themeSlotsData)
    ) {
      setThemeSlots(themeSlotsBreakCacheData);
      refetch();
    }
  }, [themeSlotsBreakCacheData]);

  useEffect(() => {
    if (templatesUpdated && !actionWidget) {
      refetch(true);
      setTemplatesUpdated(false);
    }
  }, [templatesUpdated, actionWidget]);

  useEffect(() => {
    shouldSelectCurrentTheme &&
      dispatch(setWidgetThemeType(ThemeTypeDtoEnum.CURRENT));
  }, [shouldSelectCurrentTheme]);

  // Cleanup for forceLoading
  useEffect(() => {
    if (forceLoading) {
      const timeout = setTimeout(() => {
        setForceLoading(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [forceLoading]);

  return (
    <Box width='100%' paddingBlockEnd='2000'>
      {!exploreAllWidgets ? (
        !themeSlotsDataIsFetching && currentInstallationData ? (
          <Page
            title={i18n.translate('PageTitle', {
              title: isWidgetControlCenterPage
                ? i18n.translate('Widgets')
                : i18n.translate('NinjaCart'),
              theme: i18n.translate(`${widgetThemeType}_THEME`),
            })}
            subtitle={
              i18n.translate('PageSubtitle', {
                theme: i18n.translate(`${widgetThemeType}_THEME`),
                name: currentThemeName,
                link: (
                  <Link monochrome onClick={toggleThemesModal}>
                    {i18n.translate('Link')}
                  </Link>
                ),
              }) as any
            }
            titleMetadata={
              <Badge
                tone={
                  currentInstallationData?.promotionEngine?.enabled
                    ? 'success'
                    : undefined
                }
              >
                {i18n.translate(
                  `${
                    currentInstallationData?.promotionEngine?.enabled
                      ? 'Enabled'
                      : 'Disabled'
                  }`
                )}
              </Badge>
            }
            actionGroups={
              currentInstallationData?.promotionEngine?.enabled
                ? [
                    {
                      title: i18n.translate('MoreActions'),
                      actions: [
                        {
                          content: i18n.translate('TestWidgets'),
                          prefix: <Icon source={PlayIcon} />,
                        },
                        {
                          content: i18n.translate('DisableApp'),
                          onAction: () =>
                            currentInstallationData.promotionEngine
                              ?.installationDeepLink &&
                            window.open(
                              currentInstallationData.promotionEngine
                                .installationDeepLink,
                              '_blank'
                            ),
                          destructive: true,
                          prefix: (
                            <Icon source={StopCircleIcon} tone='critical' />
                          ),
                        },
                      ],
                    },
                  ]
                : undefined
            }
            primaryAction={
              <Popover
                active={popoverActive}
                activator={
                  <Button
                    onClick={togglePopoverActive}
                    disclosure
                    disabled={refreshing}
                  >
                    {i18n.translate(`${widgetThemeType}_THEME`)}
                  </Button>
                }
                onClose={togglePopoverActive}
              >
                <div style={{ width: '200px' }}>
                  <OptionList
                    onChange={(value) => {
                      setForceLoading(true);
                      dispatch(
                        setWidgetThemeType(value[0] as ThemeTypeDtoEnum)
                      );
                    }}
                    verticalAlign='center'
                    options={slotsOptions}
                    selected={[widgetThemeType]}
                  />
                  <Box paddingInline='300' paddingBlockEnd='150'>
                    <Button onClick={toggleThemesModal} variant='plain'>
                      {i18n.translate('Link')}
                    </Button>
                  </Box>
                </div>
              </Popover>
            }
            secondaryActions={[
              {
                content: i18n.translate('Refresh'),
                onAction: () => handleRefresh(true),
                loading: refreshing,
              },
            ]}
          >
            <div className='widgetsControlCenterContent'>
              <BlockStack gap='400'>
                {pageType ===
                  WidgetsControlCenterModalsEnum.WIDGETS_CONTROL_CENTER_MAIN_PAGE &&
                  !isComponentDismissed('warningBanner') &&
                  !isAppEnabledOnCurrentTheme &&
                  widgetThemeType === ThemeTypeDtoEnum.TEST &&
                  !isLoading && (
                    <Banner
                      title={i18n.translate('WarningBannerTitle')}
                      tone='warning'
                      action={{
                        content: i18n.translate('WarningBannerAction'),
                        onAction: () => handleEnableAppModal(true),
                      }}
                      onDismiss={() => handleDismissComponent('warningBanner')}
                    >
                      <p>{i18n.translate('WarningBannerContent')}</p>
                    </Banner>
                  )}
                {pageType ===
                  WidgetsControlCenterModalsEnum.NINJA_CART_CONTROL_CENTER_MAIN_PAGE &&
                  !isComponentDismissed(
                    `${widgetThemeType}_NinjaCartWarningBanner`
                  ) &&
                  !isAppEnabledOnWidgetThemeType &&
                  !isLoading && (
                    <Banner
                      title={i18n.translate('NinjaCarWarningBannerTitle', {
                        theme: updateFirstLetter(
                          i18n.translate(`${widgetThemeType}_THEME`),
                          false
                        ),
                      })}
                      tone='warning'
                      action={{
                        content: i18n.translate('WarningBannerAction'),
                        onAction: handleEnableAppModal,
                      }}
                      onDismiss={() =>
                        handleDismissComponent(
                          `${widgetThemeType}_NinjaCartWarningBanner`
                        )
                      }
                    >
                      <p>
                        {i18n.translate('NinjaCartWarningBannerContent', {
                          theme: updateFirstLetter(
                            i18n.translate(`${widgetThemeType}_THEME`),
                            false
                          ),
                        })}
                      </p>
                    </Banner>
                  )}
                {currentInstallationData?.supportRequest?.status ===
                  InstallationSupportRequestGeneralStatusDtoEnum.SUBMITTED && (
                  <SettingsSupportDetails
                    submissionDate={
                      currentInstallationData.supportRequest.submissionDate
                    }
                    refetchWidgetInstallation={refetch}
                  />
                )}
                {!isWidgetControlCenterPage && (
                  <NinjaCartSection
                    isAppEnabled={
                      !!currentInstallationData?.promotionEngine?.enabled
                    }
                    isLoading={isLoading}
                    themeName={currentThemeName}
                    ninjaCartDrawerCart={
                      (
                        currentInstallationData as NinjaCartWidgetsInstallationStatusSettingsDto
                      )?.ninjaCartDrawerCart
                    }
                    ninjaCartCartPage={
                      (
                        currentInstallationData as NinjaCartWidgetsInstallationStatusSettingsDto
                      )?.ninjaCartCartPage
                    }
                    refetchWidgetInstallationData={refetch}
                  />
                )}
                <MyWidgets
                  isEmpty={!currentInstallationData}
                  isEnabled={
                    !!currentInstallationData?.promotionEngine?.enabled
                  }
                  themeName={currentThemeName}
                  isWidgetControlCenterPage={isWidgetControlCenterPage}
                  isLoading={isLoading}
                  widgets={currentInstallationData?.widgets || []}
                  supportRequest={currentInstallationData?.supportRequest}
                  handleExploreAllWidgets={handleExploreAllWidgets}
                  handleEnableAppModal={handleEnableAppModal}
                  setActionWidget={setActionWidget}
                  refetchWidgetInstallationData={refetch}
                  setConfigureStyleModal={setConfigureStyleModal}
                  setIsWidgetsListUpdating={setIsWidgetsListUpdating}
                />
                <RecommendedWidgets
                  isEnabled={
                    !!currentInstallationData?.promotionEngine?.enabled
                  }
                  isWidgetControlCenterPage={isWidgetControlCenterPage}
                  isLoading={isLoading}
                  widgets={recommendedWidgets}
                  supportRequest={currentInstallationData?.supportRequest}
                  handleExploreAllWidgets={handleExploreAllWidgets}
                  setActionWidget={setActionWidget}
                  refetchWidgetInstallationData={refetch}
                  setConfigureStyleModal={setConfigureStyleModal}
                  setIsWidgetsListUpdating={setIsWidgetsListUpdating}
                />
                <div
                  style={{
                    height: 'fit-content',
                  }}
                >
                  <VideoCard
                    cardData={{
                      title: isWidgetControlCenterPage
                        ? i18n.translate('CardDataTitle')
                        : i18n.translate('NinjaCartCardDataTitle'),
                      description: i18n.translate('CardDataDescription'),
                      btn: i18n.translate('CardDataBtn'),
                      link: 'http://www.google.com',
                      videoUrl: '',
                    }}
                  />
                </div>
                <MediaCard
                  title={
                    isWidgetControlCenterPage
                      ? i18n.translate('ExploreWidgetStyles')
                      : i18n.translate('ExploreNinjaCartStyles')
                  }
                  primaryAction={{
                    content: i18n.translate('ExploreWidgetBtn'),
                    onAction: redirectManageStylePresets,
                  }}
                  portrait={false}
                  description={i18n.translate('ExploreWidgetContent')}
                  size='small'
                >
                  <div className='placeholder separate'>
                    <Icon source={ImagesIcon} tone='subdued' />
                  </div>
                </MediaCard>
              </BlockStack>
            </div>

            {isThemesModalOpen && (
              <ManageThemesModal
                themeSlotsData={themeSlots || []}
                themeSlotsDataIsFetching={themeSlotsDataIsFetching}
                isOpen={isThemesModalOpen}
                isSaving={saveThemeSlotsIsLoading}
                onClose={toggleThemesModal}
                onSave={(data) => handleSaveThemeSlots(data)}
              />
            )}
          </Page>
        ) : (
          <SkeletonPage title={i18n.translate('Loading')} primaryAction>
            <div className='widgetsControlCenterContent'>
              <BlockStack gap='400'>
                <MyWidgets
                  isEmpty={!currentInstallationData}
                  isEnabled={
                    !!currentInstallationData?.promotionEngine?.enabled
                  }
                  themeName={currentThemeName}
                  isWidgetControlCenterPage={isWidgetControlCenterPage}
                  isLoading={isLoading}
                  widgets={currentInstallationData?.widgets || []}
                  supportRequest={currentInstallationData?.supportRequest}
                  handleExploreAllWidgets={handleExploreAllWidgets}
                  handleEnableAppModal={handleEnableAppModal}
                  setActionWidget={setActionWidget}
                  refetchWidgetInstallationData={refetch}
                  setConfigureStyleModal={setConfigureStyleModal}
                  setIsWidgetsListUpdating={setIsWidgetsListUpdating}
                />
                {PageContentSkeleton}
              </BlockStack>
            </div>
          </SkeletonPage>
        )
      ) : (
        <ExploreAllWidgets
          isEnabled={!!currentInstallationData?.promotionEngine?.enabled}
          isWidgetControlCenterPage={isWidgetControlCenterPage}
          widgets={currentInstallationData?.widgets || []}
          themeName={currentThemeName}
          supportRequest={currentInstallationData?.supportRequest}
          isLoading={isLoading}
          refetchWidgetInstallationData={refetch}
          setActionWidget={setActionWidget}
          handleEnableAppModal={handleEnableAppModal}
          handleExploreAllWidgets={handleExploreAllWidgets}
          setConfigureStyleModal={setConfigureStyleModal}
          setIsWidgetsListUpdating={setIsWidgetsListUpdating}
        />
      )}

      {actionWidget && (
        <ToggleWidgetModal
          isOpen={!!actionWidget}
          widgetType={actionWidget.type as WidgetTypeDtoEnum}
          installationMode={
            actionWidget.installationMode as WidgetInstallationModeDtoEnum
          }
          isAppBlockRequestSupport={actionWidget.isAppBlockRequestSupport}
          installationLink={
            ('installationDeepLink' in actionWidget
              ? actionWidget.installationDeepLink
              : '') || ''
          }
          currentThemeName={currentThemeName}
          onClose={handleActionWidgetClose}
          refetchWidget={refetch}
          setTemplatesUpdated={setTemplatesUpdated}
          setSuccessModal={setSuccessModal}
          setIsWidgetsListUpdating={setIsWidgetsListUpdating}
        />
      )}
      {!!successModal && (
        <Modal
          open={!!successModal}
          onClose={() => setSuccessModal(null)}
          title={i18n.translate('SuccessTitle')}
          primaryAction={{
            content:
              successModal?.priority ===
              InstallationSupportRequestPriorityDtoEnum.NORMAL
                ? i18n.translate('Close')
                : i18n.translate('ProceedToPayment'),
            onAction: handleSuccessPrimaryAction,
          }}
          secondaryActions={[
            {
              content:
                successModal?.priority ===
                InstallationSupportRequestPriorityDtoEnum.HIGH
                  ? i18n.translate('Close')
                  : i18n.translate('ShowRequestDetails'),
              onAction: handleSuccessSecondaryAction,
            },
          ]}
        >
          <Modal.Section>
            {i18n.translate(`${successModal?.priority}_CONTENT`, {
              warning: (
                <Text as='span' fontWeight='medium'>
                  {i18n.translate(`${successModal?.priority}_CONTENT_WARNING`, {
                    charge: successModal.highPriorityPrice,
                  })}
                </Text>
              ),
            })}
          </Modal.Section>
        </Modal>
      )}

      {isSupportDetailsOpen && (
        <SupportDetailsModal
          isOpen={isSupportDetailsOpen}
          onRefetch={refetch}
          onClose={toggleSupportModal}
        />
      )}

      {enableAppModal && (
        <DiscountNinjaAppModal
          open={enableAppModal}
          forceEnableAppOnCurrentTheme={forceEnableAppOnCurrentTheme}
          onClose={handleEnableAppModal}
          handleRefresh={handleRefresh}
        />
      )}

      {configureStyleModal && (
        <ConfigureWidgetsStyleModal
          type={configureStyleModal}
          onClose={() => setConfigureStyleModal(null)}
          redirectManageStylePresets={redirectManageStylePresets}
        />
      )}
    </Box>
  );
};
