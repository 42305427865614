import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Icon,
  InlineStack,
  Modal,
  Text,
  TextField,
} from '@shopify/polaris';
import {
  DisabledIcon,
  DeleteIcon,
  AlertTriangleIcon,
  CollectionIcon,
} from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoListString } from 'core/api/adminPromotions/adminPromotionsApi';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';
import { PickerExclusionsType } from '../../ExclusionsBox';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { useAppSelector } from 'core/hooks';
import { CustomSekeletonBox } from 'core/components/ControlLibrary';
import './ExclusionsPhrasePicker.scss';

type ExclusionsPhrasePickerProps = {
  type: keyof PickerExclusionsType;
  label: string;
  phrases: OptionDtoListString;
  isModalOpen: boolean;
  toggleModal: () => void;
  setPhrases: (data: OptionDtoListString) => void;
  onSearchFieldValidityChange?(isFormInvalid: boolean | null): void;
};

export const ExclusionsPhrasePicker: React.FC<ExclusionsPhrasePickerProps> = ({
  type,
  label,
  phrases,
  isModalOpen,
  setPhrases,
  toggleModal,
  onSearchFieldValidityChange,
}) => {
  const [i18n] = useI18n();
  const isVendors = type === 'vendors';
  const isProdutTags = type === 'tags';

  const {
    productTagsData,
    productVendorsData,
    productTagsIsFetching,
    productVendorsIsFetching,
  } = useConfigurePromotions(
    isVendors
      ? PromotionFetchTypeEnum.PRODUCT_VENDORS
      : isProdutTags
      ? PromotionFetchTypeEnum.PRODUCT_TAGS
      : undefined
  );

  const { showValidation } = useAppSelector((store) => store.promotions);

  const [modalPhrases, setModalPhrases] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const formIsInvalid = useMemo(
    () =>
      !phrases?.value?.length &&
      !productTagsIsFetching &&
      !productVendorsIsFetching,
    [phrases?.value, productTagsIsFetching, productVendorsIsFetching]
  );

  const showValidationError = useMemo(
    () => showValidation && formIsInvalid,
    [showValidation, formIsInvalid]
  );

  const vendorSuggestions = useMemo(
    () => productVendorsData?.map((item) => item.vendor || ''),
    [productVendorsData]
  );
  const productTagSuggestions = useMemo(
    () => productTagsData?.map((item) => item.tag || ''),
    [productTagsData]
  );

  const currentSuggestions = isVendors
    ? vendorSuggestions
    : isProdutTags
    ? productTagSuggestions
    : [];

  const isLoading = useMemo(() => {
    if (isVendors) {
      return !productVendorsData || productVendorsIsFetching;
    }
    if (isProdutTags) {
      return !productTagsData || productTagsIsFetching;
    }
    return false;
  }, [
    productVendorsData,
    productVendorsIsFetching,
    productTagsData,
    productTagsIsFetching,
  ]);

  const handleModalPrimary = useCallback(() => {
    setPhrases({ value: modalPhrases, enabled: true });
    toggleModal();
  }, [modalPhrases, setPhrases]);

  const handleSuggestions = useCallback(
    (suggestion: string) => {
      setModalPhrases((prev) =>
        prev.includes(suggestion)
          ? prev.filter((phrase) => phrase !== suggestion)
          : [...prev, suggestion]
      );
    },
    [setModalPhrases]
  );

  useEffect(() => {
    phrases?.value && setModalPhrases(phrases.value);
  }, [phrases?.value, isModalOpen]);

  useEffect(() => {
    onSearchFieldValidityChange?.(formIsInvalid);
    return () => {
      // change in future
      onSearchFieldValidityChange?.(null);
    };
  }, [formIsInvalid, onSearchFieldValidityChange]);

  return (
    <>
      {!!phrases?.enabled && (
        <InlineStack blockAlign='start' gap='200' wrap={false}>
          <Box
            width='100%'
            padding='400'
            borderColor='border'
            background={
              showValidationError ? 'bg-surface-critical' : 'bg-surface'
            }
            borderWidth={showValidationError ? '0' : '025'}
            borderRadius='200'
          >
            <InlineStack
              align='space-between'
              blockAlign='center'
              gap='200'
              wrap={false}
            >
              <InlineStack gap='200' blockAlign='center' wrap={false}>
                {isLoading ? (
                  <CustomSekeletonBox height='8px' width='300px' />
                ) : (
                  <>
                    <Box>
                      <Icon
                        tone={
                          showValidationError
                            ? 'textCritical'
                            : !phrases?.value?.length
                            ? 'textCaution'
                            : 'base'
                        }
                        source={
                          onSearchFieldValidityChange && !phrases?.value?.length
                            ? AlertTriangleIcon
                            : onSearchFieldValidityChange &&
                              phrases?.value?.length
                            ? CollectionIcon
                            : DisabledIcon
                        }
                      />
                    </Box>
                    {!phrases?.value?.length ? (
                      <Text
                        as='p'
                        tone={showValidation ? 'critical' : 'caution'}
                      >
                        {label}:{' '}
                        <Text as='span' fontWeight='medium'>
                          {i18n.translate(`${type}Empty`)}
                        </Text>
                      </Text>
                    ) : (
                      <Text as='p'>
                        {label}:{' '}
                        <Text as='span' fontWeight='bold'>
                          {phrases?.value?.join(', ')}
                        </Text>
                      </Text>
                    )}
                  </>
                )}
              </InlineStack>
              <Button
                variant='plain'
                onClick={toggleModal}
                disabled={isLoading}
              >
                {i18n.translate(
                  onSearchFieldValidityChange ? 'Select' : 'Edit'
                )}
              </Button>
            </InlineStack>
          </Box>
          {!onSearchFieldValidityChange && (
            <Box paddingBlockStart='400'>
              <Button
                variant='tertiary'
                icon={DeleteIcon}
                onClick={() => setPhrases({ ...phrases, enabled: false })}
              />
            </Box>
          )}
        </InlineStack>
      )}
      <Modal
        open={isModalOpen}
        title={label}
        footer={
          <Text as='p'>
            {i18n.translate(
              isVendors
                ? 'VendorsSelected'
                : isProdutTags
                ? 'TagsSelected'
                : 'PhrasesSelected',
              {
                count: modalPhrases.length,
              }
            )}
          </Text>
        }
        onClose={toggleModal}
        primaryAction={{
          content: i18n.translate('Done'),
          disabled: !modalPhrases.length,
          onAction: handleModalPrimary,
        }}
        secondaryActions={[
          {
            content: i18n.translate('Cancel'),
            onAction: toggleModal,
          },
        ]}
      >
        {isVendors || isProdutTags ? (
          <>
            <Box padding='400'>
              <TextField
                autoComplete=''
                label=''
                placeholder={i18n.translate(
                  isVendors ? 'SearchVendors' : 'SearchTags'
                )}
                loading={isLoading}
                value={searchValue}
                onChange={setSearchValue}
              />
            </Box>
            <div className='ExclusionsTagSelectBox'>
              {currentSuggestions
                ?.filter((suggestion) =>
                  searchValue
                    ? suggestion
                        .toLowerCase()
                        .includes(searchValue.toLocaleLowerCase())
                    : true
                )
                ?.map((suggestion, index) => (
                  <Box
                    key={suggestion}
                    borderBlockStartWidth='025'
                    borderBlockEndWidth={
                      index === currentSuggestions.length - 1 ? '025' : '0'
                    }
                    borderColor='border'
                    paddingInline='400'
                    paddingBlock='200'
                  >
                    <Checkbox
                      checked={modalPhrases.includes(suggestion)}
                      label={suggestion}
                      onChange={() => handleSuggestions(suggestion)}
                    />
                  </Box>
                ))}
            </div>
          </>
        ) : (
          <Modal.Section>
            <MultiselectTagCombobox
              id={label}
              requiredIndicator
              label={''}
              isLoading={isLoading}
              placeholder={i18n.translate('TypeThePhrase')}
              labelHidden={false}
              selectedTags={modalPhrases || []}
              setSelectedTags={(tags: string[]) => setModalPhrases(tags)}
            />
          </Modal.Section>
        )}
      </Modal>
    </>
  );
};
