import React, { useEffect, useState } from 'react';
import {
  Banner,
  BlockStack,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  ResourceList,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  InstallationSupportRequestSummaryDto,
  NinjaCartWidgetInstallationDto,
  WidgetInstallationDto,
  WidgetTypeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { WidgetCard } from '../WidgetCard/WidgetCard';
import { InstallationSupportRequestGeneralStatusDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { useToggleWidget } from 'features/widgets/hooks/useToggleWidget';
import { useAppSelector } from 'core/hooks';

type WidgetsCarouselProps = {
  isWidgetControlCenterPage: boolean;
  isEnabled: boolean;
  widgets?: WidgetInstallationDto[];
  supportRequest?: InstallationSupportRequestSummaryDto;
  isLoading: boolean;
  setActionWidget: React.Dispatch<
    React.SetStateAction<
      WidgetInstallationDto | NinjaCartWidgetInstallationDto | null
    >
  >;
  handleExploreAllWidgets: () => void;
  refetchWidgetInstallationData: () => void;
  setConfigureStyleModal: React.Dispatch<
    React.SetStateAction<WidgetTypeDto | 'GENERAL'>
  >;
  setIsWidgetsListUpdating: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RecommendedWidgets: React.FC<WidgetsCarouselProps> = ({
  isWidgetControlCenterPage,
  isLoading,
  isEnabled,
  widgets = [],
  supportRequest,
  handleExploreAllWidgets,
  setActionWidget,
  refetchWidgetInstallationData,
  setConfigureStyleModal,
  setIsWidgetsListUpdating,
}) => {
  const [i18n] = useI18n();
  const { widgetThemeType } = useAppSelector((store) => store.settings);
  const { toggleWidget, loading } = useToggleWidget({
    widgetThemeType,
    isWidgetControlCenterPage,
    setActionWidget,
    refetchWidgetInstallationData,
  });

  const [hideBanner, setHideBanner] = useState<boolean>(false);

  useEffect(() => {
    setIsWidgetsListUpdating(loading);
  }, [loading, setIsWidgetsListUpdating]);

  if (!isEnabled) return null;

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='center'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate(
              `Title${isWidgetControlCenterPage ? '' : '_NinjaCart'}`
            )}
          </Text>
          <Button variant='plain' onClick={handleExploreAllWidgets}>
            {i18n.translate('ExploreAllWidgets')}
          </Button>
        </InlineStack>

        {!!widgets.length && !hideBanner && (
          <Banner tone='info' onDismiss={() => setHideBanner(true)}>
            {i18n.translate('BannerInfo')}
          </Banner>
        )}

        {!widgets.length ? (
          <Banner tone='success'>{i18n.translate('BannerSuccess')}</Banner>
        ) : (
          <ResourceList
            loading={isLoading}
            items={Object.entries({ '1': widgets.slice(0, 3) })}
            renderItem={([group, widgets]) => {
              return (
                <InlineGrid key={group} gap='400' columns={3}>
                  {widgets?.map((widget) => (
                    <WidgetCard
                      key={widget.type}
                      isEnabled={isEnabled}
                      widget={widget}
                      isSupportRequested={
                        !!(
                          supportRequest?.status ===
                            InstallationSupportRequestGeneralStatusDtoEnum.SUBMITTED &&
                          supportRequest.selectedWidgets?.includes(widget.type!)
                        )
                      }
                      toggleWidget={toggleWidget}
                      setConfigureStyleModal={setConfigureStyleModal}
                    />
                  ))}
                </InlineGrid>
              );
            }}
          />
        )}
      </BlockStack>
    </Card>
  );
};
